import React from 'react';
import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import Accordination from '../../components/Accordination/Accordination';
import { TableBuilder } from '../../components/TableElements';
import APIConfig from '../../constants/api.config';

const StyledBodyContainer = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  margin: 20px;
  border-radius: 5px;
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background-color: #e5e5e5;
  padding: 15px;
  margin: 0px;
`;

const StyledContent = styled.section`
  padding: 20px;
  @media (max-width: 600px) {
    word-break: break-all;
    overflow-x: scroll;
  }
`;

const APIDocumentation = (props) => {
  const { API_BASE_URL } = APIConfig;
  return (
    <>
      <PageHeading {...props} />
      <Accordination header='Recharge API' autoExpanded={true}>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET / POST
            </p>
            <p>
              <b>URL :</b>{' '}
              <code>{`${API_BASE_URL}/public/recharge?apiid=[apiid]&apikey=[apikey]&number=[number]&operator=[operator]&amount=[amount]&field1=[field1]&field2=[field2]&field3=[field3]&field4=[field4]&refnumber=[refnumber]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: 'Field',
                  dataSelector: 'field',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Description',
                  dataSelector: 'description',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Mandatory/Optional',
                  dataSelector: 'fieldType',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Remark',
                  dataSelector: 'remark',
                  dataType: 'string',
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: 'apiid',
                  description: 'api id provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'apikey',
                  description: 'api key provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'number',
                  description: 'Mobile/Account Number to be recharged',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'operator',
                  description: 'Our Operator Code',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'amount',
                  description: 'Recharge Amount',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'refnumber',
                  description: 'Your Transaction Id',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'field1',
                  description: 'field1',
                  fieldType: 'Optional',
                  remark: 'Refer your commission Package',
                },
                {
                  field: 'field2',
                  description: 'field2',
                  fieldType: 'Optional',
                  remark: 'Refer your commission Package',
                },
                {
                  field: 'field3',
                  description: 'field3',
                  fieldType: 'Optional',
                  remark: 'Refer your commission Package',
                },
                {
                  field: 'field4',
                  description: 'field4',
                  fieldType: 'Optional',
                  remark: 'Refer your commission Package',
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id='json-pretty'
              data={JSON.stringify({
                status: 'Success/Failure/Pending',
                message: 'Information',
                transactionsid: 'Our Transactiond Id',
                number: 'Recharge Number',
                amount: 'Amount',
                commission: 'Recharge Commission',
                balance: 'Accounts Balance',
                operatorsid: 'Operator Id',
                refnumber: 'your transaction id',
              })}
            ></JSONPretty>
            <JSONPretty></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
      </Accordination>
      <Accordination header='Status Check API' autoExpanded={true}>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET / POST
            </p>
            <p>
              <b>URL :</b>{' '}
              {`${API_BASE_URL}/public/recharge-status?apiid=[apiid]&apikey=[apikey]&transactionsid=[transactionsid]&refnumber=[refnumber]`}
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: 'Field',
                  dataSelector: 'field',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Description',
                  dataSelector: 'description',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Mandatory/Optional',
                  dataSelector: 'fieldType',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Remark',
                  dataSelector: 'remark',
                  dataType: 'string',
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: 'apiid',
                  description: 'api id provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'apikey',
                  description: 'api key provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'transactionsid',
                  description: 'Our Transaction Id',
                  fieldType: 'Optional',
                  remark: 'You need to pass either transactionsid or refnumber',
                },
                {
                  field: 'refnumber',
                  description: 'Your reference number',
                  fieldType: 'Optional',
                  remark: 'You need to pass either transactionsid or refnumber',
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id='json-pretty'
              data={JSON.stringify({
                status: 'Success/Failure/Pending',
                message: 'Information',
                transactionsid: 'Our Transactiond Id',
                number: 'Recharge Number',
                amount: 'Amount',
                commission: 'Recharge Commission',
                operatorsid: 'Operator Id',
                refnumber: 'your transaction id',
              })}
            ></JSONPretty>
            <JSONPretty></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
      </Accordination>
      <Accordination header='Balance Check API' autoExpanded={true}>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET / POST
            </p>
            <p>
              <b>URL :</b>{' '}
              {`${API_BASE_URL}/public/balance-check?apiid=[apiid]&apikey=[apikey]`}
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: 'Field',
                  dataSelector: 'field',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Description',
                  dataSelector: 'description',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Mandatory/Optional',
                  dataSelector: 'fieldType',
                  dataType: 'string',
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: 'apiid',
                  description: 'api id provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'apikey',
                  description: 'api key provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id='json-pretty'
              data={JSON.stringify({
                status: 'Success/Failure',
                message: 'Information',
                balance: 'Your Balance',
              })}
            ></JSONPretty>
            <JSONPretty></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
      </Accordination>
      <Accordination header='Raise Complaint API' autoExpanded={true}>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET / POST
            </p>
            <p>
              <b>URL :</b>{' '}
              {`${API_BASE_URL}/public/raise-transaction-dispute?apiid=[apiid]&apikey=[apikey]&transactionsid=[transactionsid]`}
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: 'Field',
                  dataSelector: 'field',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Description',
                  dataSelector: 'description',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Mandatory/Optional',
                  dataSelector: 'fieldType',
                  dataType: 'string',
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: 'apiid',
                  description: 'api id provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'apikey',
                  description: 'api key provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'transactionsid',
                  description: 'Our Transaction Id',
                  fieldType: 'Mandatory',
                  remark: '',
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id='json-pretty'
              data={JSON.stringify({
                status: 'Success/Failure',
                message: 'Information',
              })}
            ></JSONPretty>
            <JSONPretty></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
      </Accordination>
    </>
  );
};

export default withAppHOC(APIDocumentation);
