import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  Textarea,
  TextInput,
} from '../../../components/FormElements';
import API from '../../../api';
import {
  API_RESPONSE_TYPES,
  MODULES,
  PERMISSION_TYPES,
} from '../../../constants';
import { camelCaseToString, authData } from '../../../utils';

const NotificationsCreate = (props) => {
  const { accountsPermissions } = authData.get();
  const hasPushNotifications =
    accountsPermissions[MODULES.PUSH_NOTIFICATIONS] &&
    accountsPermissions[MODULES.PUSH_NOTIFICATIONS].includes(
      PERMISSION_TYPES.CREATE
    )
      ? true
      : false;
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [roles, setRoles] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [accountsId, setAccountsId] = useState('');
  const [notificationType, setNotificationType] = useState(
    hasPushNotifications ? '' : 'Others'
  );
  const [sms, setSms] = useState(false);
  const [email, setEmail] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [telegram, setTelegram] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const resetForm = () => {
    setRolesId('');
    setAccountsId('');
    setNotificationType('');
    setSms(false);
    setEmail(false);
    setWhatsapp(false);
    setTelegram(false);
    setTitle('');
    setMessage('');
  };

  const readRoles = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/accounts-management/roles-and-permissions')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setRoles(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readAccounts = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/accounts-management/accounts')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setAccounts(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      rolesId: rolesId,
      accountsId: accountsId,
      notificationType: notificationType,
      sms: sms ? 'Yes' : 'No',
      email: email ? 'Yes' : 'No',
      whatsapp: whatsapp ? 'Yes' : 'No',
      telegram: telegram ? 'Yes' : 'No',
      title: title,
      message: message,
    };
    API.post('/configurations/notifications', formData);
    setIsLoading(false);
    setResponseStatus(API_RESPONSE_TYPES.SUCCESS);
    setResponseMessage('Your request is being processed');
    setTimeout(() => {
      setResponseStatus('');
      setResponseMessage('');
    }, 3000);
  };

  useEffect(() => {
    readRoles();
    readAccounts();
  }, [readRoles, readAccounts]);

  const rolesOptions = [{ title: '-- SELECT ROLE --', value: '' }];
  roles.forEach(({ id, roleName }) =>
    rolesOptions.push({
      title: camelCaseToString(roleName),
      value: id,
    })
  );

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .filter(({ accountsRolesId }) =>
      rolesId !== '' ? accountsRolesId === rolesId : true
    )
    .forEach(({ id, firstName, lastName, isEntity, entityName }) =>
      accountsOptions.push({
        title: isEntity === 'Yes' ? entityName : `${firstName} ${lastName}`,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Role</Label>
                <Dropdown
                  placeholder='Please select role'
                  value={rolesId}
                  onChange={setRolesId}
                  options={rolesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Account</Label>
                <Dropdown
                  placeholder='Please select account'
                  value={accountsId}
                  onChange={setAccountsId}
                  options={accountsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {hasPushNotifications && (
            <Fieldset>
              <Label required>Notification Type</Label>
              <Dropdown
                placeholder='Please select notification type'
                value={notificationType}
                onChange={setNotificationType}
                options={[
                  {
                    title: '-- SELECT NOTIFICATION TYPE --',
                    value: '',
                  },
                  {
                    title: 'Push Notification',
                    value: 'Push Notification',
                  },
                  {
                    title: 'Others',
                    value: 'Others',
                  },
                ]}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          {notificationType === 'Others' && (
            <Fieldset>
              <Label required>Notification Medium</Label>
              <Grid
                columns={
                  'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
                }
              >
                <GridItem>
                  <Fieldset style={{ padding: '20px 0px', margin: '0px' }}>
                    <input
                      type='checkbox'
                      value={sms}
                      id='sms'
                      onChange={(e) => setSms(e.target.checked)}
                    />
                    <Label htmlFor='sms' style={{ display: 'inline' }}>
                      SMS
                    </Label>
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset style={{ padding: '20px 0px', margin: '0px' }}>
                    <input
                      type='checkbox'
                      value={email}
                      id='email'
                      onChange={(e) => setEmail(e.target.checked)}
                    />
                    <Label htmlFor='email' style={{ display: 'inline' }}>
                      Email
                    </Label>
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset style={{ padding: '20px 0px', margin: '0px' }}>
                    <input
                      type='checkbox'
                      value={whatsapp}
                      id='whatsapp'
                      onChange={(e) => setWhatsapp(e.target.checked)}
                    />
                    <Label htmlFor='whatsapp' style={{ display: 'inline' }}>
                      Whats App
                    </Label>
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset style={{ padding: '20px 0px', margin: '0px' }}>
                    <input
                      type='checkbox'
                      value={telegram}
                      id='telegram'
                      onChange={(e) => setTelegram(e.target.checked)}
                    />
                    <Label htmlFor='telegram' style={{ display: 'inline' }}>
                      Telegram
                    </Label>
                  </Fieldset>
                </GridItem>
              </Grid>
            </Fieldset>
          )}
          {notificationType === 'Push Notification' && (
            <Fieldset>
              <Label required>Title</Label>
              <TextInput
                placeholder='Please enter title'
                value={title}
                onChange={setTitle}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          {notificationType && (
            <Fieldset>
              <Label required>Message</Label>
              <Textarea
                placeholder='Please enter message'
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type='submit'>
              Send
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(NotificationsCreate);
