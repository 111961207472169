import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Image } from "../Image";

const StyledAccountSectionContainer = styled.section`
  width: 55px;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #e5e5e5;
  position: relative;
  @media (max-width: 600px) {
    position: static;
  }
`;

const StyledAccountIconContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 100%;
    border-width: 0px;
    background-color: transparent;
    outline: 0px;
    cursor: pointer;
    transition: background-color 0.5s;
    &:hover {
      background-color: #e5e5e5;
    }
    &.active {
      background-color: ${({
        theme: {
          colors: { secondaryColor },
        },
      }) => (secondaryColor ? secondaryColor : `#0078ce`)};
      svg {
        color: #fff;
      }
    }
    svg {
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      color: #707070;
    }
  }
`;

const StyledAccountContent = styled.section`
  position: absolute;
  width: 280px;
  height: auto;
  background-color: #fff;
  top: 55px;
  right: 0px;
  border-radius: 0px;
  box-shadow: 0px 5px 15px -10px #000000;
  z-index: 1;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledAccountContentHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  background-color: ${({
    theme: {
      colors: { secondaryColor },
    },
  }) => (secondaryColor ? secondaryColor : `#0078ce`)};
  h3 {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    margin: 0px;
    padding: 12px;
    text-align: left;
  }
`;

const StyledAccountProfilePhotoContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 15px 0px;
  border-bottom: 1px solid #e5e5e5;
  img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    padding: 5px;
    border: 5px solid #e5e5e5;
    display: block;
    border-radius: 100%;
  }
`;

const StyledAccountContentLinks = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      margin: 0px;
      padding: 0px;
      display: block;
      border-bottom: 1px solid #e5e5e5;
      transition: background-color 0.5s;
      a {
        font-size: 14px;
        text-decoration: none;
        outline: 0px;
        display: block;
        color: #000;
        margin: 0px;
        padding: 12px;
        &.active {
          background-color: #e5e5e5;
        }
      }
      &:hover {
        background-color: #e5e5e5;
      }
    }
    &:last-child {
      border-bottom-width: 0px;
    }
  }
`;

const AccountSection = ({
  fullName,
  profilePhoto,
  showReferAndEarn = false,
}) => {
  const [expandAccount, setExpandAccount] = useState(false);
  const accountRef = useRef(null);

  const handleOnExpandAccount = (e) => {
    e.preventDefault();
    setExpandAccount(!expandAccount);
  };

  const closeAccountList = (e) => {
    if (accountRef.current && !accountRef.current.contains(e.target)) {
      setExpandAccount(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeAccountList, true);
    return () => {
      document.removeEventListener("click", closeAccountList, true);
    };
  }, []);

  return (
    <StyledAccountSectionContainer ref={accountRef}>
      <StyledAccountIconContainer>
        <button
          type="button"
          className={expandAccount ? `active` : ``}
          onClick={handleOnExpandAccount}
        >
          <FontAwesomeIcon icon={faUserCircle} />
        </button>
      </StyledAccountIconContainer>
      {expandAccount && (
        <StyledAccountContent>
          <StyledAccountContentHeader>
            <h3>My Account</h3>
          </StyledAccountContentHeader>
          <StyledAccountProfilePhotoContainer>
            <Image
              source={profilePhoto ? profilePhoto : "default-profile-photo.png"}
              alt={fullName}
            />
          </StyledAccountProfilePhotoContainer>
          <StyledAccountContentLinks>
            <ul>
              <li>
                <NavLink
                  to="/profile"
                  title="Profile"
                  className={(state) => (state.isActive ? "active" : "")}
                >
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/change-password"
                  title="Change Password"
                  className={(state) => (state.isActive ? "active" : "")}
                >
                  Change Password
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sessions"
                  title="Sessions"
                  className={(state) => (state.isActive ? "active" : "")}
                >
                  Sessions
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/logs"
                  title="Logs"
                  className={(state) => (state.isActive ? "active" : "")}
                >
                  Logs
                </NavLink>
              </li>
              {showReferAndEarn && (
                <li>
                  <NavLink
                    to="/refer-and-earn"
                    title="Refer &amp; Earn"
                    className={(state) => (state.isActive ? "active" : "")}
                  >
                    Refer &amp; Earn
                  </NavLink>
                </li>
              )}
            </ul>
          </StyledAccountContentLinks>
        </StyledAccountContent>
      )}
    </StyledAccountSectionContainer>
  );
};

export default AccountSection;
