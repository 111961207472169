import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  ResetButton,
  UpdateButton,
} from '../../../components/FormElements';
import { StyledMicroButton } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';

const BillFetchConfigurationUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState('');
  const [billfetchApiEndpoint, setBillfetchApiEndpoint] = useState('');
  const [billfetchBody, setBillfetchBody] = useState('');
  const [billfetchMethod, setBillfetchMethod] = useState('');
  const [billfetchStatusKey, setBillfetchStatusKey] = useState('');
  const [billfetchSuccessValue, setBillfetchSuccessValue] = useState('');
  const [billfetchFailedValue, setBillfetchFailedValue] = useState('');
  const [billfetchResponseType, setBillfetchResponseType] = useState('');
  const [billfetchCustomerNameKey, setBillfetchCustomerNameKey] = useState('');
  const [billfetchDueDateKey, setBillfetchDueDateKey] = useState('');
  const [billfetchBillDateKey, setBillfetchBillDateKey] = useState('');
  const [billfetchBillNumberKey, setBillfetchBillNumberKey] = useState('');
  const [billfetchBillAmountKey, setBillfetchBillAmountKey] = useState('');
  const [billfetchStatusMessageKey, setBillfetchStatusMessageKey] =
    useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      servicesId,
      billfetchApiEndpoint,
      billfetchBody,
      billfetchMethod,
      billfetchStatusKey,
      billfetchSuccessValue,
      billfetchFailedValue,
      billfetchResponseType,
      billfetchCustomerNameKey,
      billfetchDueDateKey,
      billfetchBillDateKey,
      billfetchBillNumberKey,
      billfetchBillAmountKey,
      billfetchStatusMessageKey,
    } = originalFormData;
    setServicesId(servicesId);
    setBillfetchApiEndpoint(billfetchApiEndpoint);
    setBillfetchBody(billfetchBody);
    setBillfetchMethod(billfetchMethod);
    setBillfetchStatusKey(billfetchStatusKey);
    setBillfetchSuccessValue(billfetchSuccessValue);
    setBillfetchFailedValue(billfetchFailedValue);
    setBillfetchResponseType(billfetchResponseType);
    setBillfetchCustomerNameKey(billfetchCustomerNameKey);
    setBillfetchDueDateKey(billfetchDueDateKey);
    setBillfetchBillDateKey(billfetchBillDateKey);
    setBillfetchBillNumberKey(billfetchBillNumberKey);
    setBillfetchBillAmountKey(billfetchBillAmountKey);
    setBillfetchStatusMessageKey(billfetchStatusMessageKey);
  };

  const readServices = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/services')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setServices(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/bill-fetch-configuration/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            servicesId,
            billfetchApiEndpoint,
            billfetchBody,
            billfetchMethod,
            billfetchStatusKey,
            billfetchSuccessValue,
            billfetchFailedValue,
            billfetchResponseType,
            billfetchCustomerNameKey,
            billfetchDueDateKey,
            billfetchBillDateKey,
            billfetchBillNumberKey,
            billfetchBillAmountKey,
            billfetchStatusMessageKey,
          } = data[0];
          setServicesId(servicesId);
          setBillfetchApiEndpoint(billfetchApiEndpoint);
          setBillfetchBody(billfetchBody);
          setBillfetchMethod(billfetchMethod);
          setBillfetchStatusKey(billfetchStatusKey);
          setBillfetchSuccessValue(billfetchSuccessValue);
          setBillfetchFailedValue(billfetchFailedValue);
          setBillfetchResponseType(billfetchResponseType);
          setBillfetchCustomerNameKey(billfetchCustomerNameKey);
          setBillfetchDueDateKey(billfetchDueDateKey);
          setBillfetchBillDateKey(billfetchBillDateKey);
          setBillfetchBillNumberKey(billfetchBillNumberKey);
          setBillfetchBillAmountKey(billfetchBillAmountKey);
          setBillfetchStatusMessageKey(billfetchStatusMessageKey);
          setOriginalFormData({
            servicesId,
            billfetchApiEndpoint,
            billfetchBody,
            billfetchMethod,
            billfetchStatusKey,
            billfetchSuccessValue,
            billfetchFailedValue,
            billfetchResponseType,
            billfetchCustomerNameKey,
            billfetchDueDateKey,
            billfetchBillDateKey,
            billfetchBillNumberKey,
            billfetchBillAmountKey,
            billfetchStatusMessageKey,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      servicesId,
      billfetchApiEndpoint,
      billfetchBody,
      billfetchMethod,
      billfetchStatusKey,
      billfetchSuccessValue,
      billfetchFailedValue,
      billfetchResponseType,
      billfetchCustomerNameKey,
      billfetchDueDateKey,
      billfetchBillDateKey,
      billfetchBillNumberKey,
      billfetchBillAmountKey,
      billfetchStatusMessageKey,
    };
    API.put(`/recharge-configurations/bill-fetch-configuration/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readServices();
    readData();
  }, [readServices, readData]);

  const servicesOptions = [
    { title: '-- SELECT SERVICE --', value: '' },
    { title: 'ALL', value: 0 },
  ];
  services.forEach(({ id, serviceName }) =>
    servicesOptions.push({
      title: serviceName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Service</Label>
            <Dropdown
              placeholder='Please select service'
              value={servicesId}
              onChange={setServicesId}
              options={servicesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>API Endpoint</Label>
            <TextInput
              value={billfetchApiEndpoint}
              onChange={setBillfetchApiEndpoint}
              placeholder='Please enter api endpoint'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Request Body</Label>
            <TextInput
              value={billfetchBody}
              onChange={setBillfetchBody}
              placeholder='Please enter request body'
              disabled={isLoading === true}
            />
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setBillfetchBody, '{{{NUMBER}}}')}
            >
              Mobile/Account Number
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setBillfetchBody, '{{{OPERATOR}}}')}
            >
              Operator
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setBillfetchBody, '{{{FIELD1}}}')}
            >
              Field 1
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setBillfetchBody, '{{{FIELD2}}}')}
            >
              Field 2
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setBillfetchBody, '{{{FIELD3}}}')}
            >
              Field 3
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setBillfetchBody, '{{{FIELD4}}}')}
            >
              Field 4
            </StyledMicroButton>
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Request Method</Label>
                <Dropdown
                  value={billfetchMethod}
                  onChange={setBillfetchMethod}
                  placeholder='Please select request method'
                  disabled={isLoading === true}
                  options={[
                    {
                      title: '-- SELECT API REQUEST METHOD --',
                      value: '',
                    },
                    {
                      title: 'POST',
                      value: 'POST',
                    },
                    {
                      title: 'GET',
                      value: 'GET',
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Response Type</Label>
                <Dropdown
                  value={billfetchResponseType}
                  onChange={setBillfetchResponseType}
                  placeholder='Please select response type'
                  disabled={isLoading === true}
                  options={[
                    {
                      title: '-- SELECT API RESPONSE TYPE --',
                      value: '',
                    },
                    {
                      title: 'JSON',
                      value: 'JSON',
                    },
                    {
                      title: 'XML',
                      value: 'XML',
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Status Key</Label>
                <TextInput
                  value={billfetchStatusKey}
                  onChange={setBillfetchStatusKey}
                  placeholder='Please enter status key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Success Value</Label>
                <TextInput
                  value={billfetchSuccessValue}
                  onChange={setBillfetchSuccessValue}
                  placeholder='Please enter success value'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Failed Value</Label>
                <TextInput
                  value={billfetchFailedValue}
                  onChange={setBillfetchFailedValue}
                  placeholder='Please enter failed value'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Customer Name Key</Label>
                <TextInput
                  value={billfetchCustomerNameKey}
                  onChange={setBillfetchCustomerNameKey}
                  placeholder='Please enter customer name key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Due Date Key</Label>
                <TextInput
                  value={billfetchDueDateKey}
                  onChange={setBillfetchDueDateKey}
                  placeholder='Please enter due date key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Bill Date Key</Label>
                <TextInput
                  value={billfetchBillDateKey}
                  onChange={setBillfetchBillDateKey}
                  placeholder='Please enter bill date key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Bill Number Key</Label>
                <TextInput
                  value={billfetchBillNumberKey}
                  onChange={setBillfetchBillNumberKey}
                  placeholder='Please enter bill number key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Bill Amount Key</Label>
                <TextInput
                  value={billfetchBillAmountKey}
                  onChange={setBillfetchBillAmountKey}
                  placeholder='Please enter bill amount key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Status Message Key</Label>
                <TextInput
                  value={billfetchStatusMessageKey}
                  onChange={setBillfetchStatusMessageKey}
                  placeholder='Please enter status message key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(BillFetchConfigurationUpdate);
