import React, { useState } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  CreateButton,
  ResetButton,
  NumberInput,
} from '../../../components/FormElements';
import { StyledSpan } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { getAmountInWords } from '../../../utils';
import { isEmpty } from 'lodash';

const PaymentGatewayDepositsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [amount, setAmount] = useState('');
  const [remark, setRemark] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setAmount('');
    setRemark('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = { amount, remark };
    API.post('/accounting/payment-gateway-deposits', formData)
      .then((response) => {
        const { status, message, data } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetResponseData();
          if (data?.url && !isEmpty(data?.url)) {
            window.location.href = data?.url;
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Amount</Label>
                <NumberInput
                  value={amount}
                  onChange={setAmount}
                  placeholder='Please enter amount'
                  maxLength={100}
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
                {amount && (
                  <StyledSpan style={{ color: '#000' }}>
                    {getAmountInWords(amount)}
                  </StyledSpan>
                )}
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Remark</Label>
                <TextInput
                  value={remark}
                  onChange={setRemark}
                  placeholder='Please enter remark'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type='submit'>
              Pay
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(PaymentGatewayDepositsCreate);
