import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import { StyledSpan } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl, getAmountInWords } from '../../../utils';

const VendorPaymentsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [vendors, setVendors] = useState([]);
  const [vendorsId, setVendorsId] = useState('');
  const [utrNumber, setUtrNumber] = useState('');
  const [remark, setRemark] = useState('');
  const [transactionsDate, setTransactionsDate] = useState('');
  const [amount, setAmount] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { vendorsId, utrNumber, remark, transactionsDate, amount } =
      originalFormData;
    setVendorsId(vendorsId);
    setUtrNumber(utrNumber);
    setRemark(remark);
    setTransactionsDate(transactionsDate);
    setAmount(amount);
  };

  const readVendors = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounts-management/vendors`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setVendors(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounting/vendor-payments/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { vendorsId, utrNumber, remark, transactionsDate, amount } =
            data[0];
          setVendorsId(vendorsId);
          setUtrNumber(utrNumber);
          setRemark(remark);
          setTransactionsDate(transactionsDate);
          setAmount(amount);
          setOriginalFormData({
            vendorsId,
            utrNumber,
            remark,
            transactionsDate,
            amount,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      vendorsId,
      utrNumber,
      remark,
      transactionsDate,
      amount,
    };
    API.put(`/accounting/vendor-payments/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readVendors();
    readData();
  }, [readVendors, readData]);

  const vendorOptions = [{ title: '-- SELECT VENDOR --', value: '' }];
  vendors.forEach(({ id, firstName, lastName, firmName }) =>
    vendorOptions.push({
      title: `${firstName} ${lastName}`,
      subTitle: firmName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Vendor</Label>
            <Dropdown
              placeholder='Please select vendor'
              value={vendorsId}
              onChange={setVendorsId}
              options={vendorOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>UTR Number</Label>
                <TextInput
                  value={utrNumber}
                  onChange={setUtrNumber}
                  placeholder='Please enter UTR number'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Transactions Date</Label>
                <TextInput
                  type='date'
                  value={transactionsDate}
                  onChange={setTransactionsDate}
                  placeholder='Please select transactions date'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Amount</Label>
                <NumberInput
                  value={amount}
                  onChange={setAmount}
                  placeholder='Please enter amount'
                  maxLength={10}
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
                {amount && (
                  <StyledSpan style={{ color: '#000' }}>
                    {getAmountInWords(amount)}
                  </StyledSpan>
                )}
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Remark</Label>
                <TextInput
                  value={remark}
                  onChange={setRemark}
                  placeholder='Please enter remark'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(VendorPaymentsUpdate);
