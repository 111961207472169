import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  TextInput,
  Label,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledFiltersContainer,
} from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { formatCurrency } from '../../../utils';

const OperatorDayBookTable = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [daybookData, setDaybookData] = useState([]);
  const [apiProviders, setApiProviders] = useState([]);
  const [apiProvidersId, setApiProvidersId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const resetForm = () => {
    setApiProvidersId('');
    setStartDate('');
    setEndDate('');
    readData();
  };

  const readApiProviders = () => {
    API.get('/recharge-configurations/api-providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setApiProviders(data);
      }
    });
  };

  const readData = useCallback(
    (apiProvidersId = '', startDate = '', endDate = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/reports/operators-day-book?apiProvidersId=${apiProvidersId}&startDate=${startDate}&endDate=${endDate}`
      )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setDaybookData(data);
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const filterData = (e) => {
    e.preventDefault();
    readData(apiProvidersId, startDate, endDate);
  };

  useEffect(() => {
    readApiProviders();
  }, []);

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: '' },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} canReloadProp={false} />
      <StyledFiltersContainer className='show'>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>API Provider</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='API Provider'
              value={apiProvidersId}
              onChange={setApiProvidersId}
              options={apiProvidersOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type='date'
              value={startDate}
              onChange={setStartDate}
              placeholder='Start Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type='date'
              value={endDate}
              onChange={setEndDate}
              placeholder='End Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Search
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' && daybookData?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          daybookData?.length === 0
        }
        message={`No data found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {daybookData?.length > 0 && (
        <TableBuilder
          isLoading={daybookData?.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: 'Operator',
              dataSelector: 'operatorName',
              dataType: 'string',
              CellRenderer: (value, item) => (
                <StyledIconTitleContainer>
                  <StyledIconContainer>
                    <Image
                      source={
                        item.operatorLogo ? item.operatorLogo : '/no-image.png'
                      }
                      alt={value}
                      style={{ width: '50px', height: '50px' }}
                    />
                  </StyledIconContainer>
                  <StyledTitleContainer>
                    {value}
                    <StyledSpan>{item.serviceName}</StyledSpan>
                  </StyledTitleContainer>
                </StyledIconTitleContainer>
              ),
            },
            {
              title: 'Success',
              dataSelector: 'successAmount',
              dataType: 'string',
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.successCount})`,
            },
            {
              title: 'Failed',
              dataSelector: 'failureAmount',
              dataType: 'string',
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.failureCount})`,
            },
            {
              title: 'Pending',
              dataSelector: 'pendingAmount',
              dataType: 'string',
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.pendingCount})`,
            },
            {
              title: 'Commission Earned',
              dataSelector: 'commissionEarned',
              dataType: 'string',
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: 'Commission Distributed',
              dataSelector: 'commissionDistributed',
              dataType: 'string',
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: 'Profit',
              dataSelector: 'profit',
              dataType: 'string',
              CellRenderer: (value) => formatCurrency(value),
            },
          ]}
          tableData={daybookData}
          tableFooter={[
            {
              value: '',
            },
            {
              value: `${formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.successAmount),
                  0
                )
              )} (${daybookData.reduce(
                (previousValue, currentValue) =>
                  previousValue + Number(currentValue.successCount),
                0
              )})`,
            },
            {
              value: `${formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.failureAmount),
                  0
                )
              )} (${daybookData.reduce(
                (previousValue, currentValue) =>
                  previousValue + Number(currentValue.failureCount),
                0
              )})`,
            },
            {
              value: `${formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.pendingAmount),
                  0
                )
              )} (${daybookData.reduce(
                (previousValue, currentValue) =>
                  previousValue + Number(currentValue.pendingCount),
                0
              )})`,
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.commissionEarned),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.commissionDistributed),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.profit),
                  0
                )
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default withAppHOC(OperatorDayBookTable);
