import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import StatusHighliter from '../../../components/StatusHighliter/StatusHighliter';
import {
  Switch,
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  Label,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledLink,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledFiltersContainer,
} from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
  formatCurrency,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';

const OperatorsTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [operators, setOperators] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState('');
  const [onlyForApiUser, setOnlyForApiUser] = useState('');

  const resetForm = () => {
    setServicesId('');
    setOnlyForApiUser('');
    readData();
  };

  const readServices = () => {
    API.get('/recharge-configurations/services').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setServices(data);
      }
    });
  };

  const readData = useCallback(
    (servicesId = '', onlyForApiUser = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setOperators([]);
      API.get(
        `/recharge-configurations/operators/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fServicesId=${servicesId}&fOnlyForApiUser=${onlyForApiUser}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setOperators(data);
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(servicesId, onlyForApiUser);
  };

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.isActiveForApiUser = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(
      `/recharge-configurations/operators/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fServicesId=${servicesId}&fOnlyForApiUser=${onlyForApiUser}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setOperators(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/recharge-configurations/operators/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fServicesId=${servicesId}&fOnlyForApiUser=${onlyForApiUser}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setOperators(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    readServices();
  }, []);

  useEffect(() => {
    if (!isFiltering) {
      readData(servicesId, onlyForApiUser);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const servicesOptions = [{ title: '-- SELECT SERVICE --', value: '' }];
  services.forEach(({ id, serviceName }) =>
    servicesOptions.push({
      title: serviceName,
      value: id,
    })
  );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Service</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Service'
              value={servicesId}
              onChange={setServicesId}
              options={servicesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Only for API User</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Only for API User'
              value={onlyForApiUser}
              onChange={setOnlyForApiUser}
              options={[
                {
                  title: '-- SELECT ONLY FOR API USER --',
                  value: '',
                },
                {
                  title: 'Yes',
                  value: 'Yes',
                },
                {
                  title: 'No',
                  value: 'No',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' && operators?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          operators?.length === 0
        }
        message={`No operators found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {operators?.length > 0 && (
        <>
          <TableBuilder
            isLoading={operators?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Operator',
                dataSelector: 'operatorName',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={
                          item.operatorLogo
                            ? item.operatorLogo
                            : '/no-image.png'
                        }
                        alt={value}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>
                      {value}
                      <StyledSpan>{item.serviceName}</StyledSpan>
                    </StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: 'Operator Code',
                dataSelector: 'id',
                dataType: 'number',
              },
              {
                title: 'Plan API Operator Code',
                dataSelector: 'planApiCode',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Backup Plan API Operator Code',
                dataSelector: 'backupPlanApiCode',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Only For API Users',
                dataSelector: 'onlyForApiUser',
                dataType: 'string',
                align: 'center',
                CellRenderer: (value) =>
                  value && (
                    <StatusHighliter
                      className={value === 'Yes' ? 'green' : 'red'}
                    >
                      {value}
                    </StatusHighliter>
                  ),
              },
              {
                title: 'Active for API User',
                dataSelector: 'isActiveForApiUser',
                dataType: 'string',
                align: 'center',
                CellRenderer: (value, data) =>
                  value && (
                    <Switch
                      value={value === 'Active'}
                      onChange={(value) => updateData(value, data)}
                    />
                  ),
              },
              {
                title: 'Min Amount',
                dataSelector: 'minAmount',
                dataType: 'number',
                CellRenderer: (value) => (value || value === 0 ? formatCurrency(value) : 'N/A'),
              },
              {
                title: 'Max Amount',
                dataSelector: 'maxAmount',
                dataType: 'number',
                CellRenderer: (value) => (value || value === 0 ? formatCurrency(value) : 'N/A'),
              },
              {
                title: 'Field 1',
                dataSelector: 'field1',
                dataType: 'string',
              },
              {
                title: 'Field 2',
                dataSelector: 'field2',
                dataType: 'string',
              },
              {
                title: 'Field 3',
                dataSelector: 'field3',
                dataType: 'string',
              },
              {
                title: 'Field 4',
                dataSelector: 'field4',
                dataType: 'string',
              },
            ]}
            tableData={operators}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(OperatorsTable);
