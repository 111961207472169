import React from 'react';
import styled from 'styled-components';

const StyledTableRow = styled.tr`
  margin: 0px;
  padding: 0px;
  td:first-child {
    background-color: ${({ hideDataBackgroundColor, backgroundColor }) =>
      backgroundColor !== ''
        ? backgroundColor
        : hideDataBackgroundColor === true
        ? 'transparent'
        : '#fff'};
  }
  &:nth-child(even) {
    background-color: ${({ hideDataBackgroundColor }) =>
      hideDataBackgroundColor === true ? 'transparent' : '#f4f4f4'};
    td:first-child {
      background-color: ${({ hideDataBackgroundColor }) =>
        hideDataBackgroundColor === true ? 'transparent' : '#f4f4f4'};
    }
  }
  &.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TableRow = ({ children, ...rest }) => {
  return <StyledTableRow {...rest}>{children}</StyledTableRow>;
};

export default TableRow;
