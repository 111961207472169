import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import { MessageBox } from '../../components/FormElements';
import { TableBuilder } from '../../components/TableElements';
import Pagination from '../../components/Pagination/Pagination';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../utils';

const Logs = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [logs, setLogs] = useState([]);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/auth/logs/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    )
      .then((response) => {
        const { status, message, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setLogs(data);
            setTotalRecords(pageInfo.totalRecords);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [paginationData]);

  const reloadData = () => {
    readData();
  };

  useEffect(() => {
    readData();
  }, [paginationData, readData]);

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <LoadingSpinner
        isLoading={responseStatus === '' && logs?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          logs?.length === 0
        }
        message={`No logs found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {logs?.length > 0 && (
        <>
          <TableBuilder
            isLoading={logs?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: 'IP Address',
                dataSelector: 'ip',
                dataType: 'string',
              },
              {
                title: 'Date & Time',
                dataSelector: 'timestamp',
                dataType: 'string',
              },
              {
                title: 'Platform',
                dataSelector: 'platform',
                dataType: 'string',
              },
              {
                title: 'Device',
                dataSelector: 'deviceType',
                dataType: 'string',
              },
              {
                title: 'Operating System',
                dataSelector: 'operatingSystem',
                dataType: 'string',
              },
              {
                title: 'Location',
                dataSelector: 'location',
                dataType: 'string',
              },
              {
                title: 'Action',
                dataSelector: 'action',
                dataType: 'string',
              },
            ]}
            tableData={logs}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(Logs);
