import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  NumberInput,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';

const AccountsAPIMappingCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [operators, setOperators] = useState([]);
  const [apiProviders, setApiProviders] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [accountsId, setAccountsId] = useState('');
  const [operatorsId, setOperatorsId] = useState('');
  const [primaryApiProvidersId, setPrimaryApiProvidersId] = useState(null);
  const [secondaryApiProvidersId, setSecondaryApiProvidersId] = useState(null);
  const [tertiaryApiProvidersId, setTertiaryApiProvidersId] = useState(null);
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setRolesId('');
    setAccountsId('');
    setOperatorsId('');
    setPrimaryApiProvidersId('');
    setSecondaryApiProvidersId('');
    setTertiaryApiProvidersId('');
    setFromAmount('');
    setToAmount('');
  };

  const readAccounts = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/accounts-management/accounts')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setAccounts(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/operators')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readApiProviders = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/api-providers')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setApiProviders(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      accountsId,
      operatorsId,
      primaryApiProvidersId,
      secondaryApiProvidersId,
      tertiaryApiProvidersId,
      fromAmount,
      toAmount,
    };
    API.post('/recharge-configurations/accounts-api-mapping', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readAccounts();
    readOperators();
    readApiProviders();
  }, [readAccounts, readOperators, readApiProviders]);

  const rolesOptions = [
    { title: '-- SELECT ROLE --', value: '' },
    { title: 'Master Distributors', value: 2 },
    { title: 'Distributors', value: 3 },
    { title: 'Retailers', value: 4 },
    { title: 'API Users', value: 5 },
  ];

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  const filteredAccounts =
    accounts &&
    accounts.filter(({ accountsRolesId }) => accountsRolesId === rolesId);
  filteredAccounts
    ?.filter((account) => account?.isDeleted === 'No')
    ?.forEach(({ id, firstName, lastName, isEntity, entityName }) =>
      accountsOptions.push({
        title:
          isEntity === 'Yes' ? `${entityName}` : `${firstName} ${lastName}`,
        value: id,
      })
    );

  const operatorsOptions = [{ title: '-- SELECT OPERATOR --', value: '' }];
  operators.forEach(({ id, operatorName }) =>
    operatorsOptions.push({
      title: operatorName,
      value: id,
    })
  );

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: null },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Role</Label>
                <Dropdown
                  placeholder='Please select role'
                  value={rolesId}
                  onChange={(value) => {
                    setRolesId(value);
                    setAccountsId('');
                  }}
                  options={rolesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Account</Label>
                <Dropdown
                  placeholder='Please select account'
                  value={accountsId}
                  onChange={setAccountsId}
                  options={accountsOptions}
                  disabled={isLoading || rolesId === ''}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Operator</Label>
                <Dropdown
                  placeholder='Please select operator'
                  value={operatorsId}
                  onChange={setOperatorsId}
                  options={operatorsOptions}
                  disabled={isLoading || rolesId === ''}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Primary API Provider</Label>
                <Dropdown
                  placeholder='Please select primary api provider'
                  value={primaryApiProvidersId}
                  onChange={setPrimaryApiProvidersId}
                  options={apiProvidersOptions}
                  disabled={isLoading || rolesId === ''}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Secondary API Provider</Label>
                <Dropdown
                  placeholder='Please select secondary api provider'
                  value={secondaryApiProvidersId}
                  onChange={setSecondaryApiProvidersId}
                  options={apiProvidersOptions}
                  disabled={isLoading || rolesId === ''}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Tertiary API Provider</Label>
                <Dropdown
                  placeholder='Please select tertiary api provider'
                  value={tertiaryApiProvidersId}
                  onChange={setTertiaryApiProvidersId}
                  options={apiProvidersOptions}
                  disabled={isLoading || rolesId === ''}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>From Amount</Label>
                <NumberInput
                  placeholder='Please enter from amount'
                  value={fromAmount}
                  onChange={setFromAmount}
                  disabled={isLoading || rolesId === ''}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>To Amount</Label>
                <NumberInput
                  placeholder='Please enter to amount'
                  value={toAmount}
                  onChange={setToAmount}
                  disabled={isLoading || rolesId === ''}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton
              disabled={isLoading === true || rolesId === ''}
              type='submit'
            >
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true || rolesId === ''}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(AccountsAPIMappingCreate);
