import React from 'react';
import styled from 'styled-components';

const StyledExpiryContainer = styled.section`
  border-left: 1px solid #e5e5e5;
  padding: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  color: red;
  @media (max-width: 600px) {
    display: none;
  }
`;

const StyledExpiryDate = styled.p`
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const StyledExpiryText = styled.p`
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const StyledColon = styled.span`
  @media (max-width: 600px) {
    display: none;
  }
`;

const AccountExpirySection = ({ packageExpiryDate }) => {
  return (
    packageExpiryDate && (
      <StyledExpiryContainer>
        <StyledExpiryText>
          Expiry Date<StyledColon> :</StyledColon>
        </StyledExpiryText>
        <StyledExpiryDate>{packageExpiryDate}</StyledExpiryDate>
      </StyledExpiryContainer>
    )
  );
};

export default AccountExpirySection;
