import React from 'react';
import styled from 'styled-components';

const StyledTableData = styled.td`
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
  padding: 15px;
  text-align: ${({ align }) => (align === 'center' ? `center` : `left`)};
  left: 0px;
  width: ${({ width }) => (width ? width : 'auto')};
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  border-right: ${({ hideDataBackgroundColor }) =>
    hideDataBackgroundColor ? '0px' : '1px solid #e5e5e5'};
  color: ${({ hideDataBackgroundColor }) =>
    hideDataBackgroundColor ? '#ffffff' : '#000000'};
  &.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:last-child {
    border-right: 0px;
  }
`;

const TableData = ({ children, ...rest }) => {
  return <StyledTableData {...rest}>{children}</StyledTableData>;
};

export default TableData;
