import React, { useState, useEffect, useCallback, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faWallet } from '@fortawesome/free-solid-svg-icons';
import { ACCOUNT_TYPES } from '../../constants';
import { authData, formatCurrency } from '../../utils';

const StyledWalletSectionContainer = styled.section`
  width: 55px;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #e5e5e5;
  position: relative;
  @media (max-width: 600px) {
    position: static;
  }
`;

const StyledWalletIconContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 100%;
    border-width: 0px;
    background-color: transparent;
    outline: 0px;
    cursor: pointer;
    transition: background-color 0.5s;
    &:hover {
      background-color: #e5e5e5;
    }
    &.active {
      background-color: ${({
        theme: {
          colors: { secondaryColor },
        },
      }) => (secondaryColor ? secondaryColor : `#0078ce`)};
      svg {
        color: #fff;
      }
    }
    svg {
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      color: #707070;
    }
  }
`;

const StyledWalletContent = styled.section`
  position: absolute;
  width: 280px;
  height: auto;
  background-color: #fff;
  top: 55px;
  right: 0px;
  border-radius: 0px;
  box-shadow: 0px 5px 15px -10px #000000;
  z-index: 1;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledWalletContentHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  background-color: ${({
    theme: {
      colors: { secondaryColor },
    },
  }) => (secondaryColor ? secondaryColor : `#0078ce`)};
  h3 {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    margin: 0px;
    padding: 12px;
    text-align: left;
  }
`;

const StyledWalletBalance = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 15px 0px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    padding: 10px;
    border: 5px solid #228b22;
    color: #228b22;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  h3 {
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    color: #228b22;
    margin: 0px;
    padding: 20px 0px 0px 0px;
  }
`;

const StyledDueContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  border-bottom: 1px solid #e5e5e5;
`;

const StyledDueSpan = styled.span`
  font-size: 14px;
  display: block;
  color: #848484;
  margin: 0px;
  font-style: normal;
  text-transform: capitalize;
  background-color: #f6f6f6;
  padding: 10px;
`;

const StyledWalletContentLinks = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      margin: 0px;
      padding: 0px;
      display: block;
      border-bottom: 1px solid #e5e5e5;
      transition: background-color 0.5s;
      a {
        font-size: 14px;
        text-decoration: none;
        outline: 0px;
        color: #000;
        margin: 0px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          font-size: 25px;
          color: ${({
            theme: {
              colors: { primaryColor },
            },
          }) => (primaryColor ? primaryColor : `#0095ff`)};
          margin-right: 12px;
        }
      }
      &.active,
      &:hover {
        background-color: #e5e5e5;
      }
    }
    &:last-child {
      border-bottom-width: 0px;
    }
  }
`;

const WalletSection = ({
  walletBalance,
  creditDue,
  minimumBalance,
  commissionMode,
  commissionValue,
}) => {
  const { accountType } = authData.get();
  const [expandWallet, setExpandWallet] = useState(false);
  const walletRef = useRef(null);

  const onClickExpandWallet = (e) => {
    e.preventDefault();
    setExpandWallet((expandWallet) => !expandWallet);
  };

  const closeWalletList = useCallback((e) => {
    if (walletRef.current && !walletRef.current.contains(e.target)) {
      setExpandWallet(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeWalletList, true);
    return () => {
      document.removeEventListener('click', closeWalletList, true);
    };
  }, [closeWalletList]);

  return (
    <StyledWalletSectionContainer ref={walletRef}>
      <StyledWalletIconContainer>
        <button
          type='button'
          className={expandWallet ? `active` : ``}
          onClick={onClickExpandWallet}
        >
          <FontAwesomeIcon icon={faWallet} />
        </button>
      </StyledWalletIconContainer>
      {expandWallet && (
        <StyledWalletContent>
          <StyledWalletContentHeader>
            <h3>Wallet Balance</h3>
          </StyledWalletContentHeader>
          {[ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,].includes(accountType) && (
            <StyledWalletBalance>
              <FontAwesomeIcon icon={faIndianRupeeSign} />
              <h3>{formatCurrency(walletBalance)}</h3>
            </StyledWalletBalance>
          )}
          <StyledDueContainer>
            <StyledDueSpan>
              Credit Due : {formatCurrency(creditDue)}
            </StyledDueSpan>
            <StyledDueSpan>
              Minimum Balance : {formatCurrency(minimumBalance)}
            </StyledDueSpan>
            <StyledDueSpan>Commission Mode : {commissionMode}</StyledDueSpan>
            {commissionMode === 'Flat' && (
              <StyledDueSpan>
                Commission Value : {commissionValue}%
              </StyledDueSpan>
            )}
          </StyledDueContainer>
          {[
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <StyledWalletContentLinks>
              <ul>
                <li>
                  <NavLink
                    to='/wallet-transactions'
                    title='Wallet Transactions'
                    className={(state) => (state.isActive ? 'active' : '')}
                  >
                    Wallet Transactions
                  </NavLink>
                </li>
              </ul>
            </StyledWalletContentLinks>
          )}
        </StyledWalletContent>
      )}
    </StyledWalletSectionContainer>
  );
};

export default WalletSection;
