import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  TextInput,
  Label,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledFiltersContainer,
} from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, ACCOUNT_TYPES } from '../../../constants';
import { formatCurrency, authData } from '../../../utils';

const UsersOperatorsDayBookTable = (props) => {
  const { accountType } = authData.get();
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [daybookData, setDaybookData] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [accountsId, setAccountsId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const resetForm = () => {
    setAccountsId('');
    setStartDate('');
    setEndDate('');
    readData();
  };

  const readAccounts = () => {
    API.get('/accounts-management/accounts').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readData = useCallback(
    (accountsId = '', startDate = '', endDate = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/reports/users-operators-day-book?accountsId=${accountsId}&startDate=${startDate}&endDate=${endDate}`
      )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setDaybookData(data);
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const filterData = (e) => {
    e.preventDefault();
    readData(accountsId, startDate, endDate);
  };

  useEffect(() => {
    readAccounts();
  }, []);

  const rolesOptions = [{ title: '-- SELECT ROLE --', value: '' }];
  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
    [
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
    [
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else {
    [
      {
        id: 2,
        roleName: 'Master Distributors',
      },
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
      {
        id: 5,
        roleName: 'Api Users',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  }

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .filter(({ accountsRolesId }) =>
      rolesId !== ''
        ? accountsRolesId === rolesId
        : [2, 3, 4, 5].includes(accountsRolesId)
    )
    .forEach(({ id, firstName, lastName }) =>
      accountsOptions.push({
        title: `${firstName} ${lastName}`,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} canReloadProp={false} />
      <StyledFiltersContainer className='show'>
        <Form method='POST' action='#' onSubmit={filterData}>
          {![
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <>
              <Fieldset>
                <Label>Role</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder='Role'
                  value={rolesId}
                  onChange={(value) => {
                    setAccountsId('');
                    setRolesId(value);
                  }}
                  options={rolesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset>
                <Label>Account</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder='Account'
                  value={accountsId}
                  onChange={setAccountsId}
                  options={accountsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </>
          )}
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type='date'
              value={startDate}
              onChange={setStartDate}
              placeholder='Start Date'
              disabled={isLoading === true}
              max={moment(new Date()).format('YYYY-MM-DD')}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type='date'
              value={endDate}
              onChange={setEndDate}
              placeholder='End Date'
              disabled={isLoading === true}
              max={moment(new Date()).format('YYYY-MM-DD')}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Search
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' && daybookData?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          daybookData?.length === 0
        }
        message={`No data found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {daybookData?.length > 0 && (
        <TableBuilder
          isLoading={daybookData?.length !== 0 && isLoading}
          tableHeadings={
            [
              ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
              ACCOUNT_TYPES.DISTRIBUTORS,
              ACCOUNT_TYPES.RETAILERS,
              ACCOUNT_TYPES.API_USERS,
            ].includes(accountType)
              ? [
                  {
                    title: 'Operator',
                    dataSelector: 'operatorName',
                    dataType: 'string',
                    CellRenderer: (value, item) => (
                      <StyledIconTitleContainer>
                        <StyledIconContainer>
                          <Image
                            source={
                              item.operatorLogo
                                ? item.operatorLogo
                                : '/no-image.png'
                            }
                            alt={value}
                            style={{ width: '50px', height: '50px' }}
                          />
                        </StyledIconContainer>
                        <StyledTitleContainer>
                          {value}
                          <StyledSpan>{item.serviceName}</StyledSpan>
                        </StyledTitleContainer>
                      </StyledIconTitleContainer>
                    ),
                  },
                  {
                    title: 'Success',
                    dataSelector: 'successAmount',
                    dataType: 'string',
                    CellRenderer: (value, item) =>
                      `${formatCurrency(value)} (${item.successCount})`,
                  },
                  {
                    title: 'Failed',
                    dataSelector: 'failureAmount',
                    dataType: 'string',
                    CellRenderer: (value, item) =>
                      `${formatCurrency(value)} (${item.failureCount})`,
                  },
                  {
                    title: 'Pending',
                    dataSelector: 'pendingAmount',
                    dataType: 'string',
                    CellRenderer: (value, item) =>
                      `${formatCurrency(value)} (${item.pendingCount})`,
                  },
                  {
                    title: 'Commission',
                    dataSelector: 'commission',
                    dataType: 'string',
                    CellRenderer: (value) => formatCurrency(value),
                  },
                ]
              : [
                  {
                    title: 'Operator',
                    dataSelector: 'operatorName',
                    dataType: 'string',
                    CellRenderer: (value, item) => (
                      <StyledIconTitleContainer>
                        <StyledIconContainer>
                          <Image
                            source={
                              item.operatorLogo
                                ? item.operatorLogo
                                : '/no-image.png'
                            }
                            alt={value}
                            style={{ width: '50px', height: '50px' }}
                          />
                        </StyledIconContainer>
                        <StyledTitleContainer>
                          {value}
                          <StyledSpan>{item.serviceName}</StyledSpan>
                        </StyledTitleContainer>
                      </StyledIconTitleContainer>
                    ),
                  },
                  {
                    title: 'Success',
                    dataSelector: 'successAmount',
                    dataType: 'string',
                    CellRenderer: (value, item) =>
                      `${formatCurrency(value)} (${item.successCount})`,
                  },
                  {
                    title: 'Failed',
                    dataSelector: 'failureAmount',
                    dataType: 'string',
                    CellRenderer: (value, item) =>
                      `${formatCurrency(value)} (${item.failureCount})`,
                  },
                  {
                    title: 'Pending',
                    dataSelector: 'pendingAmount',
                    dataType: 'string',
                    CellRenderer: (value, item) =>
                      `${formatCurrency(value)} (${item.pendingCount})`,
                  },
                  {
                    title: 'Commission Distributed',
                    dataSelector: 'commission',
                    dataType: 'string',
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: 'Commission Earned',
                    dataSelector: 'commissionEarned',
                    dataType: 'string',
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: 'Profit',
                    dataSelector: 'profit',
                    dataType: 'string',
                    CellRenderer: (value) => formatCurrency(value),
                  },
                ]
          }
          tableData={daybookData}
          tableFooter={
            [
              ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
              ACCOUNT_TYPES.DISTRIBUTORS,
              ACCOUNT_TYPES.RETAILERS,
              ACCOUNT_TYPES.API_USERS,
            ].includes(accountType)
              ? [
                  {
                    value: '',
                  },
                  {
                    value: `${formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.successAmount),
                        0
                      )
                    )} (${daybookData.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.successCount),
                      0
                    )})`,
                  },
                  {
                    value: `${formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.failureAmount),
                        0
                      )
                    )} (${daybookData.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.failureCount),
                      0
                    )})`,
                  },
                  {
                    value: `${formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.pendingAmount),
                        0
                      )
                    )} (${daybookData.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.pendingCount),
                      0
                    )})`,
                  },
                  {
                    value: formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.commission),
                        0
                      )
                    ),
                  },
                ]
              : [
                  {
                    value: '',
                  },
                  {
                    value: `${formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.successAmount),
                        0
                      )
                    )} (${daybookData.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.successCount),
                      0
                    )})`,
                  },
                  {
                    value: `${formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.failureAmount),
                        0
                      )
                    )} (${daybookData.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.failureCount),
                      0
                    )})`,
                  },
                  {
                    value: `${formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.pendingAmount),
                        0
                      )
                    )} (${daybookData.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.pendingCount),
                      0
                    )})`,
                  },
                  {
                    value: formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.commission),
                        0
                      )
                    ),
                  },
                  {
                    value: formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.commissionEarned),
                        0
                      )
                    ),
                  },
                  {
                    value: formatCurrency(
                      daybookData.reduce(
                        (previousValue, currentValue) =>
                          previousValue + Number(currentValue.profit),
                        0
                      )
                    ),
                  },
                ]
          }
        />
      )}
    </>
  );
};

export default withAppHOC(UsersOperatorsDayBookTable);
