import {
  faUsers,
  faPercent,
  faGears,
  faIndianRupeeSign,
  faBank,
  faChartPie,
  faHome,
  faBook,
} from '@fortawesome/free-solid-svg-icons';
import { ROUTE_TYPES, MODULES, PERMISSION_TYPES, VIEW_TYPES } from './index';
import Register from '../containers/Auth/Register';
import Login from '../containers/Auth/Login';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import Profile from '../containers/Auth/Profile';
import ChangePassword from '../containers/Auth/ChangePassword';
import Sessions from '../containers/Auth/Sessions';
import Logs from '../containers/Auth/Logs';
import ReferAndEarn from '../containers/Auth/ReferAndEarn';
import WalletTransactions from '../containers/Auth/WalletTransactions';
import Dashboard from '../containers/Dashboard/Dashboard';
import Stats from '../containers/Stats/Stats';
// Accounts Management
import RolesAndPermissionsTable from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsTable';
import RolesAndPermissionsCreate from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsCreate';
import RolesAndPermissionsUpdate from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsUpdate';
import AccountsTable from '../containers/AccountsManagement/Accounts/AccountsTable';
import AccountsCreate from '../containers/AccountsManagement/Accounts/AccountsCreate';
import AccountsRead from '../containers/AccountsManagement/Accounts/AccountsRead';
import AccountsUpdate from '../containers/AccountsManagement/Accounts/AccountsUpdate';
import VendorsTable from '../containers/AccountsManagement/Vendors/VendorsTable';
import VendorsCreate from '../containers/AccountsManagement/Vendors/VendorsCreate';
import VendorsUpdate from '../containers/AccountsManagement/Vendors/VendorsUpdate';
// Configurations
import AppMetaData from '../containers/Configurations/AppMetaData/AppMetaDataGrid';
import CompanyDetails from '../containers/Configurations/AppMetaData/CompanyDetails';
import ReferralConfiguration from '../containers/Configurations/ReferralConfiguration/ReferralConfiguration';
import PaymentGatewayConfiguration from '../containers/Configurations/PaymentGatewayConfiguration/PaymentGatewayConfiguration';
import MessagingConfiguration from '../containers/Configurations/MessagingConfiguration/MessagingConfiguration';
import PushNotificationsConfiguration from '../containers/Configurations/PushNotificationsConfiguration/PushNotificationsConfiguration';
import NotificationsGrid from '../containers/Configurations/Notifications/NotificationsGrid';
import ScrollMessageGrid from '../containers/Configurations/ScrollMessage/ScrollMessageGrid';
import SmsTemplates from '../containers/Configurations/SmsTemplates/SmsTemplates';
import BankAccountsTable from '../containers/Configurations/BankAccounts/BankAccountsTable';
import BankAccountsCreate from '../containers/Configurations/BankAccounts/BankAccountsCreate';
import BankAccountsUpdate from '../containers/Configurations/BankAccounts/BankAccountsUpdate';
import APISettings from '../containers/Configurations/APISettings/APISettingsGrid';
import APIKeys from '../containers/Configurations/APIKeys/APIKeysGrid';
// Recharge Configurations
import ServicesTable from '../containers/Configurations/Services/ServicesTable';
import ServicesCreate from '../containers/Configurations/Services/ServicesCreate';
import ServicesUpdate from '../containers/Configurations/Services/ServicesUpdate';
import CirclesTable from '../containers/Configurations/Circles/CirclesTable';
import CirclesCreate from '../containers/Configurations/Circles/CirclesCreate';
import CirclesUpdate from '../containers/Configurations/Circles/CirclesUpdate';
import OperatorsTable from '../containers/Configurations/Operators/OperatorsTable';
import OperatorsCreate from '../containers/Configurations/Operators/OperatorsCreate';
import OperatorsUpdate from '../containers/Configurations/Operators/OperatorsUpdate';
import OperatorsAmountRangesTable from '../containers/Configurations/OperatorsAmountRanges/OperatorsAmountRangesTable';
import OperatorsAmountRangesCreate from '../containers/Configurations/OperatorsAmountRanges/OperatorsAmountRangesCreate';
import OperatorsAmountRangesUpdate from '../containers/Configurations/OperatorsAmountRanges/OperatorsAmountRangesUpdate';
import APIProvidersTable from '../containers/Configurations/APIProviders/APIProvidersTable';
import APIProvidersCreate from '../containers/Configurations/APIProviders/APIProvidersCreate';
import APIProvidersUpdate from '../containers/Configurations/APIProviders/APIProvidersUpdate';
import APIProvidersOperatorsMapping from '../containers/Configurations/APIProviders/APIProvidersOperatorsMapping';
import OperatorsMappingTable from '../containers/Configurations/OperatorsMapping/OperatorsMappingTable';
import OperatorsMappingCreate from '../containers/Configurations/OperatorsMapping/OperatorsMappingCreate';
import OperatorsMappingUpdate from '../containers/Configurations/OperatorsMapping/OperatorsMappingUpdate';
import APISwitchingTable from '../containers/Configurations/APISwitching/APISwitchingTable';
import APISwitchingCreate from '../containers/Configurations/APISwitching/APISwitchingCreate';
import APISwitchingUpdate from '../containers/Configurations/APISwitching/APISwitchingUpdate';
import StockManagementTable from '../containers/Configurations/StockManagement/StockManagementTable';
import StockManagementCreate from '../containers/Configurations/StockManagement/StockManagementCreate';
import StockManagementUpdate from '../containers/Configurations/StockManagement/StockManagementUpdate';
import AccountsAPIMappingTable from '../containers/Configurations/AccountsAPIMapping/AccountsAPIMappingTable';
import AccountsAPIMappingCreate from '../containers/Configurations/AccountsAPIMapping/AccountsAPIMappingCreate';
import AccountsAPIMappingUpdate from '../containers/Configurations/AccountsAPIMapping/AccountsAPIMappingUpdate';
import RolesAPIMappingTable from '../containers/Configurations/RolesAPIMapping/RolesAPIMappingTable';
import RolesAPIMappingUpdate from '../containers/Configurations/RolesAPIMapping/RolesAPIMappingUpdate';
import InvalidRechargeAmountsTable from '../containers/Configurations/InvalidRechargeAmounts/InvalidRechargeAmountsTable';
import InvalidRechargeAmountsCreate from '../containers/Configurations/InvalidRechargeAmounts/InvalidRechargeAmountsCreate';
import InvalidRechargeAmountsUpdate from '../containers/Configurations/InvalidRechargeAmounts/InvalidRechargeAmountsUpdate';
import AccountOperatorBlockersTable from '../containers/Configurations/AccountOperatorBlockers/AccountOperatorBlockersTable';
import AccountOperatorBlockersCreate from '../containers/Configurations/AccountOperatorBlockers/AccountOperatorBlockersCreate';
import BillFetchConfigurationTable from '../containers/Configurations/BillFetchConfiguration/BillFetchConfigurationTable';
import BillFetchConfigurationCreate from '../containers/Configurations/BillFetchConfiguration/BillFetchConfigurationCreate';
import BillFetchConfigurationUpdate from '../containers/Configurations/BillFetchConfiguration/BillFetchConfigurationUpdate';
import PlanAPIConfiguration from '../containers/Configurations/PlanAPIConfiguration/PlanAPIConfigurationGrid';
// Margins
import MyMarginsTable from '../containers/Margins/MyMargins/MyMarginsTable';
import MarginTemplatesTable from '../containers/Margins/MarginTemplates/MarginTemplatesTable';
import MarginTemplatesCreate from '../containers/Margins/MarginTemplates/MarginTemplatesCreate';
import MarginTemplatesUpdate from '../containers/Margins/MarginTemplates/MarginTemplatesUpdate';
import RolesDefaultMarginTemplatesTable from '../containers/Margins/RolesDefaultMarginTemplatesTable/RolesDefaultMarginTemplatesTable';
// Transactions
import RechargeTransactionsTable from '../containers/Transactions/RechargeTransactions/RechargeTransactionsTable';
import PendingRechargeTransactionsTable from '../containers/Transactions/PendingRechargeTransactions/PendingRechargeTransactionsTable';
import DisputedRechargeTransactionsTable from '../containers/Transactions/DisputedRechargeTransactions/DisputedRechargeTransactionsTable';
import LiveRechargeTransactionsTable from '../containers/Transactions/RechargeTransactions/LiveRechargeTransactionsTable';
import ApiCallbackLogsTable from '../containers/Transactions/ApiCallbackLogs/ApiCallbackLogs';
// Accounting
import WalletDepositRequestsTable from '../containers/Accounting/WalletDepositRequests/WalletDepositRequestsTable';
import WalletDepositRequestsCreate from '../containers/Accounting/WalletDepositRequests/WalletDepositRequestsCreate';
import PaymentGatewayDepositsTable from '../containers/Accounting/PaymentGatewayDeposits/PaymentGatewayDepositsTable';
import PaymentGatewayDepositsCreate from '../containers/Accounting/PaymentGatewayDeposits/PaymentGatewayDepositsCreate';
import PaymentGatewayDepositsStatus from '../containers/Accounting/PaymentGatewayDeposits/PaymentGatewayDepositsStatus';
import VendorPaymentsTable from '../containers/Accounting/VendorPayments/VendorPaymentsTable';
import VendorPaymentsCreate from '../containers/Accounting/VendorPayments/VendorPaymentsCreate';
import VendorPaymentsUpdate from '../containers/Accounting/VendorPayments/VendorPaymentsUpdate';
import VendorGSTInvoices from '../containers/Accounting/VendorGSTInvoices/VendorGSTInvoices';
import AccountGSTInvoices from '../containers/Accounting/AccountGSTInvoices/AccountGSTInvoices';
import GSTInvoicesSummary from '../containers/Accounting/GSTInvoicesSummary/GSTInvoicesSummary';
import APIProviderPaymentsTable from '../containers/Accounting/APIProviderPayments/APIProviderPaymentsTable';
import APIProviderPaymentsCreate from '../containers/Accounting/APIProviderPayments/APIProviderPaymentsCreate';
import APIProviderPaymentsUpdate from '../containers/Accounting/APIProviderPayments/APIProviderPaymentsUpdate';
// Reports
import OperatorDayBookTable from '../containers/Reports/OperatorDayBook/OperatorDayBookTable';
import APISummaryBookTable from '../containers/Reports/APISummaryBook/APISummaryBookTable';
import AccountsDayBookTable from '../containers/Reports/AccountsDayBook/AccountsDayBookTable';
import UsersOperatorsDayBookTable from '../containers/Reports/UsersOperatorsDayBook/UsersOperatorsDayBookTable';
import AlertHistory from '../containers/Reports/AlertHistory/AlertHistory';
// API Documentation
import APIDocumentation from '../containers/APIDocumentation/APIDocumentationGrid';
// Others
import PrivacyPolicy from '../containers/Auth/PrivacyPolicy';
import TermsAndConditions from '../containers/Auth/TermsAndConditions';
import DeleteAccount from '../containers/Auth/DeleteAccount';

const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
const {
  DASHBOARD,
  STATS,
  // Accounts Management
  ACCOUNTS_MANAGEMENT,
  ROLES_AND_PERMISSIONS,
  ACCOUNTS,
  VENDORS,
  // Configurations
  CONFIGURATIONS_MANAGEMENT,
  APP_META_DATA,
  REFERRAL_CONFIGURATION,
  PAYMENT_GATEWAY_CONFIGURATION,
  MESSAGING_CONFIGURATION,
  PUSH_NOTIFICATIONS_CONFIGURATION,
  NOTIFICATIONS,
  SCROLL_MESSAGE,
  SMS_TEMPLATES,
  BANK_ACCOUNTS,
  API_SETTINGS,
  API_KEYS,
  // Recharge Configurations
  RECHARGE_CONFIGURATIONS_MANAGEMENT,
  SERVICES,
  CIRCLES,
  OPERATORS,
  OPERATORS_AMOUNT_RANGES,
  API_PROVIDERS,
  OPERATORS_MAPPING,
  API_SWITCHING,
  STOCK_MANAGEMENT,
  ACCOUNTS_API_MAPPING,
  ROLES_API_MAPPING,
  INVALID_RECHARGE_AMOUNTS,
  ACCOUNT_OPERATOR_BLOCKERS,
  BILL_FETCH_CONFIGURATION,
  COMMON_APIS_CONFIGURATION,
  // Margins
  MARGIN_TEMPLATES_MANAGEMENT,
  MY_MARGINS,
  MARGIN_TEMPLATES,
  ROLES_DEFAULT_MARGIN_TEMPLATES,
  // Transactions
  TRANSACTIONS_MANAGEMENT,
  RECHARGE_TRANSACTIONS,
  PENDING_RECHARGE_TRANSACTIONS,
  DISPUTED_RECHARGE_TRANSACTIONS,
  LIVE_RECHARGE_TRANSACTIONS,
  API_CALLBACK_LOGS,
  // Accounting
  ACCOUNTING_MANAGEMENT,
  WALLET_DEPOSIT_REQUESTS,
  PAYMENT_GATEWAY_DEPOSITS,
  VENDOR_PAYMENTS,
  VENDOR_GST_INVOICES,
  ACCOUNT_GST_INVOICES,
  GST_INVOICES_SUMMARY,
  API_PROVIDER_PAYMENTS,
  // Reports
  REPORTS_MANAGEMENT,
  OPERATORS_DAY_BOOK,
  API_SUMMARY_BOOK,
  ACCOUNTS_DAY_BOOK,
  USERS_OPERATORS_DAY_BOOK,
  ALERT_HISTORY,
  // API Documentation
  API_DOCS,
} = MODULES;
const { CREATE, READ, UPDATE, WALLET_TRANSACTIONS_READ, OPERATORS_CONFIG } =
  PERMISSION_TYPES;
const { TABLE, GRID, VIEW } = VIEW_TYPES;

const ROUTES = [
  {
    path: '/',
    title: 'Home',
    icon: faHome,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: DASHBOARD,
    permissionRequired: READ,
    component: Dashboard,
    hideHeading: true,
    hideBreadcrumbs: true,
    exact: true,
  },
  {
    path: '/register',
    title: 'Register',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Register,
    hideInNavigation: true,
  },

  {
    path: '/login',
    title: 'Login',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Login,
    hideInNavigation: true,
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: ForgotPassword,
    hideInNavigation: true,
  },
  {
    path: '/profile',
    title: 'My Profile',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Profile,
    hideInNavigation: true,
  },
  {
    path: '/change-password',
    title: 'Change Password',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: ChangePassword,
    hideInNavigation: true,
  },
  {
    path: '/sessions',
    title: 'My Sessions',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Sessions,
    hideInNavigation: true,
    viewType: TABLE,
    accountsPermissions: [READ],
  },
  {
    path: '/logs',
    title: 'My Logs',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Logs,
    hideInNavigation: true,
    viewType: TABLE,
    accountsPermissions: [READ],
  },
  {
    path: '/refer-and-earn',
    title: 'Refer & Earn',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: ReferAndEarn,
    hideInNavigation: true,
  },
  {
    path: '/wallet-transactions',
    title: 'My Wallet Transactions',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: WalletTransactions,
    hideInNavigation: true,
    viewType: TABLE,
    accountsPermissions: [READ],
  },
  {
    path: '/stats',
    title: 'Stats',
    icon: faBook,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: STATS,
    permissionRequired: READ,
    viewType: GRID,
    component: Stats,
  },
  // Accounts Management
  {
    path: '/accounts-management',
    title: 'Accounts Management',
    icon: faUsers,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: ACCOUNTS_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/accounts-management/roles-and-permissions',
        title: 'Roles & Permissions',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ROLES_AND_PERMISSIONS,
        permissionRequired: READ,
        component: RolesAndPermissionsTable,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/roles-and-permissions/create',
            title: 'Create Roles & Permissions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: CREATE,
            component: RolesAndPermissionsCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/roles-and-permissions/update/:id',
            title: 'Update Roles & Permissions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: UPDATE,
            component: RolesAndPermissionsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounts-management/accounts',
        title: 'Accounts',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNTS,
        permissionRequired: READ,
        component: AccountsTable,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/accounts/create',
            title: 'Create Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: CREATE,
            component: AccountsCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/accounts/view/:id',
            title: 'Accounts Wallet Transactions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: WALLET_TRANSACTIONS_READ,
            component: AccountsRead,
            viewType: VIEW,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/accounts/update/:id',
            title: 'Update Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: UPDATE,
            component: AccountsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounts-management/vendors',
        title: 'Vendors',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: VENDORS,
        permissionRequired: READ,
        component: VendorsTable,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/vendors/create',
            title: 'Create Vendor',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: VENDORS,
            permissionRequired: CREATE,
            component: VendorsCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/vendors/update/:id',
            title: 'Update Vendor',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: VENDORS,
            permissionRequired: UPDATE,
            component: VendorsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
    ],
  },
  // Configurations
  {
    path: '/configurations',
    title: 'Configurations',
    icon: faGears,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: CONFIGURATIONS_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/configurations/app-configuration',
        title: 'App Configuration',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: APP_META_DATA,
        permissionRequired: UPDATE,
        component: AppMetaData,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/company-details',
        title: 'Company Details',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: APP_META_DATA,
        permissionRequired: UPDATE,
        component: CompanyDetails,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/referral-configuration',
        title: 'Referral Configuration',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: REFERRAL_CONFIGURATION,
        permissionRequired: UPDATE,
        component: ReferralConfiguration,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/payment-gateway-configuration',
        title: 'Payment Gateway Configuration',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PAYMENT_GATEWAY_CONFIGURATION,
        permissionRequired: UPDATE,
        component: PaymentGatewayConfiguration,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/messaging-configuration',
        title: 'Messaging Configuration',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: MESSAGING_CONFIGURATION,
        permissionRequired: UPDATE,
        component: MessagingConfiguration,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/push-notifications-configuration',
        title: 'Push Notifications Configuration',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PUSH_NOTIFICATIONS_CONFIGURATION,
        permissionRequired: UPDATE,
        component: PushNotificationsConfiguration,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/notifications',
        title: 'Send Notifications',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: NOTIFICATIONS,
        permissionRequired: CREATE,
        component: NotificationsGrid,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/scroll-message',
        title: 'Scroll Message',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SCROLL_MESSAGE,
        permissionRequired: UPDATE,
        component: ScrollMessageGrid,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/sms-templates',
        title: 'SMS Templates',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SMS_TEMPLATES,
        permissionRequired: READ,
        component: SmsTemplates,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/bank-accounts',
        title: 'Bank Accounts',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BANK_ACCOUNTS,
        permissionRequired: READ,
        component: BankAccountsTable,
        viewType: TABLE,
        masterModule: CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/configurations/bank-accounts/create',
            title: 'Create Bank Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BANK_ACCOUNTS,
            permissionRequired: CREATE,
            component: BankAccountsCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/configurations/bank-accounts/update/:id',
            title: 'Update Bank Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BANK_ACCOUNTS,
            permissionRequired: UPDATE,
            component: BankAccountsUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/api-settings',
        title: 'API Settings',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: API_SETTINGS,
        permissionRequired: READ,
        component: APISettings,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
      {
        path: '/configurations/api-keys',
        title: 'API Keys',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: API_KEYS,
        permissionRequired: READ,
        component: APIKeys,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
    ],
  },
  // Recharge Configurations
  {
    path: '/recharge-configurations',
    title: 'Recharge Configurations',
    icon: faGears,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/recharge-configurations/services',
        title: 'Services',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SERVICES,
        permissionRequired: READ,
        component: ServicesTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/services/create',
            title: 'Create Service',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SERVICES,
            permissionRequired: CREATE,
            component: ServicesCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/services/update/:id',
            title: 'Update Service',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SERVICES,
            permissionRequired: UPDATE,
            component: ServicesUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/circles',
        title: 'Circles',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: CIRCLES,
        permissionRequired: READ,
        component: CirclesTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/circles/create',
            title: 'Create Circle',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CIRCLES,
            permissionRequired: CREATE,
            component: CirclesCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/circles/update/:id',
            title: 'Update Circle',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CIRCLES,
            permissionRequired: UPDATE,
            component: CirclesUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/operators',
        title: 'Operators',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: OPERATORS,
        permissionRequired: READ,
        component: OperatorsTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/operators/create',
            title: 'Create Operator',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS,
            permissionRequired: CREATE,
            component: OperatorsCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/operators/update/:id',
            title: 'Update Operator',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS,
            permissionRequired: UPDATE,
            component: OperatorsUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/operators-amount-ranges',
        title: 'Operators Amount Ranges',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: OPERATORS_AMOUNT_RANGES,
        permissionRequired: READ,
        component: OperatorsAmountRangesTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/operators-amount-ranges/create',
            title: 'Create Operators Amount Range',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS_AMOUNT_RANGES,
            permissionRequired: CREATE,
            component: OperatorsAmountRangesCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/operators-amount-ranges/update/:id',
            title: 'Update Operators Amount Range',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS_AMOUNT_RANGES,
            permissionRequired: UPDATE,
            component: OperatorsAmountRangesUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/api-providers',
        title: 'API Providers',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: API_PROVIDERS,
        permissionRequired: READ,
        component: APIProvidersTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/api-providers/create',
            title: 'Create API Provider',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: API_PROVIDERS,
            permissionRequired: CREATE,
            component: APIProvidersCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/api-providers/update/:id',
            title: 'Update API Provider',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: API_PROVIDERS,
            permissionRequired: UPDATE,
            component: APIProvidersUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/api-providers/operator-mapping/:id',
            title: 'Operators Mapping for API Provider',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: API_PROVIDERS,
            permissionRequired: OPERATORS_CONFIG,
            component: APIProvidersOperatorsMapping,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/operators-mapping',
        title: 'Operators Mapping',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: OPERATORS_MAPPING,
        permissionRequired: READ,
        component: OperatorsMappingTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/operators-mapping/create',
            title: 'Create Operators Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS_MAPPING,
            permissionRequired: CREATE,
            component: OperatorsMappingCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/operators-mapping/update/:id',
            title: 'Update Operators Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS_MAPPING,
            permissionRequired: UPDATE,
            component: OperatorsMappingUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/api-switching',
        title: 'API Switchings',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: API_SWITCHING,
        permissionRequired: READ,
        component: APISwitchingTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/api-switching/create',
            title: 'Create API Switching',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: API_SWITCHING,
            permissionRequired: CREATE,
            component: APISwitchingCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/api-switching/update/:id',
            title: 'Update API Switching',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: API_SWITCHING,
            permissionRequired: UPDATE,
            component: APISwitchingUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/stock-management',
        title: 'Stock Management',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: STOCK_MANAGEMENT,
        permissionRequired: READ,
        component: StockManagementTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/stock-management/create',
            title: 'Create Stock Management',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: STOCK_MANAGEMENT,
            permissionRequired: CREATE,
            component: StockManagementCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/stock-management/update/:id',
            title: 'Update Stock Management',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: STOCK_MANAGEMENT,
            permissionRequired: UPDATE,
            component: StockManagementUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/accounts-api-mapping',
        title: 'Accounts API Mapping',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNTS_API_MAPPING,
        permissionRequired: READ,
        component: AccountsAPIMappingTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/accounts-api-mapping/create',
            title: 'Create Accounts API Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS_API_MAPPING,
            permissionRequired: CREATE,
            component: AccountsAPIMappingCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/accounts-api-mapping/update/:id',
            title: 'Update Accounts API Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS_API_MAPPING,
            permissionRequired: UPDATE,
            component: AccountsAPIMappingUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/roles-api-mapping',
        title: 'Roles API Mapping',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ROLES_API_MAPPING,
        permissionRequired: READ,
        component: RolesAPIMappingTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/roles-api-mapping/update/:id',
            title: 'Update Roles API Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_API_MAPPING,
            permissionRequired: UPDATE,
            component: RolesAPIMappingUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/invalid-recharge-amounts',
        title: 'Invalid Recharge Amounts',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: INVALID_RECHARGE_AMOUNTS,
        permissionRequired: READ,
        component: InvalidRechargeAmountsTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/invalid-recharge-amounts/create',
            title: 'Create Invalid Recharge Amount',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: INVALID_RECHARGE_AMOUNTS,
            permissionRequired: CREATE,
            component: InvalidRechargeAmountsCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/invalid-recharge-amounts/update/:id',
            title: 'Update Invalid Recharge Amount',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: INVALID_RECHARGE_AMOUNTS,
            permissionRequired: UPDATE,
            component: InvalidRechargeAmountsUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/account-operator-blockers',
        title: 'Account Operator Blockers',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNT_OPERATOR_BLOCKERS,
        permissionRequired: READ,
        component: AccountOperatorBlockersTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/account-operator-blockers/create',
            title: 'Create Account Operator Blocker',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNT_OPERATOR_BLOCKERS,
            permissionRequired: CREATE,
            component: AccountOperatorBlockersCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/bill-fetch-configuration',
        title: 'Bill Fetch Configuration',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BILL_FETCH_CONFIGURATION,
        permissionRequired: READ,
        component: BillFetchConfigurationTable,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/recharge-configurations/bill-fetch-configuration/create',
            title: 'Create Bill Fetch Configuration',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BILL_FETCH_CONFIGURATION,
            permissionRequired: CREATE,
            component: BillFetchConfigurationCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/recharge-configurations/bill-fetch-configuration/update/:id',
            title: 'Update Bill Fetch Configuration',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BILL_FETCH_CONFIGURATION,
            permissionRequired: UPDATE,
            component: BillFetchConfigurationUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/recharge-configurations/plan-api-configuration',
        title: 'Plan API Configuration',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: COMMON_APIS_CONFIGURATION,
        permissionRequired: READ,
        component: PlanAPIConfiguration,
        viewType: GRID,
        masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
      },
    ],
  },
  // Margins
  {
    path: '/margins',
    title: 'Margins',
    icon: faPercent,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: MARGIN_TEMPLATES_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/margins/my-margins',
        title: 'My Margins',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: MY_MARGINS,
        permissionRequired: READ,
        component: MyMarginsTable,
        viewType: VIEW,
        masterModule: MARGIN_TEMPLATES_MANAGEMENT,
      },
      {
        path: '/margins/margin-templates',
        title: 'Margin Templates',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: MARGIN_TEMPLATES,
        permissionRequired: READ,
        component: MarginTemplatesTable,
        viewType: TABLE,
        masterModule: MARGIN_TEMPLATES_MANAGEMENT,
        subRoutes: [
          {
            path: '/margins/margin-templates/create',
            title: 'Create Margin Template',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: MARGIN_TEMPLATES,
            permissionRequired: CREATE,
            component: MarginTemplatesCreate,
            viewType: GRID,
            masterModule: MARGIN_TEMPLATES_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/margins/margin-templates/update/:id',
            title: 'Update Margin Template',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: MARGIN_TEMPLATES,
            permissionRequired: UPDATE,
            component: MarginTemplatesUpdate,
            viewType: GRID,
            masterModule: MARGIN_TEMPLATES_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/margins/roles-default-margin-templates',
        title: 'Roles Default Margin Templates',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ROLES_DEFAULT_MARGIN_TEMPLATES,
        permissionRequired: READ,
        component: RolesDefaultMarginTemplatesTable,
        viewType: TABLE,
        masterModule: MARGIN_TEMPLATES_MANAGEMENT,
      },
    ],
  },
  // Transactions
  {
    path: '/transactions',
    title: 'Transactions',
    icon: faIndianRupeeSign,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: TRANSACTIONS_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/transactions/recharge-transactions',
        title: 'Recharge Transactions',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: RechargeTransactionsTable,
        viewType: TABLE,
        masterModule: TRANSACTIONS_MANAGEMENT,
      },
      {
        path: '/transactions/pending-recharge-transactions',
        title: 'Pending Recharge Transactions',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PENDING_RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: PendingRechargeTransactionsTable,
        viewType: TABLE,
        masterModule: TRANSACTIONS_MANAGEMENT,
      },
      {
        path: '/transactions/disputed-recharge-transactions',
        title: 'Disputed Recharge Transactions',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: DISPUTED_RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: DisputedRechargeTransactionsTable,
        viewType: TABLE,
        masterModule: TRANSACTIONS_MANAGEMENT,
      },
      {
        path: '/transactions/live-recharge-transactions',
        title: 'Live Recharge Transactions',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: LIVE_RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: LiveRechargeTransactionsTable,
        viewType: TABLE,
        masterModule: TRANSACTIONS_MANAGEMENT,
        target: '_blank',
      },
      {
        path: '/transactions/api-callback-logs',
        title: 'API Callback Logs',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: API_CALLBACK_LOGS,
        permissionRequired: READ,
        component: ApiCallbackLogsTable,
        viewType: TABLE,
        masterModule: TRANSACTIONS_MANAGEMENT,
      },
    ],
  },
  // Accounting
  {
    path: '/accounting',
    title: 'Accounting',
    icon: faBank,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: ACCOUNTING_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/accounting/wallet-deposit-requests',
        title: 'Wallet Deposit Requests',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: WALLET_DEPOSIT_REQUESTS,
        permissionRequired: READ,
        component: WalletDepositRequestsTable,
        viewType: TABLE,
        masterModule: ACCOUNTING_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounting/wallet-deposit-requests/create',
            title: 'Create Wallet Deposit Request',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: WALLET_DEPOSIT_REQUESTS,
            permissionRequired: CREATE,
            component: WalletDepositRequestsCreate,
            viewType: GRID,
            masterModule: ACCOUNTING_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounting/payment-gateway-deposits',
        title: 'Payment Gateway Deposits',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PAYMENT_GATEWAY_DEPOSITS,
        permissionRequired: READ,
        component: PaymentGatewayDepositsTable,
        viewType: TABLE,
        masterModule: ACCOUNTING_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounting/payment-gateway-deposits/create',
            title: 'Create Payment Gateway Deposit',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: PAYMENT_GATEWAY_DEPOSITS,
            permissionRequired: CREATE,
            component: PaymentGatewayDepositsCreate,
            viewType: GRID,
            masterModule: ACCOUNTING_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounting/payment-gateway-deposits/status/:id',
            title: 'Payment Gateway Deposit Status',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: PAYMENT_GATEWAY_DEPOSITS,
            permissionRequired: CREATE,
            component: PaymentGatewayDepositsStatus,
            viewType: GRID,
            masterModule: ACCOUNTING_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounting/vendor-payments',
        title: 'Vendor Payments',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: VENDOR_PAYMENTS,
        permissionRequired: READ,
        component: VendorPaymentsTable,
        viewType: TABLE,
        masterModule: ACCOUNTING_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounting/vendor-payments/create',
            title: 'Create Vendor Payment',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: VENDOR_PAYMENTS,
            permissionRequired: CREATE,
            component: VendorPaymentsCreate,
            viewType: GRID,
            masterModule: ACCOUNTING_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounting/vendor-payments/update/:id',
            title: 'Update Vendor Payment',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: VENDOR_PAYMENTS,
            permissionRequired: UPDATE,
            component: VendorPaymentsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTING_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounting/vendor-gst-invoices',
        title: 'Vendor GST Invoices',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: VENDOR_GST_INVOICES,
        permissionRequired: READ,
        component: VendorGSTInvoices,
        viewType: GRID,
        masterModule: ACCOUNTING_MANAGEMENT,
      },
      {
        path: '/accounting/account-gst-invoices',
        title: 'Account GST Invoices',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNT_GST_INVOICES,
        permissionRequired: READ,
        component: AccountGSTInvoices,
        viewType: GRID,
        masterModule: ACCOUNTING_MANAGEMENT,
      },
      {
        path: '/accounting/gst-invoices-summary',
        title: 'GST Invoices Summary',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: GST_INVOICES_SUMMARY,
        permissionRequired: READ,
        component: GSTInvoicesSummary,
        viewType: GRID,
        masterModule: ACCOUNTING_MANAGEMENT,
      },
      {
        path: '/accounting/api-provider-payments',
        title: 'API Provider Payments',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: API_PROVIDER_PAYMENTS,
        permissionRequired: READ,
        component: APIProviderPaymentsTable,
        viewType: TABLE,
        masterModule: ACCOUNTING_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounting/api-provider-payments/create',
            title: 'Create API Provider Payment',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: API_PROVIDER_PAYMENTS,
            permissionRequired: CREATE,
            component: APIProviderPaymentsCreate,
            viewType: GRID,
            masterModule: ACCOUNTING_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounting/api-provider-payments/update/:id',
            title: 'Update API Provider Payment',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: API_PROVIDER_PAYMENTS,
            permissionRequired: UPDATE,
            component: APIProviderPaymentsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTING_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
    ],
  },
  // Reports
  {
    path: '/reports',
    title: 'Reports',
    icon: faChartPie,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: REPORTS_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/reports/operator-day-book',
        title: 'Operator Day Book',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: OPERATORS_DAY_BOOK,
        permissionRequired: READ,
        component: OperatorDayBookTable,
        viewType: TABLE,
        masterModule: REPORTS_MANAGEMENT,
      },
      {
        path: '/reports/api-summary-book',
        title: 'API Summary Book',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: API_SUMMARY_BOOK,
        permissionRequired: READ,
        component: APISummaryBookTable,
        viewType: TABLE,
        masterModule: REPORTS_MANAGEMENT,
      },
      {
        path: '/reports/accounts-day-book',
        title: 'Day Book',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNTS_DAY_BOOK,
        permissionRequired: READ,
        component: AccountsDayBookTable,
        viewType: TABLE,
        masterModule: REPORTS_MANAGEMENT,
      },
      {
        path: '/reports/users-operators-day-book',
        title: 'Users Operators Day Book',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS_OPERATORS_DAY_BOOK,
        permissionRequired: READ,
        component: UsersOperatorsDayBookTable,
        viewType: TABLE,
        masterModule: REPORTS_MANAGEMENT,
      },
      {
        path: '/reports/alert-history',
        title: 'Alert History',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ALERT_HISTORY,
        permissionRequired: READ,
        component: AlertHistory,
        viewType: GRID,
        masterModule: REPORTS_MANAGEMENT,
      },
    ],
  },
  // API Documentation
  {
    path: '/api-documentation',
    title: 'API Documentation',
    icon: faBook,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: API_DOCS,
    permissionRequired: READ,
    viewType: GRID,
    component: APIDocumentation,
  },
  // Others
  {
    path: '/privacy-policy',
    title: 'Privacy Policy',
    component: PrivacyPolicy,
    hideInNavigation: true,
  },
  {
    path: '/terms-and-conditions',
    title: 'Terms & Conditions',
    component: TermsAndConditions,
    hideInNavigation: true,
  },
  {
    path: '/delete-account',
    title: 'Delete Account',
    component: DeleteAccount,
    hideInNavigation: true,
  },
];

export { ROUTES };
