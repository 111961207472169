import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Modal from '../Modal';
import { StyledModalContainer } from '../Styled';

const StyledNotificationsSectionContainer = styled.section`
  width: 55px;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #e5e5e5;
  position: relative;
  @media (max-width: 600px) {
    position: static;
  }
`;

const StyledNotificationsIconContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 100%;
    border-width: 0px;
    background-color: transparent;
    outline: 0px;
    cursor: pointer;
    transition: background-color 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:hover {
      background-color: #e5e5e5;
    }
    &.active {
      background-color: ${({
        theme: {
          colors: { secondaryColor },
        },
      }) => (secondaryColor ? secondaryColor : `#0078ce`)};
      svg {
        color: #fff;
      }
    }
    svg {
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      color: #707070;
    }
  }
`;

const StyledNotificationsContent = styled.section`
  position: absolute;
  width: 380px;
  height: auto;
  background-color: #fff;
  top: 55px;
  right: 0px;
  border-radius: 0px;
  box-shadow: 0px 5px 15px -10px #000000;
  z-index: 1;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledNotificationsContentHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  background-color: ${({
    theme: {
      colors: { secondaryColor },
    },
  }) => (secondaryColor ? secondaryColor : `#0078ce`)};
  h3 {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    margin: 0px;
    padding: 12px;
    text-align: left;
  }
`;

const StyledNotificationsContentLinks = styled.section`
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  margin: 0 auto;
  padding: 0px;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      margin: 0px;
      padding: 0px;
      display: block;
      border-bottom: 1px solid #e5e5e5;
      transition: background-color 0.5s;
      a {
        font-size: 14px;
        text-decoration: none;
        outline: 0px;
        color: #000;
        margin: 0px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          font-size: 25px;
          color: ${({
            theme: {
              colors: { primaryColor },
            },
          }) => (primaryColor ? primaryColor : `#0095ff`)};
          margin-right: 12px;
        }
      }
      &.active,
      &:hover {
        background-color: #e5e5e5;
      }
    }
    &:last-child {
      border-bottom-width: 0px;
    }
  }
`;

const StyledRedDot = styled.section`
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: red;
  position: absolute;
  top: 18%;
`;

const NoNotifications = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0px;
    padding: 0px;
  }
`;

const NotificationItem = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  background-color: ${({ status }) =>
    status === 'Sent' ? '#ffffff' : '#ffffff'};
  cursor: pointer;
`;

const NotificationItemHeading = styled.section`
  background-color: #e7e7e7;
  h3 {
    width: 80%;
    font-size: 14px;
    color: #000000;
    margin: 0px;
    padding: 10px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NotificationItemBody = styled.section`
  width: 80%;
  font-size: 12px;
  color: #545454;
  margin: 0px;
  padding: 10px 10px 0px 10px;
  line-height: 16px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NotificationItemFooter = styled.section`
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 10px;
    color: #8d8d8d;
    margin: 0px;
    padding: 10px;
    font-weight: normal;
    font-style: italic;
  }
`;

const ModalTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
  padding: 10px;
`;

const ModalMessage = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #545454;
  margin: 0px;
  padding: 0px 10px 20px 10px;
  line-height: 20px;
`;

const NotificationsSection = ({ notifications, updateNotification }) => {
  const [expandNotifications, setExpandNotifications] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const notificationsRef = useRef(null);

  const onClickExpandNotifications = (e) => {
    e.preventDefault();
    setExpandNotifications((expandNotifications) => !expandNotifications);
  };

  const closeNotificationsList = useCallback((e) => {
    if (
      notificationsRef.current &&
      !notificationsRef.current.contains(e.target)
    ) {
      setExpandNotifications(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeNotificationsList, true);
    return () => {
      document.removeEventListener('click', closeNotificationsList, true);
    };
  }, [closeNotificationsList]);

  const hasNewNotifications = notifications.some(
    (item) => item.status === 'Sent'
  );

  return (
    <>
      <StyledNotificationsSectionContainer ref={notificationsRef}>
        <StyledNotificationsIconContainer>
          <button
            type='button'
            className={expandNotifications ? `active` : ``}
            onClick={onClickExpandNotifications}
          >
            <FontAwesomeIcon icon={faBell} />
            {hasNewNotifications && <StyledRedDot />}
          </button>
        </StyledNotificationsIconContainer>
        {expandNotifications && (
          <StyledNotificationsContent>
            <StyledNotificationsContentHeader>
              <h3>Notifications</h3>
            </StyledNotificationsContentHeader>
            <StyledNotificationsContentLinks>
              {notifications.length === 0 && (
                <NoNotifications>
                  <p>No notifications found</p>
                </NoNotifications>
              )}
              {notifications.length > 0 &&
                notifications.map((item, index) => {
                  const { id, title, message, status, createdOn } = item;
                  return (
                    <NotificationItem
                      key={index}
                      status={status}
                      onClick={() => {
                        setExpandNotifications(false);
                        setNotificationData(item);
                        setShowModal(true);
                        updateNotification(id);
                      }}
                    >
                      <NotificationItemHeading>
                        <h3>{title}</h3>
                      </NotificationItemHeading>
                      <NotificationItemBody>{message}</NotificationItemBody>
                      <NotificationItemFooter>
                        <span>{moment(createdOn).format('DD-MM-YYYY')}</span>
                      </NotificationItemFooter>
                    </NotificationItem>
                  );
                })}
            </StyledNotificationsContentLinks>
          </StyledNotificationsContent>
        )}
      </StyledNotificationsSectionContainer>
      <Modal
        modalStatus={showModal}
        setModalStatus={() => {
          setShowModal(false);
          setNotificationData(null);
        }}
        hideCloseButton
      >
        <StyledModalContainer width={`500px`}>
          <h3>Notification</h3>
          <ModalTitle>{notificationData?.title}</ModalTitle>
          <ModalMessage>{notificationData?.message}</ModalMessage>
        </StyledModalContainer>
      </Modal>
    </>
  );
};

export default NotificationsSection;
