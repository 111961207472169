import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  NumberInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';

const APISwitchingCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [operators, setOperators] = useState([]);
  const [circles, setCircles] = useState([]);
  const [apiProviders, setApiProviders] = useState([]);
  const [operatorsId, setOperatorsId] = useState('');
  const [circlesId, setCirclesId] = useState(0);
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');
  const [primaryApiProvidersId, setPrimaryApiProvidersId] = useState(null);
  const [secondaryApiProvidersId, setSecondaryApiProvidersId] = useState(null);
  const [tertiaryApiProvidersId, setTertiaryApiProvidersId] = useState(null);
  const [routingMode, setRoutingMode] = useState('Dynamic');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setOperatorsId('');
    setCirclesId(0);
    setFromAmount('');
    setToAmount('');
    setPrimaryApiProvidersId(null);
    setSecondaryApiProvidersId(null);
    setTertiaryApiProvidersId(null);
    setRoutingMode('');
  };

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/operators')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readCircles = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/circles')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setCircles(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readApiProviders = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/api-providers')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setApiProviders(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      operatorsId: operatorsId,
      circlesId: circlesId,
      fromAmount: fromAmount,
      toAmount: toAmount,
      primaryApiProvidersId: primaryApiProvidersId,
      secondaryApiProvidersId: secondaryApiProvidersId,
      tertiaryApiProvidersId: tertiaryApiProvidersId,
      routingMode: routingMode,
    };
    API.post('/recharge-configurations/api-switching', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readOperators();
    readCircles();
    readApiProviders();
  }, [readOperators, readCircles, readApiProviders]);

  const operatorsOptions = [{ title: '-- SELECT OPERATOR --', value: '' }];
  operators.forEach(({ id, operatorName }) =>
    operatorsOptions.push({
      title: operatorName,
      value: id,
    })
  );

  const circlesOptions = [{ title: '-- SELECT CIRCLE --', value: 0 }];
  circles.forEach(({ id, circleName }) =>
    circlesOptions.push({
      title: circleName,
      value: id,
    })
  );

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: null },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Operator</Label>
                <Dropdown
                  placeholder='Please select operator'
                  value={operatorsId}
                  onChange={setOperatorsId}
                  options={operatorsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Circle</Label>
                <Dropdown
                  placeholder='Please select circle'
                  value={circlesId}
                  onChange={setCirclesId}
                  options={circlesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>From Amount</Label>
                <NumberInput
                  placeholder='Please enter from amount'
                  value={fromAmount}
                  onChange={setFromAmount}
                  maxLength={100}
                  autoComplete='off'
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>To Amount</Label>
                <NumberInput
                  placeholder='Please enter to amount'
                  value={toAmount}
                  onChange={setToAmount}
                  maxLength={100}
                  autoComplete='off'
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Primary API Provider</Label>
                <Dropdown
                  placeholder='Please select primary api provider'
                  value={primaryApiProvidersId}
                  onChange={setPrimaryApiProvidersId}
                  options={apiProvidersOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Secondary API Provider</Label>
                <Dropdown
                  placeholder='Please select secondary api provider'
                  value={secondaryApiProvidersId}
                  onChange={setSecondaryApiProvidersId}
                  options={apiProvidersOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Tertiary API Provider</Label>
                <Dropdown
                  placeholder='Please select tertiary api provider'
                  value={tertiaryApiProvidersId}
                  onChange={setTertiaryApiProvidersId}
                  options={apiProvidersOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {/* <Fieldset>
            <Label required>Routing Mode</Label>
            <Dropdown
              placeholder="Please select routing mode"
              value={routingMode}
              onChange={setRoutingMode}
              options={[
                {
                  title: '-- SELECT ROUTING MODE --',
                  value: '',
                },
                {
                  title: 'Dynamic',
                  value: 'Dynamic',
                },
                {
                  title: 'Backup',
                  value: 'Backup',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset> */}
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type='submit'>
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(APISwitchingCreate);
