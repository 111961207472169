import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const StyledResetButton = styled(Button)`
  background-color: #323232;
  margin: 0px 20px;
  color: #ffffff;
  width: 100px;
  &:hover {
    background-color: #000000;
  }
`;

const ResetButton = ({ children, ...rest }) => {
  return <StyledResetButton {...rest}>{children}</StyledResetButton>;
};

export default ResetButton;
