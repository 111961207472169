import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  PasswordInput,
  MessageBox,
  Button,
  OTPInput,
} from '../../../components/FormElements';
import { StyledPara, StyledSpanAsButton } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { authData } from '../../../utils';

const APIKeys = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [apiId, setApiId] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [routeStage, setRouteStage] = useState('Request');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [requestToken, setRequestToken] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/auth/api-settings')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          const { apiId, apiKey } = data;
          setApiId(apiId);
          setApiKey(apiKey);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const generateApiKeys = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/auth/generate-api-keys/?routeStage=${routeStage}&oneTimePassword=${
        oneTimePassword ? oneTimePassword.join('') : ''
      }&requestToken=${requestToken}`
    )
      .then((response) => {
        const { status, message, data, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          if (routeStage === 'Request') {
            setRouteStage('OTPVerification');
            setRequestToken(token);
          } else {
            setRouteStage('Request');
            setRequestToken('');
            setOneTimePassword('');
            const { apiId, apiKey } = data;
            setApiId(apiId);
            setApiKey(apiKey);
            setTimeout(() => {
              resetResponseData();
            }, 3000);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const { authId } = authData.get();
    const formData = {
      requestType: 'API_KEYS',
      mobileNumber: authId,
      requestToken
    };
    API.post('/auth/resend-otp', formData)
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setRequestToken(token);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={generateApiKeys}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>API ID</Label>
                <PasswordInput
                  placeholder="Your API ID"
                  value={apiId}
                  autoComplete="off"
                  disabled={true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>API Key</Label>
                <PasswordInput
                  placeholder="Your API Key"
                  value={apiKey}
                  autoComplete="off"
                  disabled={true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {routeStage === 'OTPVerification' && (
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <StyledPara>
                  Please enter your OTP sent to your mobile number
                </StyledPara>
                <Fieldset>
                  <OTPInput
                    values={oneTimePassword}
                    onChange={setOneTimePassword}
                    length={6}
                    alignInputs="center"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          )}
          {responseStatus && (
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <MessageBox
                    status={responseStatus}
                    message={responseMessage}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          )}
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              {routeStage === 'OTPVerification'
                ? 'Verify & Generate'
                : 'Generate New Keys'}
            </Button>
            {routeStage === 'OTPVerification' && (
              <StyledSpanAsButton onClick={onClickResendOTP}>
                Resend OTP
              </StyledSpanAsButton>
            )}
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(APIKeys);
