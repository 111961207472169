import React, { useState, useEffect, useCallback } from 'react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import JSONPretty from 'react-json-pretty';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  MessageBox,
  CustomButton,
  Label,
  Fieldset,
  TextInput,
  Button,
  Form,
  ResetButton,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import Modal from '../../../components/Modal';
import {
  StyledFiltersContainer,
  StyledModalContainer,
  StyledApiCallLogsContainer,
  StyledBodyContainer,
  StyledHeading,
  StyledContent,
} from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { getSearchParams } from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import Accordination from '../../../components/Accordination/Accordination';
import { Grid, GridItem } from '../../../components/Grid';
import moment from 'moment';

const ApiCallbackLogsTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [log, setLog] = useState('');
  const [logs, setLogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [transactionsId, setTransactionsId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setSearchQuery('');
    setTransactionsId('');
    setStartDate('');
    setEndDate('');
    readData();
  };

  const readData = useCallback(
    (searchQuery = '', transactionsId = '', startDate = '', endDate = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/transactions/api-call-logs?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;

          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setTotalRecords(pageInfo.totalRecords);
          } else {
            setTotalRecords(0);
          }
          setLogs(data);
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(searchQuery, transactionsId, startDate, endDate);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(searchQuery, transactionsId, startDate, endDate);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='Search API'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Transaction ID</Label>
            <TextInput
              value={transactionsId}
              onChange={setTransactionsId}
              placeholder='Transactions ID'
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type='date'
              value={startDate}
              onChange={setStartDate}
              placeholder='Start Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type='date'
              value={endDate}
              onChange={setEndDate}
              placeholder='End Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          !showModal && responseStatus === '' && logs?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          logs?.length === 0
        }
        message={`No api callback logs found`}
      />
      {!showModal && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {logs?.length > 0 && (
        <>
          <TableBuilder
            isLoading={!showModal && logs?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value, item) => (
                  <CustomButton
                    icon={faEye}
                    onClick={() => {
                      setLog(item);
                      setShowModal(true);
                    }}
                    style={{ color: 'orange' }}
                  />
                ),
              },
              {
                title: 'Transactions ID',
                dataSelector: 'transactionsId',
                dataType: 'number',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Response Type',
                dataSelector: 'responseType',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Request URL',
                dataSelector: 'request',
                dataType: 'string',
                width: '300px',
                CellRenderer: (value) => (value ? <span>{value}</span> : 'N/A'),
              },
              {
                title: 'Received On',
                dataSelector: 'createdOn',
                dataType: 'string',
                CellRenderer: (value) =>
                  value ? moment(value).format('DD-MM-YYYY HH:MM:SS') : 'N/A',
              },
            ]}
            tableData={logs}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              setShowModal(false);
              setLog('');
            }}
            hideCloseButton
          >
            <StyledModalContainer width={`1200px`}>
              <h3>API Callback Logs</h3>
              <StyledApiCallLogsContainer>
                <Accordination
                  header={`${log.createdOn} ( ${log.processType} )`}
                  autoExpanded={true}
                >
                  <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                    <GridItem>
                      <StyledBodyContainer>
                        <StyledHeading>API Request</StyledHeading>
                        <StyledContent>{log.request}</StyledContent>
                      </StyledBodyContainer>
                      {log.requestBody && (
                        <StyledBodyContainer>
                          <StyledHeading>API Request Body</StyledHeading>
                          <StyledContent>{log.requestBody}</StyledContent>
                        </StyledBodyContainer>
                      )}
                    </GridItem>
                    <GridItem>
                      <StyledBodyContainer>
                        <StyledHeading>API Response</StyledHeading>
                        <StyledContent>
                          {log.responseType === 'XML' ? (
                            <code>{log.response}</code>
                          ) : (
                            <JSONPretty
                              id='json-pretty'
                              data={log.response}
                            ></JSONPretty>
                          )}
                        </StyledContent>
                      </StyledBodyContainer>
                    </GridItem>
                  </Grid>
                </Accordination>
              </StyledApiCallLogsContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(ApiCallbackLogsTable);
