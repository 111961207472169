import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import Modal from '../../components/Modal';
import { Image } from '../../components/Image';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  FileInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES, ACCOUNT_TYPES } from '../../constants';
import { authData, getBase64 } from '../../utils';

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const Profile = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [whatsappMobileNumber, setWhatsappMobileNumber] = useState('');
  const [telegramId, setTelegramId] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [photo, setPhoto] = useState([]);
  const [photoBase64, setPhotoBase64] = useState('');
  const [address, setAddress] = useState('');
  const [isEntity, setIsEntity] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityAddress, setEntityAddress] = useState('');
  const [panName, setPanName] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [panFile, setPanFile] = useState([]);
  const [panBase64, setPanBase64] = useState('');
  const [panVerified, setPanVerified] = useState('');
  const [aadhaarName, setAadhaarName] = useState('');
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [aadhaarFile, setAadhaarFile] = useState([]);
  const [aadhaarBase64, setAadhaarBase64] = useState('');
  const [aadhaarVerified, setAadhaarVerified] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const [editable, setEditable] = useState(false);
  const { accountType } = authData.get();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      firstName,
      lastName,
      mobileNumber,
      whatsappMobileNumber,
      telegramId,
      emailAddress,
      gender,
      dateOfBirth,
      photo,
      address,
      isEntity,
      entityName,
      entityAddress,
      panName,
      panNumber,
      panFile,
      aadhaarName,
      aadhaarNumber,
      aadhaarFile,
    } = originalFormData;
    setFirstName(firstName);
    setLastName(lastName);
    setMobileNumber(mobileNumber);
    setWhatsappMobileNumber(whatsappMobileNumber);
    setTelegramId(telegramId);
    setEmailAddress(emailAddress);
    setGender(gender);
    setDateOfBirth(dateOfBirth);
    setPhoto([]);
    setPhotoBase64(photo);
    setAddress(address);
    setIsEntity(isEntity);
    setEntityName(entityName);
    setEntityAddress(entityAddress);
    setPanName(panName);
    setPanNumber(panNumber);
    setPanFile([]);
    setPanBase64(panFile);
    setAadhaarName(aadhaarName);
    setAadhaarNumber(aadhaarNumber);
    setAadhaarFile([]);
    setAadhaarBase64(aadhaarFile);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/auth/profile`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            firstName,
            lastName,
            mobileNumber,
            whatsappMobileNumber,
            telegramId,
            emailAddress,
            gender,
            dateOfBirth,
            photo,
            address,
            isEntity,
            entityName,
            entityAddress,
            panName,
            panNumber,
            panFile,
            panVerified,
            aadhaarName,
            aadhaarNumber,
            aadhaarFile,
            aadhaarVerified,
          } = data;
          setFirstName(firstName);
          setLastName(lastName);
          setMobileNumber(mobileNumber);
          setWhatsappMobileNumber(whatsappMobileNumber);
          setTelegramId(telegramId);
          setEmailAddress(emailAddress);
          setGender(gender);
          setDateOfBirth(dateOfBirth);
          setPhoto([]);
          setPhotoBase64(photo);
          setAddress(address);
          setIsEntity(isEntity);
          setEntityName(entityName);
          setEntityAddress(entityAddress);
          setPanName(panName);
          setPanNumber(panNumber);
          setPanFile([]);
          setPanBase64(panFile);
          setPanVerified(panVerified);
          setAadhaarName(aadhaarName);
          setAadhaarNumber(aadhaarNumber);
          setAadhaarFile([]);
          setAadhaarBase64(aadhaarFile);
          setAadhaarVerified(aadhaarVerified);
          setOriginalFormData({
            firstName,
            lastName,
            mobileNumber,
            whatsappMobileNumber,
            telegramId,
            emailAddress,
            gender,
            dateOfBirth,
            photo,
            address,
            isEntity,
            entityName,
            entityAddress,
            panName,
            panNumber,
            panFile,
            panVerified,
            aadhaarName,
            aadhaarNumber,
            aadhaarFile,
            aadhaarVerified,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      firstName,
      lastName,
      whatsappMobileNumber,
      telegramId,
      emailAddress,
      gender,
      dateOfBirth,
      photo: photoBase64,
      address,
      isEntity,
      entityName,
      entityAddress,
      panName,
      panNumber,
      panFile: panBase64,
      aadhaarName,
      aadhaarNumber,
      aadhaarFile: aadhaarBase64,
    };
    API.put(`/auth/profile`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setEditable(false);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (photo?.length > 0) {
      getBase64(photo[0], setPhotoBase64);
    }
    if (panFile?.length > 0) {
      getBase64(panFile[0], setPanBase64);
    }
    if (aadhaarFile?.length > 0) {
      getBase64(aadhaarFile[0], setAadhaarBase64);
    }
  }, [photo, panFile, aadhaarFile]);

  useEffect(() => {
    readData();
  }, [readData]);

  const businessRole = [
    ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
    ACCOUNT_TYPES.DISTRIBUTORS,
    ACCOUNT_TYPES.RETAILERS,
    ACCOUNT_TYPES.API_USERS,
  ].includes(accountType);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  value={firstName}
                  onChange={setFirstName}
                  placeholder='Please enter first name'
                  maxLength={100}
                  disabled={!editable || isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  value={lastName}
                  onChange={setLastName}
                  placeholder='Please enter last name'
                  maxLength={100}
                  disabled={!editable || isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  placeholder='Please enter mobile number'
                  maxLength={10}
                  disabled={true}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Whatsapp Mobile Number</Label>
                <NumberInput
                  value={whatsappMobileNumber}
                  onChange={setWhatsappMobileNumber}
                  placeholder='Please enter whatsapp mobile number'
                  maxLength={10}
                  disabled={!editable || isLoading === true}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Telegram ID</Label>
                <TextInput
                  value={telegramId}
                  onChange={setTelegramId}
                  placeholder='Please enter telegram id'
                  maxLength={100}
                  disabled={!editable || isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Email Address</Label>
                <TextInput
                  value={emailAddress}
                  onChange={setEmailAddress}
                  placeholder='Please enter email address'
                  maxLength={200}
                  disabled={!editable || isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Gender</Label>
                <Dropdown
                  placeholder='Please select gender'
                  value={gender}
                  onChange={setGender}
                  options={[
                    {
                      title: '-- SELECT GENDER --',
                      value: '',
                    },
                    {
                      title: 'Male',
                      value: 'Male',
                    },
                    {
                      title: 'Female',
                      value: 'Female',
                    },
                    {
                      title: 'Others',
                      value: 'Others',
                    },
                  ]}
                  disabled={!editable || isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Date Of Birth</Label>
                <TextInput
                  type='date'
                  value={dateOfBirth}
                  onChange={setDateOfBirth}
                  placeholder='Please select date of birth'
                  disabled={!editable || isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Photo</Label>
            <FileInput
              files={photo}
              onChange={setPhoto}
              accept='image/*'
              disabled={!editable || isLoading === true}
            />
            {originalFormData?.photo && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowExistingImage(true);
                  setModalImage(originalFormData.photo);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>
          <Fieldset>
            <Label>Address</Label>
            <TextInput
              value={address}
              onChange={setAddress}
              placeholder='Please enter address'
              disabled={!editable || isLoading === true}
            />
          </Fieldset>
          {businessRole && (
            <>
              <Fieldset>
                <Label required>Is Entity?</Label>
                <Dropdown
                  placeholder='Please select if the account is linked to any entity'
                  value={isEntity}
                  onChange={setIsEntity}
                  options={[
                    {
                      title: '-- SELECT IF ACCOUNT IS LINKED TO ANY ENTITY --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={!editable || isLoading}
                />
              </Fieldset>
              {isEntity === 'Yes' && (
                <>
                  <Fieldset>
                    <Label required>Entity Name</Label>
                    <TextInput
                      value={entityName}
                      onChange={setEntityName}
                      placeholder='Please enter entity name'
                      disabled={!editable || isLoading === true}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label required>Entity Address</Label>
                    <TextInput
                      value={entityAddress}
                      onChange={setEntityAddress}
                      placeholder='Please enter entity address'
                      disabled={!editable || isLoading === true}
                    />
                  </Fieldset>
                </>
              )}
              <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                <GridItem>
                  <Fieldset>
                    <Label>PAN Name</Label>
                    <TextInput
                      value={panName}
                      onChange={setPanName}
                      placeholder='Please enter name on pan card'
                      disabled={
                        !editable || panVerified === 'Yes' || isLoading === true
                      }
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>PAN Number</Label>
                    <TextInput
                      value={panNumber}
                      onChange={setPanNumber}
                      placeholder='Please enter pan number'
                      disabled={
                        !editable || panVerified === 'Yes' || isLoading === true
                      }
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Pan File</Label>
                    <FileInput
                      files={panFile}
                      onChange={setPanFile}
                      accept='image/*'
                      disabled={
                        !editable || panVerified === 'Yes' || isLoading === true
                      }
                    />
                    {originalFormData?.panFile && (
                      <StyledViewAttachmentButton
                        onClick={() => {
                          setShowExistingImage(true);
                          setModalImage(originalFormData.panFile);
                        }}
                      >
                        Click here to view existing file
                      </StyledViewAttachmentButton>
                    )}
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <Label>Aadhaar Name</Label>
                    <TextInput
                      value={aadhaarName}
                      onChange={setAadhaarName}
                      placeholder='Please enter name on aadhaar card'
                      disabled={
                        !editable ||
                        aadhaarVerified === 'Yes' ||
                        isLoading === true
                      }
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Aadhaar Number</Label>
                    <TextInput
                      value={aadhaarNumber}
                      onChange={setAadhaarNumber}
                      placeholder='Please enter aadhaar number'
                      disabled={
                        !editable ||
                        aadhaarVerified === 'Yes' ||
                        isLoading === true
                      }
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Aadhaar File</Label>
                    <FileInput
                      files={aadhaarFile}
                      onChange={setAadhaarFile}
                      accept='image/*'
                      disabled={
                        !editable ||
                        aadhaarVerified === 'Yes' ||
                        isLoading === true
                      }
                    />
                    {originalFormData?.aadhaarFile && (
                      <StyledViewAttachmentButton
                        onClick={() => {
                          setShowExistingImage(true);
                          setModalImage(originalFormData.aadhaarFile);
                        }}
                      >
                        Click here to view existing file
                      </StyledViewAttachmentButton>
                    )}
                  </Fieldset>
                </GridItem>
              </Grid>
            </>
          )}
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          {!editable ? (
            <Fieldset>
              <UpdateButton
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  setEditable((editable) => !editable);
                }}
              >
                Edit
              </UpdateButton>
            </Fieldset>
          ) : (
            <Fieldset>
              <UpdateButton disabled={isLoading === true} type='submit'>
                Update
              </UpdateButton>
              <ResetButton
                disabled={isLoading === true}
                type='button'
                onClick={resetForm}
              >
                Reset
              </ResetButton>
            </Fieldset>
          )}
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image width={150} source={modalImage} alt={'Existing File'} />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(Profile);
