import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  Form,
  Fieldset,
  Button,
  ResetButton,
  TextInput,
  Label,
  MessageBox,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import { StyledFiltersContainer, StyledSpan } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { formatCurrency } from '../../../utils';
import ExcelExport from './ExcelExport';

const StyledPageHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: #000000;
  margin: 20px 0px;
  padding: 0px;
  align-self: center;
`;

const GSTInvoicesSummary = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [yearMonth, setYearMonth] = useState(
    moment().subtract(1, 'months').endOf('month').format('YYYY-MM')
  );
  const [year, setYear] = useState(
    moment().subtract(1, 'months').endOf('month').format('YYYY')
  );
  const [month, setMonth] = useState(
    moment().subtract(1, 'months').endOf('month').format('MM')
  );
  const [summary, setSummary] = useState([]);
  const [summaryTotals, setSummaryTotals] = useState([]);

  const readData = useCallback((year = '', month = '') => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounting/gst-invoices-summary?year=${year}&month=${month}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        }
        setSummary(data.summary || []);
        setSummaryTotals(data.summaryTotals || []);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const filterData = (e) => {
    e.preventDefault();
    readData(year, month);
  };

  const resetFilter = () => {
    const currentYearMonth = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM');
    const currentYear = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY');
    const currentMonth = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('MM');
    setYearMonth(currentYearMonth);
    setYear(currentYear);
    setMonth(currentMonth);
    readData(currentYear, currentMonth);
  };

  useEffect(() => {
    readData(year, month);
  }, []);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading
        {...props}
        canReloadProp={false}
        showExport={summary?.length > 0 ? true : false}
        isCustomExport={summary?.length > 0 ? true : false}
        exportButton={() =>
          summary?.length > 0 ? (
            <ReactHTMLTableToExcel
              className='excel-download-btn'
              table='data'
              filename={`GSTR Summary for ${month}-${year}`}
              sheet='Sheet'
              buttonText={`Download in Excel`}
              style={{
                backgroundColor: 'green',
              }}
            />
          ) : (
            ``
          )
        }
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Year & Month</Label>
            <TextInput
              type='month'
              value={yearMonth}
              onChange={(value) => {
                setYearMonth(value);
                setYear(value.split('-')[0]);
                setMonth(value.split('-')[1]);
              }}
              placeholder='Year & Month'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Get Details
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetFilter}
            >
              Clear
            </ResetButton>
          </Fieldset>
          <Fieldset>
            {summary?.length > 0 && (
              <ExcelExport
                fileName={`GSTR Summary for ${month}-${year}`}
                data={{ summary, summaryTotals }}
              />
            )}
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      <LoadingSpinner
        isLoading={responseStatus === '' && summary?.length === 0 && isLoading}
      />
      <NoData
        status={!isLoading && (!summary || summary?.length === 0)}
        message={`No data found`}
      />
      {summary?.length > 0 && (
        <TableBuilder
          isLoading={summary?.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: 'Invoice Type',
              dataSelector: 'invoiceType',
              dataType: 'string',
            },
            {
              title: 'Seller',
              dataSelector: 'firmName',
              dataType: 'string',
              CellRenderer: (value, item) => (
                <>
                  {value}
                  {item.gstNumber && <StyledSpan>{item.gstNumber}</StyledSpan>}
                </>
              ),
            },
            {
              title: 'Invoice Number',
              dataSelector: 'invoiceNumber',
              dataType: 'string',
            },
            {
              title: 'Invoice Date',
              dataSelector: 'invoiceDate',
              dataType: 'string',
            },
            {
              title: 'Subtotal',
              dataSelector: 'subTotal',
              dataType: 'number',
              CellRenderer: (value) => formatCurrency(value ? value : 0),
            },
            {
              title: 'CGST',
              dataSelector: 'csgtAmount',
              dataType: 'number',
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.csgtPercentage}%)`,
            },
            {
              title: 'SGST',
              dataSelector: 'sgstAmount',
              dataType: 'number',
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.sgstPercentage}%)`,
            },
            {
              title: 'IGST',
              dataSelector: 'igstAmount',
              dataType: 'number',
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.igstPercentage}%)`,
            },
            {
              title: 'Total',
              dataSelector: 'total',
              dataType: 'number',
              CellRenderer: (value) => formatCurrency(value ? value : 0),
            },
          ]}
          tableData={summary}
        />
      )}
      {summaryTotals?.length > 0 && (
        <>
          <StyledPageHeading>Summary Total</StyledPageHeading>
          <TableBuilder
            isLoading={summaryTotals?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: 'Invoice Type',
                dataSelector: 'invoiceType',
                dataType: 'string',
              },
              {
                title: 'Subtotal',
                dataSelector: 'subTotal',
                dataType: 'number',
                CellRenderer: (value) => formatCurrency(value ? value : 0),
              },
              {
                title: 'Total GST',
                dataSelector: 'taxAmount',
                dataType: 'number',
                CellRenderer: (value, item) =>
                  formatCurrency(value ? value : 0),
              },
              {
                title: 'Total',
                dataSelector: 'total',
                dataType: 'number',
                CellRenderer: (value) => formatCurrency(value ? value : 0),
              },
            ]}
            tableData={summaryTotals}
            tableFooter={
              summaryTotals.some((item) => item.invoiceType === 'Sale') &&
              summaryTotals.some((item) => item.invoiceType === 'Purchase')
                ? [
                    {
                      value: 'Sale - Purchase',
                    },
                    {
                      value: formatCurrency(
                        parseFloat(
                          summaryTotals.find(
                            (item) => item.invoiceType === 'Sale'
                          )?.['subTotal'] || 0
                        ) -
                          parseFloat(
                            summaryTotals.find(
                              (item) => item.invoiceType === 'Purchase'
                            )?.['subTotal'] || 0
                          )
                      ),
                    },
                    {
                      value: formatCurrency(
                        parseFloat(
                          summaryTotals.find(
                            (item) => item.invoiceType === 'Sale'
                          )?.['taxAmount'] || 0
                        ) -
                          parseFloat(
                            summaryTotals.find(
                              (item) => item.invoiceType === 'Purchase'
                            )?.['taxAmount'] || 0
                          )
                      ),
                    },
                    {
                      value: formatCurrency(
                        parseFloat(
                          summaryTotals.find(
                            (item) => item.invoiceType === 'Sale'
                          )?.['total'] || 0
                        ) -
                          parseFloat(
                            summaryTotals.find(
                              (item) => item.invoiceType === 'Purchase'
                            )?.['total'] || 0
                          )
                      ),
                    },
                  ]
                : []
            }
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(GSTInvoicesSummary);
