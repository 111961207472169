import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faEdit,
  faEye,
  faIndianRupeeSign,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  PasswordInput,
  NumberInput,
  TextInput,
  CreateButton,
  ResetButton,
  CustomConfirmButton,
  MessageBox,
  Dropdown,
  Switch,
  CustomButton,
  Button,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import { TableBuilder } from '../../../components/TableElements';
import Modal from '../../../components/Modal';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  StyledLink,
  StyledSpan,
  StyledModalContainer,
  StyledFiltersContainer,
  StyledOTPModal,
  StyledOTPText,
} from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import {
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from '../../../constants';
import {
  authData,
  camelCaseToString,
  getSearchParams,
  getAmountInWords,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';

const AccountsTable = (props) => {
  const { readWalletBalance } = props;
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [accountsId, setAccountsId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [remark, setRemark] = useState('');
  const [amount, setAmount] = useState('');
  const { accountType } = authData.get();
  const [roles, setRoles] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [commissionMode, setCommissionMode] = useState('');
  const [accountsLatestOtp, setAccountsLatestOtp] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setNewPassword('');
    setConfirmNewPassword('');
    setTransactionType('');
    setRemark('');
    setAmount('');
  };

  const resetFilters = () => {
    setRolesId('');
    setSearchQuery('');
    setCommissionMode('');
    readData();
  };

  const readRoles = () => {
    API.get('/accounts-management/roles-and-permissions').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setRoles(data);
      }
    });
  };

  const readData = useCallback(
    (rolesId = '', searchQuery = '', commissionMode = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setAccounts([]);
      API.get(
        `/accounts-management/accounts/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&rolesId=${rolesId}&searchQuery=${searchQuery}&commissionMode=${commissionMode}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setAccounts(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(rolesId, searchQuery, commissionMode);
  };

  const readAccountsLatestOTP = (accountsId) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounts-management/accounts/last-otp/${accountsId}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setAccountsLatestOtp(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.accountsStatus = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(
      `/accounts-management/accounts/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&rolesId=${rolesId}&searchQuery=${searchQuery}&fcommissionMode=${commissionMode}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setAccounts(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/accounts-management/accounts/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&rolesId=${rolesId}&searchQuery=${searchQuery}&commissionMode=${commissionMode}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setAccounts(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    let formData = {};
    if (modalType === PERMISSION_TYPES.CHANGE_PASSWORD) {
      formData = {
        newPassword,
        confirmNewPassword,
      };
    } else {
      formData = {
        transactionType,
        remark,
        amount,
      };
    }
    API.put(
      modalType === PERMISSION_TYPES.CHANGE_PASSWORD
        ? `/accounts-management/accounts/change-password/${accountsId}`
        : `/accounts-management/accounts/wallet-process-funds/${accountsId}`,
      formData
    )
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          if (
            [
              ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
              ACCOUNT_TYPES.DISTRIBUTORS,
            ].includes(accountType) &&
            modalType !== PERMISSION_TYPES.CHANGE_PASSWORD
          ) {
            readWalletBalance();
          }
          resetForm();
          setTimeout(() => {
            resetResponseData();
            setAccountsId('');
            setModalType('');
            setShowModal(false);
            readData(rolesId, searchQuery, commissionMode);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (
      accountType !== ACCOUNT_TYPES.MASTER_DISTRIBUTORS &&
      accountType !== ACCOUNT_TYPES.DISTRIBUTORS
    ) {
      readRoles();
    }
  }, [accountType]);

  useEffect(() => {
    if (!isFiltering) {
      readData(rolesId, searchQuery, commissionMode);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  const canChangePassword =
    accountsPermissions.includes(PERMISSION_TYPES.CHANGE_PASSWORD) || false;
  const canReadLatestOTP =
    accountsPermissions.includes(PERMISSION_TYPES.READ_LATEST_OTP) || false;
  const canProcessFunds =
    accountsPermissions.includes(PERMISSION_TYPES.WALLET_PROCESS_FUNDS) ||
    false;
  const canTrack =
    accountsPermissions.includes(PERMISSION_TYPES.WALLET_TRANSACTIONS_READ) ||
    false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }
  let trackLink = '';
  let trackTitle = '';
  if (canTrack) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) =>
        permissionRequired === PERMISSION_TYPES.WALLET_TRANSACTIONS_READ
    );
    trackLink = path;
    trackTitle = title;
  }

  const rolesOptions = [{ title: '-- SELECT ROLE --', value: '' }];
  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
    [
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
    [
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else {
    roles.forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: camelCaseToString(roleName),
        value: id,
      })
    );
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Role</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Role'
              value={rolesId}
              onChange={setRolesId}
              options={rolesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Commission Mode</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Commission Mode'
              value={commissionMode}
              onChange={setCommissionMode}
              options={[
                {
                  title: '-- SELECT COMMISSION MODE --',
                  value: '',
                },
                {
                  title: 'Percentage',
                  value: 'Percentage',
                },
                {
                  title: 'Flat',
                  value: 'Flat',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='First Name, Last Name, Entity Name, Mobile Number'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type='button'
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' &&
          !showModal &&
          accounts?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          accounts?.length === 0
        }
        message={`No accounts found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {accounts?.length > 0 && (
        <>
          {[
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
          ].includes(accountType) ? (
            <TableBuilder
              isLoading={!showModal && accounts?.length !== 0 && isLoading}
              tableHeadings={[
                {
                  title: '',
                  dataSelector: 'id',
                  sticky: true,
                  canSort: false,
                  width: '100px',
                  CellRenderer: (value, item) =>
                    item?.isDeleted === 'No' && (
                      <>
                        {canUpdate && (
                          <StyledLink
                            to={updateLink.replace(':id', value)}
                            title={updateTitle}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </StyledLink>
                        )}
                        {canDelete && (
                          <CustomConfirmButton
                            onClick={() => deleteData(value)}
                          />
                        )}
                        {canChangePassword && (
                          <CustomButton
                            icon={faKey}
                            onClick={() => {
                              setAccountsId(value);
                              setModalType(PERMISSION_TYPES.CHANGE_PASSWORD);
                              setShowModal(true);
                            }}
                            style={{ color: 'orange' }}
                          />
                        )}
                        {canReadLatestOTP && (
                          <CustomButton
                            icon={faAsterisk}
                            onClick={() => {
                              readAccountsLatestOTP(value);
                              setModalType(PERMISSION_TYPES.READ_LATEST_OTP);
                              setShowModal(true);
                            }}
                            style={{ color: 'gray' }}
                          />
                        )}
                        {canProcessFunds &&
                          [2, 3, 4, 5].includes(item.accountsRolesId) && (
                            <CustomButton
                              icon={faIndianRupeeSign}
                              onClick={() => {
                                setAccountsId(value);
                                setModalType(
                                  PERMISSION_TYPES.WALLET_PROCESS_FUNDS
                                );
                                setShowModal(true);
                              }}
                            />
                          )}
                        {canTrack &&
                          [2, 3, 4, 5].includes(item.accountsRolesId) && (
                            <StyledLink
                              to={trackLink.replace(':id', value)}
                              title={trackTitle}
                              style={{ color: 'orange' }}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </StyledLink>
                          )}
                      </>
                    ),
                },
                {
                  title: 'Photo',
                  dataSelector: 'photo',
                  dataType: 'string',
                  canSort: false,
                  CellRenderer: (value, item) => (
                    <Image
                      source={value ? value : '/no-image.png'}
                      alt={item.firstName}
                      style={{ width: '50px', height: '50px' }}
                    />
                  ),
                },
                {
                  title: 'Full Name',
                  dataSelector: 'firstName',
                  dataType: 'string',
                  CellRenderer: (value, item) => {
                    const { firstName, lastName, roleName } = item;
                    return (
                      <>
                        {firstName} {lastName}
                        <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
                      </>
                    );
                  },
                },
                {
                  title: 'Commission',
                  dataSelector: 'templateName',
                  dataType: 'string',
                  CellRenderer: (value, item) => {
                    const { commissionMode, commissionValue } = item;
                    return (
                      <>
                        {commissionMode}
                        <StyledSpan>
                          {commissionMode === 'Percentage'
                            ? `Commission Template : ${value}`
                            : `Commission Value : ${commissionValue}%`}
                        </StyledSpan>
                      </>
                    );
                  },
                },
                {
                  title: 'Wallet Balance',
                  dataSelector: 'walletBalance',
                  dataType: 'number',
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: 'Credit Due',
                  dataSelector: 'creditDue',
                  dataType: 'number',
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: 'Minimum Balance',
                  dataSelector: 'minimumBalance',
                  dataType: 'number',
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: 'Supervisor',
                  dataSelector: 'createdByName',
                  dataType: 'string',
                  CellRenderer: (value, item) => {
                    const { createdByRole } = item;
                    return (
                      item.createdBy !== 0 && (
                        <>
                          {value}
                          <StyledSpan>
                            {camelCaseToString(createdByRole)}
                          </StyledSpan>
                        </>
                      )
                    );
                  },
                },
              ].filter((item) =>
                props.appData.commissionMode === 'Flat'
                  ? item.dataSelector !== 'templateName'
                  : true
              )}
              tableData={accounts}
            />
          ) : (
            <TableBuilder
              isLoading={!showModal && accounts?.length !== 0 && isLoading}
              tableHeadings={[
                {
                  title: '',
                  dataSelector: 'id',
                  align: 'center',
                  sticky: true,
                  canSort: false,
                  width: '100px',
                  CellRenderer: (value, item) =>
                    item?.isDeleted === 'No' && (
                      <>
                        {canUpdate && (
                          <StyledLink
                            to={updateLink.replace(':id', value)}
                            title={updateTitle}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </StyledLink>
                        )}
                        {canDelete && (
                          <CustomConfirmButton
                            onClick={() => deleteData(value)}
                          />
                        )}
                        {canChangePassword && (
                          <CustomButton
                            icon={faKey}
                            onClick={() => {
                              setAccountsId(value);
                              setModalType(PERMISSION_TYPES.CHANGE_PASSWORD);
                              setShowModal(true);
                            }}
                            style={{ color: 'orange' }}
                          />
                        )}
                        {canReadLatestOTP && (
                          <CustomButton
                            icon={faAsterisk}
                            onClick={() => {
                              readAccountsLatestOTP(value);
                              setModalType(PERMISSION_TYPES.READ_LATEST_OTP);
                              setShowModal(true);
                            }}
                            style={{ color: 'gray' }}
                          />
                        )}
                        {canProcessFunds &&
                          [2, 3, 4, 5].includes(item.accountsRolesId) && (
                            <CustomButton
                              icon={faIndianRupeeSign}
                              onClick={() => {
                                setAccountsId(value);
                                setModalType(
                                  PERMISSION_TYPES.WALLET_PROCESS_FUNDS
                                );
                                setShowModal(true);
                              }}
                            />
                          )}
                        {canTrack &&
                          [2, 3, 4, 5].includes(item.accountsRolesId) && (
                            <StyledLink
                              to={trackLink.replace(':id', value)}
                              title={trackTitle}
                              style={{ color: 'orange' }}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </StyledLink>
                          )}
                      </>
                    ),
                },
                {
                  title: 'Photo',
                  dataSelector: 'photo',
                  dataType: 'string',
                  canSort: false,
                  CellRenderer: (value, item) => (
                    <Image
                      source={value ? value : '/no-image.png'}
                      alt={item.firstName}
                      style={{ width: '50px', height: '50px' }}
                    />
                  ),
                },
                {
                  title: 'Full Name',
                  dataSelector: 'firstName',
                  dataType: 'string',
                  CellRenderer: (value, item) => {
                    const { firstName, lastName, roleName } = item;
                    return (
                      <>
                        {firstName} {lastName}
                        <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
                      </>
                    );
                  },
                },
                {
                  title: 'Commission',
                  dataSelector: 'templateName',
                  dataType: 'string',
                  CellRenderer: (value, item) => {
                    const { commissionMode, commissionValue, accountsRolesId } =
                      item;
                    return [2, 3, 4, 5].includes(accountsRolesId) ? (
                      <>
                        {commissionMode}
                        <StyledSpan>
                          {commissionMode === 'Percentage'
                            ? `Commission Template : ${value}`
                            : `Commission Value : ${commissionValue}%`}
                        </StyledSpan>
                      </>
                    ) : (
                      'N/A'
                    );
                  },
                },
                {
                  title: 'Wallet Balance',
                  dataSelector: 'walletBalance',
                  dataType: 'number',
                  CellRenderer: (value, item) =>
                    [2, 3, 4, 5].includes(item.accountsRolesId)
                      ? formatCurrency(value)
                      : 'N/A',
                },
                {
                  title: 'Credit Due',
                  dataSelector: 'creditDue',
                  dataType: 'number',
                  CellRenderer: (value, item) =>
                    [2, 3, 4, 5].includes(item.accountsRolesId)
                      ? formatCurrency(value)
                      : 'N/A',
                },
                {
                  title: 'Minimum Balance',
                  dataSelector: 'minimumBalance',
                  dataType: 'number',
                  CellRenderer: (value, item) =>
                    [2, 3, 4, 5].includes(item.accountsRolesId)
                      ? formatCurrency(value)
                      : 'N/A',
                },
                {
                  title: 'Supervisor',
                  dataSelector: 'createdByName',
                  dataType: 'string',
                  CellRenderer: (value, item) => {
                    const { createdByRole } = item;
                    return (
                      item.createdBy !== 0 && (
                        <>
                          {value}
                          <StyledSpan>
                            {camelCaseToString(createdByRole)}
                          </StyledSpan>
                        </>
                      )
                    );
                  },
                },
                {
                  title: 'Status',
                  dataSelector: 'accountsStatus',
                  dataType: 'string',
                  align: 'center',
                  canSort: false,
                  CellRenderer: (value, data) => {
                    return (
                      data?.isDeleted === 'No' && (
                        <Switch
                          value={value === 'Active'}
                          onChange={(value) => updateData(value, data)}
                        />
                      )
                    );
                  },
                },
              ].filter((item) =>
                props.appData.commissionMode === 'Flat'
                  ? item.dataSelector !== 'templateName'
                  : true
              )}
              tableData={accounts}
            />
          )}
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              resetForm();
              setModalType('');
              setShowModal(false);
            }}
            hideCloseButton
          >
            <StyledModalContainer width={'500px'}>
              <h3>
                {modalType === PERMISSION_TYPES.CHANGE_PASSWORD
                  ? 'Change Password'
                  : modalType === PERMISSION_TYPES.READ_LATEST_OTP
                  ? 'Accounts Latest OTP'
                  : 'Add / Deduct Wallet Balance'}
              </h3>
              <FormContainer>
                {modalType === PERMISSION_TYPES.READ_LATEST_OTP ? (
                  <StyledOTPModal>
                    {isLoading ? (
                      <StyledOTPText>
                        Please wait, we are feting the data
                      </StyledOTPText>
                    ) : accountsLatestOtp?.length > 0 ? (
                      <StyledOTPText>OTP is {accountsLatestOtp}</StyledOTPText>
                    ) : (
                      <StyledOTPText>No OTP Found</StyledOTPText>
                    )}
                    <OverlayLoader showLoader={showModal && isLoading} />
                  </StyledOTPModal>
                ) : (
                  <Form method='POST' action='#' onSubmit={onSubmit}>
                    {modalType === PERMISSION_TYPES.CHANGE_PASSWORD && (
                      <>
                        <Fieldset>
                          <Label required>New Password</Label>
                          <PasswordInput
                            placeholder='Please enter your new password'
                            value={newPassword}
                            onChange={setNewPassword}
                            autoComplete='off'
                            disabled={isLoading === true}
                          />
                        </Fieldset>
                        <Fieldset>
                          <Label required>Confirm New Password</Label>
                          <PasswordInput
                            placeholder='Please re-enter your new password'
                            value={confirmNewPassword}
                            onChange={setConfirmNewPassword}
                            autoComplete='off'
                            disabled={isLoading === true}
                          />
                        </Fieldset>
                      </>
                    )}
                    {modalType === PERMISSION_TYPES.WALLET_PROCESS_FUNDS && (
                      <>
                        <Fieldset>
                          <Label required>Transaction Type</Label>
                          <Dropdown
                            placeholder='Please select transaction type'
                            value={transactionType}
                            onChange={setTransactionType}
                            options={[
                              {
                                title: '-- SELECT TRANSACTION TYPE --',
                                value: '',
                              },
                              {
                                title: 'Add Balance (Advance)',
                                value: 'ADD_ADVANCE_BALANCE',
                              },
                              {
                                title: 'Add Balance (Credit)',
                                value: 'ADD_CREDIT_BALANCE',
                              },
                              {
                                title: 'Deduct Balance',
                                value: 'DEDUCT_BALANCE',
                              },
                              {
                                title: 'Clear Credit Dues',
                                value: 'CLEAR_CREDIT_DUES',
                              },
                            ]}
                            disabled={isLoading === true}
                          />
                        </Fieldset>
                        <Fieldset>
                          <Label required>Remark</Label>
                          <TextInput
                            value={remark}
                            onChange={setRemark}
                            placeholder='Please enter remark'
                            disabled={isLoading === true}
                          />
                        </Fieldset>
                        <Fieldset>
                          <Label required>Amount</Label>
                          <NumberInput
                            value={amount}
                            onChange={setAmount}
                            placeholder='Please enter amount'
                            maxLength={10}
                            disabled={isLoading === true}
                            pattern={`[0-9.]*`}
                          />
                          {amount && (
                            <StyledSpan style={{ color: '#000' }}>
                              {getAmountInWords(amount)}
                            </StyledSpan>
                          )}
                        </Fieldset>
                      </>
                    )}
                    {showModal && responseStatus !== '' && (
                      <Fieldset>
                        <MessageBox
                          status={responseStatus}
                          message={responseMessage}
                        />
                      </Fieldset>
                    )}
                    <Fieldset>
                      <CreateButton
                        disabled={isLoading === true}
                        type='submit'
                        style={{ width: 'auto' }}
                      >
                        {modalType === PERMISSION_TYPES.CHANGE_PASSWORD
                          ? `Change Password`
                          : `Submit`}
                      </CreateButton>
                      <ResetButton
                        disabled={isLoading === true}
                        type='button'
                        onClick={resetForm}
                      >
                        Reset
                      </ResetButton>
                    </Fieldset>
                    <OverlayLoader showLoader={showModal && isLoading} />
                  </Form>
                )}
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(AccountsTable);
