import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import { Switch, MessageBox } from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';

const SmsTemplates = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [smsTemplates, setSmsTemplates] = useState([]);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/sms-templates`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setSmsTemplates(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const reloadData = () => {
    readData();
  };

  const updateData = (value, data) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/configurations/sms-templates/${data.id}`, {
      templateStatus: value === true ? `Active` : `Inactive`,
    })
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setSmsTemplates(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <LoadingSpinner
        isLoading={
          responseStatus === '' && smsTemplates?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          smsTemplates?.length === 0
        }
        message={`No sms templates found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {smsTemplates?.length > 0 && (
        <TableBuilder
          isLoading={smsTemplates?.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: 'Purpose',
              dataSelector: 'purpose',
              dataType: 'string',
            },
            {
              title: 'Template',
              dataSelector: 'template',
              dataType: 'string',
            },
            {
              title: 'Status',
              dataSelector: 'status',
              dataType: 'string',
              align: 'center',
              canSort: false,
              CellRenderer: (value, data) => {
                return (
                  <Switch
                    value={value === 'Active'}
                    onChange={(value) => updateData(value, data)}
                  />
                );
              },
            },
          ]}
          tableData={smsTemplates}
        />
      )}
    </>
  );
};

export default withAppHOC(SmsTemplates);
