import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { ThemeProvider } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import GlobalStyles from './styles';
import { ROUTE_TYPES } from './constants';
import { authData, generateRoutes } from './utils';
import API from './api';

const App = () => {
  const routesList = generateRoutes();
  const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
  const { isLoggedIn, accountsPermissions } = authData.get();
  const [appData, setAppData] = useState({
    appName: '',
    appLogo: '',
    appFavicon: '',
    appPhoneNumber: '',
    appEmailAddress: '',
    packageExpiryDate: '',
  });
  const theme = {
    colors: {
      backgroundColor: appData.appBackgroundColor
        ? appData.appBackgroundColor
        : '#ffffff',
      primaryColor: appData.appPrimaryColor
        ? appData.appPrimaryColor
        : '#0095ff',
      secondaryColor: appData.appSecondaryColor
        ? appData.appSecondaryColor
        : '#0078ce',
      tertiaryColor: appData.appTertiaryColor
        ? appData.appTertiaryColor
        : '#0067b0',
      tableHeaderColor: appData.appTableHeaderColor
        ? appData.appTableHeaderColor
        : '#545454',
    },
  };

  const ProcessedRoute = ({
    component: Component,
    routeType,
    moduleName,
    permissionRequired,
    appData,
    ...rest
  }) => {
    let ProcessedComponent;

    if (!isEmpty(routeType)) {
      if (isLoggedIn && accountsPermissions === null) {
        authData.clear();
        window.location.href = '/login';
      } else {
        if (
          (routeType === ALLOW_IF_NOT_LOGGEDIN && !isLoggedIn) ||
          (routeType === ALLOW_IF_LOGGEDIN && isLoggedIn)
        ) {
          if (
            isEmpty(moduleName) ||
            (!isEmpty(moduleName) &&
              !isEmpty(accountsPermissions[moduleName]) &&
              accountsPermissions[moduleName].includes(permissionRequired))
          ) {
            ProcessedComponent = (
              <Component
                accountsPermissions={
                  !isEmpty(moduleName) &&
                  accountsPermissions !== null &&
                  accountsPermissions[moduleName]
                }
                routeType={!isEmpty(routeType) && routeType}
                moduleName={!isEmpty(moduleName) && moduleName}
                permissionRequired={
                  !isEmpty(permissionRequired) && permissionRequired
                }
                appData={appData}
                {...rest}
              />
            );
          } else {
            ProcessedComponent = <Navigate to='/' />;
          }
        } else {
          ProcessedComponent = (
            <Navigate to={routeType === ALLOW_IF_LOGGEDIN ? '/login' : '/'} />
          );
        }
      }
    } else {
      ProcessedComponent = <Component {...rest} appData={appData} />;
    }

    return ProcessedComponent;
  };

  const readAppData = () => {
    API.get(`/configurations/meta-details`).then(({ data }) => {
      setAppData(data);
    });
  };

  useEffect(() => {
    readAppData();
  }, []);

  return (
    <>
      <MetaTags>
        <title>{appData.appName}</title>
        <meta name='description' content={appData.appName} />
        <meta property='og:title' content={appData.appName} />
        <meta property='og:image' content={appData.appLogo} />
        <link rel='icon' href={appData.appFavicon} />
        <link rel='apple-touch-icon' href={appData.appLogo} />
        <meta name='theme-color' content={theme.colors.primaryColor} />
      </MetaTags>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Routes>
            {routesList.map(
              (
                {
                  path,
                  routeType,
                  moduleName,
                  permissionRequired,
                  component,
                  target,
                  ...rest
                },
                index
              ) =>
                path && (
                  <Route
                    path={path}
                    element={
                      <ProcessedRoute
                        routeType={routeType}
                        moduleName={moduleName}
                        permissionRequired={permissionRequired}
                        component={component}
                        appData={appData}
                        {...rest}
                      />
                    }
                    key={index}
                  />
                )
            )}
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
