import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { MessageBox } from '../../components/FormElements';
import { camelCaseToString } from '../../utils';

const StyledStatsContainerOuter = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledStatsContainer = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const StyledStatsSection = styled.section`
  width: 300px;
  height: 100px;
  background: linear-gradient(
    to top right,
    ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)},
    ${({
      theme: {
        colors: { secondaryColor },
      },
    }) => (secondaryColor ? secondaryColor : `#0078ce`)},
    ${({
      theme: {
        colors: { secondaryColor },
      },
    }) => (secondaryColor ? secondaryColor : `#0078ce`)},
    ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)}
  );
  padding: 0px;
  margin: 0px;
  display: grid;
  border-radius: 10px;
  grid-template-columns: max-content auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledStatsIcon = styled.section`
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  i {
    width: 30px;
    height: 30px;
    color: #ffffff;
    border: 5px solid #ffffff;
    padding: 15px;
    border-radius: 100px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledStatsCounter = styled.section`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
`;

const StyledStatsHeading = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
  padding: 15px;
  text-align: left;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledStatsCount = styled.h3`
  font-size: 22px;
  font-weight: normal;
  margin: 0px;
  padding: 18px 15px 0px 15px;
`;

const Stats = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [stats, setStats] = useState([]);

  const readStats = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/common/stats')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          const stats = [];
          for (const property in data) {
            if (
              property.indexOf('Color') !== -1 ||
              property.indexOf('Icon') !== -1
            ) {
              continue;
            } else {
              const colorKey = `${property}Color`;
              const iconKey = `${property}Icon`;
              stats.push({
                title: property,
                processedTitle: camelCaseToString(
                  property.indexOf('_') !== -1
                    ? property.split('_')[1]
                    : property
                ),
                value: data[property],
                color: data[colorKey],
                icon: data[iconKey],
              });
            }
          }
          setStats(stats);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readStats();
  }, []);

  return (
    <>
      <PageHeading {...props} />
      <LoadingSpinner
        isLoading={responseStatus === '' && stats?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          stats?.length === 0
        }
        message={`No stats found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {stats && stats?.length !== 0 && (
        <StyledStatsContainerOuter>
          <StyledStatsContainer>
            {stats.map(({ value, icon, processedTitle }, index) => (
              <StyledStatsSection key={index}>
                <StyledStatsIcon>
                  <i className={icon}></i>
                </StyledStatsIcon>
                <StyledStatsCounter>
                  <StyledStatsCount>{value}</StyledStatsCount>
                  <StyledStatsHeading title={processedTitle}>
                    {processedTitle}
                  </StyledStatsHeading>
                </StyledStatsCounter>
              </StyledStatsSection>
            ))}
          </StyledStatsContainer>
        </StyledStatsContainerOuter>
      )}
    </>
  );
};

export default withAppHOC(Stats);
