import React, { useState, useEffect, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMobileAlt,
  faBriefcase,
  faAt,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Logo from '../components/Logo/Logo';
import Header from '../components/Header/Header';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import API from '../api';
import { API_RESPONSE_TYPES } from '../constants';
import { authData } from '../utils';
import ScrollMessage from '../components/ScrollMessage/ScrollMessage';

const StyledMain = styled.main`
  width: 95%;
  max-width: 1800px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
`;

const StyledAuthMainOuter = styled.section`
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
`;

const StyledMainSection = styled.section`
  width: 95%;
  max-width: 1800px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 50px;
  padding: 50px 0px;
  flex: 1;
  @media (max-width: 1000px) {
    width: 100%;
    display: block;
    padding: 10px 0px;
  }
`;

const StyledAuthHeader = styled.section`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }
`;

const StyledAuthFormSection = styled.section`
  width: 700px;
  height: auto;
  margin: 0px;
  padding: 0px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const StyledContentArea = styled.section`
  width: 100%;
  height: auto;
`;

const StyledContantBlocksSection = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  row-gap: 25px;
  column-gap: 25px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 0px;
  padding: 0px;
  @media (max-width: 1000px) {
    display: block;
    width: 92%;
    margin: 0 auto;
  }
`;

const StyledContentBlock = styled.section`
  width: calc(33.33% - 20px);
  min-height: 150px;
  height: auto;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#0095ff'};
  border-radius: 15px;
  box-shadow: 0px 0px 50px -10px #d1d1d1;
  @media (max-width: 1000px) {
    width: 100%;
    margin: 15px auto;
  }
`;

const StyledContentHeading = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin: 20px 20px 10px;
  padding: 0px;
  @media (max-width: 1000px) {
    margin: 0px;
    padding: 15px 15px 0px;
  }
`;

const StyledContentPara = styled.p`
  font-size: 14px;
  color: ${({ color }) => (color ? color : '#ffffff')};
  margin: 0px 20px 20px;
  padding: 0px;
  line-height: 24px;
  @media (max-width: 1000px) {
    margin: 0px;
    padding: 15px 15px 0px 15px;
  }
`;

const StyledContactSection = styled.section`
  width: calc(100% - 50px);
  height: auto;
  padding: 0px;
  margin: 10px 0px 0px 15px;
`;

const StyledAddressContent = styled.section``;

const StyledPhoneContent = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const StyledContactInfo = styled.section`
  height: auto;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  margin-bottom: 5px;
`;

const StyledIcon = styled.section`
  font-size: 25px;
  color: #545454;
`;

const StyledContent = styled.section`
  h3 {
    font-size: 16px;
    color: #545454;
    margin: 0px 0px 10px 0px;
    padding: 0px;
    font-weight: normal;
  }
  address {
    font-size: 14px;
    color: #545454;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;
  }
`;

const StyledNumberContent = styled.section`
  align-items: center;
  display: flex;
  a {
    font-size: 14px;
    text-decoration: none;
    color: #0095ff;
  }
`;

const StyledContactUsHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  margin: 25px 15px 0px;
  padding: 0px;
  color: #000000;
  font-family: math;
`;

const StyledAboutUsText = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  margin: 10px 15px;
  color: #545454;
`;

const withAuthHOC = (WrappedComponent) => {
  return (props) => {
    const {
      appName,
      appLogo,
      firmName,
      firmAddress,
      appEmailAddress,
      appPhoneNumber,
      aboutUs,
    } = props.appData;

    return (
      <>
        <StyledAuthMainOuter>
          <StyledMainSection>
            <StyledAuthFormSection>
              <StyledAuthHeader>
                <Logo src={appLogo} alt={appName} type="auth" />
              </StyledAuthHeader>
              <WrappedComponent {...props} />
            </StyledAuthFormSection>
            <StyledContentArea>
              <StyledContantBlocksSection>
                <StyledContentBlock backgroundColor="#e91e63">
                  <StyledContentHeading>Mobile Recharge</StyledContentHeading>
                  <StyledContentPara>
                    Prepaid Mobile Recharge with all major operators
                  </StyledContentPara>
                </StyledContentBlock>
                <StyledContentBlock backgroundColor="#009688">
                  <StyledContentHeading>DTH Recharge</StyledContentHeading>
                  <StyledContentPara>
                    DTH Recharge with all major operators in just few clicks
                    with Great Success Rate.
                  </StyledContentPara>
                </StyledContentBlock>
                <StyledContentBlock backgroundColor="#0095ff">
                  <StyledContentHeading>
                    Utility Bill Payments
                  </StyledContentHeading>
                  <StyledContentPara>
                    Electricity, Postpaid, Gas, Water & many more.
                  </StyledContentPara>
                </StyledContentBlock>
                <StyledContentBlock backgroundColor="#673ab7">
                  <StyledContentHeading>
                    Whitelabel Account
                  </StyledContentHeading>
                  <StyledContentPara>
                    Get your Own Brand with our Whitelabel User Account, You can
                    have your own Brands.
                  </StyledContentPara>
                </StyledContentBlock>
                <StyledContentBlock backgroundColor="#ff9800">
                  <StyledContentHeading>
                    Begin your journey
                  </StyledContentHeading>
                  <StyledContentPara>
                    Register yourself as master distributor, distributors,
                    retailer or api user to enjoy benefits.
                  </StyledContentPara>
                </StyledContentBlock>
                <StyledContentBlock backgroundColor="#4caf50">
                  <StyledContentHeading>99% Success Ratio</StyledContentHeading>
                  <StyledContentPara>
                    Enjoy hassel free experience with 99.99% success ratio.
                  </StyledContentPara>
                </StyledContentBlock>
              </StyledContantBlocksSection>
              {aboutUs && (
                <>
                  <StyledContactUsHeading>About Us</StyledContactUsHeading>
                  <StyledAboutUsText>{aboutUs}</StyledAboutUsText>
                </>
              )}
              <StyledContactUsHeading>Contact Us</StyledContactUsHeading>
              <StyledContactSection>
                {(firmName || firmAddress) && (
                  <StyledAddressContent>
                    <StyledContactInfo>
                      <StyledIcon>
                        <FontAwesomeIcon
                          icon={faBriefcase}
                          style={{ color: '#795548' }}
                        />
                      </StyledIcon>
                      <StyledContent>
                        {firmName && <h3>{firmName}</h3>}
                        {firmAddress && <address>{firmAddress}</address>}
                      </StyledContent>
                    </StyledContactInfo>
                  </StyledAddressContent>
                )}
                <StyledPhoneContent>
                  <StyledContactInfo>
                    <StyledIcon>
                      <FontAwesomeIcon
                        icon={faMobileAlt}
                        style={{ color: '#192ea1' }}
                      />
                    </StyledIcon>
                    <StyledNumberContent>
                      <a
                        href={`tel:+91${appPhoneNumber}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {appPhoneNumber}
                      </a>
                    </StyledNumberContent>
                  </StyledContactInfo>
                  <StyledContactInfo>
                    <StyledIcon>
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{ color: '#128C7E' }}
                      />
                    </StyledIcon>
                    <StyledNumberContent>
                      <a
                        href={`whatsapp://send?abid=+91${appPhoneNumber}&text=Hello`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {appPhoneNumber}
                      </a>
                    </StyledNumberContent>
                  </StyledContactInfo>
                  {appEmailAddress && (
                    <StyledContactInfo>
                      <StyledIcon>
                        <FontAwesomeIcon
                          icon={faAt}
                          style={{ color: '#c71610' }}
                        />
                      </StyledIcon>
                      <StyledNumberContent>
                        <a
                          href={`mailto:${appEmailAddress}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {appEmailAddress}
                        </a>
                      </StyledNumberContent>
                    </StyledContactInfo>
                  )}
                </StyledPhoneContent>
              </StyledContactSection>
            </StyledContentArea>
          </StyledMainSection>
        </StyledAuthMainOuter>
        <Footer appName={appName} />
      </>
    );
  };
};

const withAppHOC = (WrappedComponent) => {
  return (props) => {
    const { accountData } = authData.get();
    const {
      appName,
      appLogo,
      appPhoneNumber,
      appEmailAddress,
      packageExpiryDate,
    } = props.appData;
    const [showNav, setShowNav] = useState(false);
    const [walletBalance, setWalletBalance] = useState('-');
    const [creditDue, setCreditDue] = useState('-');
    const [minimumBalance, setMinimumBalance] = useState('-');
    const [profileName, setProfileName] = useState('-');
    const [profilePhoto, setProfilePhoto] = useState('');
    const [scrollMessage, setScrollMessage] = useState('');
    const [fcmToken, setFcmToken] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [authDomain, setAuthDomain] = useState('');
    const [projectId, setProjectId] = useState('');
    const [storageBucket, setStorageBucket] = useState('');
    const [messagingSenderId, setMessagingSenderId] = useState('');
    const [appId, setAppId] = useState('');
    const [measurementId, setMeasurementId] = useState('');
    const [vapidKey, setVapidKey] = useState('');
    const [notifications, setNotifications] = useState([]);

    const onClickLogout = (e) => {
      e.preventDefault();
      const { authToken } = authData.get();
      API.delete(`/auth/sessions/?sessionToken=${authToken}`);
      authData.clear();
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
      window.location.href = '/login';
    };

    const saveFcm = useCallback(() => {
      API.post(`/auth/save-fcm`, { fcmToken });
    }, [fcmToken]);

    const readNotifications = useCallback(() => {
      API.get(`/configurations/notifications`).then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setNotifications(data);
        }
      });
    }, []);

    const updateNotification = (id) => {
      API.put(`/configurations/notifications/${id}`).then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setNotifications(data);
        }
      });
    };

    const readWalletBalance = useCallback(() => {
      API.get(`/auth/profile`).then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setWalletBalance(data.walletBalance);
          setCreditDue(data.creditDue);
          setMinimumBalance(data.minimumBalance);
          setProfileName(`${data.firstName} ${data.lastName}` || 'N/A');
          setProfilePhoto(data.photo || '');
        }
      });
    }, []);

    const readScrollMessage = useCallback(() => {
      API.get(`/configurations/scroll-message`).then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setScrollMessage(data);
        }
      });
    }, []);

    const onClickNavigationIcon = () => {
      setShowNav((showNav) => !showNav);
    };

    const readPublicFCMWebConfig = useCallback(() => {
      API.get(`/configurations/push-notifications-configuration/public`).then(
        (response) => {
          const { status, data } = response.data;
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            const {
              apiKey,
              authDomain,
              projectId,
              storageBucket,
              messagingSenderId,
              appId,
              measurementId,
              vapidKey,
            } = data;
            setApiKey(apiKey ? apiKey : '');
            setAuthDomain(authDomain ? authDomain : '');
            setProjectId(projectId ? projectId : '');
            setStorageBucket(storageBucket ? storageBucket : '');
            setMessagingSenderId(messagingSenderId ? messagingSenderId : '');
            setAppId(appId ? appId : '');
            setMeasurementId(measurementId ? measurementId : '');
            setVapidKey(vapidKey ? vapidKey : '');
          }
        }
      );
    }, []);

    const initFirebase = useCallback(async () => {
      const app = await initializeApp({
        apiKey,
        authDomain,
        projectId,
        storageBucket,
        messagingSenderId,
        appId,
        measurementId,
      });

      const messaging = getMessaging(app);

      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.info('Notification User Permission Granted.');
          return getToken(messaging, {
            vapidKey,
          })
            .then((currentToken) => {
              if (currentToken) {
                console.info('FCM Token: ', currentToken);
                if (fcmToken !== currentToken) {
                  setFcmToken(currentToken);
                }
              } else {
                console.info('Failed to generate FCM token.');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.info('Notification User Permission Denied.');
        }
      });

      onMessage(
        messaging,
        (payload) => {
          console.info(payload);
          readNotifications();
        },
        (error) => {
          console.error(error);
        }
      );

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js', {
            apiKey,
            authDomain,
            projectId,
            storageBucket,
            messagingSenderId,
            appId,
            measurementId,
          })
          .then((registration) => {
            console.info(
              'Registration successful, scope is:',
              registration.scope
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }, [
      fcmToken,
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId,
      vapidKey,
      readNotifications,
    ]);

    useEffect(() => {
      readWalletBalance();
      readScrollMessage();
      readPublicFCMWebConfig();
      readNotifications();
    }, [
      readWalletBalance,
      readScrollMessage,
      readPublicFCMWebConfig,
      readNotifications,
    ]);

    useEffect(() => {
      if (
        apiKey !== '' &&
        authDomain !== '' &&
        projectId !== '' &&
        storageBucket !== '' &&
        messagingSenderId !== '' &&
        appId !== '' &&
        measurementId !== '' &&
        vapidKey !== ''
      ) {
        initFirebase();
      }
    }, [
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId,
      vapidKey,
      initFirebase,
    ]);

    useEffect(() => {
      saveFcm();
    }, [fcmToken, saveFcm]);

    return (
      <>
        <Header
          walletBalance={walletBalance}
          creditDue={creditDue}
          minimumBalance={minimumBalance}
          supportMobileNumber={appPhoneNumber}
          supportEmailAddress={appEmailAddress}
          fullName={profileName}
          profilePhoto={profilePhoto}
          onClickLogout={onClickLogout}
          appLogo={appLogo}
          appName={appName}
          packageExpiryDate={packageExpiryDate}
          showNav={showNav}
          setShowNav={onClickNavigationIcon}
          notifications={notifications}
          updateNotification={updateNotification}
          commissionMode={accountData.commissionMode}
          commissionValue={accountData.commissionValue}
        />
        <Navigation showNav={showNav} />
        <ScrollMessage scrollMessage={scrollMessage} />
        <StyledMain>
          <WrappedComponent {...props} readWalletBalance={readWalletBalance} />
        </StyledMain>
        <Footer appName={appName} />
      </>
    );
  };
};

export { withAuthHOC, withAppHOC };
