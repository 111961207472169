import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  display: block;
  width: calc(100% - 22px);
  height: auto;
  font-size: 14px;
  padding: 15px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  outline-color: #cacaca;
  background-color: #ffffff;
  &:disabled {
    cursor: not-allowed;
    background-color: #fafafa;
  }
`;

const TextareaInput = ({
  value = '',
  onChange = () => {},
  maxLength = 1000,
  focus = false,
  disabled = false,
  ...rest
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  return (
    <StyledTextarea
      type="textarea"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      ref={inputRef}
      autoComplete="off"
      disabled={disabled}
      {...rest}
    />
  );
};

export default TextareaInput;
