import React from 'react';
import styled from 'styled-components';
import { getAmountInWords } from '../../../utils';

export const Container = styled.div`
  display: none;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
  position: relative;
  box-sizing: border-box;
  height: 1070px;
`;

const Invoice = ({ invoices }) => {
  return (
    <Container id='invoice-container'>
      {invoices?.length > 0 &&
        invoices.map(
          (
            {
              fromEntityLogoBase64String,
              fromFirmName,
              fromFirmAddress,
              fromGstNumber,
              fromPanNumber,
              fromMobileNumber,
              fromEmailAddress,
              invoiceNumber,
              invoiceDate: date,
              toFirmName,
              toFirmAddress,
              toGstNumber,
              toPanNumber,
              toMobileNumber,
              toEmailAddress,
              description,
              hsnCode: hsn,
              subtotal,
              gstPercentage: taxRate,
              taxAmount,
              total,
              fromBankName,
              fromBankBranch,
              fromBankAccountNumber,
              fromBankIfscCode,
              sameRagion,
              fromInvoiceBackgroundColor,
              fromInvoiceTextColor,
            },
            index
          ) => (
            <Page key={index}>
              <section className='invoice-header'>
                <section className='from-details'>
                  <section className='logo'>
                    {/* {fromEntityLogoBase64String && (
                      <img
                        src={`${fromEntityLogoBase64String}`}
                        alt={fromFirmName}
                      />
                    )} */}
                  </section>
                  <section className='details'>
                    {fromFirmName && <h1>{fromFirmName}</h1>}
                    {fromFirmAddress && <address>{fromFirmAddress}</address>}
                    {fromGstNumber && (
                      <p>
                        <b>GSTIN : </b>
                        {fromGstNumber}
                      </p>
                    )}
                    {fromPanNumber && (
                      <p>
                        <b>PAN : </b>
                        {fromPanNumber}
                      </p>
                    )}
                    {fromMobileNumber && (
                      <p>
                        <b>Mobile : </b>
                        {fromMobileNumber}
                      </p>
                    )}
                    {fromEmailAddress && (
                      <p>
                        <b>Email : </b>
                        {fromEmailAddress}
                      </p>
                    )}
                  </section>
                </section>
                <section className='invoice-no-date'>
                  <section className='invoice-id'>
                    <h2>Invoice No</h2>
                    <p>{invoiceNumber}</p>
                  </section>
                  <section className='invoice-date'>
                    <h2>Invoice Date</h2>
                    <p>{date}</p>
                  </section>
                </section>
              </section>
              <section className='to-section'>
                <h2>Bill To</h2>
                {toFirmName && <h2>{toFirmName}</h2>}
                {toFirmAddress && <address>{toFirmAddress}</address>}
                {toGstNumber && (
                  <p>
                    <b>GSTIN : </b>
                    {toGstNumber}
                  </p>
                )}
                {toPanNumber && (
                  <p>
                    <b>PAN : </b>
                    {toPanNumber}
                  </p>
                )}
                {toMobileNumber && (
                  <p>
                    <b>Mobile : </b>
                    {toMobileNumber}
                  </p>
                )}
                {toEmailAddress && (
                  <p>
                    <b>Email : </b>
                    {toEmailAddress}
                  </p>
                )}
              </section>
              <section className='invoice-body'>
                <table>
                  <thead
                    style={{
                      backgroundColor: fromInvoiceBackgroundColor,
                      color: fromInvoiceTextColor,
                    }}
                  >
                    <tr>
                      <th>S.NO</th>
                      <th>Description</th>
                      <th>HSN Code</th>
                      <th>Rate</th>
                      {sameRagion ? (
                        <>
                          <th>SGST</th>
                          <th>CGST</th>
                        </>
                      ) : (
                        <th>IGST</th>
                      )}
                      <th>AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{description}</td>
                      <td>{hsn}</td>
                      <td>{parseFloat(subtotal).toFixed(2)}</td>
                      {sameRagion ? (
                        <>
                          <td>
                            {parseFloat(taxAmount / 2).toFixed(2)} (
                            {taxRate / 2}%)
                          </td>
                          <td>
                            {parseFloat(taxAmount / 2).toFixed(2)} (
                            {taxRate / 2}%)
                          </td>
                        </>
                      ) : (
                        <td>
                          {parseFloat(taxAmount).toFixed(2)} ({taxRate}%)
                        </td>
                      )}
                      <td>{parseFloat(total).toFixed(2)}</td>
                    </tr>
                  </tbody>
                  <tfoot
                    style={{
                      backgroundColor: fromInvoiceBackgroundColor,
                      color: fromInvoiceTextColor,
                    }}
                  >
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td></td>
                      <td></td>
                      {sameRagion ? (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      ) : (
                        <td></td>
                      )}
                      <td>
                        <b>Rs {parseFloat(total).toFixed(2)}</b>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </section>
              {total && (
                <section className='amount-words'>
                  <h2>
                    <b>Amount In Words :</b> {getAmountInWords(total)} Only
                  </h2>
                </section>
              )}
              <section className='invoice-footer'>
                <section className='bank-details'>
                  {(fromBankName ||
                    fromBankBranch ||
                    fromBankAccountNumber ||
                    fromBankIfscCode) && (
                    <>
                      <h2>Bank Details</h2>
                      {fromBankName && (
                        <section className='grid-two'>
                          <p>Bank Name :</p>
                          <p>{fromBankName}</p>
                        </section>
                      )}
                      {fromBankBranch && (
                        <section className='grid-two'>
                          <p>Bank Branch :</p>
                          <p>{fromBankBranch}</p>
                        </section>
                      )}
                      {fromBankAccountNumber && (
                        <section className='grid-two'>
                          <p>Account Number :</p>
                          <p>{fromBankAccountNumber}</p>
                        </section>
                      )}
                      {fromBankIfscCode && (
                        <section className='grid-two'>
                          <p>IFSC Code :</p>
                          <p>{fromBankIfscCode}</p>
                        </section>
                      )}
                    </>
                  )}
                </section>
                <section className='signature'>
                  <section className='signature-box'></section>
                  <p>Authorised Signature for {fromFirmName}</p>
                </section>
              </section>
            </Page>
          )
        )}
    </Container>
  );
};

export default Invoice;
