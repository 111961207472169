import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import {
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  ResetButton,
  Button,
  Label,
} from '../../components/FormElements';
import { TableBuilder } from '../../components/TableElements';
import Pagination from '../../components/Pagination/Pagination';
import { StyledFiltersContainer } from '../../components/Styled';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import {
  getSearchParams,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
  authData,
} from '../../utils';
import FiltersButton from '../../components/FiltersButton/FiltersButton';

const WalletTransactions = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { accountData } = authData.get();
  const id = accountData.id;

  const resetForm = () => {
    setStartDate('');
    setEndDate('');
    readData();
  };

  const readData = useCallback(
    (startDate = '', endDate = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/auth/wallet-transactions?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&startDate=${startDate}&endDate=${endDate}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setWalletTransactions(data);
              setTotalRecords(pageInfo.totalRecords);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(startDate, endDate);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(startDate, endDate);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type='date'
              value={startDate}
              onChange={setStartDate}
              placeholder='Start Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type='date'
              value={endDate}
              onChange={setEndDate}
              placeholder='End Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' && walletTransactions?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          walletTransactions?.length === 0
        }
        message={`No wallet transactions found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {walletTransactions?.length > 0 && (
        <>
          <TableBuilder
            isLoading={walletTransactions?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: 'Date & Time',
                dataSelector: 'createdOn',
                dataType: 'date',
              },
              {
                title: 'Transaction Reference Id',
                dataSelector: 'transactionsReferenceId',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Transaction Type',
                dataSelector: 'transactionsType',
                dataType: 'string',
              },
              {
                title: 'Amount',
                dataSelector: 'amount',
                dataType: 'number',
                align: 'center',
                CellRenderer: (value, item) => (
                  <span
                    style={{
                      color:
                        item.transactionsType === 'Credit'
                          ? 'forestgreen'
                          : 'red',
                    }}
                  >
                    {formatCurrency(value)}
                  </span>
                ),
              },
              {
                title: 'Balance',
                dataSelector: 'balance',
                dataType: 'number',
                align: 'center',
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: 'Action',
                dataSelector: 'action',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
            ]}
            tableData={walletTransactions}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(WalletTransactions);
