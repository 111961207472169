import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  Button,
  ResetButton,
  Label,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledLink,
  StyledFiltersContainer,
  StyledSpan,
} from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import StatusHighliter from '../../../components/StatusHighliter/StatusHighliter';

const VendorsTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [vendors, setVendors] = useState([]);
  const [vendorName, setVendorName] = useState('');

  const resetForm = () => {
    setVendorName('');
    readData();
  };

  const readData = useCallback(
    (vendorName = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/accounts-management/vendors/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fVendorName=${vendorName}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setVendors(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(vendorName);
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/accounts-management/vendors/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fVendorName=${vendorName}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setVendors(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(vendorName);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Vendor Name</Label>
            <TextInput
              value={vendorName}
              onChange={setVendorName}
              placeholder='Vendor Name'
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && vendors?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          vendors?.length === 0
        }
        message={`No vendors found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {vendors?.length > 0 && (
        <>
          <TableBuilder
            isLoading={vendors?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value, item) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Full Name',
                dataSelector: 'firstName',
                dataType: 'string',
                CellRenderer: (value, item) => {
                  const { firstName, lastName, firmName } = item;
                  return (
                    <>
                      {firstName} {lastName}
                      {firmName && <StyledSpan>{firmName}</StyledSpan>}
                    </>
                  );
                },
              },
              {
                title: 'Mobile Number',
                dataSelector: 'mobileNumber',
                dataType: 'number',
              },
              {
                title: 'Bank Name',
                dataSelector: 'bankName',
                dataType: 'string',
              },
              {
                title: 'Account Number',
                dataSelector: 'accountNumber',
                dataType: 'string',
              },
              {
                title: 'IFSC Code',
                dataSelector: 'ifscCode',
                dataType: 'string',
              },
              {
                title: 'Is API Provider',
                dataSelector: 'isAPIProvider',
                dataType: 'string',
                align: 'center',
                CellRenderer: (value) =>
                  value && (
                    <StatusHighliter
                      className={value === 'Yes' ? 'green' : 'red'}
                    >
                      {value}
                    </StatusHighliter>
                  ),
              },
              {
                title: 'API Providers Name',
                dataSelector: 'apiProvidersName',
                dataType: 'string',
                CellRenderer: (value, item) => (value ? value : 'N/A'),
              },
            ]}
            tableData={vendors}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(VendorsTable);
