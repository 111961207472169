import React, { useEffect, useCallback, memo } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const StyledPaginationContainer = styled.section`
  width: calc(100% - 20px);
  height: auto;
  margin: 0px;
  padding: 10px;
  display: grid;
  grid-template-columns: max-content auto max-content;
  background-color: ${({
    theme: {
      colors: { tableHeaderColor },
    },
  }) => (tableHeaderColor ? tableHeaderColor : `#545454`)};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  @media (max-width: 600px) {
    grid-template-columns: auto;
    grid-gap: 20px;
  }
`;

const StyledPageinationCounter = styled.section`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
`;

const StyledPagination = styled.section`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
`;

const StyledNextPrevButtons = styled.button`
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 0px;
  margin: 0px 5px;
  width: 35px;
  height: 35px;
  outline: 0px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;
  color: #ffffff;
  opacity: 0.5;
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
    margin: 0px 2px;
  }
  &:hover {
    opacity: 1;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledPaginationNumber = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  background-color: transparent;
  padding: 0px;
  margin: 0px 5px;
  width: 35px;
  height: 35px;
  outline: 0px;
  border-radius: 100px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;
  opacity: 0.5;
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
    margin: 0px 2px;
  }
  &:hover {
    background-color: #fff;
    opacity: 1;
    color: ${({
      theme: {
        colors: { tableHeaderColor },
      },
    }) => (tableHeaderColor ? tableHeaderColor : `#545454`)};
  }
  &.active {
    background-color: #fff;
    opacity: 1;
    color: ${({
      theme: {
        colors: { tableHeaderColor },
      },
    }) => (tableHeaderColor ? tableHeaderColor : `#545454`)};
  }
`;

const StyledDots = styled.span`
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 0px;
  margin: 0px 5px;
`;

const StyledNumberOfRecords = styled.section`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
`;

const StyledSelect = styled.select`
  font-size: 14px;
  outline: 0px;
  padding: 5px;
  margin: 0px 0px 0px 5px;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
`;

const Pagination = ({
  totalRecords,
  paginationData,
  setPaginationData,
  disableQueryString = false,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageNumber, recordsPerPage } = paginationData;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const recordsPerPageOptions =
    totalRecords >= 10
      ? [10, 30, 50, 100, 200, 500].filter((item) => item <= totalRecords)
      : [10];

  const onClickPrevPage = () => {
    setPaginationData({
      pageNumber: pageNumber - 1,
      recordsPerPage: recordsPerPage,
    });
  };

  const onClickNextPage = () => {
    setPaginationData({
      pageNumber: pageNumber + 1,
      recordsPerPage: recordsPerPage,
    });
  };

  const onClickPageNumber = (pageNumber) => {
    setPaginationData({
      pageNumber: pageNumber,
      recordsPerPage: recordsPerPage,
    });
  };

  const onChangeNumberOfRecords = (event) => {
    event.preventDefault();

    setPaginationData({
      pageNumber: 1,
      recordsPerPage: Number(event.target.value),
    });
  };

  const generatePageNumbers = () => {
    let pageNumbers = [];
    const paginationSection = Math.ceil(pageNumber / 5);
    const paginationStartNumber = paginationSection * 5 - 4;
    const totalSections = Math.ceil(totalPages / 5);

    for (let i = paginationStartNumber; i <= totalPages; i++) {
      if (pageNumbers.length < 5) {
        pageNumbers.push(i);
      } else {
        break;
      }
    }
    if (totalSections > 1 && totalSections !== paginationSection) {
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    }
    if (paginationSection > 1) {
      pageNumbers.unshift('...');
      pageNumbers.unshift(1);
    }

    return pageNumbers;
  };

  const getSearchParam = useCallback(
    (key) => {
      return Number(searchParams.get(key));
    },
    [searchParams]
  );

  useEffect(() => {
    const newPageNumber = getSearchParam('pageNumber');
    const newRecordsPerPage = getSearchParam('recordsPerPage');
    if (
      (pageNumber !== newPageNumber || recordsPerPage !== newRecordsPerPage) &&
      newPageNumber !== 0 &&
      newRecordsPerPage !== 0
    ) {
      setPaginationData({
        pageNumber: newPageNumber,
        recordsPerPage: newRecordsPerPage,
      });
    }
  }, [searchParams, getSearchParam, setPaginationData]);

  return (
    <StyledPaginationContainer>
      <StyledPageinationCounter>
        Page {pageNumber} of {totalPages}
      </StyledPageinationCounter>
      <StyledPagination>
        <StyledNextPrevButtons
          onClick={onClickPrevPage}
          disabled={pageNumber === 1}
          key='prev'
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </StyledNextPrevButtons>
        {generatePageNumbers()?.map((item, index) =>
          item === '...' ? (
            <StyledDots key={`pg-${index}`}>...</StyledDots>
          ) : (
            <StyledPaginationNumber
              onClick={() => onClickPageNumber(item)}
              className={pageNumber === item && `${'active'}`}
              key={`pg-${index}`}
            >
              {item}
            </StyledPaginationNumber>
          )
        )}
        <StyledNextPrevButtons
          onClick={onClickNextPage}
          disabled={pageNumber === totalPages}
          key='next'
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </StyledNextPrevButtons>
      </StyledPagination>
      <StyledNumberOfRecords>
        Records Per Page
        <StyledSelect value={recordsPerPage} onChange={onChangeNumberOfRecords}>
          {recordsPerPageOptions.map((item) => (
            <option
              value={item}
              key={item}
              style={{ color: '#000' }}
              {...(item === recordsPerPage && `selected`)}
            >
              {item}
            </option>
          ))}
        </StyledSelect>
      </StyledNumberOfRecords>
    </StyledPaginationContainer>
  );
};

export default memo(Pagination);
