import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  TextInput,
  Label,
  CreateButton,
  CustomButton,
  NumberInput,
  FormContainer,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledModalContainer,
  StyledFiltersContainer,
  StyledSpan,
} from '../../../components/Styled';
import API from '../../../api';
import { ACCOUNT_TYPES, API_RESPONSE_TYPES } from '../../../constants';
import {
  authData,
  formatCurrency,
  generatePDF,
  generateSinglePDF,
  generateExistingInvoicePDF,
  getAmountInWords,
} from '../../../utils';
import Invoice from '../common/Invoice';
import SingleInvoices from '../common/SingleInvoices';
import APIConfig from '../../../constants/api.config';
import Modal from '../../../components/Modal';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  faDownload,
  faEdit,
  faScissors,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Grid, GridItem } from '../../../components/Grid';
import ExistingInvoice from '../common/ExistingInvoice';
import moment from 'moment';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import StatusHighliter from '../../../components/StatusHighliter/StatusHighliter';

const StyledPageHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: #000000;
  margin: 20px 0px;
  padding: 0px;
  align-self: center;
`;

const StyledExistingAmount = styled.h2`
  font-size: 16px;
  margin: 0px 0px 20px 0px;
  padding: 0;
`;

const StyledDeleteIconContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AccountGSTInvoices = (props) => {
  const { accountType } = authData.get();
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [invoicesData, setInvoicesData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsId, setAccountsId] = useState('');
  const [yearMonth, setYearMonth] = useState(
    moment().subtract(1, 'months').endOf('month').format('YYYY-MM')
  );
  const [year, setYear] = useState(
    moment().subtract(1, 'months').endOf('month').format('YYYY')
  );
  const [month, setMonth] = useState(
    moment().subtract(1, 'months').endOf('month').format('MM')
  );
  const [existingInvoices, setExistingInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalId, setModalId] = useState('');
  const [amount, setAmount] = useState('');
  const [customInvoicesData, setCustomInvoicesData] = useState([]);

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const readAccounts = () => {
    API.get('/accounts-management/accounts').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readData = useCallback((accountsId = '', year = '', month = '') => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/accounting/account-gst-invoices/?accountsId=${accountsId}&year=${year}&month=${month}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        }
        setInvoicesData(data.invoicesData || []);
        setExistingInvoices(data.existingInvoices || []);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const generateInvoices = (id, accountsId) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');

    const formData = {
      accountsId,
      year: year,
      month: month,
      invoices: invoicesData.filter((item) => {
        return item.id === id;
      }),
    };
    API.post(
      '/accounting/account-gst-invoices',
      formData,
      APIConfig.HEADERS.PUT
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        }
        setInvoices(
          data.map((item) => ({
            sameRagion:
              item.fromGstNumber &&
              item.toGstNumber &&
              item.fromGstNumber.substr(0, 2) === item.toGstNumber.substr(0, 2),
            ...item,
          }))
        );
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generateCustomInvoices = (e) => {
    e.preventDefault();
    const invoiceTotal =
      parseFloat(invoicesData.find((item) => item.id === modalId)?.total) || 0;
    const customInvoicesTotal = parseFloat(
      customInvoicesData
        .filter(({ total }) => total !== '')
        .reduce(
          (tot, item) => (item.total ? parseFloat(item.total) + tot : tot),
          0
        )
    );

    if (invoiceTotal !== customInvoicesTotal) {
      setResponseStatus('failed');
      setResponseMessage('Your invoices sum is not equal to total');
    } else {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      const formData = {
        accountsId: customInvoicesData[0]?.accountsId,
        year: year,
        month: month,
        invoices: customInvoicesData,
      };
      API.post(
        '/accounting/account-gst-invoices',
        formData,
        APIConfig.HEADERS.PUT
      )
        .then((response) => {
          const { status, message, data } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setInvoices(
              data.map((item) => ({
                sameRagion:
                  item.fromGstNumber &&
                  item.toGstNumber &&
                  item.fromGstNumber.substr(0, 2) ===
                    item.toGstNumber.substr(0, 2),
                ...item,
              }))
            );
            setTimeout(() => {
              setModalType('');
              setModalId('');
              setShowModal(false);
              resetResponseData();
            }, 3000);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const updateInvoice = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      amount,
    };
    API.put(
      `/accounting/account-gst-invoices/${modalId}?accountsId=${accountsId}&year=${year}&month=${month}`,
      formData
    )
      .then((response) => {
        const {
          status,
          message,
          // data: { invoicesData, existingInvoices },
        } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          // setInvoicesData(invoicesData);
          // setExistingInvoices(existingInvoices);
          readData(accountsId, year, month);
          setAmount('');
          setTimeout(() => {
            setModalType('');
            setModalId('');
            setShowModal(false);
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    readData(accountsId, year, month);
  };

  const generateZipPDF = async (fileName) => {
    const zip = new JSZip();
    await invoices.forEach(async (invoice, index) => {
      const { invoiceNumber, toGstNumber } = invoice;

      await zip.file(
        `${invoiceNumber.replaceAll('/', '-')}.pdf`,
        generateSinglePDF(index)
      );
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${fileName}.zip`);
    });
  };

  const resetFilter = () => {
    const currentYearMonth = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM');
    const currentYear = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY');
    const currentMonth = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('MM');
    setAccountsId('');
    setYearMonth(currentYearMonth);
    setYear(currentYear);
    setMonth(currentMonth);
    readData('', currentYear, currentMonth);
  };

  const resetForm = () => {
    setAmount('');
    setCustomInvoicesData('');
  };

  useEffect(() => {
    if (
      ![
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
        ACCOUNT_TYPES.API_USERS,
      ].includes(accountType)
    ) {
      readAccounts();
    }
    readData('', year, month);
  }, []);

  useEffect(() => {
    if (invoices?.length > 0) {
      if (invoices?.length === 1) {
        generatePDF(`${year}_${month}`);
      } else {
        generateZipPDF(`${year}_${month}`);
      }
    }
  }, [invoices]);

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .forEach(({ id, firstName, lastName, entityName }) =>
      accountsOptions.push({
        title: `${firstName} ${lastName}`,
        subTitle: entityName,
        value: id,
      })
    );

  let totalInvoiceAmount = 0;
  let splittedAmount = 0;
  if (showModal && modalId !== '') {
    totalInvoiceAmount =
      parseFloat(invoicesData.find((item) => item.id === modalId)?.total) || 0;
  }
  if (showModal && modalType === 'SPLIT') {
    splittedAmount = customInvoicesData
      .filter(({ total }) => total !== '')
      .reduce(
        (tot, item) => (item.total ? parseFloat(item.total) + tot : tot),
        0
      );
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} canReloadProp={false} />
      <Invoice invoices={invoices} />
      {invoices &&
        invoices?.length > 0 &&
        invoices.map((invoice, index) => (
          <SingleInvoices key={index} invoice={invoice} index={index} />
        ))}
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Account</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Account'
              value={accountsId}
              onChange={setAccountsId}
              options={accountsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Year & Month</Label>
            <TextInput
              type='month'
              value={yearMonth}
              onChange={(value) => {
                setYearMonth(value);
                setYear(value.split('-')[0]);
                setMonth(value.split('-')[1]);
              }}
              placeholder='Year & Month'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Get Details
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetFilter}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          !showModal &&
          responseStatus === '' &&
          invoicesData?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={!isLoading && (!invoicesData || invoicesData?.length === 0)}
        message={`No data found`}
      />
      {invoicesData?.length > 0 && (
        <TableBuilder
          isLoading={!showModal && invoicesData?.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: '',
              dataSelector: 'id',
              sticky: true,
              canSort: false,
              width: '100px',
              CellRenderer: (value, item) => {
                return (
                  <>
                    <CustomButton
                      icon={faEdit}
                      onClick={() => {
                        setModalType('EDIT');
                        setModalId(value);
                        setShowModal(true);
                      }}
                      style={{ color: '#0095ff' }}
                    />
                    <CustomButton
                      icon={faDownload}
                      onClick={() => generateInvoices(value, item.accountsId)}
                    />
                    <CustomButton
                      icon={faScissors}
                      onClick={() => {
                        setModalType('SPLIT');
                        setModalId(value);
                        setShowModal(true);
                        setCustomInvoicesData(
                          invoicesData
                            .filter((item) => item.id === value)
                            .map((item) => ({
                              ...item,
                              total: '',
                            }))
                        );
                      }}
                      style={{ color: 'orange' }}
                    />
                  </>
                );
              },
            },
            {
              title: 'Account Name',
              dataSelector: 'firstName',
              dataType: 'string',
              CellRenderer: (value, item) => (
                <>
                  {value} {item.lastName}
                  {item.firmName && <StyledSpan>{item.firmName}</StyledSpan>}
                </>
              ),
            },
            {
              title: 'Year',
              dataSelector: 'year',
              dataType: 'string',
            },
            {
              title: 'Month',
              dataSelector: 'month',
              dataType: 'string',
            },
            {
              title: 'Total',
              dataSelector: 'total',
              dataType: 'number',
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: 'Invoice Date',
              dataSelector: 'invoiceDate',
              dataType: 'date',
            },
            {
              title: 'Invoice Generated',
              dataSelector: 'invoiceCount',
              dataType: 'string',
              align: 'center',
              CellRenderer: (value) =>
                value && (
                  <StatusHighliter className={value === 0 ? 'red' : 'green'}>
                    {value === 0 ? 'No' : 'Yes'}
                  </StatusHighliter>
                ),
            },
          ]}
          tableData={invoicesData}
        />
      )}

      {existingInvoices?.length > 0 && (
        <>
          <StyledPageHeading>Existing Invoices</StyledPageHeading>
          <TableBuilder
            isLoading={
              !showModal && existingInvoices?.length !== 0 && isLoading
            }
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value, item) => (
                  <>
                    <ExistingInvoice invoices={{ ...item }} />
                    <CustomButton
                      icon={faDownload}
                      onClick={() => {
                        generateExistingInvoicePDF(`${year}_${month}`, value);
                      }}
                    />
                  </>
                ),
              },
              {
                title: 'Invoice Number',
                dataSelector: 'invoiceNumber',
                dataType: 'string',
              },
              {
                title: 'Invoice Date',
                dataSelector: 'invoiceDate',
                dataType: 'date',
              },
              {
                title: 'Invoice Value',
                dataSelector: 'subtotal',
                dataType: 'number',
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: 'GST',
                dataSelector: 'taxAmount',
                dataType: 'number',
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: 'Total',
                dataSelector: 'total',
                dataType: 'number',
                CellRenderer: (value) => formatCurrency(value),
              },
            ]}
            tableData={existingInvoices}
          />
        </>
      )}
      <Modal
        modalStatus={showModal}
        setModalStatus={() => {
          resetResponseData();
          resetForm();
          setModalType('');
          setModalId('');
          setShowModal(false);
        }}
        hideCloseButton
      >
        <StyledModalContainer width={'500px'}>
          <h3>
            {modalType === 'EDIT'
              ? 'Update Invoice'
              : 'Split Invoice & Download'}
          </h3>
          <FormContainer>
            {modalType === 'EDIT' ? (
              <>
                {totalInvoiceAmount && totalInvoiceAmount !== 0 && (
                  <StyledExistingAmount>
                    Existing Invoice Amount :{' '}
                    {formatCurrency(totalInvoiceAmount)}
                  </StyledExistingAmount>
                )}
                <Form method='POST' action='#' onSubmit={updateInvoice}>
                  <Fieldset>
                    <Label required>Amount</Label>
                    <NumberInput
                      placeholder='Please enter invoice amount'
                      value={amount}
                      onChange={setAmount}
                      autoComplete='off'
                      disabled={isLoading}
                    />
                    {amount && (
                      <StyledSpan style={{ color: '#000' }}>
                        {getAmountInWords(amount)}
                      </StyledSpan>
                    )}
                  </Fieldset>
                  {showModal && responseStatus !== '' && (
                    <Fieldset>
                      <MessageBox
                        status={responseStatus}
                        message={responseMessage}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <CreateButton
                      disabled={isLoading === true}
                      type='submit'
                      style={{ width: 'auto' }}
                    >
                      Submit
                    </CreateButton>
                  </Fieldset>
                  <OverlayLoader showLoader={showModal && isLoading} />
                </Form>
              </>
            ) : (
              <>
                {totalInvoiceAmount && totalInvoiceAmount !== 0 && (
                  <StyledExistingAmount>
                    Existing Invoice Amount :{' '}
                    {formatCurrency(totalInvoiceAmount)}
                  </StyledExistingAmount>
                )}
                <Form
                  method='POST'
                  action='#'
                  onSubmit={generateCustomInvoices}
                >
                  {customInvoicesData &&
                    customInvoicesData?.length > 0 &&
                    customInvoicesData.map(({ invoiceDate, total }, index) => (
                      <Grid columns={'calc(95% - 10px) calc(5%)'} key={index}>
                        <GridItem>
                          <Grid
                            columns={'calc(50% - 10px) calc(50% - 10px)'}
                            key={index}
                          >
                            <GridItem>
                              <Fieldset>
                                <Label required>Invoice Date</Label>
                                <TextInput
                                  type='date'
                                  placeholder='Please enter invoice date'
                                  value={invoiceDate}
                                  onChange={(value) => {
                                    const newCustomInvoicesData = [
                                      ...customInvoicesData,
                                    ];
                                    newCustomInvoicesData[index].invoiceDate =
                                      value;
                                    setCustomInvoicesData(
                                      newCustomInvoicesData
                                    );
                                  }}
                                  autoComplete='off'
                                  disabled={isLoading}
                                />
                              </Fieldset>
                            </GridItem>
                            <GridItem>
                              <Fieldset>
                                <Label required>Amount</Label>
                                <NumberInput
                                  placeholder='Please enter invoice amount'
                                  value={total}
                                  onChange={(value) => {
                                    const newCustomInvoicesData = [
                                      ...customInvoicesData,
                                    ];
                                    newCustomInvoicesData[index].total = value;
                                    setCustomInvoicesData(
                                      newCustomInvoicesData
                                    );
                                  }}
                                  autoComplete='off'
                                  disabled={isLoading}
                                />
                              </Fieldset>
                            </GridItem>
                          </Grid>
                        </GridItem>
                        <GridItem>
                          <StyledDeleteIconContainer>
                            {index !== 0 && (
                              <CustomButton
                                icon={faTrashAlt}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const newCustomInvoicesData = [
                                    ...customInvoicesData,
                                  ];
                                  newCustomInvoicesData.splice(index, 1);
                                  setCustomInvoicesData(newCustomInvoicesData);
                                }}
                                style={{ color: 'red' }}
                              />
                            )}
                          </StyledDeleteIconContainer>
                        </GridItem>
                      </Grid>
                    ))}
                  {totalInvoiceAmount && totalInvoiceAmount !== 0 && (
                    <Fieldset>
                      <StyledSpan>
                        Total Splitted Amount : {formatCurrency(splittedAmount)}
                      </StyledSpan>
                      <StyledSpan>
                        Balance Left :{' '}
                        {formatCurrency(totalInvoiceAmount - splittedAmount)}
                      </StyledSpan>
                    </Fieldset>
                  )}
                  {showModal && responseStatus !== '' && (
                    <Fieldset>
                      <MessageBox
                        status={responseStatus}
                        message={responseMessage}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <CreateButton
                      disabled={isLoading}
                      type='submit'
                      style={{ width: 'auto' }}
                    >
                      Download
                    </CreateButton>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        const newCustomInvoicesData = [...customInvoicesData];
                        newCustomInvoicesData.push({
                          invoiceDate: invoicesData[0].invoiceDate,
                          total: '',
                        });
                        setCustomInvoicesData(newCustomInvoicesData);
                      }}
                      disabled={isLoading}
                      type='submit'
                      style={{ width: 'auto', marginLeft: 20 }}
                    >
                      Add
                    </Button>
                  </Fieldset>
                  <OverlayLoader showLoader={showModal && isLoading} />
                </Form>
              </>
            )}
          </FormContainer>
        </StyledModalContainer>
      </Modal>
    </>
  );
};

export default withAppHOC(AccountGSTInvoices);
