import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import Modal from '../../../components/Modal';
import { Image } from '../../../components/Image';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  FileInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  NumberInput,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl } from '../../../utils';

const StyledViewIconButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const OperatorsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState('');
  const [operatorName, setOperatorName] = useState('');
  const [operatorLogo, setOperatorLogo] = useState([]);
  const [operatorLogoBase64, setOperatorLogoBase64] = useState('');
  const [backupPlanApiCode, setBackupPlanApiCode] = useState('');
  const [planApiCode, setPlanApiCode] = useState('');
  const [onlyForApiUser, setOnlyForApiUser] = useState('No');
  const [isActiveForApiUser, setIsActiveForApiUser] = useState('Active');
  const [field1, setField1] = useState('');
  const [field2, setField2] = useState('');
  const [field3, setField3] = useState('');
  const [field4, setField4] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      servicesId,
      operatorName,
      operatorLogo,
      planApiCode,
      backupPlanApiCode,
      onlyForApiUser,
      isActiveForApiUser,
      field1,
      field2,
      field3,
      field4,
      minAmount,
      maxAmount,
    } = originalFormData;
    setServicesId(servicesId);
    setOperatorName(operatorName);
    setOperatorLogoBase64(operatorLogo);
    setPlanApiCode(planApiCode);
    setBackupPlanApiCode(backupPlanApiCode);
    setOnlyForApiUser(onlyForApiUser);
    setIsActiveForApiUser(isActiveForApiUser);
    setField1(field1);
    setField2(field2);
    setField3(field3);
    setField4(field4);
    setMinAmount(minAmount);
    setMaxAmount(maxAmount);
  };

  const readServices = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/services')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setServices(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/operators/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            servicesId,
            operatorName,
            operatorLogo,
            planApiCode,
            backupPlanApiCode,
            onlyForApiUser,
            isActiveForApiUser,
            field1,
            field2,
            field3,
            field4,
            minAmount,
            maxAmount,
          } = data[0];
          setServicesId(servicesId);
          setOperatorName(operatorName);
          setOperatorLogoBase64(operatorLogo);
          setPlanApiCode(planApiCode);
          setBackupPlanApiCode(backupPlanApiCode);
          setOnlyForApiUser(onlyForApiUser);
          setIsActiveForApiUser(isActiveForApiUser);
          setField1(field1);
          setField2(field2);
          setField3(field3);
          setField4(field4);
          setMinAmount(minAmount);
          setMaxAmount(maxAmount);
          setOriginalFormData({
            servicesId,
            operatorName,
            operatorLogo,
            planApiCode,
            backupPlanApiCode,
            onlyForApiUser,
            isActiveForApiUser,
            field1,
            field2,
            field3,
            field4,
            minAmount,
            maxAmount,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      servicesId,
      operatorName,
      operatorLogo: operatorLogoBase64,
      planApiCode,
      backupPlanApiCode,
      onlyForApiUser,
      isActiveForApiUser,
      field1,
      field2,
      field3,
      field4,
      minAmount,
      maxAmount,
    };
    API.put(`/recharge-configurations/operators/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (operatorLogo?.length > 0) {
      getBase64(operatorLogo[0], setOperatorLogoBase64);
    }
  }, [operatorLogo]);

  useEffect(() => {
    readServices();
    readData();
  }, [readServices, readData]);

  const serviceOptions = [{ title: '-- SELECT SERVICE --', value: '' }];
  services.forEach(({ id, serviceName }) =>
    serviceOptions.push({
      title: serviceName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Service</Label>
                <Dropdown
                  placeholder='Please select service'
                  value={servicesId}
                  onChange={setServicesId}
                  options={serviceOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Only For Api User ?</Label>
                <Dropdown
                  placeholder='Please select if operator is only for api user'
                  value={onlyForApiUser}
                  onChange={setOnlyForApiUser}
                  options={[
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Is Active For Api User ?</Label>
                <Dropdown
                  placeholder='Please select if operator is active for api user'
                  value={isActiveForApiUser}
                  onChange={setIsActiveForApiUser}
                  options={[
                    {
                      title: 'Active',
                      value: 'Active',
                    },
                    {
                      title: 'Inactive',
                      value: 'Inactive',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Operator Name</Label>
                <TextInput
                  value={operatorName}
                  onChange={setOperatorName}
                  placeholder='Please enter operator name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Plan API Operator Code</Label>
                <TextInput
                  value={planApiCode}
                  onChange={setPlanApiCode}
                  placeholder='Please enter plan api operator code'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Backup Plan API Operator Code</Label>
                <TextInput
                  value={backupPlanApiCode}
                  onChange={setBackupPlanApiCode}
                  placeholder='Please enter backup plan api operator code'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Operator Logo</Label>
            <FileInput
              files={operatorLogo}
              onChange={setOperatorLogo}
              accept='image/*'
              disabled={isLoading === true}
            />
            {originalFormData?.operatorLogo && (
              <StyledViewIconButton onClick={() => setShowExistingImage(true)}>
                Click here to view existing file
              </StyledViewIconButton>
            )}
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>Min Amount</Label>
                <NumberInput
                  value={minAmount}
                  onChange={setMinAmount}
                  placeholder="Please enter min amount"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Max Amount</Label>
                <NumberInput
                  value={maxAmount}
                  onChange={setMaxAmount}
                  placeholder="Please enter max amount"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Field 1</Label>
                <TextInput
                  value={field1}
                  onChange={setField1}
                  placeholder='Please enter field 1'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Field 2</Label>
                <TextInput
                  value={field2}
                  onChange={setField2}
                  placeholder='Please enter field 2'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Field 3</Label>
                <TextInput
                  value={field3}
                  onChange={setField3}
                  placeholder='Please enter field 3'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Field 4</Label>
                <TextInput
                  value={field4}
                  onChange={setField4}
                  placeholder='Please enter field 4'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image
            width={150}
            source={originalFormData?.operatorLogo}
            alt={operatorName}
          />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(OperatorsUpdate);
