import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
} from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { camelCaseToString, getReturnUrl } from '../../../utils';

const StyledModuleContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-bottom: 0px;
  margin: 0px;
  padding: 0px;
  &:last-child {
    border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: 1200px) {
    padding: 10px;
    display: block;
    width: calc(100% - 22px);
    label {
      margin: 10px 0px;
    }
  }
`;

const StyledModuleNameContainer = styled.section`
  width: auto;
  min-width: 25%;
  padding: 0px 10px;
  @media (max-width: 1200px) {
    padding: 0px;
    width: 100%;
  }
`;

const StyledModuleCommissionContainer = styled.section`
  width: 100%;
  padding: 10px;
  height: auto;
  display: flex;
  flex-direction: row;
  border-left: 1px solid #e5e5e5;
  @media (max-width: 1200px) {
    padding: 10px 0px;
    width: 100%;
    border: 0px;
    display: block;
  }
`;

const StyledRoleName = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin: 0px 0px 20px;
  padding: 0px;
`;

const RolesAPIMappingUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [operators, setOperators] = useState([]);
  const [apiProviders, setApiProviders] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [template, setTemplate] = useState(null);
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { template } = originalFormData;
    setTemplate(template);
  };

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/operators')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      });
  }, []);

  const readApiProviders = useCallback(() => {
    API.get('/recharge-configurations/api-providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setApiProviders(data);
      }
    });
  }, []);

  const generateTemplate = useCallback(
    (templateData) => {
      const processedTemplateData =
        templateData?.length !== 0 ? JSON.parse(templateData) : templateData;
      const newTemplate = operators?.map(
        ({ id, serviceName, operatorName, operatorLogo }) => {
          const item =
            processedTemplateData?.length > 0 &&
            processedTemplateData?.find((item) => item?.operatorsId === id);

          const primaryApiProvidersId = item?.primaryApiProvidersId ?? '';
          const secondaryApiProvidersId = item?.secondaryApiProvidersId ?? '';
          const tertiaryApiProvidersId = item?.tertiaryApiProvidersId ?? '';
          return {
            operatorsId: id,
            serviceName,
            operatorName,
            operatorLogo,
            primaryApiProvidersId,
            secondaryApiProvidersId,
            tertiaryApiProvidersId,
          };
        }
      );
      setTemplate(newTemplate);
      setOriginalFormData({
        template: newTemplate,
      });
    },
    [operators]
  );

  const readData = useCallback(() => {
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/roles-api-mapping/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { roleName, template } = data[0];
          setRoleName(roleName);
          generateTemplate(template);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      });
  }, [id, generateTemplate]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      template: JSON.stringify(template),
    };
    API.put(`/recharge-configurations/roles-api-mapping/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangePrimaryApiProvider = (operatorsId, providerId) => {
    const newTemplate = [...template];
    const index = newTemplate.findIndex(
      (item) => item.operatorsId === operatorsId
    );
    if (index !== -1) {
      const currentValue = newTemplate[index];
      newTemplate.splice(index, 1, {
        ...currentValue,
        primaryApiProvidersId: providerId,
      });
    }
    setTemplate(newTemplate);
  };

  const onChangeSecondaryApiProvider = (operatorsId, providerId) => {
    const newTemplate = [...template];
    const index = newTemplate.findIndex(
      (item) => item.operatorsId === operatorsId
    );
    if (index !== -1) {
      const currentValue = newTemplate[index];
      newTemplate.splice(index, 1, {
        ...currentValue,
        secondaryApiProvidersId: providerId,
      });
    }
    setTemplate(newTemplate);
  };

  const onChangeTertiaryApiProvider = (operatorsId, providerId) => {
    const newTemplate = [...template];
    const index = newTemplate.findIndex(
      (item) => item.operatorsId === operatorsId
    );
    if (index !== -1) {
      const currentValue = newTemplate[index];
      newTemplate.splice(index, 1, {
        ...currentValue,
        tertiaryApiProvidersId: providerId,
      });
    }
    setTemplate(newTemplate);
  };

  useEffect(() => {
    readApiProviders();
    readOperators();
  }, [readOperators, readApiProviders]);

  useEffect(() => {
    readData();
  }, [operators, readData]);

  useEffect(() => {
    if (template?.length > 0) {
      setIsLoading(false);
    }
  }, [template]);

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: null },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        {roleName && (
          <StyledRoleName>
            Role Name : {camelCaseToString(roleName)}
          </StyledRoleName>
        )}
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            {template &&
              template.map(
                (
                  {
                    operatorsId,
                    serviceName,
                    operatorLogo,
                    operatorName,
                    primaryApiProvidersId,
                    secondaryApiProvidersId,
                    tertiaryApiProvidersId,
                  },
                  index
                ) => (
                  <StyledModuleContainer key={index}>
                    <StyledModuleNameContainer>
                      <StyledIconTitleContainer>
                        <StyledIconContainer>
                          <Image
                            source={
                              operatorLogo ? operatorLogo : '/no-image.png'
                            }
                            alt={operatorName}
                            style={{ width: '50px', height: '50px' }}
                          />
                        </StyledIconContainer>
                        <StyledTitleContainer>
                          {operatorName}
                          <StyledSpan>{serviceName}</StyledSpan>
                        </StyledTitleContainer>
                      </StyledIconTitleContainer>
                    </StyledModuleNameContainer>
                    <StyledModuleCommissionContainer>
                      <Fieldset style={{ margin: '0px 10px 0px 0px' }}>
                        <Label>Primary API Provider</Label>
                        <Dropdown
                          placeholder='Please select primary api provider'
                          value={primaryApiProvidersId}
                          onChange={(value) =>
                            onChangePrimaryApiProvider(operatorsId, value)
                          }
                          options={apiProvidersOptions}
                          disabled={isLoading}
                        />
                      </Fieldset>
                      <Fieldset style={{ margin: '0px 10px 0px 0px' }}>
                        <Label>Secondary API Provider</Label>
                        <Dropdown
                          placeholder='Please select secondary api provider'
                          value={secondaryApiProvidersId}
                          onChange={(value) =>
                            onChangeSecondaryApiProvider(operatorsId, value)
                          }
                          options={apiProvidersOptions}
                          disabled={isLoading}
                        />
                      </Fieldset>
                      <Fieldset style={{ margin: '0px' }}>
                        <Label>Tertiary API Provider</Label>
                        <Dropdown
                          placeholder='Please select tertiary api provider'
                          value={tertiaryApiProvidersId}
                          onChange={(value) =>
                            onChangeTertiaryApiProvider(operatorsId, value)
                          }
                          options={apiProvidersOptions}
                          disabled={isLoading}
                        />
                      </Fieldset>
                    </StyledModuleCommissionContainer>
                  </StyledModuleContainer>
                )
              )}
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(RolesAPIMappingUpdate);
