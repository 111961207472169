import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import { StyledSpan } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl, getAmountInWords } from '../../../utils';

const APIProviderPaymentsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [apiProviders, setApiProviders] = useState([]);
  const [apiProvidersId, setApiProvidersId] = useState('');
  const [utrNumber, setUtrNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionDate, setTransactionDate] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { apiProviders, utrNumber, amount, transactionDate } =
      originalFormData;
    setApiProvidersId(apiProviders);
    setUtrNumber(utrNumber);
    setAmount(amount);
    setTransactionDate(transactionDate);
  };

  const readApiProviders = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/api-providers`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setApiProviders(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounting/api-provider-payments/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { apiProvidersId, utrNumber, amount, transactionDate } =
            data[0];
          setApiProvidersId(apiProvidersId);
          setUtrNumber(utrNumber);
          setAmount(amount);
          setTransactionDate(transactionDate);
          setOriginalFormData({
            apiProvidersId,
            utrNumber,
            amount,
            transactionDate,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      apiProvidersId,
      utrNumber,
      amount,
      transactionDate,
    };
    API.put(`/accounting/api-provider-payments/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readApiProviders();
    readData();
  }, [readApiProviders, readData]);

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: '' },
  ];
  apiProviders?.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>API Provider</Label>
            <Dropdown
              placeholder='Please select api provider'
              value={apiProvidersId}
              onChange={setApiProvidersId}
              options={apiProvidersOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>UTR Number</Label>
                <TextInput
                  value={utrNumber}
                  onChange={setUtrNumber}
                  placeholder='Please enter UTR number'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Amount</Label>
                <NumberInput
                  value={amount}
                  onChange={setAmount}
                  placeholder='Please enter amount'
                  maxLength={10}
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
                {amount && (
                  <StyledSpan style={{ color: '#000' }}>
                    {getAmountInWords(amount)}
                  </StyledSpan>
                )}
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Transaction Date</Label>
                <TextInput
                  type='date'
                  value={transactionDate}
                  onChange={setTransactionDate}
                  placeholder='Please select transaction date'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(APIProviderPaymentsUpdate);
