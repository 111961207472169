import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  Legend,
} from '../../../components/FormElements';
import { StyledMicroButton } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';

const MessagingConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  // SMS
  const [smsApiEndpoint, setSmsApiEndpoint] = useState('');
  const [smsApiRequestMethod, setSmsApiRequestMethod] = useState('');
  const [smsApiRequestBody, setSmsApiRequestBody] = useState('');
  const [smsApiResponseType, setSmsApiResponseType] = useState('');
  const [smsApiStatusKey, setSmsApiStatusKey] = useState('');
  const [smsApiSuccessKey, setSmsApiSuccessKey] = useState('');
  const [smsApiFailureKey, setSmsApiFailureKey] = useState('');
  const [smsRegards, setSmsRegards] = useState('');
  // Whatsapp
  const [whatsappApiEndpoint, setWhatsappApiEndpoint] = useState('');
  const [whatsappApiRequestMethod, setWhatsappApiRequestMethod] = useState('');
  const [whatsappApiRequestBody, setWhatsappApiRequestBody] = useState('');
  const [whatsappApiResponseType, setWhatsappApiResponseType] = useState('');
  const [whatsappApiStatusKey, setWhatsappApiStatusKey] = useState('');
  const [whatsappApiSuccessKey, setWhatsappApiSuccessKey] = useState('');
  const [whatsappApiFailureKey, setWhatsappApiFailureKey] = useState('');
  const [whatsAppRegards, setWhatsAppRegards] = useState('');
  // Telegram
  const [telegramApiEndpoint, setTelegramApiEndpoint] = useState('');
  const [telegramApiRequestMethod, setTelegramApiRequestMethod] = useState('');
  const [telegramApiRequestBody, setTelegramApiRequestBody] = useState('');
  const [telegramApiResponseType, setTelegramApiResponseType] = useState('');
  const [telegramApiStatusKey, setTelegramApiStatusKey] = useState('');
  const [telegramApiSuccessKey, setTelegramApiSuccessKey] = useState('');
  const [telegramApiFailureKey, setTelegramApiFailureKey] = useState('');
  const [telegramRegards, setTelegramRegards] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      // SMS
      smsApiEndpoint,
      smsApiRequestMethod,
      smsApiRequestBody,
      smsApiResponseType,
      smsApiStatusKey,
      smsApiSuccessKey,
      smsApiFailureKey,
      smsRegards,
      // Whatsapp
      whatsappApiEndpoint,
      whatsappApiRequestMethod,
      whatsappApiRequestBody,
      whatsappApiResponseType,
      whatsappApiStatusKey,
      whatsappApiSuccessKey,
      whatsappApiFailureKey,
      whatsAppRegards,
      // Telegram
      telegramApiEndpoint,
      telegramApiRequestMethod,
      telegramApiRequestBody,
      telegramApiResponseType,
      telegramApiStatusKey,
      telegramApiSuccessKey,
      telegramApiFailureKey,
      telegramRegards,
    } = originalFormData;
    // SMS
    setSmsApiEndpoint(smsApiEndpoint);
    setSmsApiRequestMethod(smsApiRequestMethod);
    setSmsApiRequestBody(smsApiRequestBody);
    setSmsApiResponseType(smsApiResponseType);
    setSmsApiStatusKey(smsApiStatusKey);
    setSmsApiSuccessKey(smsApiSuccessKey);
    setSmsApiFailureKey(smsApiFailureKey);
    setSmsRegards(smsRegards);
    // Whatsapp
    setWhatsappApiEndpoint(whatsappApiEndpoint);
    setWhatsappApiRequestMethod(whatsappApiRequestMethod);
    setWhatsappApiRequestBody(whatsappApiRequestBody);
    setWhatsappApiResponseType(whatsappApiResponseType);
    setWhatsappApiStatusKey(whatsappApiStatusKey);
    setWhatsappApiSuccessKey(whatsappApiSuccessKey);
    setWhatsappApiFailureKey(whatsappApiFailureKey);
    setWhatsAppRegards(whatsAppRegards);
    // Telegram
    setTelegramApiEndpoint(telegramApiEndpoint);
    setTelegramApiRequestMethod(telegramApiRequestMethod);
    setTelegramApiRequestBody(telegramApiRequestBody);
    setTelegramApiResponseType(telegramApiResponseType);
    setTelegramApiStatusKey(telegramApiStatusKey);
    setTelegramApiSuccessKey(telegramApiSuccessKey);
    setTelegramApiFailureKey(telegramApiFailureKey);
    setTelegramRegards(telegramRegards);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/messaging-configuration`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            // SMS
            smsApiEndpoint,
            smsApiRequestMethod,
            smsApiRequestBody,
            smsApiResponseType,
            smsApiStatusKey,
            smsApiSuccessKey,
            smsApiFailureKey,
            smsRegards,
            // Whatsapp
            whatsappApiEndpoint,
            whatsappApiRequestMethod,
            whatsappApiRequestBody,
            whatsappApiResponseType,
            whatsappApiStatusKey,
            whatsappApiSuccessKey,
            whatsappApiFailureKey,
            whatsAppRegards,
            // Telegram
            telegramApiEndpoint,
            telegramApiRequestMethod,
            telegramApiRequestBody,
            telegramApiResponseType,
            telegramApiStatusKey,
            telegramApiSuccessKey,
            telegramApiFailureKey,
            telegramRegards,
          } = data;
          // SMS
          setSmsApiEndpoint(smsApiEndpoint);
          setSmsApiRequestMethod(smsApiRequestMethod);
          setSmsApiRequestBody(smsApiRequestBody);
          setSmsApiResponseType(smsApiResponseType);
          setSmsApiStatusKey(smsApiStatusKey);
          setSmsApiSuccessKey(smsApiSuccessKey);
          setSmsApiFailureKey(smsApiFailureKey);
          setSmsRegards(smsRegards);
          // Whatsapp
          setWhatsappApiEndpoint(whatsappApiEndpoint);
          setWhatsappApiRequestMethod(whatsappApiRequestMethod);
          setWhatsappApiRequestBody(whatsappApiRequestBody);
          setWhatsappApiResponseType(whatsappApiResponseType);
          setWhatsappApiStatusKey(whatsappApiStatusKey);
          setWhatsappApiSuccessKey(whatsappApiSuccessKey);
          setWhatsappApiFailureKey(whatsappApiFailureKey);
          setWhatsAppRegards(whatsAppRegards);
          // Telegram
          setTelegramApiEndpoint(telegramApiEndpoint);
          setTelegramApiRequestMethod(telegramApiRequestMethod);
          setTelegramApiRequestBody(telegramApiRequestBody);
          setTelegramApiResponseType(telegramApiResponseType);
          setTelegramApiStatusKey(telegramApiStatusKey);
          setTelegramApiSuccessKey(telegramApiSuccessKey);
          setTelegramApiFailureKey(telegramApiFailureKey);
          setTelegramRegards(telegramRegards);
          setOriginalFormData({
            // SMS
            smsApiEndpoint,
            smsApiRequestMethod,
            smsApiRequestBody,
            smsApiResponseType,
            smsApiStatusKey,
            smsApiSuccessKey,
            smsApiFailureKey,
            smsRegards,
            // Whatsapp
            whatsappApiEndpoint,
            whatsappApiRequestMethod,
            whatsappApiRequestBody,
            whatsappApiResponseType,
            whatsappApiStatusKey,
            whatsappApiSuccessKey,
            whatsappApiFailureKey,
            whatsAppRegards,
            // Telegram
            telegramApiEndpoint,
            telegramApiRequestMethod,
            telegramApiRequestBody,
            telegramApiResponseType,
            telegramApiStatusKey,
            telegramApiSuccessKey,
            telegramApiFailureKey,
            telegramRegards,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      // SMS
      smsApiEndpoint,
      smsApiRequestMethod,
      smsApiRequestBody,
      smsApiResponseType,
      smsApiStatusKey,
      smsApiSuccessKey,
      smsApiFailureKey,
      smsRegards,
      // Whatsapp
      whatsappApiEndpoint,
      whatsappApiRequestMethod,
      whatsappApiRequestBody,
      whatsappApiResponseType,
      whatsappApiStatusKey,
      whatsappApiSuccessKey,
      whatsappApiFailureKey,
      whatsAppRegards,
      // Telegram
      telegramApiEndpoint,
      telegramApiRequestMethod,
      telegramApiRequestBody,
      telegramApiResponseType,
      telegramApiStatusKey,
      telegramApiSuccessKey,
      telegramApiFailureKey,
      telegramRegards,
    };
    API.put(`/configurations/messaging-configuration`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          {/* SMS */}
          <Fieldset forLegend>
            <Legend>SMS API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={smsApiEndpoint}
                onChange={setSmsApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={smsApiRequestBody}
                onChange={setSmsApiRequestBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setSmsApiRequestBody, '{{{NUMBER}}}')}
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setSmsApiRequestBody, '{{{MESSAGE}}}')}
              >
                Message
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={smsApiRequestMethod}
                    onChange={setSmsApiRequestMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={smsApiResponseType}
                    onChange={setSmsApiResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={smsApiStatusKey}
                    onChange={setSmsApiStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Key</Label>
                  <TextInput
                    value={smsApiSuccessKey}
                    onChange={setSmsApiSuccessKey}
                    placeholder='Please enter success key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failure Key</Label>
                  <TextInput
                    value={smsApiFailureKey}
                    onChange={setSmsApiFailureKey}
                    placeholder='Please enter failure key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset>
              <Label>Company Name ( Registered Firm Name )</Label>
              <TextInput
                value={smsRegards}
                onChange={setSmsRegards}
                placeholder='Please enter company name'
                disabled={isLoading === true}
              />
            </Fieldset>
          </Fieldset>
          {/* Whatsapp */}
          <Fieldset forLegend>
            <Legend>Whats APP API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={whatsappApiEndpoint}
                onChange={setWhatsappApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={whatsappApiRequestBody}
                onChange={setWhatsappApiRequestBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setWhatsappApiRequestBody, '{{{NUMBER}}}')
                }
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setWhatsappApiRequestBody, '{{{MESSAGE}}}')
                }
              >
                Message
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={whatsappApiRequestMethod}
                    onChange={setWhatsappApiRequestMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={whatsappApiResponseType}
                    onChange={setWhatsappApiResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={whatsappApiStatusKey}
                    onChange={setWhatsappApiStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Key</Label>
                  <TextInput
                    value={whatsappApiSuccessKey}
                    onChange={setWhatsappApiSuccessKey}
                    placeholder='Please enter success key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failure Key</Label>
                  <TextInput
                    value={whatsappApiFailureKey}
                    onChange={setWhatsappApiFailureKey}
                    placeholder='Please enter failure key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset>
              <Label>Company Name ( Registered Firm Name )</Label>
              <TextInput
                value={whatsAppRegards}
                onChange={setWhatsAppRegards}
                placeholder='Please enter company name'
                disabled={isLoading === true}
              />
            </Fieldset>
          </Fieldset>
          {/* Telegram */}
          <Fieldset forLegend>
            <Legend>Telegram API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={telegramApiEndpoint}
                onChange={setTelegramApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={telegramApiRequestBody}
                onChange={setTelegramApiRequestBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setTelegramApiRequestBody, '{{{NUMBER}}}')
                }
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setTelegramApiRequestBody, '{{{MESSAGE}}}')
                }
              >
                Message
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={telegramApiRequestMethod}
                    onChange={setTelegramApiRequestMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={telegramApiResponseType}
                    onChange={setTelegramApiResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={telegramApiStatusKey}
                    onChange={setTelegramApiStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Key</Label>
                  <TextInput
                    value={telegramApiSuccessKey}
                    onChange={setTelegramApiSuccessKey}
                    placeholder='Please enter success key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failure Key</Label>
                  <TextInput
                    value={telegramApiFailureKey}
                    onChange={setTelegramApiFailureKey}
                    placeholder='Please enter failure key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset>
              <Label>Company Name ( Registered Firm Name )</Label>
              <TextInput
                value={telegramRegards}
                onChange={setTelegramRegards}
                placeholder='Please enter company name'
                disabled={isLoading === true}
              />
            </Fieldset>
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(MessagingConfiguration);
