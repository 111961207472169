import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import Modal from '../../../components/Modal';
import { Image } from '../../../components/Image';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  FileInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import {
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
  ACCOUNT_TYPES,
} from '../../../constants';
import { authData, getBase64, getReturnUrl } from '../../../utils';

const StyledViewIconButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const DenominationBlockerUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [type, setType] = useState('');
  const [payeeName, setPayeeName] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankLogo, setBankLogo] = useState([]);
  const [bankLogoBase64, setBankLogoBase64] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [isDefaultUpi, setIsDefaultUpi] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();
  const { accountType } = authData.get();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      type,
      payeeName,
      bankName,
      bankLogo,
      accountNumber,
      ifscCode,
      isDefaultUpi,
    } = originalFormData;
    setType(type);
    setPayeeName(payeeName);
    setBankName(bankName);
    setBankLogo([]);
    setBankLogoBase64(bankLogo);
    setAccountNumber(accountNumber);
    setIfscCode(ifscCode);
    setIsDefaultUpi(isDefaultUpi);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/bank-accounts/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            type,
            payeeName,
            bankName,
            bankLogo,
            accountNumber,
            ifscCode,
            isDefaultUpi,
          } = data[0];
          setType(type);
          setPayeeName(payeeName);
          setBankName(bankName);
          setBankLogoBase64(bankLogo);
          setAccountNumber(accountNumber);
          setIfscCode(ifscCode);
          setIsDefaultUpi(isDefaultUpi);
          setOriginalFormData({
            type,
            payeeName,
            bankName,
            bankLogo,
            accountNumber,
            ifscCode,
            isDefaultUpi,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      type,
      payeeName,
      bankName,
      bankLogo: bankLogoBase64,
      accountNumber,
      ifscCode,
      isDefaultUpi,
    };
    API.put(`/configurations/bank-accounts/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  useEffect(() => {
    if (bankLogo?.length > 0) {
      getBase64(bankLogo[0], setBankLogoBase64);
    }
  }, [bankLogo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Account Type</Label>
            <Dropdown
              placeholder='Please select account type'
              value={type}
              onChange={setType}
              options={[
                {
                  title: '-- SELECT ACCOUNT TYPE --',
                  value: '',
                },
                {
                  title: 'Wiretransfer',
                  value: 'Wiretransfer',
                },
                {
                  title: 'UPI',
                  value: 'UPI',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Payee Name</Label>
                <TextInput
                  value={payeeName}
                  onChange={setPayeeName}
                  placeholder='Please enter payee name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Bank Name</Label>
                <TextInput
                  value={bankName}
                  onChange={setBankName}
                  placeholder='Please enter bank name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Account Number / UPI</Label>
                <TextInput
                  value={accountNumber}
                  onChange={setAccountNumber}
                  placeholder='Please enter account number or upi id'
                  maxLength={200}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required={type === 'UPI' ? false : true}>
                  IFSC Code
                </Label>
                <TextInput
                  value={ifscCode}
                  onChange={setIfscCode}
                  placeholder='Please enter ifsc code'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Bank Logo</Label>
            <FileInput
              files={bankLogo}
              onChange={setBankLogo}
              accept='image/*'
              disabled={isLoading === true}
            />
            {originalFormData?.bankLogo && (
              <StyledViewIconButton onClick={() => setShowExistingImage(true)}>
                Click here to view existing file
              </StyledViewIconButton>
            )}
          </Fieldset>
          {type === 'UPI' &&
            ![
              ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
              ACCOUNT_TYPES.DISTRIBUTORS,
            ].includes(accountType) && (
              <Fieldset>
                <Label required>Is Default UPI ?</Label>
                <Dropdown
                  placeholder='Please select if the upi id is default'
                  value={isDefaultUpi}
                  onChange={setIsDefaultUpi}
                  options={[
                    {
                      title: '-- SELECT IF UPI ID IS DEFAULT --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            )}
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image
            width={150}
            source={originalFormData?.bankLogo}
            alt={bankName}
          />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(DenominationBlockerUpdate);
