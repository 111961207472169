import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  NumberInput,
} from "../../../components/FormElements";
import { StyledMicroButton } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";

const ReferralConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [promotionType, setPromotionType] = useState("");
  const [bonusType, setBonusType] = useState("");
  const [bonusValue, setBonusValue] = useState("");
  const [supervisorBonus, setSupervisorBonus] = useState("");
  const [referrerBonus, setReferrerBonus] = useState("");
  const [refereeBonus, setRefereeBonus] = useState("");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [numberofDaysfromReg, setNumberofDaysfromReg] = useState("");
  const [message, setMessage] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      promotionType,
      bonusType,
      bonusValue,
      supervisorBonus,
      referrerBonus,
      refereeBonus,
      fromAmount,
      toAmount,
      numberofDaysfromReg,
      message,
    } = originalFormData;
    setPromotionType(promotionType);
    setBonusType(bonusType);
    setBonusValue(bonusValue);

    setSupervisorBonus(supervisorBonus);
    setReferrerBonus(referrerBonus);
    setRefereeBonus(refereeBonus);

    setFromAmount(fromAmount);
    setToAmount(toAmount);
    setNumberofDaysfromReg(numberofDaysfromReg);
    setMessage(message);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/configurations/referral-configuration`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            promotionType,
            bonusType,
            bonusValue,
            supervisorBonus,
            referrerBonus,
            refereeBonus,
            fromAmount,
            toAmount,
            numberofDaysfromReg,
            message,
          } = data;
          setPromotionType(promotionType);
          setBonusType(bonusType);
          setBonusValue(bonusValue);
          setSupervisorBonus(supervisorBonus);
          setReferrerBonus(referrerBonus);
          setRefereeBonus(refereeBonus);
          setFromAmount(fromAmount);
          setToAmount(toAmount);
          setNumberofDaysfromReg(numberofDaysfromReg);
          setMessage(message);
          setOriginalFormData({
            promotionType,
            bonusType,
            bonusValue,
            supervisorBonus,
            referrerBonus,
            refereeBonus,
            fromAmount,
            toAmount,
            numberofDaysfromReg,
            message,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      promotionType,
      bonusType,
      bonusValue,
      supervisorBonus,
      referrerBonus,
      refereeBonus,
      fromAmount,
      toAmount,
      numberofDaysfromReg,
      message,
    };
    API.put(`/configurations/referral-configuration`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Promotion Type</Label>
                <Dropdown
                  value={promotionType}
                  onChange={(value) => {
                    setPromotionType(value);
                    if (value === "Registration") {
                      setBonusType("Flat");
                      setFromAmount("");
                      setToAmount("");
                      setNumberofDaysfromReg("");
                    }
                  }}
                  placeholder="Please select promotion type"
                  disabled={isLoading === true}
                  options={[
                    {
                      title: "-- SELECT PROMOTION TYPE --",
                      value: "",
                    },
                    {
                      title: "Registration",
                      value: "Registration",
                    },
                    {
                      title: "Wallet Load",
                      value: "Wallet Load",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Bonus Type</Label>
                <Dropdown
                  value={bonusType}
                  onChange={setBonusType}
                  placeholder="Please select bonus type"
                  disabled={
                    promotionType === "Registration" || isLoading === true
                  }
                  options={[
                    {
                      title: "-- SELECT BONUS TYPE --",
                      value: "",
                    },
                    {
                      title: "Percentage",
                      value: "Percentage",
                    },
                    {
                      title: "Flat",
                      value: "Flat",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Bonus Value</Label>
                <NumberInput
                  value={bonusValue}
                  onChange={setBonusValue}
                  placeholder="Please enter bonus value"
                  disabled={isLoading === true}
                  maxLength={100}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Supervisor Bonus</Label>
                <Dropdown
                  value={supervisorBonus}
                  onChange={setSupervisorBonus}
                  placeholder="Please select supervisor bonus"
                  disabled={isLoading === true}
                  options={[
                    {
                      title: "-- SELECT SUPERVISOR BONUS --",
                      value: "",
                    },
                    {
                      title: "Yes",
                      value: "Yes",
                    },
                    {
                      title: "No",
                      value: "No",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Referrer Bonus</Label>
                <Dropdown
                  value={referrerBonus}
                  onChange={setReferrerBonus}
                  placeholder="Please select referrer bonus"
                  disabled={isLoading === true}
                  options={[
                    {
                      title: "-- SELECT REFERRER BONUS --",
                      value: "",
                    },
                    {
                      title: "Yes",
                      value: "Yes",
                    },
                    {
                      title: "No",
                      value: "No",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Referee Bonus</Label>
                <Dropdown
                  value={refereeBonus}
                  onChange={setRefereeBonus}
                  placeholder="Please select referee bonus"
                  disabled={isLoading === true}
                  options={[
                    {
                      title: "-- SELECT REFEREE BONUS --",
                      value: "",
                    },
                    {
                      title: "Yes",
                      value: "Yes",
                    },
                    {
                      title: "No",
                      value: "No",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {promotionType === "Wallet Load" && (
            <Grid
              columns={
                "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
              }
            >
              <GridItem>
                <Fieldset>
                  <Label required>From Amount</Label>
                  <NumberInput
                    value={fromAmount}
                    onChange={setFromAmount}
                    placeholder="Please enter from amount"
                    disabled={isLoading === true}
                    maxLength={100}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>To Amount</Label>
                  <NumberInput
                    value={toAmount}
                    onChange={setToAmount}
                    placeholder="Please enter to amount"
                    disabled={isLoading === true}
                    maxLength={100}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Num of Days from Registration</Label>
                  <NumberInput
                    value={numberofDaysfromReg}
                    onChange={setNumberofDaysfromReg}
                    placeholder="Please enter number of days from registration"
                    disabled={isLoading === true}
                    maxLength={100}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          )}
          <Fieldset>
            <Label required>Referral Message</Label>
            <TextInput
              value={message}
              onChange={setMessage}
              placeholder="Please enter referral message"
              disabled={isLoading === true}
            />
            <StyledMicroButton
              disabled={isLoading === true}
              type="button"
              onClick={() => addText(setMessage, "{{{REFERRAL_CODE}}}")}
            >
              My Referral Code
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type="button"
              onClick={() => addText(setMessage, "{{{PROMOTION_TYPE}}}")}
            >
              Promotion Type
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type="button"
              onClick={() => addText(setMessage, "{{{BONUS_TYPE}}}")}
            >
              Bonus Type
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type="button"
              onClick={() => addText(setMessage, "{{{BONUS_VALUE}}}")}
            >
              Bonus Value
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type="button"
              onClick={() => addText(setMessage, "{{{FROM_AMOUNT}}}")}
            >
              From Amount
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type="button"
              onClick={() => addText(setMessage, "{{{TO_AMOUNT}}}")}
            >
              To Amount
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type="button"
              onClick={() =>
                addText(setMessage, "{{{DAYS_FROM_REGISTRATION}}}")
              }
            >
              Days From Registration
            </StyledMicroButton>
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(ReferralConfiguration);
