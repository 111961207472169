import React, { useState, useEffect } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import { TableBuilder } from '../../../components/TableElements';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  CreateButton,
  MessageBox,
  Dropdown,
  CustomButton,
} from '../../../components/FormElements';
import Modal from '../../../components/Modal';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { StyledModalContainer } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { camelCaseToString } from '../../../utils';

const RolesDefaultMarginTemplatesTable = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [marginTemplates, setMarginTemplates] = useState([]);
  const [rolesDefaultMarginTemplates, setRolesDefaultMarginTemplates] =
    useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState('');
  const [marginTemplatesId, setMarginTemplatesId] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const readMarginTemplates = () => {
    API.get('/margins/margin-templates').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setMarginTemplates(data);
      }
    });
  };

  const readData = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/margins/roles-default-margin-templates`)
      .then((response) => {
        const { data } = response.data;
        setRolesDefaultMarginTemplates(data);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      templatesId: marginTemplatesId,
    };
    API.put(`/margins/roles-default-margin-templates/${modalId}`, formData)
      .then((response) => {
        const { status, message, data } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setRolesDefaultMarginTemplates(data);
          setTimeout(() => {
            resetResponseData();
            setShowModal(false);
            setModalId('');
            setMarginTemplatesId('');
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData();
  };

  useEffect(() => {
    readData();
    readMarginTemplates();
  }, []);

  const { accountsPermissions } = props;
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;

  const marginTemplatesOptions = [
    { title: '-- SELECT MARGIN TEMPLATE --', value: '' },
  ];
  marginTemplates.forEach(({ id, templateName }) =>
    marginTemplatesOptions.push({
      title: templateName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <LoadingSpinner
        isLoading={
          !showModal &&
          responseStatus === '' &&
          rolesDefaultMarginTemplates?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          !showModal &&
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          rolesDefaultMarginTemplates?.length === 0
        }
        message={`No roles default margin templates found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {rolesDefaultMarginTemplates?.length > 0 && (
        <>
          <TableBuilder
            isLoading={
              !showModal &&
              rolesDefaultMarginTemplates?.length !== 0 &&
              isLoading
            }
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value, item) => (
                  <>
                    {canUpdate && (
                      <CustomButton
                        icon={faEdit}
                        onClick={() => {
                          setModalId(value);
                          setShowModal(true);
                          setMarginTemplatesId(
                            item.templatesId ? item.templatesId : ''
                          );
                        }}
                        style={{ color: '#0095ff' }}
                      />
                    )}
                  </>
                ),
              },
              {
                title: 'Role Name',
                dataSelector: 'roleName',
                dataType: 'string',
                CellRenderer: (value) => camelCaseToString(value),
              },
              {
                title: 'Template Name',
                dataSelector: 'templateName',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
            ]}
            tableData={rolesDefaultMarginTemplates}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              setShowModal(false);
              setModalId('');
              setMarginTemplatesId('');
            }}
            hideCloseButton
          >
            <StyledModalContainer width={'500px'}>
              <h3>Update Roles Default Margin Template</h3>
              <FormContainer>
                <Form method='POST' action='#' onSubmit={onSubmit}>
                  <Fieldset>
                    <Label>Margin Template</Label>
                    <Dropdown
                      placeholder='Please select margin template'
                      value={marginTemplatesId}
                      onChange={setMarginTemplatesId}
                      options={marginTemplatesOptions}
                      disabled={isLoading}
                    />
                  </Fieldset>
                  {showModal && responseStatus !== '' && (
                    <Fieldset>
                      <MessageBox
                        status={responseStatus}
                        message={responseMessage}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <CreateButton
                      disabled={isLoading}
                      type='submit'
                      style={{ width: 'auto' }}
                    >
                      Submit
                    </CreateButton>
                  </Fieldset>
                  <OverlayLoader showLoader={showModal && isLoading} />
                </Form>
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(RolesDefaultMarginTemplatesTable);
