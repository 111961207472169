import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: verdana;
    margin: 0px;
    padding: 0px;
    background-color: ${({
      theme: {
        colors: { backgroundColor },
      },
    }) => (backgroundColor ? backgroundColor : `#f1f1f1`)};
  }

  /*** SCROLLBAR ***/
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #545454;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 10px;
    border: 4px solid #545454;
  }

  /*** INVOICE ***/

    #invoice-container {
        border: 1px solid #000;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
    }

    .invoice-header {
        width: calc(100% - 2px);
        display: grid;
        border: 1px solid #000;
        grid-template-columns: auto 30%;
    }

    .from-details {
        display: grid;
        grid-template-columns: max-content auto;
        border-right: 1px solid #000;
        padding: 10px;
    }

    .from-details .logo{
        width: auto;
        height: auto;
        max-width: 150px;
        max-height: 150px;
    }

    .from-details .logo img{
        width: 100%;
        height: 100%;
    }

    .from-details .details{
        flex: 1;
        padding: 10px;
    }

    .from-details .details h1{
        font-size: 16px;
        font-weight: bold;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 25px;
        text-transform: capitalize;
    }

    .from-details .details address,
    .from-details .details p
    {
        font-size: 12px;
        font-weight: normal;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
        text-transform: capitalize;
        font-style: normal;
    }

    #invoice-container .grid-two{
        margin: 0px;
    }

    .invoice-no-date {
        flex: 1;
        display: flex;
        flex-direction: 'row';
        text-transform: capitalize;
        flex-direction: column;
    }

    .invoice-no-date h2{
        font-size: 14px;
        font-weight: bold;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
    }

    .invoice-no-date p{
        font-size: 12px;
        font-weight: normal;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
    }
    
    .invoice-no-date .invoice-id {
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #000;
    }

    .invoice-no-date .invoice-date {
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    
    .to-section{
        padding: 10px;
        width: calc(100% - 22px);
        border: 1px solid #000;
        border-top-width: 0px;
    }

    .to-section h2{
        font-size: 14px;
        font-weight: bold;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
    }
    
    .to-section address,
    .to-section p
    {
        font-size: 12px;
        font-weight: normal;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
        text-transform: capitalize;
        font-style: normal;
    }

    .invoice-body{
        width: calc(100% - 2px);
        border: 1px solid #000;
        border-top-width: 0px;
    }

    .invoice-body table{
        width:100%;
        margin:0px;
        padding:0px;
        border: 0px;
        border-spacing: 0;
        border-collapse: collapse;
    }

    .invoice-body table thead{
        background-color:#ccc;
        color: #000;
    }

    .invoice-body table thead tr{
        margin: 0px;
        padding:0px;
        background-color: transparent;
    }

    .invoice-body table thead tr th{
        font-size: 12px;
        font-weight: bold;
        text-transform: 'uppercase';
        text-align: center;
        letter-spacing: 1px;
        margin:0px;
        padding: 10px;
        background-color: transparent;
        color: inherit;
    }

    .invoice-body table tbody{
        background-color: white;
        margin: 0px;
        padding:0px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
    }

    .invoice-body table tbody tr{
        margin: 0px;
        padding:0px;
    }

    .invoice-body table tbody tr td{
        font-size: 12px;
        font-weight: normal;
        text-transform: capitalize;
        text-align: center;
        margin:0px;
        padding: 10px;
        border-right: 1px solid #000;
    }

    .invoice-body table tbody tr td:last-child{
        border-right: 0px;
    }

    .invoice-body table tfoot{
        background-color: #ccc;
        color: #000;
    }

    .invoice-body table tfoot tr{
        margin: 0px;
        padding:0px;
        background-color: transparent;
    }

    .invoice-body table tfoot tr td{
        font-size: 12px;
        font-weight: bold;
        text-transform: 'uppercase';
        text-align: center;
        letter-spacing: 1px;
        margin:0px;
        padding: 10px;
        background-color: transparent;
    }
    
    .amount-words{
        display: flex;
        flex-direction: column;
        padding: 15px;
        width: calc(100% - 32px);
        border: 1px solid #000;
        border-top-width: 0px;
    }

    .amount-words h2{
        font-size: 14px;
        font-weight: normal;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
        text-transform: capitalize;
    }

    .amount-words p{
        font-size: 12px;
        font-weight: normal;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
        text-transform: capitalize;
    }

    .invoice-footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        width: calc(100% - 2px);
        border: 1px solid #000;
        border-top-width: 0px;
    }

    .invoice-footer .bank-details {
        flex: 1;
        border-right: 1px solid #000;
        padding: 10px;
    }

    .invoice-footer h2{
        font-size: 14px;
        font-weight: bold;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
    }

    .invoice-footer p{
        font-size: 12px;
        font-weight: normal;
        margin: 0px;
        padding: 0px;
        color: #000;
        line-height: 20px;
        text-transform: capitalize;
    }

    .invoice-footer .signature {
        flex: 1;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }

    .grid-two {
      display: grid;
      grid-template-columns: 49% 49%;
      grid-column-gap: 2%;
      margin: 20px 0px;
    }

    .signature-box{
      height: auto;
      min-height: 100px;
    }

    /*** EXCEL TABLE ***/

    .excel-download-btn{
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
      background-color: green;
      border: 0px;
      border-radius: 50px;
      padding: 12px 30px;
      -webkit-letter-spacing: 0px;
      -moz-letter-spacing: 0px;
      -ms-letter-spacing: 0px;
      letter-spacing: 0px;
      outline: 0px;
      cursor: pointer;
      width: auto;
      height: auto;
      -webkit-transition: background-color 0.5s,color 0.5s;
      transition: background-color 0.5s,color 0.5s;
      margin: 0px 10px;
    }
`;

export default GlobalStyles;
