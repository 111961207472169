import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  Label,
  NumberInput,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledLink,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledFiltersContainer,
} from '../../../components/Styled';
import { Image } from '../../../components/Image';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  camelCaseToString,
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
  formatCurrency,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';

const AccountsAPIMappingTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [accountsApiMappings, setAccountsApiMappings] = useState([]);
  const [services, setServices] = useState([]);
  const [operators, setOperators] = useState([]);
  const [apiProviders, setApiProviders] = useState([]);
  const [servicesId, setServicesId] = useState('');
  const [operatorsId, setOperatorsId] = useState('');
  const [apiProvidersId, setApiProvidersId] = useState('');
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');

  const resetForm = () => {
    setServicesId('');
    setOperatorsId('');
    setApiProvidersId('');
    setFromAmount('');
    setToAmount('');
    readData();
  };

  const readServices = () => {
    API.get('/recharge-configurations/services').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setServices(data);
      }
    });
  };

  const readOperators = () => {
    API.get('/recharge-configurations/operators').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setOperators(data);
      }
    });
  };

  const readApiProviders = () => {
    API.get('/recharge-configurations/api-providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setApiProviders(data);
      }
    });
  };

  const readData = useCallback(
    (operatorsId = '', apiProvidersId = '', fromAmount = '', toAmount = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/recharge-configurations/accounts-api-mapping?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fOperatorsId=${operatorsId}&fApiProvidersId=${apiProvidersId}&fFromAmount=${fromAmount}&fToAmount=${toAmount}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
            }
            setAccountsApiMappings(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    if (servicesId !== '' && operatorsId === '' && apiProvidersId === '') {
      alert('Please select operator or api providers');
    } else {
      readData(operatorsId, apiProvidersId, fromAmount, toAmount);
    }
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/recharge-configurations/accounts-api-mapping/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fOperatorsId=${operatorsId}&fApiProvidersId=${apiProvidersId}&fFromAmount=${fromAmount}&fToAmount=${toAmount}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setAccountsApiMappings(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    if (servicesId !== '' && operatorsId === '' && apiProvidersId === '') {
      alert('Please select operator or api providers');
    } else {
      setIsFiltering(true);
    }
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    readServices();
    readOperators();
    readApiProviders();
  }, []);

  useEffect(() => {
    if (!isFiltering) {
      readData(operatorsId, apiProvidersId, fromAmount, toAmount);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const servicesOptions = [{ title: '-- SELECT SERVICE --', value: '' }];
  services.forEach(({ id, serviceName }) =>
    servicesOptions.push({
      title: serviceName,
      value: id,
    })
  );

  const operatorsOptions = [{ title: '-- SELECT OPERATOR --', value: '' }];
  operators
    .filter((operator) =>
      servicesId !== '' ? operator.servicesId === servicesId : true
    )
    .forEach(({ id, operatorName }) =>
      operatorsOptions.push({
        title: operatorName,
        value: id,
      })
    );

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: '' },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Service</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Service'
              value={servicesId}
              onChange={(value) => {
                setOperatorsId('');
                setServicesId(value);
              }}
              options={servicesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Operator</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Operator'
              value={operatorsId}
              onChange={setOperatorsId}
              options={operatorsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>API Provider</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='API Provider'
              value={apiProvidersId}
              onChange={setApiProvidersId}
              options={apiProvidersOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>From Amount</Label>
            <NumberInput
              placeholder='From Amount'
              value={fromAmount}
              onChange={setFromAmount}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>To Amount</Label>
            <NumberInput
              placeholder='To Amount'
              value={toAmount}
              onChange={setToAmount}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' &&
          accountsApiMappings?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          accountsApiMappings?.length === 0
        }
        message={`No accounts api mappings found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {accountsApiMappings?.length > 0 && (
        <>
          <TableBuilder
            isLoading={accountsApiMappings?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Operator',
                dataSelector: 'operatorName',
                dataType: 'string',
                canSort: false,
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={
                          item.operatorLogo
                            ? item.operatorLogo
                            : '/no-image.png'
                        }
                        alt={value}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>
                      {value}
                      <StyledSpan>{item.serviceName}</StyledSpan>
                    </StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: 'Accounts Details',
                dataSelector: 'firstName',
                dataType: 'string',
                CellRenderer: (value, item) => {
                  const { lastName, roleName, entityName } = item;
                  return (
                    <>
                      {entityName && entityName !== 'null'
                        ? entityName
                        : `${value} ${lastName}`}
                      {roleName && (
                        <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
                      )}
                    </>
                  );
                },
              },
              {
                title: 'Primary API Provider',
                dataSelector: 'primaryApiProvidersName',
                dataType: 'number',
              },
              {
                title: 'Secondary API Provider',
                dataSelector: 'secondaryApiProvidersName',
                dataType: 'number',
              },
              {
                title: 'Tertiary API Provider',
                dataSelector: 'tertiaryApiProvidersName',
                dataType: 'number',
              },
              {
                title: 'From Amount',
                dataSelector: 'fromAmount',
                dataType: 'number',
                CellRenderer: (value) => formatCurrency(value ? value : 0),
              },
              {
                title: 'To Amount',
                dataSelector: 'toAmount',
                dataType: 'number',
                CellRenderer: (value) => formatCurrency(value ? value : 0),
              },
            ]}
            tableData={accountsApiMappings}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(AccountsAPIMappingTable);
