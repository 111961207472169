import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  Label,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledFiltersContainer,
} from '../../../components/Styled';
import { Image } from '../../../components/Image';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import {
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
  authData,
  camelCaseToString,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';

const AccountOperatorBlockersTable = (props) => {
  const { accountType } = authData.get();
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [accountOperatorBlockers, setAccountOperatorBlockers] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [accountsId, setAccountsId] = useState('');
  const [operators, setOperators] = useState([]);
  const [operatorsId, setOperatorsId] = useState('');

  const resetForm = () => {
    setRolesId('');
    setAccountsId('');
    setOperatorsId('');
    readData();
  };

  const readAccounts = () => {
    API.get('/accounts-management/accounts').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readOperators = () => {
    API.get('/recharge-configurations/operators').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setOperators(data);
      }
    });
  };

  const readData = useCallback(
    (rolesId = '', accountsId = '', operatorsId = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/recharge-configurations/account-operator-blockers?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fRolesId=${rolesId}&fAccountsId=${accountsId}&fOperatorsId=${operatorsId}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
            }
            setAccountOperatorBlockers(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(rolesId, accountsId, operatorsId);
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/recharge-configurations/account-operator-blockers/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fRolesId=${rolesId}&fAccountsId=${accountsId}&fOperatorsId=${operatorsId}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setAccountOperatorBlockers(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    readAccounts();
    readOperators();
  }, []);

  useEffect(() => {
    if (!isFiltering) {
      readData(rolesId, accountsId, operatorsId);
    }
  }, [paginationData, readData]);

  const { accountsPermissions } = props;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  const rolesOptions = [{ title: '-- SELECT ROLE --', value: '' }];
  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
    [
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
    [
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else {
    [
      {
        id: 2,
        roleName: 'Master Distributors',
      },
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
      {
        id: 5,
        roleName: 'API Users',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  }

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .filter(({ accountsRolesId }) =>
      rolesId !== ''
        ? accountsRolesId === rolesId
        : [2, 3, 4, 5].includes(accountsRolesId)
    )
    .forEach(({ id, firstName, lastName }) =>
      accountsOptions.push({
        title: `${firstName} ${lastName}`,
        value: id,
      })
    );

  const operatorsOptions = [{ title: '-- SELECT OPERATOR --', value: '' }];
  operators.forEach(({ id, operatorName }) =>
    operatorsOptions.push({
      title: operatorName,
      value: id,
    })
  );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Role</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Role'
              value={rolesId}
              onChange={(value) => {
                setAccountsId('');
                setRolesId(value);
              }}
              options={rolesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Account</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Account'
              value={accountsId}
              onChange={setAccountsId}
              options={accountsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Operator</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Operator'
              value={operatorsId}
              onChange={setOperatorsId}
              options={operatorsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' &&
          accountOperatorBlockers?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          accountOperatorBlockers?.length === 0
        }
        message={`No account operator blockers found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {accountOperatorBlockers?.length > 0 && (
        <>
          <TableBuilder
            isLoading={accountOperatorBlockers?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => (
                  <>
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Account',
                dataSelector: 'firstName',
                dataType: 'string',
                CellRenderer: (value, item) => {
                  const { lastName, roleName, entityName } = item;
                  return (
                    <>
                      {entityName && entityName !== 'null'
                        ? entityName
                        : `${value} ${lastName}`}
                      {roleName && (
                        <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
                      )}
                    </>
                  );
                },
              },
              {
                title: 'Operator',
                dataSelector: 'operatorName',
                dataType: 'string',
                canSort: false,
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={
                          item.operatorLogo
                            ? item.operatorLogo
                            : '/no-image.png'
                        }
                        alt={value}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>
                      {value}
                      <StyledSpan>{item.serviceName}</StyledSpan>
                    </StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
            ]}
            tableData={accountOperatorBlockers}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(AccountOperatorBlockersTable);
