import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  MessageBox,
  Label,
  Fieldset,
  TextInput,
  Button,
  Form,
  ResetButton,
  Dropdown,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import { StyledFiltersContainer, StyledSpan } from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { ACCOUNT_TYPES, API_RESPONSE_TYPES } from '../../../constants';
import {
  getSearchParams,
  authData,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';

const AlertHistory = (props) => {
  const { accountType } = authData.get();
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 200,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [accountsId, setAccountsId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [medium, setMedium] = useState('');

  const resetForm = () => {
    setRolesId('');
    setAccountsId('');
    setStartDate('');
    setEndDate('');
    setMedium('');
    readData();
  };

  const readAccounts = () => {
    API.get('/accounts-management/accounts').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readData = useCallback(
    (accountsId = '', startDate = '', endDate = '', medium = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/reports/alert-history/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fAccountsId=${accountsId}&fStartDate=${startDate}&fEndDate=${endDate}&fAlertType=${medium}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
            }
            setAlerts(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(accountsId, startDate, endDate, medium);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    readAccounts();
  }, []);

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(accountsId, startDate, endDate, medium);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  const rolesOptions = [{ title: '-- SELECT ROLE --', value: '' }];
  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
    [
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
    [
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else {
    [
      {
        id: 1,
        roleName: 'Admins',
      },
      {
        id: 2,
        roleName: 'Master Distributors',
      },
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
      {
        id: 5,
        roleName: 'Api Users',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  }

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .filter(({ accountsRolesId }) =>
      rolesId !== ''
        ? accountsRolesId === rolesId
        : [2, 3, 4, 5].includes(accountsRolesId)
    )
    .forEach(({ id, firstName, lastName }) =>
      accountsOptions.push({
        title: `${firstName} ${lastName}`,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Role</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Role'
              value={rolesId}
              onChange={(value) => {
                setAccountsId('');
                setRolesId(value);
              }}
              options={rolesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Account</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Account'
              value={accountsId}
              onChange={setAccountsId}
              options={accountsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type='date'
              value={startDate}
              onChange={setStartDate}
              placeholder='Start Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type='date'
              value={endDate}
              onChange={setEndDate}
              placeholder='End Date'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Medium</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Medium'
              value={medium}
              onChange={setMedium}
              options={[
                {
                  title: '-- SELECT STATUS --',
                  value: '',
                },
                {
                  title: 'SMS',
                  value: 'SMS',
                },
                {
                  title: 'WhatsApp',
                  value: 'WhatsApp',
                },
                {
                  title: 'Telegram',
                  value: 'Telegram',
                },
                {
                  title: 'Email',
                  value: 'Email',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && alerts?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          alerts?.length === 0
        }
        message={`No alerts found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {alerts?.length > 0 && (
        <>
          <TableBuilder
            isLoading={alerts?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: 'Receiver',
                dataSelector: 'mobileNumber',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <>
                    {item.accountsName}
                    <StyledSpan>{value}</StyledSpan>
                  </>
                ),
              },
              {
                title: 'Message',
                dataSelector: 'messageText',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Medium',
                dataSelector: 'alertType',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Delivery Status',
                dataSelector: 'deliveryStatus',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Sent On',
                dataSelector: 'createdOn',
                dataType: 'string',
                CellRenderer: (value) =>
                  value ? moment(value).format('DD-MM-YYYY HH:MM:SS') : 'N/A',
              },
            ]}
            tableData={alerts}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(AlertHistory);
