import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  CustomConfirmButton,
  MessageBox,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import { StyledLink } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { camelCaseToString } from '../../../utils';

const RolesAPIMappingTable = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [rolesApiMappings, setRolesApiMappings] = useState([]);

  const readData = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/roles-api-mapping`)
      .then((response) => {
        const { data } = response.data;
        setRolesApiMappings(data);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClearMapping = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(`/recharge-configurations/roles-api-mapping/${id}`)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setTimeout(() => {
          setResponseStatus('');
          setResponseMessage('');
        }, 3000);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData();
  };

  useEffect(() => {
    readData();
  }, []);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <LoadingSpinner
        isLoading={
          responseStatus === '' && rolesApiMappings?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          rolesApiMappings?.length === 0
        }
        message={`No roles api mappings found`}
      />
      {responseStatus === API_RESPONSE_TYPES.SUCCESS ||
        (responseStatus === API_RESPONSE_TYPES.FAILURE && (
          <MessageBox status={responseStatus} message={responseMessage} />
        ))}
      {rolesApiMappings?.length > 0 && (
        <TableBuilder
          isLoading={rolesApiMappings?.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: '',
              dataSelector: 'id',
              sticky: true,
              canSort: false,
              width: '100px',
              CellRenderer: (value) => (
                <>
                  {canUpdate && (
                    <>
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                      <CustomConfirmButton
                        disabled={isLoading === true}
                        icon={faBroom}
                        showTitle={false}
                        showIcon={true}
                        showTextButton={false}
                        title={`Reset Mapping ?`}
                        buttonTitle='Submit'
                        message={`Are you sure, you want to reset ?`}
                        proceedButtonText='Clear'
                        cancelButtonText='Cancel'
                        onClick={() => onClearMapping(value)}
                        color='red'
                        hoverColor='#b30000'
                      />
                    </>
                  )}
                </>
              ),
            },
            {
              title: 'Role Name',
              dataSelector: 'roleName',
              dataType: 'string',
              CellRenderer: (value) => camelCaseToString(value),
            },
          ]}
          tableData={rolesApiMappings}
        />
      )}
    </>
  );
};

export default withAppHOC(RolesAPIMappingTable);
