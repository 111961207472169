import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Form,
  Fieldset,
  Label,
  NumberInput,
  PasswordInput,
  Button,
  MessageBox,
} from '../../components/FormElements';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import Logo from '../../components/Logo/Logo';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const StyledAuthArea = styled.section`
  width: 90%;
  max-width: 500px;
  height: auto;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 0px;
  margin: 0px auto;
  box-shadow: 0px 0px 50px -10px #b9b9b9;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const StyledBody = styled.section`
  width: calc(100% - 60px);
  height: auto;
  margin: 0px;
  padding: 30px;
`;

const StyledAuthHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 0px;
`;

const DeleteAccount = (props) => {
  const { appName, appLogo } = props.appData;
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/auth/delete-account', {
      loginType: 'Password',
      mobileNumber,
      password,
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setMobileNumber('');
          setPassword('');
          setResponseStatus(status);
          setResponseMessage(message);
          setTimeout(() => {
            setResponseStatus('');
            setResponseMessage('');
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StyledAuthArea>
        <StyledBody>
          <Logo src={appLogo} alt={appName} type='auth' />
          <StyledAuthHeading>Delete your Account</StyledAuthHeading>
          <Form action='#' method='POST' onSubmit={onSubmit}>
            <Fieldset>
              <Label required>Mobile Number</Label>
              <NumberInput
                placeholder='Please enter your mobile number'
                value={mobileNumber}
                onChange={setMobileNumber}
                maxLength={10}
                autoComplete='off'
                disabled={isLoading === true}
                pattern={`[0-9]*`}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Password</Label>
              <PasswordInput
                placeholder='Please enter your password'
                value={password}
                onChange={setPassword}
                autoComplete='off'
                disabled={isLoading}
              />
            </Fieldset>
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={responseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={isLoading}>Delete</Button>
            </Fieldset>
          </Form>
        </StyledBody>
        <OverlayLoader showLoader={isLoading} />
      </StyledAuthArea>
    </>
  );
};

export default DeleteAccount;
