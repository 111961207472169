import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';

const OperatorsMappingUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [operators, setOperators] = useState([]);
  const [circles, setCircles] = useState([]);
  const [apiProviders, setApiProviders] = useState([]);
  const [operatorsId, setOperatorsId] = useState('');
  const [circlesId, setCirclesId] = useState('');
  const [apiProvidersId, setApiProvidersId] = useState('');
  const [operatorCode, setOperatorCode] = useState('');
  const [commissionType, setCommissionType] = useState('');
  const [commission, setCommission] = useState('');
  const [fromAmount, setFromAmount] = useState('0');
  const [toAmount, setToAmount] = useState('10000');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      operatorsId,
      circlesId,
      apiProvidersId,
      operatorCode,
      commissionType,
      commission,
      fromAmount,
      toAmount,
    } = originalFormData;
    setOperatorsId(operatorsId);
    setCirclesId(circlesId);
    setApiProvidersId(apiProvidersId);
    setOperatorCode(operatorCode);
    setCommissionType(commissionType);
    setCommission(commission);
    setFromAmount(fromAmount);
    setToAmount(toAmount);
  };

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/operators')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readCircles = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/circles')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setCircles(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readApiProviders = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/api-providers')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setApiProviders(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/operators-mapping/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            operatorsId,
            circlesId,
            apiProvidersId,
            operatorCode,
            commissionType,
            commission,
            fromAmount,
            toAmount,
          } = data[0];
          setOperatorsId(operatorsId);
          setCirclesId(circlesId);
          setApiProvidersId(apiProvidersId);
          setOperatorCode(operatorCode);
          setCommissionType(commissionType);
          setCommission(commission);
          setFromAmount(fromAmount);
          setToAmount(toAmount);
          setOriginalFormData({
            operatorsId,
            circlesId,
            apiProvidersId,
            operatorCode,
            commissionType,
            commission,
            fromAmount,
            toAmount,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      operatorsId,
      circlesId,
      apiProvidersId,
      operatorCode,
      commissionType,
      commission,
      fromAmount,
      toAmount,
    };
    API.put(`/recharge-configurations/operators-mapping/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readOperators();
    readCircles();
    readApiProviders();
    readData();
  }, [readOperators, readCircles, readApiProviders, readData]);

  const operatorsOptions = [{ title: '-- SELECT OPERATOR --', value: '' }];
  operators.forEach(({ id, operatorName }) =>
    operatorsOptions.push({
      title: operatorName,
      value: id,
    })
  );

  const circlesOptions = [
    { title: '-- SELECT CIRCLE --', value: '' },
    { title: 'ALL', value: 0 },
  ];
  circles.forEach(({ id, circleName }) =>
    circlesOptions.push({
      title: circleName,
      value: id,
    })
  );

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: '' },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Operator</Label>
                <Dropdown
                  placeholder='Please select operator'
                  value={operatorsId}
                  onChange={setOperatorsId}
                  options={operatorsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Circle</Label>
                <Dropdown
                  placeholder='Please select circle'
                  value={circlesId}
                  onChange={setCirclesId}
                  options={circlesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>API Provider</Label>
                <Dropdown
                  placeholder='Please select api provider'
                  value={apiProvidersId}
                  onChange={setApiProvidersId}
                  options={apiProvidersOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Operator Code</Label>
            <TextInput
              value={operatorCode}
              onChange={setOperatorCode}
              placeholder='Please enter operator code'
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Commission Type</Label>
                <Dropdown
                  placeholder='Please select commission type'
                  value={commissionType}
                  onChange={setCommissionType}
                  options={[
                    {
                      title: '-- SELECT COMMISSION TYPE --',
                      value: '',
                    },
                    {
                      title: 'Percentage',
                      value: 'Percentage',
                    },
                    {
                      title: 'Flat',
                      value: 'Flat',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Commission</Label>
                <NumberInput
                  value={commission}
                  onChange={setCommission}
                  placeholder='Please enter commission'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>From Amount</Label>
                <NumberInput
                  value={fromAmount}
                  onChange={setFromAmount}
                  placeholder='Please enter from amount'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>To Amount</Label>
                <NumberInput
                  value={toAmount}
                  onChange={setToAmount}
                  placeholder='Please enter to amount'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(OperatorsMappingUpdate);
