import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button } from '../FormElements';

const StyledLink = styled(NavLink)`
  font-size: 14px;
  text-decoration: none;
  padding: 0px;
  margin: 5px;
  color: #0095ff;
  &:hover {
    color: #0078ce;
  }
`;

const StyledSpan = styled.span`
  font-size: 12px;
  display: block;
  color: #848484;
  margin-top: 5px;
  font-style: italic;
  text-transform: capitalize;
`;

const StyledIconTitleContainer = styled.section`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
`;

const StyledIconContainer = styled.section`
  width: 50px;
  height: 50px;
  margin: 0px 10px 0px 0px;
  padding: 0px;
`;

const StyledTitleContainer = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 0px;
`;

const StyledMicroButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
  margin: 10px 10px 10px 0px;
`;

const StyledModalContainer = styled.section`
  width: ${({ width }) => width ?? `90%`};
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 10px;
  @media (max-width: 600px) {
    width: 90%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  h3 {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    background-color: ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)};
    margin: 0px;
    padding: 15px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
`;

const StyledFiltersContainer = styled.section`
  width: calc(100% - 42px);
  height: auto;
  padding: 20px 20px 0px;
  margin: 0px 0px 20px 0px;
  border: 1px solid
    ${({
      theme: {
        colors: { tableHeaderColor },
      },
    }) => (tableHeaderColor ? tableHeaderColor : `#545454`)};
  border-radius: 5px;
  &.no-border {
    width: 100%;
    border: 0px;
    padding: 0px;
    margin: 0px;
  }
  @media (max-width: 600px) {
    display: none;
    &.show {
      display: block;
    }
  }
  form {
    fieldset {
      width: 300px;
      display: inline-block;
      margin-right: 15px;
      @media (max-width: 600px) {
        width: 100%;
        margin-right: 0px;
      }
      input {
        border: 1px solid #cacaca;
      }
      &:last-child {
        margin-right: 0px;
        button {
          width: calc(50% - 10px);
          height: 48px;
          border-radius: 5px;
          margin: 0px;
          &:first-child {
            margin-right: 15px;
          }
        }
      }
    }
  }
`;

const StyledPara = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const StyledFiltersButton = styled(Button)`
  display: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  color: #545454;
  margin-bottom: 20px;
  cursor: pointer;
  svg {
    margin-right: 15px;
  }
  @media (max-width: 600px) {
    display: block;
  }
  &:hover {
    background-color: #c1c1c1;
    color: #545454;
  }
  &.active {
    background-color: #c1c1c1;
    color: #545454;
  }
`;

const StyledSpanAsButton = styled.span`
  font-size: 14px;
  color: #0095ff;
  cursor: pointer;
  text-decoration: none;
  margin: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledRechargeCountsContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0px 0px 20px 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledRechargeCounter = styled.section`
  width: calc(33.333% - 6.666px);
  height: auto;
  background-color: ${({ color }) => (color ? color : '#0095ff')};
  padding: 0px;
  margin: 0px;
  display: grid;
  border-radius: 0px;
  text-align: center;
  color: #fff;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledHeader = styled.h3`
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
  padding: 20px 0px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCount = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
  padding: 15px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledOTPModal = styled.section`
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const StyledOTPText = styled.h1`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  text-align: center;
  margin: 0px;
  padding: 0px;
`;

const StyledBodyContainer = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  margin: 20px;
  border-radius: 5px;
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background-color: #e5e5e5;
  padding: 15px;
  margin: 0px;
`;

const StyledContent = styled.section`
  padding: 20px;
  @media (max-width: 600px) {
    word-break: break-all;
    overflow-x: scroll;
  }
`;

const StyledApiCallLogsContainer = styled.section`
  width: auto;
  height: auto;
  max-height: 500px;
  overflow-y: scroll;
  padding: 20px;
  pre {
    white-space: pre-wrap;
  }
`;

export {
  StyledLink,
  StyledSpan,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledMicroButton,
  StyledModalContainer,
  StyledFiltersContainer,
  StyledPara,
  StyledFiltersButton,
  StyledSpanAsButton,
  StyledRechargeCountsContainer,
  StyledRechargeCounter,
  StyledHeader,
  StyledCount,
  StyledOTPModal,
  StyledOTPText,
  StyledBodyContainer,
  StyledHeading,
  StyledContent,
  StyledApiCallLogsContainer,
};
