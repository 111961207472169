import React from 'react';
import styled from 'styled-components';

const StyledOverlayContainer = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  background-image: url('/loading.gif');
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  z-index: 9999;
  border-radius: inherit;
`;

const OverlayLoader = ({ showLoader = false }) => {
  return showLoader && <StyledOverlayContainer />;
};

export default OverlayLoader;
