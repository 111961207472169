import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withAuthHOC } from '../../hoc';
import {
  Form,
  Fieldset,
  Label,
  NumberInput,
  PasswordInput,
  OTPInput,
  Button,
  MessageBox,
  TextInput,
  Dropdown,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { authData } from '../../utils';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';

const StyledAuthArea = styled.section`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 0px;
  margin: 0px;
  box-shadow: 0px 0px 50px -10px #b9b9b9;
  position: relative;
  @media (max-width: 900px) {
    width: calc(100% - 40px);
    min-width: auto;
    height: auto;
    padding: 0px;
    margin: 0 auto;
    position: relative;
    top: 0px;
    left: 0px;
    transform: none;
  }
  @media (max-width: 1000px) {
    width: 92%;
    min-width: auto;
    height: auto;
    padding: 0px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
`;

const StyledBody = styled.section`
  width: calc(100% - 60px);
  height: auto;
  margin: 0px;
  padding: 30px;
  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    padding: 20px;
  }
`;

const StyledAuthHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 0px;
`;

const StyleLink = styled(NavLink)`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSpanAsButton = styled.span`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 11.5px;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledPara = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const StyledSpan = styled.span`
  color: forestgreen;
`;

const StyledRegisterLink = styled.h2`
  display: block;
  font-size: 14px;
  margin: 0px;
  color: #000000;
  text-transform: normal;
  font-weight: normal;
  text-align: center;
  padding: 20px 0px 0px 0px;
  a {
    position: static;
  }
`;

const StyledGrid = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
`;

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [routeStage, setRouteStage] = useState('Register');
  const [accountType, setAccountType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [supervisorsMobileNumber, setSupervisorsMobileNumber] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');

  const setAuthData = (data) => {
    const {
      token,
      data: { accountsRole, permissions },
    } = data;
    authData.set('isLoggedIn', true);
    authData.set('accountType', accountsRole);
    authData.set('authId', mobileNumber);
    authData.set('authToken', token);
    authData.set('accountsPermissions', permissions);
    authData.set('accountData', JSON.stringify(data.data));
    window.location.href = '/';
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    if (routeStage === 'Register') {
      const formData = {
        accountType,
        firstName,
        lastName,
        mobileNumber,
        password,
        supervisorsMobileNumber,
        referralCode,
      };
      API.post('/auth/register', formData)
        .then((response) => {
          const { status, message, token } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setRequestToken(token);
            setRouteStage('OTPVerification');
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (routeStage === 'OTPVerification') {
      const formData = {
        requestType: 'Register',
        requestToken,
        mobileNumber,
        oneTimePassword: oneTimePassword
          ? oneTimePassword.join('')
          : oneTimePassword,
      };
      API.post('/auth/verify-otp', formData)
        .then((response) => {
          const { status, message, token } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
            setRequestToken(token);
          } else {
            setAuthData(response.data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      requestType: 'Register',
      mobileNumber,
      requestToken,
    };
    API.post('/auth/resend-otp', formData)
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setRequestToken(token);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StyledAuthArea>
        <StyledBody>
          <StyledAuthHeading>
            {routeStage === 'Register'
              ? `Create your Account`
              : `Verify & Login`}
          </StyledAuthHeading>
          <Form action='#' method='POST' onSubmit={onSubmit}>
            {routeStage === 'Register' && (
              <>
                <Fieldset>
                  <Label required>Account Type</Label>
                  <Dropdown
                    placeholder='Account Type'
                    value={accountType}
                    onChange={setAccountType}
                    options={[
                      {
                        title: '-- SELECT ACCOUNT TYPE --',
                        value: '',
                      },
                      {
                        title: 'Retailer',
                        value: 4,
                      },
                      {
                        title: 'API User',
                        value: 5,
                      },
                    ]}
                    disabled={isLoading}
                  />
                </Fieldset>
                <StyledGrid>
                  <Fieldset style={{ flex: 1 }}>
                    <Label required>First Name</Label>
                    <TextInput
                      placeholder='Please enter your first name'
                      value={firstName}
                      onChange={setFirstName}
                      maxLength={100}
                      autoComplete='off'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                  <Fieldset style={{ flex: 1 }}>
                    <Label required>Last Name</Label>
                    <TextInput
                      placeholder='Please enter your last name'
                      value={lastName}
                      onChange={setLastName}
                      maxLength={100}
                      autoComplete='off'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                </StyledGrid>
                <Fieldset>
                  <Label required>Mobile Number</Label>
                  <NumberInput
                    placeholder='Please enter your mobile number'
                    value={mobileNumber}
                    onChange={setMobileNumber}
                    maxLength={10}
                    autoComplete='off'
                    disabled={isLoading === true}
                    pattern={`[0-9]*`}
                  />
                </Fieldset>
                <Fieldset>
                  <Label required>Password</Label>
                  <PasswordInput
                    placeholder='Please enter your password'
                    value={password}
                    onChange={setPassword}
                    autoComplete='off'
                    disabled={isLoading}
                  />
                </Fieldset>
                {accountType === 4 && (
                  <Fieldset>
                    <Label>Distributors Mobile Number</Label>
                    <NumberInput
                      placeholder='Please enter distributors mobile number (Optional)'
                      value={supervisorsMobileNumber}
                      onChange={setSupervisorsMobileNumber}
                      maxLength={10}
                      autoComplete='off'
                      disabled={isLoading === true}
                      pattern={`[0-9]*`}
                    />
                  </Fieldset>
                )}
                {accountType !== 5 && (
                  <Fieldset>
                    <Label>Referral Code</Label>
                    <TextInput
                      placeholder='Please enter referral code (Optional)'
                      value={referralCode}
                      onChange={setReferralCode}
                      maxLength={6}
                      autoComplete='off'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                )}
              </>
            )}
            {routeStage === 'OTPVerification' && (
              <>
                <StyledPara>
                  Please enter your OTP sent to{' '}
                  <StyledSpan>{mobileNumber}</StyledSpan>
                </StyledPara>
                <Fieldset>
                  <OTPInput
                    values={oneTimePassword}
                    onChange={setOneTimePassword}
                    length={6}
                    alignInputs='center'
                    disabled={isLoading}
                  />
                </Fieldset>
              </>
            )}
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={responseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={isLoading}>
                {routeStage === 'Register' ? `Register` : `Verify & Proceed`}
              </Button>
              {routeStage === 'OTPVerification' && (
                <StyledSpanAsButton onClick={onClickResendOTP}>
                  Resend OTP
                </StyledSpanAsButton>
              )}
            </Fieldset>
          </Form>
          <StyledRegisterLink>
            Already have an account ?{' '}
            <StyleLink to='/login' title='Login'>
              Login
            </StyleLink>
          </StyledRegisterLink>
        </StyledBody>
        <OverlayLoader showLoader={isLoading} />
      </StyledAuthArea>
    </>
  );
};

export default withAuthHOC(Register);
