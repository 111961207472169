import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Legend,
  Label,
  TextInput,
  MessageBox,
  Button,
  ResetButton,
  OTPInput,
} from '../../../components/FormElements';
import { StyledPara, StyledSpanAsButton } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { authData } from '../../../utils';

const CompanyDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [appName, setAppName] = useState('');
  const [appLogo, setAppLogo] = useState('');
  const [appFavicon, setAppFavicon] = useState('');
  const [homeBanner, setHomeBanner] = useState('');
  const [appPhoneNumber, setAppPhoneNumber] = useState('');
  const [appEmailAddress, setAppEmailAddress] = useState('');
  const [firmName, setFirmName] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [firmAddress, setFirmAddress] = useState('');
  const [invoicePrefix, setInvoicePrefix] = useState('');
  const [hsnCode, setHsnCode] = useState('');
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [appBackgroundColor, setAppBackgroundColor] = useState('');
  const [appPrimaryColor, setAppPrimaryColor] = useState('');
  const [appSecondaryColor, setAppSecondaryColor] = useState('');
  const [appTertiaryColor, setAppTertiaryColor] = useState('');
  const [appTableHeaderColor, setAppTableHeaderColor] = useState('');
  const [masterDistributorsCommission, setMasterDistributorsCommission] =
    useState('');
  const [distributorsCommission, setDistributorsCommission] = useState('');
  const [retailersCommission, setRetailersCommission] = useState('');
  const [apiUsersCommission, setApiUsersCommission] = useState('');
  const [upiMinimumAmount, setUpiMinimumAmount] = useState(0);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const [routeStage, setRouteStage] = useState('Request');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [requestToken, setRequestToken] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      appName,
      appLogo,
      appFavicon,
      homeBanner,
      appPhoneNumber,
      appEmailAddress,
      appBackgroundColor,
      appPrimaryColor,
      appSecondaryColor,
      appTertiaryColor,
      appTableHeaderColor,
      firmName,
      gstNumber,
      firmAddress,
      invoicePrefix,
      hsnCode,
      invoiceDescription,
      masterDistributorsCommission,
      distributorsCommission,
      retailersCommission,
      apiUsersCommission,
      upiMinimumAmount,
      privacyPolicy,
      termsAndConditions,
      aboutUs,
    } = originalFormData;
    setAppName(appName);
    setAppLogo(appLogo);
    setAppFavicon(appFavicon);
    setHomeBanner(homeBanner);
    setAppPhoneNumber(appPhoneNumber);
    setAppEmailAddress(appEmailAddress);
    setAppBackgroundColor(appBackgroundColor);
    setAppPrimaryColor(appPrimaryColor);
    setAppSecondaryColor(appSecondaryColor);
    setAppTertiaryColor(appTertiaryColor);
    setAppTableHeaderColor(appTableHeaderColor);
    setFirmName(firmName);
    setGstNumber(gstNumber);
    setFirmAddress(firmAddress);
    setInvoicePrefix(invoicePrefix);
    setHsnCode(hsnCode);
    setInvoiceDescription(invoiceDescription);
    setMasterDistributorsCommission(masterDistributorsCommission);
    setDistributorsCommission(distributorsCommission);
    setRetailersCommission(retailersCommission);
    setApiUsersCommission(apiUsersCommission);
    setUpiMinimumAmount(upiMinimumAmount);
    setPrivacyPolicy(privacyPolicy);
    setTermsAndConditions(termsAndConditions);
    setAboutUs(aboutUs);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/meta-details`)
      .then(({ data }) => {
        const {
          appName,
          appLogo,
          appFavicon,
          homeBanner,
          appPhoneNumber,
          appEmailAddress,
          appBackgroundColor,
          appPrimaryColor,
          appSecondaryColor,
          appTertiaryColor,
          appTableHeaderColor,
          firmName,
          gstNumber,
          firmAddress,
          invoicePrefix,
          hsnCode,
          invoiceDescription,
          masterDistributorsCommission,
          distributorsCommission,
          retailersCommission,
          apiUsersCommission,
          upiMinimumAmount,
          privacyPolicy,
          termsAndConditions,
          aboutUs,
        } = data;
        setAppName(appName);
        setAppLogo(appLogo);
        setAppFavicon(appFavicon);
        setHomeBanner(homeBanner);
        setAppPhoneNumber(appPhoneNumber);
        setAppEmailAddress(appEmailAddress);
        setAppBackgroundColor(appBackgroundColor);
        setAppPrimaryColor(appPrimaryColor);
        setAppSecondaryColor(appSecondaryColor);
        setAppTertiaryColor(appTertiaryColor);
        setAppTableHeaderColor(appTableHeaderColor);
        setFirmName(firmName);
        setGstNumber(gstNumber);
        setFirmAddress(firmAddress);
        setInvoicePrefix(invoicePrefix);
        setHsnCode(hsnCode);
        setInvoiceDescription(invoiceDescription);
        setMasterDistributorsCommission(masterDistributorsCommission);
        setDistributorsCommission(distributorsCommission);
        setRetailersCommission(retailersCommission);
        setApiUsersCommission(apiUsersCommission);
        setUpiMinimumAmount(upiMinimumAmount);
        setPrivacyPolicy(privacyPolicy);
        setTermsAndConditions(termsAndConditions);
        setAboutUs(aboutUs);
        setOriginalFormData({
          appName,
          appLogo,
          appFavicon,
          homeBanner,
          appPhoneNumber,
          appEmailAddress,
          appBackgroundColor,
          appPrimaryColor,
          appSecondaryColor,
          appTertiaryColor,
          appTableHeaderColor,
          firmName,
          gstNumber,
          firmAddress,
          invoicePrefix,
          hsnCode,
          invoiceDescription,
          masterDistributorsCommission,
          distributorsCommission,
          retailersCommission,
          apiUsersCommission,
          upiMinimumAmount,
          privacyPolicy,
          termsAndConditions,
          aboutUs,
        });
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      appName,
      appLogo,
      appFavicon,
      homeBanner,
      appPhoneNumber,
      appEmailAddress,
      firmName,
      gstNumber,
      firmAddress,
      invoicePrefix,
      hsnCode,
      invoiceDescription,
      appBackgroundColor,
      appPrimaryColor,
      appSecondaryColor,
      appTertiaryColor,
      appTableHeaderColor,
      masterDistributorsCommission,
      distributorsCommission,
      retailersCommission,
      apiUsersCommission,
      upiMinimumAmount,
      routeStage,
      oneTimePassword: oneTimePassword ? oneTimePassword.join('') : '',
      requestToken,
      privacyPolicy,
      termsAndConditions,
      aboutUs,
    };
    API.put(`/configurations/meta-details`, formData)
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          if (routeStage === 'Request') {
            setRouteStage('OTPVerification');
            setRequestToken(token);
            setOriginalFormData(formData);
          } else {
            setRouteStage('Request');
            setRequestToken('');
            setOneTimePassword('');
            setTimeout(() => {
              resetResponseData();
            }, 3000);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const { authId } = authData.get();
    const formData = {
      requestType: 'APP_META_DATA',
      mobileNumber: authId,
      requestToken
    };
    API.post('/auth/resend-otp', formData)
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setRequestToken(token);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset forLegend>
            <Legend>Firm Details</Legend>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Firm Name</Label>
                  <TextInput
                    value={firmName}
                    onChange={setFirmName}
                    placeholder='Please enter firm name'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>GST Number</Label>
                  <TextInput
                    value={gstNumber}
                    onChange={setGstNumber}
                    placeholder='Please enter gst number'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset>
              <Label>Firm Address</Label>
              <TextInput
                value={firmAddress}
                onChange={setFirmAddress}
                placeholder='Please enter firm address'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Invoice Prefix</Label>
                  <TextInput
                    value={invoicePrefix}
                    onChange={setInvoicePrefix}
                    placeholder='Please enter invoice prefix'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>HSN Code</Label>
                  <TextInput
                    value={hsnCode}
                    onChange={setHsnCode}
                    placeholder='Please enter hsn code'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset>
              <Label>Invoice Description</Label>
              <TextInput
                value={invoiceDescription}
                onChange={setInvoiceDescription}
                placeholder='Please enter invoice description'
                disabled={isLoading === true}
              />
            </Fieldset>
          </Fieldset>
          <Fieldset forLegend>
            <Legend>Support Details</Legend>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Phone Number</Label>
                  <TextInput
                    value={appPhoneNumber}
                    onChange={setAppPhoneNumber}
                    placeholder='Please enter phone number'
                    disabled={
                      routeStage === 'OTPVerification' || isLoading === true
                    }
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Email Address</Label>
                  <TextInput
                    value={appEmailAddress}
                    onChange={setAppEmailAddress}
                    placeholder='Please enter email address'
                    disabled={
                      routeStage === 'OTPVerification' || isLoading === true
                    }
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {routeStage === 'OTPVerification' && (
            <Grid>
              <GridItem>
                <StyledPara>
                  Please enter your OTP sent to your mobile number
                </StyledPara>
                <Fieldset>
                  <OTPInput
                    values={oneTimePassword}
                    onChange={setOneTimePassword}
                    length={6}
                    alignInputs='center'
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          )}
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              {routeStage === 'OTPVerification' ? 'Verify & Update' : 'Update'}
            </Button>
            {routeStage === 'OTPVerification' && (
              <StyledSpanAsButton onClick={onClickResendOTP}>
                Resend OTP
              </StyledSpanAsButton>
            )}
            {routeStage === 'Request' && (
              <ResetButton
                disabled={isLoading === true}
                type='button'
                onClick={resetForm}
              >
                Reset
              </ResetButton>
            )}
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(CompanyDetails);
