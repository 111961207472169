import React, { useState, useEffect } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  FileInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl } from '../../../utils';

const ServicesCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [serviceIcon, setServiceIcon] = useState([]);
  const [serviceIconBase64, setServiceIconBase64] = useState('');
  const [surcharge, setSurcharge] = useState('');
  const [defaultMasterDistributorsShare, setDefaultMasterDistributorsShare] =
    useState('');
  const [defaultDistributorsShare, setDefaultDistributorsShare] = useState('');
  const [defaultRetailersShare, setDefaultRetailersShare] = useState('');
  const [defaultApiUsersShare, setDefaultApiUsersShare] = useState('');
  const [serviceStatus, setServiceStatus] = useState('');
  const [enablePlanApi, setEnablePlanApi] = useState('');
  const [enableBillFetchApi, setEnableBillFetchApi] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setServiceName('');
    setServiceIcon([]);
    setSurcharge('');
    setDefaultMasterDistributorsShare('');
    setDefaultDistributorsShare('');
    setDefaultRetailersShare('');
    setDefaultApiUsersShare('');
    setServiceStatus('');
    setEnablePlanApi('');
    setEnableBillFetchApi('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      serviceName: serviceName,
      serviceIcon: serviceIconBase64,
      surcharge: surcharge,
      defaultMasterDistributorsShare: defaultMasterDistributorsShare,
      defaultDistributorsShare: defaultDistributorsShare,
      defaultRetailersShare: defaultRetailersShare,
      defaultApiUsersShare: defaultApiUsersShare,
      serviceStatus: serviceStatus,
      enablePlanApi: enablePlanApi,
      enableBillFetchApi: enableBillFetchApi,
    };
    API.post('/recharge-configurations/services', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (serviceIcon?.length > 0) {
      getBase64(serviceIcon[0], setServiceIconBase64);
    }
  }, [serviceIcon]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Service Name</Label>
            <TextInput
              value={serviceName}
              onChange={setServiceName}
              placeholder='Please enter service name'
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Service Icon</Label>
            <FileInput
              files={serviceIcon}
              onChange={setServiceIcon}
              accept='image/*'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Surcharge</Label>
                <NumberInput
                  value={surcharge}
                  onChange={setSurcharge}
                  placeholder='Please enter surcharge'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Master Distributors Commission</Label>
                <NumberInput
                  value={defaultMasterDistributorsShare}
                  onChange={setDefaultMasterDistributorsShare}
                  placeholder='Please enter master distributors commission'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Distributors Commission</Label>
                <NumberInput
                  value={defaultDistributorsShare}
                  onChange={setDefaultDistributorsShare}
                  placeholder='Please enter distributors commission'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Retailers Commission</Label>
                <NumberInput
                  value={defaultRetailersShare}
                  onChange={setDefaultRetailersShare}
                  placeholder='Please enter retailers commission'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>API Users Commission</Label>
                <NumberInput
                  value={defaultApiUsersShare}
                  onChange={setDefaultApiUsersShare}
                  placeholder='Please enter api users commission'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Enable Plan API</Label>
                <Dropdown
                  placeholder='Please select plan api status'
                  value={enablePlanApi}
                  onChange={setEnablePlanApi}
                  options={[
                    {
                      title: '-- SELECT PLAN API STATUS --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Enable Bill Fetch API</Label>
                <Dropdown
                  placeholder='Please select bill fetch api status'
                  value={enableBillFetchApi}
                  onChange={setEnableBillFetchApi}
                  options={[
                    {
                      title: '-- SELECT BILL FETCH API STATUS --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Service Status</Label>
                <Dropdown
                  placeholder='Please select service status'
                  value={serviceStatus}
                  onChange={setServiceStatus}
                  options={[
                    {
                      title: '-- SELECT SERVICE STATUS --',
                      value: '',
                    },
                    {
                      title: 'Active',
                      value: 'Active',
                    },
                    {
                      title: 'Inactive',
                      value: 'Inactive',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type='submit'>
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(ServicesCreate);
