import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faPhoneSquare,
  faSquareEnvelope,
} from '@fortawesome/free-solid-svg-icons';

const StyledSupportSectionContainer = styled.section`
  width: 55px;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #e5e5e5;
  position: relative;
  @media (max-width: 600px) {
    position: static;
  }
`;

const StyledSupportIconContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 100%;
    border-width: 0px;
    background-color: transparent;
    outline: 0px;
    cursor: pointer;
    transition: background-color 0.5s;
    &:hover {
      background-color: #e5e5e5;
    }
    &.active {
      background-color: ${({
        theme: {
          colors: { secondaryColor },
        },
      }) => (secondaryColor ? secondaryColor : `#0078ce`)};
      svg {
        color: #fff;
      }
    }
    svg {
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      color: #707070;
    }
  }
`;

const StyledSupportContent = styled.section`
  position: absolute;
  width: 280px;
  height: auto;
  background-color: #fff;
  top: 55px;
  right: 0px;
  border-radius: 0px;
  box-shadow: 0px 5px 15px -10px #000000;
  z-index: 1;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledSupportContentHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  background-color: ${({
    theme: {
      colors: { secondaryColor },
    },
  }) => (secondaryColor ? secondaryColor : `#0078ce`)};
  h3 {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    margin: 0px;
    padding: 12px;
    text-align: left;
  }
`;

const StyledSupportContentLinks = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      margin: 0px;
      padding: 0px;
      display: block;
      border-bottom: 1px solid #e5e5e5;
      transition: background-color 0.5s;
      a {
        font-size: 14px;
        text-decoration: none;
        outline: 0px;
        color: #000;
        margin: 0px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          font-size: 25px;
          color: ${({
            theme: {
              colors: { primaryColor },
            },
          }) => (primaryColor ? primaryColor : `#0095ff`)};
          margin-right: 12px;
        }
      }
      &.active,
      &:hover {
        background-color: #e5e5e5;
      }
    }
    &:last-child {
      border-bottom-width: 0px;
    }
  }
`;

const SupportSection = ({ supportMobileNumber, supportEmailAddress }) => {
  const [expandSupport, setExpandSupport] = useState(false);
  const supportRef = useRef(null);

  const onClickExpandSupport = (e) => {
    e.preventDefault();
    setExpandSupport((expandSupport) => !expandSupport);
  };

  const closeSupportList = useCallback((e) => {
    if (supportRef.current && !supportRef.current.contains(e.target)) {
      setExpandSupport(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeSupportList, true);
    return () => {
      document.removeEventListener('click', closeSupportList, true);
    };
  }, [closeSupportList]);

  return (
    <StyledSupportSectionContainer ref={supportRef}>
      <StyledSupportIconContainer>
        <button
          type="button"
          className={expandSupport ? `active` : ``}
          onClick={onClickExpandSupport}
        >
          <FontAwesomeIcon icon={faHeadset} />
        </button>
      </StyledSupportIconContainer>
      {expandSupport && (
        <StyledSupportContent>
          <StyledSupportContentHeader>
            <h3>Contact Support</h3>
          </StyledSupportContentHeader>
          <StyledSupportContentLinks>
            <ul>
              {supportMobileNumber && (
                <li>
                  <a
                    href={`tel:${supportMobileNumber}`}
                    title="Support Mobile Number"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faPhoneSquare} /> +91{' '}
                    {supportMobileNumber}
                  </a>
                </li>
              )}
              {supportEmailAddress && (
                <li>
                  <a
                    href={`mailto:${supportEmailAddress}`}
                    title="Support Email Address"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faSquareEnvelope} />{' '}
                    {supportEmailAddress}
                  </a>
                </li>
              )}
            </ul>
          </StyledSupportContentLinks>
        </StyledSupportContent>
      )}
    </StyledSupportSectionContainer>
  );
};

export default SupportSection;
