import React, { useState } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Legend,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../../components/FormElements';
import { StyledMicroButton } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';

const APIProvidersCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [providerName, setProviderName] = useState('');
  const [providerNumber, setProviderNumber] = useState('');
  const [providerIPAddress, setProviderIPAddress] = useState('');
  const [apiEndpoint, setApiEndpoint] = useState('');
  const [apiRequestMethod, setApiRequestMethod] = useState('');
  const [apiRequestBody, setApiRequestBody] = useState('');
  const [apiResponseType, setApiResponseType] = useState('');
  const [statusKey, setStatusKey] = useState('');
  const [successKey, setSuccessKey] = useState('');
  const [failureKey, setFailureKey] = useState('');
  const [transactionIdKey, setTransactionIdKey] = useState('');
  const [operatorsIdKey, setOperatorsIdKey] = useState('');
  const [balanceKey, setBalanceKey] = useState('');
  const [messageKey, setMessageKey] = useState('');
  const [lapuNumberKey, setLapuNumberKey] = useState('');
  const [rOfferKey, setRofferKey] = useState('');
  const [canCheckStatus, setCanCheckStatus] = useState('No');
  const [checkStatusApiEndpoint, setCheckStatusApiEndpoint] = useState('');
  const [checkStatusApiRequestMethod, setCheckStatusApiRequestMethod] =
    useState('');
  const [checkStatusApiRequestBody, setCheckStatusApiRequestBody] =
    useState('');
  const [checkStatusApiResponseType, setCheckStatusApiResponseType] =
    useState('');
  const [checkStatusStatusKey, setCheckStatusStatusKey] = useState('');
  const [checkStatusSuccessKey, setCheckStatusSuccessKey] = useState('');
  const [checkStatusFailureKey, setCheckStatusFailureKey] = useState('');
  const [checkStatusOperatorsIdKey, setCheckStatusOperatorsIdKey] =
    useState('');
  const [canBalanceCheck, setCanBalanceCheck] = useState('No');
  const [balanceCheckApiEndpoint, setBalanceCheckApiEndpoint] = useState('');
  const [balanceCheckApiRequestMethod, setBalanceCheckApiRequestMethod] =
    useState('');
  const [balanceCheckApiRequestBody, setBalanceCheckApiRequestBody] =
    useState('');
  const [balanceCheckApiResponseType, setBalanceCheckApiResponseType] =
    useState('');
  const [balanceCheckBalanceKey, setBalanceCheckBalanceKey] = useState('');
  const [canDisputeCheck, setCanDisputeCheck] = useState('No');
  const [disputeApiEndpoint, setDisputeApiEndpoint] = useState('');
  const [disputeApiRequestMethod, setDisputeApiRequestMethod] = useState('');
  const [disputeApiRequestBody, setDisputeApiRequestBody] = useState('');
  const [disputeApiResponseType, setDisputeApiResponseType] = useState('');
  const [callbackOurTransactionId, setCallbackOurTransactionId] = useState('');
  const [callbackApiTransactionId, setCallbackApiTransactionId] = useState('');
  const [callbackStatusKey, setCallbackStatusKey] = useState('');
  const [callbackSuccessKey, setCallbackSuccessKey] = useState('');
  const [callbackFailureKey, setCallbackFailureKey] = useState('');
  const [callbackRefundKey, setCallbackRefundKey] = useState('');
  const [callbackOperatorsId, setCallbackOperatorsId] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setProviderName('');
    setProviderNumber('');
    setProviderIPAddress('');
    setApiEndpoint('');
    setApiRequestMethod('');
    setApiRequestBody('');
    setApiResponseType('');
    setStatusKey('');
    setSuccessKey('');
    setFailureKey('');
    setTransactionIdKey('');
    setOperatorsIdKey('');
    setBalanceKey('');
    setMessageKey('');
    setLapuNumberKey('');
    setRofferKey('');
    setCanCheckStatus('No');
    setCheckStatusApiEndpoint('');
    setCheckStatusApiRequestMethod('');
    setCheckStatusApiRequestBody('');
    setCheckStatusApiResponseType('');
    setCheckStatusStatusKey('');
    setCheckStatusSuccessKey('');
    setCheckStatusFailureKey('');
    setCheckStatusOperatorsIdKey('');
    setCanBalanceCheck('No');
    setBalanceCheckApiEndpoint('');
    setBalanceCheckApiRequestMethod('');
    setBalanceCheckApiRequestBody('');
    setBalanceCheckApiResponseType('');
    setBalanceCheckBalanceKey('');
    setCanDisputeCheck('No');
    setDisputeApiEndpoint('');
    setDisputeApiRequestMethod('');
    setDisputeApiRequestBody('');
    setDisputeApiResponseType('');
    setCallbackOurTransactionId('');
    setCallbackApiTransactionId('');
    setCallbackStatusKey('');
    setCallbackSuccessKey('');
    setCallbackFailureKey('');
    setCallbackRefundKey('');
    setCallbackOperatorsId('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      providerName: providerName,
      providerNumber: providerNumber,
      providerIPAddress: providerIPAddress,
      apiEndpoint: apiEndpoint,
      apiRequestMethod: apiRequestMethod,
      apiRequestBody: apiRequestBody,
      apiResponseType: apiResponseType,
      statusKey: statusKey,
      successKey: successKey,
      failureKey: failureKey,
      transactionIdKey: transactionIdKey,
      operatorsIdKey: operatorsIdKey,
      balanceKey: balanceKey,
      messageKey: messageKey,
      lapuNumberKey: lapuNumberKey,
      rOfferKey: rOfferKey,
      canCheckStatus: canCheckStatus,
      checkStatusApiEndpoint: checkStatusApiEndpoint,
      checkStatusApiRequestMethod: checkStatusApiRequestMethod,
      checkStatusApiRequestBody: checkStatusApiRequestBody,
      checkStatusApiResponseType: checkStatusApiResponseType,
      checkStatusStatusKey: checkStatusStatusKey,
      checkStatusSuccessKey: checkStatusSuccessKey,
      checkStatusFailureKey: checkStatusFailureKey,
      checkStatusOperatorsIdKey: checkStatusOperatorsIdKey,
      canBalanceCheck: canBalanceCheck,
      balanceCheckApiEndpoint: balanceCheckApiEndpoint,
      balanceCheckApiRequestMethod: balanceCheckApiRequestMethod,
      balanceCheckApiRequestBody: balanceCheckApiRequestBody,
      balanceCheckApiResponseType: balanceCheckApiResponseType,
      balanceCheckBalanceKey: balanceCheckBalanceKey,
      canDisputeCheck: canDisputeCheck,
      disputeApiEndpoint: disputeApiEndpoint,
      disputeApiRequestMethod: disputeApiRequestMethod,
      disputeApiRequestBody: disputeApiRequestBody,
      disputeApiResponseType: disputeApiResponseType,
      callbackOurTransactionId: callbackOurTransactionId,
      callbackApiTransactionId: callbackApiTransactionId,
      callbackStatusKey: callbackStatusKey,
      callbackSuccessKey: callbackSuccessKey,
      callbackFailureKey: callbackFailureKey,
      callbackRefundKey: callbackRefundKey,
      callbackOperatorsId: callbackOperatorsId,
    };
    API.post('/recharge-configurations/api-providers', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Provider Name</Label>
                <TextInput
                  value={providerName}
                  onChange={setProviderName}
                  placeholder='Please enter provider name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Provider Number</Label>
                <NumberInput
                  value={providerNumber}
                  onChange={setProviderNumber}
                  placeholder='Please enter provider number'
                  maxLength={10}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>IP Address</Label>
                <NumberInput
                  value={providerIPAddress}
                  onChange={setProviderIPAddress}
                  placeholder='Please enter provider ip address'
                  maxLength={15}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>API Endpoint</Label>
            <TextInput
              value={apiEndpoint}
              onChange={setApiEndpoint}
              placeholder='Please enter api endpoint'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label required>API Request Body</Label>
            <TextInput
              value={apiRequestBody}
              onChange={setApiRequestBody}
              placeholder='Please enter api request body'
              disabled={isLoading === true}
            />
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{NUMBER}}}')}
            >
              Mobile/Account Number
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{OPERATOR}}}')}
            >
              Operator
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{AMOUNT}}}')}
            >
              Amount
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{TRANSACTION_ID}}}')}
            >
              Transaction Id
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{ISSTV}}}')}
            >
              Is STV
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{CIRCLE}}}')}
            >
              Circle
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{FIELD1}}}')}
            >
              Field 1
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{FIELD2}}}')}
            >
              Field 2
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{FIELD3}}}')}
            >
              Field 3
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setApiRequestBody, '{{{FIELD4}}}')}
            >
              Field 4
            </StyledMicroButton>
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>API Request Method</Label>
                <Dropdown
                  value={apiRequestMethod}
                  onChange={setApiRequestMethod}
                  placeholder='Please select api request method'
                  disabled={isLoading === true}
                  options={[
                    {
                      title: '-- SELECT API REQUEST METHOD --',
                      value: '',
                    },
                    {
                      title: 'POST',
                      value: 'POST',
                    },
                    {
                      title: 'GET',
                      value: 'GET',
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>API Response Type</Label>
                <Dropdown
                  value={apiResponseType}
                  onChange={setApiResponseType}
                  placeholder='Please select api response type'
                  disabled={isLoading === true}
                  options={[
                    {
                      title: '-- SELECT API RESPONSE TYPE --',
                      value: '',
                    },
                    {
                      title: 'JSON',
                      value: 'JSON',
                    },
                    {
                      title: 'XML',
                      value: 'XML',
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Status Key</Label>
                <TextInput
                  value={statusKey}
                  onChange={setStatusKey}
                  placeholder='Please enter status key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Success Key</Label>
                <TextInput
                  value={successKey}
                  onChange={setSuccessKey}
                  placeholder='Please enter success key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Failure Key</Label>
                <TextInput
                  value={failureKey}
                  onChange={setFailureKey}
                  placeholder='Please enter failure key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Transaction ID Key</Label>
                <TextInput
                  value={transactionIdKey}
                  onChange={setTransactionIdKey}
                  placeholder='Please enter transaction id key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Operators ID Key</Label>
                <TextInput
                  value={operatorsIdKey}
                  onChange={setOperatorsIdKey}
                  placeholder='Please enter operators id key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Balance Key</Label>
                <TextInput
                  value={balanceKey}
                  onChange={setBalanceKey}
                  placeholder='Please enter balance key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Message Key</Label>
                <TextInput
                  value={messageKey}
                  onChange={setMessageKey}
                  placeholder='Please enter message key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Lapu Number Key</Label>
                <TextInput
                  value={lapuNumberKey}
                  onChange={setLapuNumberKey}
                  placeholder='Please enter lapu number key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>rOffer Key</Label>
                <TextInput
                  value={rOfferKey}
                  onChange={setRofferKey}
                  placeholder='Please enter rOffer key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Can Check Status?</Label>
            <Dropdown
              value={canCheckStatus}
              onChange={setCanCheckStatus}
              placeholder='Please select if status can be checked via api'
              disabled={isLoading === true}
              options={[
                {
                  title: 'Yes',
                  value: 'Yes',
                },
                {
                  title: 'No',
                  value: 'No',
                },
              ]}
            />
          </Fieldset>
          {canCheckStatus === 'Yes' && (
            <Fieldset forLegend>
              <Legend>Check Status API Configuration</Legend>
              <Fieldset>
                <Label required>Check Status API Endpoint</Label>
                <TextInput
                  value={checkStatusApiEndpoint}
                  onChange={setCheckStatusApiEndpoint}
                  placeholder='Please enter check status api endpoint'
                  disabled={isLoading === true}
                />
              </Fieldset>
              <Fieldset>
                <Label required>Check Status API Request Body</Label>
                <TextInput
                  value={checkStatusApiRequestBody}
                  onChange={setCheckStatusApiRequestBody}
                  placeholder='Please enter check status api request body'
                  disabled={isLoading === true}
                />
                <StyledMicroButton
                  disabled={isLoading === true}
                  type='button'
                  onClick={() =>
                    addText(
                      setCheckStatusApiRequestBody,
                      '{{{TRANSACTION_ID}}}'
                    )
                  }
                >
                  Transaction Id
                </StyledMicroButton>
                <StyledMicroButton
                  disabled={isLoading === true}
                  type='button'
                  onClick={() =>
                    addText(
                      setCheckStatusApiRequestBody,
                      '{{{API_TRANSACTION_ID}}}'
                    )
                  }
                >
                  API Transaction Id
                </StyledMicroButton>
              </Fieldset>
              <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                <GridItem>
                  <Fieldset>
                    <Label required>Check Status API Request Method</Label>
                    <Dropdown
                      value={checkStatusApiRequestMethod}
                      onChange={setCheckStatusApiRequestMethod}
                      placeholder='Please select check status api request method'
                      disabled={isLoading === true}
                      options={[
                        {
                          title: '-- SELECT CHECK STATUS API REQUEST METHOD --',
                          value: '',
                        },
                        {
                          title: 'POST',
                          value: 'POST',
                        },
                        {
                          title: 'GET',
                          value: 'GET',
                        },
                      ]}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <Label required>Check Status API Response Type</Label>
                    <Dropdown
                      value={checkStatusApiResponseType}
                      onChange={setCheckStatusApiResponseType}
                      placeholder='Please select check status api response type'
                      disabled={isLoading === true}
                      options={[
                        {
                          title: '-- SELECT CHECK STATUS API RESPONSE TYPE --',
                          value: '',
                        },
                        {
                          title: 'JSON',
                          value: 'JSON',
                        },
                        {
                          title: 'XML',
                          value: 'XML',
                        },
                      ]}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
              <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                <GridItem>
                  <Fieldset>
                    <Label required>Check Status Status Key</Label>
                    <TextInput
                      value={checkStatusStatusKey}
                      onChange={setCheckStatusStatusKey}
                      placeholder='Please enter check status status key'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <Label required>Check Status Operators ID Key</Label>
                    <TextInput
                      value={checkStatusOperatorsIdKey}
                      onChange={setCheckStatusOperatorsIdKey}
                      placeholder='Please enter check status operators id key'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
              <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                <GridItem>
                  <Fieldset>
                    <Label required>Check Status Success Key</Label>
                    <TextInput
                      value={checkStatusSuccessKey}
                      onChange={setCheckStatusSuccessKey}
                      placeholder='Please enter check status success key'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <Label required>Check Status Failure Key</Label>
                    <TextInput
                      value={checkStatusFailureKey}
                      onChange={setCheckStatusFailureKey}
                      placeholder='Please enter check status failure key'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
            </Fieldset>
          )}
          <Fieldset>
            <Label required>Can Check Balance?</Label>
            <Dropdown
              value={canBalanceCheck}
              onChange={setCanBalanceCheck}
              placeholder='Please select if balance can be checked via api'
              disabled={isLoading === true}
              options={[
                {
                  title: 'Yes',
                  value: 'Yes',
                },
                {
                  title: 'No',
                  value: 'No',
                },
              ]}
            />
          </Fieldset>
          {canBalanceCheck === 'Yes' && (
            <Fieldset forLegend>
              <Legend>Balance Check API Configuration</Legend>
              <Fieldset>
                <Label required>Balance Check API Endpoint</Label>
                <TextInput
                  value={balanceCheckApiEndpoint}
                  onChange={setBalanceCheckApiEndpoint}
                  placeholder='Please enter balance check api endpoint'
                  disabled={isLoading === true}
                />
              </Fieldset>
              <Fieldset>
                <Label required>Balance Check API Request Body</Label>
                <TextInput
                  value={balanceCheckApiRequestBody}
                  onChange={setBalanceCheckApiRequestBody}
                  placeholder='Please enter balance check api request body'
                  disabled={isLoading === true}
                />
              </Fieldset>
              <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                <GridItem>
                  <Fieldset>
                    <Label required>Balance Check API Request Method</Label>
                    <Dropdown
                      value={balanceCheckApiRequestMethod}
                      onChange={setBalanceCheckApiRequestMethod}
                      placeholder='Please select balance check api request method'
                      disabled={isLoading === true}
                      options={[
                        {
                          title:
                            '-- SELECT BALANCE CHECK API REQUEST METHOD --',
                          value: '',
                        },
                        {
                          title: 'POST',
                          value: 'POST',
                        },
                        {
                          title: 'GET',
                          value: 'GET',
                        },
                      ]}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <Label required>Balance Check API Response Type</Label>
                    <Dropdown
                      value={balanceCheckApiResponseType}
                      onChange={setBalanceCheckApiResponseType}
                      placeholder='Please select balance check api response type'
                      disabled={isLoading === true}
                      options={[
                        {
                          title: '-- SELECT BALANCE CHECK API RESPONSE TYPE --',
                          value: '',
                        },
                        {
                          title: 'JSON',
                          value: 'JSON',
                        },
                        {
                          title: 'XML',
                          value: 'XML',
                        },
                      ]}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
              <Fieldset>
                <Label required>Balance Check Balance Key</Label>
                <TextInput
                  value={balanceCheckBalanceKey}
                  onChange={setBalanceCheckBalanceKey}
                  placeholder='Please enter balance check balance key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </Fieldset>
          )}
          <Fieldset>
            <Label required>Can Dispute Check?</Label>
            <Dropdown
              value={canDisputeCheck}
              onChange={setCanDisputeCheck}
              placeholder='Please select if dispute can be checked via api'
              disabled={isLoading === true}
              options={[
                {
                  title: 'Yes',
                  value: 'Yes',
                },
                {
                  title: 'No',
                  value: 'No',
                },
              ]}
            />
          </Fieldset>
          {canDisputeCheck === 'Yes' && (
            <Fieldset forLegend>
              <Legend>Dispute Check API Configuration</Legend>
              <Fieldset>
                <Label required>Dispute Check API Endpoint</Label>
                <TextInput
                  value={disputeApiEndpoint}
                  onChange={setDisputeApiEndpoint}
                  placeholder='Please enter dispute check api endpoint'
                  disabled={isLoading === true}
                />
              </Fieldset>
              <Fieldset>
                <Label required>Dispute Check API Request Body</Label>
                <TextInput
                  value={disputeApiRequestBody}
                  onChange={setDisputeApiRequestBody}
                  placeholder='Please enter dispute check api request body'
                  disabled={isLoading === true}
                />
                <StyledMicroButton
                  disabled={isLoading === true}
                  type='button'
                  onClick={() =>
                    addText(setDisputeApiRequestBody, '{{{TRANSACTION_ID}}}')
                  }
                >
                  Transaction Id
                </StyledMicroButton>
                <StyledMicroButton
                  disabled={isLoading === true}
                  type='button'
                  onClick={() =>
                    addText(
                      setDisputeApiRequestBody,
                      '{{{API_TRANSACTION_ID}}}'
                    )
                  }
                >
                  API Transaction Id
                </StyledMicroButton>
              </Fieldset>
              <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                <GridItem>
                  <Fieldset>
                    <Label required>Dispute Check API Request Method</Label>
                    <Dropdown
                      value={disputeApiRequestMethod}
                      onChange={setDisputeApiRequestMethod}
                      placeholder='Please select dispute check api request method'
                      disabled={isLoading === true}
                      options={[
                        {
                          title:
                            '-- SELECT DISPUTE CHECK API REQUEST METHOD --',
                          value: '',
                        },
                        {
                          title: 'POST',
                          value: 'POST',
                        },
                        {
                          title: 'GET',
                          value: 'GET',
                        },
                      ]}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <Label required>Dispute Check API Response Type</Label>
                    <Dropdown
                      value={disputeApiResponseType}
                      onChange={setDisputeApiResponseType}
                      placeholder='Please select dispute check api response type'
                      disabled={isLoading === true}
                      options={[
                        {
                          title: '-- SELECT DISPUTE CHECK API RESPONSE TYPE --',
                          value: '',
                        },
                        {
                          title: 'JSON',
                          value: 'JSON',
                        },
                        {
                          title: 'XML',
                          value: 'XML',
                        },
                      ]}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
            </Fieldset>
          )}
          <Fieldset forLegend>
            <Legend>Callback Configuration</Legend>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <Fieldset>
                <Label>Status Key</Label>
                <TextInput
                  value={callbackStatusKey}
                  onChange={setCallbackStatusKey}
                  placeholder='Please enter status key'
                  disabled={isLoading === true}
                />
              </Fieldset>
              <Fieldset>
                <Label>Success Key</Label>
                <TextInput
                  value={callbackSuccessKey}
                  onChange={setCallbackSuccessKey}
                  placeholder='Please enter success key'
                  disabled={isLoading === true}
                />
              </Fieldset>
              <Fieldset>
                <Label>Failure Key</Label>
                <TextInput
                  value={callbackFailureKey}
                  onChange={setCallbackFailureKey}
                  placeholder='Please enter failure key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </Grid>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <Fieldset>
                <Label>Our Transaction Id Key</Label>
                <TextInput
                  value={callbackOurTransactionId}
                  onChange={setCallbackOurTransactionId}
                  placeholder='Please enter our transaction id key'
                  disabled={isLoading === true}
                />
              </Fieldset>
              <Fieldset>
                <Label>API Transaction Id Key</Label>
                <TextInput
                  value={callbackApiTransactionId}
                  onChange={setCallbackApiTransactionId}
                  placeholder='Please enter api transaction id key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </Grid>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <Fieldset>
                <Label>Refund Key</Label>
                <TextInput
                  value={callbackRefundKey}
                  onChange={setCallbackRefundKey}
                  placeholder='Please enter refund key'
                  disabled={isLoading === true}
                />
              </Fieldset>
              <Fieldset>
                <Label>Operators ID Key</Label>
                <TextInput
                  value={callbackOperatorsId}
                  onChange={setCallbackOperatorsId}
                  placeholder='Please enter operators id key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </Grid>
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type='submit'>
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(APIProvidersCreate);
