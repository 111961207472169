import React, { useState, useEffect } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  FileInput,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import {
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
  ACCOUNT_TYPES,
} from '../../../constants';
import { authData, getBase64, getReturnUrl } from '../../../utils';

const BankAccountsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [type, setType] = useState('');
  const [payeeName, setPayeeName] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankLogo, setBankLogo] = useState([]);
  const [bankLogoBase64, setBankLogoBase64] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [isDefaultUpi, setIsDefaultUpi] = useState('No');
  const { accountType } = authData.get();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setType('');
    setPayeeName('');
    setBankName('');
    setBankLogo('');
    setBankLogoBase64('');
    setAccountNumber('');
    setIfscCode('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      type,
      bankName,
      bankLogo: bankLogoBase64,
      payeeName,
      accountNumber,
      ifscCode,
      isDefaultUpi,
    };
    API.post('/configurations/bank-accounts', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (bankLogo?.length > 0) {
      getBase64(bankLogo[0], setBankLogoBase64);
    }
  }, [bankLogo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Account Type</Label>
            <Dropdown
              placeholder='Please select account type'
              value={type}
              onChange={setType}
              options={[
                {
                  title: '-- SELECT ACCOUNT TYPE --',
                  value: '',
                },
                {
                  title: 'Wiretransfer',
                  value: 'Wiretransfer',
                },
                {
                  title: 'UPI',
                  value: 'UPI',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Payee Name</Label>
                <TextInput
                  value={payeeName}
                  onChange={setPayeeName}
                  placeholder='Please enter payee name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Bank Name</Label>
                <TextInput
                  value={bankName}
                  onChange={setBankName}
                  placeholder='Please enter bank name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Account Number / UPI</Label>
                <TextInput
                  value={accountNumber}
                  onChange={setAccountNumber}
                  placeholder='Please enter account number or upi id'
                  maxLength={200}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required={type === 'UPI' ? false : true}>
                  IFSC Code
                </Label>
                <TextInput
                  value={ifscCode}
                  onChange={setIfscCode}
                  placeholder='Please enter ifsc code'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Bank Logo</Label>
            <FileInput
              files={bankLogo}
              onChange={setBankLogo}
              accept='image/*'
              disabled={isLoading === true}
            />
          </Fieldset>
          {type === 'UPI' &&
            ![
              ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
              ACCOUNT_TYPES.DISTRIBUTORS,
            ].includes(accountType) && (
              <Fieldset>
                <Label required>Is Default UPI ?</Label>
                <Dropdown
                  placeholder='Please select if the upi id is default'
                  value={isDefaultUpi}
                  onChange={setIsDefaultUpi}
                  options={[
                    {
                      title: '-- SELECT IF UPI ID IS DEFAULT --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            )}
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type='submit'>
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(BankAccountsCreate);
