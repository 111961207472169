import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import Modal from '../../../components/Modal';
import { Image } from '../../../components/Image';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  FileInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import {
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
  ACCOUNT_TYPES,
} from '../../../constants';
import {
  authData,
  getBase64,
  camelCaseToString,
  getReturnUrl,
} from '../../../utils';
import { isEmpty } from 'lodash';

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const RolesAndPermissionsUpdate = (props) => {
  const { accountType, accountData } = authData.get();
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [defaultMarginTemplates, setDefaultMarginTemplates] = useState([]);
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [marginTemplates, setMarginTemplates] = useState([]);
  const [roles, setRoles] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [whatsappMobileNumber, setWhatsappMobileNumber] = useState('');
  const [telegramId, setTelegramId] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [photo, setPhoto] = useState([]);
  const [photoBase64, setPhotoBase64] = useState('');
  const [address, setAddress] = useState('');
  const [isEntity, setIsEntity] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityAddress, setEntityAddress] = useState('');
  const [marginTemplatesId, setMarginTemplatesId] = useState('');
  const [accountsRolesId, setAccountsRolesId] = useState('');
  const [minimumBalance, setMinimumBalance] = useState(0);
  const [panName, setPanName] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [panFile, setPanFile] = useState([]);
  const [panBase64, setPanBase64] = useState('');
  const [panVerified, setPanVerified] = useState(null);
  const [aadhaarName, setAadhaarName] = useState('');
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [aadhaarFile, setAadhaarFile] = useState([]);
  const [aadhaarBase64, setAadhaarBase64] = useState(null);
  const [aadhaarVerified, setAadhaarVerified] = useState('');
  const [accountsStatus, setAccountsStatus] = useState('');
  const [isGstInvoiceRequired, setIsGstInvoiceRequired] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const [createdBy, setCreatedBy] = useState(0);
  const [commissionMode, setCommissionMode] = useState(
    accountData.commissionMode ? accountData.commissionMode : 'Percentage'
  );
  const [commissionValue, setCommissionValue] = useState(0);
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      firstName,
      lastName,
      mobileNumber,
      whatsappMobileNumber,
      telegramId,
      emailAddress,
      gender,
      dateOfBirth,
      photo,
      address,
      isEntity,
      entityName,
      entityAddress,
      marginTemplatesId,
      accountsRolesId,
      minimumBalance,
      panName,
      panNumber,
      panFile,
      panVerified,
      aadhaarName,
      aadhaarNumber,
      aadhaarFile,
      aadhaarVerified,
      accountsStatus,
      isGstInvoiceRequired,
      createdBy,
      commissionMode,
      commissionValue,
    } = originalFormData;
    setFirstName(firstName);
    setLastName(lastName);
    setMobileNumber(mobileNumber);
    setWhatsappMobileNumber(whatsappMobileNumber);
    setTelegramId(telegramId);
    setEmailAddress(emailAddress);
    setGender(gender);
    setDateOfBirth(dateOfBirth);
    setPhoto([]);
    setPhotoBase64(photo);
    setAddress(address);
    setIsEntity(isEntity);
    setEntityName(entityName);
    setEntityAddress(entityAddress);
    setMarginTemplatesId(marginTemplatesId);
    setAccountsRolesId(accountsRolesId);
    setMinimumBalance(minimumBalance);
    setPanName(panName);
    setPanNumber(panNumber);
    setPanFile([]);
    setPanBase64(panFile);
    setPanVerified(panVerified);
    setAadhaarName(aadhaarName);
    setAadhaarNumber(aadhaarNumber);
    setAadhaarFile([]);
    setAadhaarBase64(aadhaarFile);
    setAadhaarVerified(aadhaarVerified);
    setAccountsStatus(accountsStatus);
    setIsGstInvoiceRequired(isGstInvoiceRequired);
    setCreatedBy(createdBy);
    setCommissionMode(commissionMode);
    setCommissionValue(commissionValue);
  };

  const readMarginTemplates = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/margins/margin-templates')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setMarginTemplates(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readRoles = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/accounts-management/roles-and-permissions')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setRoles(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readAccounts = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/accounts-management/accounts/?files=No&allAccounts=Yes')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const filteredAccounts = data.filter(
            ({ roleName }) =>
              [
                ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
                ACCOUNT_TYPES.DISTRIBUTORS,
              ].includes(roleName) === true
          );
          setAccounts(filteredAccounts);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readDefaultMarginTemplates = () => {
    API.get('/margins/roles-default-margin-templates').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setDefaultMarginTemplates(data);
      }
    });
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounts-management/accounts/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            firstName,
            lastName,
            mobileNumber,
            whatsappMobileNumber,
            telegramId,
            emailAddress,
            gender,
            dateOfBirth,
            photo,
            address,
            isEntity,
            entityName,
            entityAddress,
            marginTemplatesId,
            accountsRolesId,
            minimumBalance,
            panName,
            panNumber,
            panFile,
            panVerified,
            aadhaarName,
            aadhaarNumber,
            aadhaarFile,
            aadhaarVerified,
            accountsStatus,
            isGstInvoiceRequired,
            createdBy,
            commissionMode,
            commissionValue,
          } = data[0];
          setFirstName(firstName);
          setLastName(lastName);
          setMobileNumber(mobileNumber);
          setWhatsappMobileNumber(whatsappMobileNumber);
          setTelegramId(telegramId);
          setEmailAddress(emailAddress);
          setGender(gender);
          setDateOfBirth(dateOfBirth);
          setPhoto([]);
          setPhotoBase64(photo);
          setAddress(address);
          setIsEntity(isEntity);
          setEntityName(entityName);
          setEntityAddress(entityAddress);
          setMarginTemplatesId(marginTemplatesId);
          setAccountsRolesId(accountsRolesId);
          setMinimumBalance(minimumBalance);
          setPanName(panName);
          setPanNumber(panNumber);
          setPanFile([]);
          setPanBase64(panFile);
          setPanVerified(panVerified);
          setAadhaarName(aadhaarName);
          setAadhaarNumber(aadhaarNumber);
          setAadhaarFile([]);
          setAadhaarBase64(aadhaarFile);
          setAadhaarVerified(aadhaarVerified);
          setAccountsStatus(accountsStatus);
          setIsGstInvoiceRequired(isGstInvoiceRequired);
          setCreatedBy(createdBy);
          setCommissionMode(commissionMode);
          setCommissionValue(commissionValue);
          setOriginalFormData({
            firstName,
            lastName,
            mobileNumber,
            whatsappMobileNumber,
            telegramId,
            emailAddress,
            gender,
            dateOfBirth,
            photo,
            address,
            isEntity,
            entityName,
            entityAddress,
            marginTemplatesId,
            accountsRolesId,
            minimumBalance,
            panName,
            panNumber,
            panFile,
            panVerified,
            aadhaarName,
            aadhaarNumber,
            aadhaarFile,
            aadhaarVerified,
            accountsStatus,
            isGstInvoiceRequired,
            createdBy,
            commissionMode,
            commissionValue,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      firstName,
      lastName,
      whatsappMobileNumber,
      telegramId,
      emailAddress,
      gender,
      dateOfBirth,
      photo: photoBase64,
      address,
      isEntity,
      entityName,
      entityAddress,
      marginTemplatesId,
      accountsRolesId,
      minimumBalance,
      panName,
      panNumber,
      panFile: panBase64,
      panVerified,
      aadhaarName,
      aadhaarNumber,
      aadhaarFile: aadhaarBase64,
      aadhaarVerified,
      accountsStatus,
      isGstInvoiceRequired,
      createdBy,
      commissionMode,
      commissionValue,
    };
    API.put(`/accounts-management/accounts/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (photo?.length > 0) {
      getBase64(photo[0], setPhotoBase64);
    }
    if (panFile?.length > 0) {
      getBase64(panFile[0], setPanBase64);
    }
    if (aadhaarFile?.length > 0) {
      getBase64(aadhaarFile[0], setAadhaarBase64);
    }
  }, [photo, panFile, aadhaarFile]);

  useEffect(() => {
    if (accountData.commissionMode === 'Percentage') {
      readMarginTemplates();
    }
    if (
      ![ACCOUNT_TYPES.MASTER_DISTRIBUTORS, ACCOUNT_TYPES.DISTRIBUTORS].includes(
        accountType
      )
    ) {
      readRoles();
      readAccounts();
    }
    readData();
  }, [accountType, readRoles, readMarginTemplates, readAccounts, readData]);

  useEffect(() => {
    readDefaultMarginTemplates();
  }, []);

  useEffect(() => {
    if (defaultMarginTemplates && defaultMarginTemplates?.length > 0) {
      const defaultTemplate = defaultMarginTemplates?.find(
        (item) => item.rolesId === accountsRolesId
      );
      if (defaultTemplate !== -1) {
        setMarginTemplatesId(defaultTemplate?.templatesId);
      }
    }
  }, [accountsRolesId]);

  const accountsOptions = [
    { title: '-- SELECT SUPERVISOR ACCOUNT --', value: 0 },
  ];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .forEach(({ id, isEntity, firstName, lastName, entityName, roleName }) =>
      accountsOptions.push({
        title:
          isEntity === 'Yes'
            ? `${entityName} (${camelCaseToString(roleName)})`
            : `${firstName} ${lastName} (${camelCaseToString(roleName)})`,
        value: id,
      })
    );

  const rolesOptions = [{ title: '-- SELECT ROLE --', value: '' }];

  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
    [
      {
        id: 3,
        roleName: ACCOUNT_TYPES.DISTRIBUTORS,
      },
      {
        id: 4,
        roleName: ACCOUNT_TYPES.RETAILERS,
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: camelCaseToString(roleName),
        value: id,
      })
    );
  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
    [
      {
        id: 4,
        roleName: ACCOUNT_TYPES.RETAILERS,
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: camelCaseToString(roleName),
        value: id,
      })
    );
  } else {
    if (createdBy !== 0 && accounts?.length > 0) {
      const findAccount = accounts?.find(({ id }) => id === createdBy);
      if (findAccount !== -1) {
        if (findAccount?.accountsRolesId === 2) {
          [
            {
              id: 3,
              roleName: ACCOUNT_TYPES.DISTRIBUTORS,
            },
            {
              id: 4,
              roleName: ACCOUNT_TYPES.RETAILERS,
            },
          ].forEach(({ id, roleName }) =>
            rolesOptions.push({
              title: camelCaseToString(roleName),
              value: id,
            })
          );
        } else if (findAccount?.accountsRolesId === 3) {
          [
            {
              id: 4,
              roleName: ACCOUNT_TYPES.RETAILERS,
            },
          ].forEach(({ id, roleName }) =>
            rolesOptions.push({
              title: camelCaseToString(roleName),
              value: id,
            })
          );
        }
      } else {
        roles.forEach(({ id, roleName }) =>
          rolesOptions.push({
            title: camelCaseToString(roleName),
            value: id,
          })
        );
      }
    } else {
      roles.forEach(({ id, roleName }) =>
        rolesOptions.push({
          title: camelCaseToString(roleName),
          value: id,
        })
      );
    }
  }

  const marginTemplatesOptions = [
    { title: '-- SELECT MARGIN TEMPLATE --', value: '' },
  ];
  marginTemplates.forEach(({ id, templateName }) =>
    marginTemplatesOptions.push({
      title: templateName,
      value: id,
    })
  );

  const businessRole = [2, 3, 4, 5].includes(accountsRolesId);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          {accountType === ACCOUNT_TYPES.ADMINS && (
            <Fieldset>
              <Label>Account Supervisor</Label>
              <Dropdown
                placeholder='Please select account supervisor'
                value={createdBy}
                onChange={(value) => {
                  const supervisorAccount = accounts.find(
                    (item) => item.id === value
                  );
                  if (
                    supervisorAccount !== -1 &&
                    supervisorAccount?.commissionMode
                  ) {
                    setCommissionMode(supervisorAccount.commissionMode);
                  }
                  setCreatedBy(value);
                }}
                options={accountsOptions}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          <Fieldset>
            <Label required>Role</Label>
            <Dropdown
              placeholder='Please select accounts role'
              value={accountsRolesId}
              onChange={setAccountsRolesId}
              options={rolesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  value={firstName}
                  onChange={setFirstName}
                  placeholder='Please enter first name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  value={lastName}
                  onChange={setLastName}
                  placeholder='Please enter last name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  placeholder='Please enter mobile number'
                  maxLength={10}
                  disabled={true}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Whatsapp Mobile Number</Label>
                <NumberInput
                  value={whatsappMobileNumber}
                  onChange={setWhatsappMobileNumber}
                  placeholder='Please enter whatsapp mobile number'
                  maxLength={10}
                  disabled={isLoading === true}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Telegram ID</Label>
                <TextInput
                  value={telegramId}
                  onChange={setTelegramId}
                  placeholder='Please enter telegram id'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Email Address</Label>
                <TextInput
                  value={emailAddress}
                  onChange={setEmailAddress}
                  placeholder='Please enter email address'
                  maxLength={200}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Gender</Label>
                <Dropdown
                  placeholder='Please select gender'
                  value={gender}
                  onChange={setGender}
                  options={[
                    {
                      title: '-- SELECT GENDER --',
                      value: '',
                    },
                    {
                      title: 'Male',
                      value: 'Male',
                    },
                    {
                      title: 'Female',
                      value: 'Female',
                    },
                    {
                      title: 'Others',
                      value: 'Others',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Date Of Birth</Label>
                <TextInput
                  type='date'
                  value={dateOfBirth}
                  onChange={setDateOfBirth}
                  placeholder='Please select date of birth'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Photo</Label>
            <FileInput
              files={photo}
              onChange={setPhoto}
              accept='image/*'
              disabled={isLoading === true}
            />
            {originalFormData?.photo && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowExistingImage(true);
                  setModalImage(originalFormData.photo);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>
          <Fieldset>
            <Label>Address</Label>
            <TextInput
              value={address}
              onChange={setAddress}
              placeholder='Please enter address'
              disabled={isLoading === true}
            />
          </Fieldset>
          {businessRole && (
            <>
              <Fieldset>
                <Label required>Is Entity?</Label>
                <Dropdown
                  placeholder='Please select if the account is linked to any entity'
                  value={isEntity}
                  onChange={setIsEntity}
                  options={[
                    {
                      title: '-- SELECT IF ACCOUNT IS LINKED TO ANY ENTITY --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
              {isEntity === 'Yes' && (
                <>
                  <Fieldset>
                    <Label required>Entity Name</Label>
                    <TextInput
                      value={entityName}
                      onChange={setEntityName}
                      placeholder='Please enter entity name'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label required>Entity Address</Label>
                    <TextInput
                      value={entityAddress}
                      onChange={setEntityAddress}
                      placeholder='Please enter entity address'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                </>
              )}
              <Grid
                columns={
                  'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
                }
              >
                <GridItem>
                  <Fieldset>
                    <Label required>Commission Mode</Label>
                    <Dropdown
                      placeholder='Please select commission mode'
                      value={commissionMode}
                      onChange={(value) => {
                        if (value === 'Percentage') {
                          setCommissionValue(0);
                          setMarginTemplatesId(
                            defaultMarginTemplates.find(
                              (item) => item.rolesId === accountsRolesId
                            )?.templatesId || ''
                          );
                        }
                        setCommissionMode(value);
                      }}
                      options={[
                        {
                          title: 'Percentage',
                          value: 'Percentage',
                        },
                        {
                          title: 'Flat',
                          value: 'Flat',
                        },
                      ]}
                      disabled={
                        isLoading ||
                        ([
                          ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
                          ACCOUNT_TYPES.DISTRIBUTORS,
                        ].includes(accountType)
                          ? true
                          : createdBy !== 0 &&
                            accounts?.find((item) => item.id === createdBy)
                              ?.commissionMode)
                      }
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  {commissionMode === 'Percentage' ? (
                    <Fieldset>
                      <Label required>Margin Template</Label>
                      <Dropdown
                        placeholder='Please select margin template'
                        value={marginTemplatesId}
                        onChange={setMarginTemplatesId}
                        options={marginTemplatesOptions}
                        disabled={isLoading}
                      />
                    </Fieldset>
                  ) : (
                    <Fieldset>
                      <Label required>Commission Value</Label>
                      <NumberInput
                        value={commissionValue}
                        onChange={setCommissionValue}
                        placeholder='Please enter commission value'
                        disabled={isLoading}
                        pattern={`[0-9.]*`}
                      />
                    </Fieldset>
                  )}
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <Label required>Minimum Balance</Label>
                    <NumberInput
                      value={minimumBalance}
                      onChange={setMinimumBalance}
                      placeholder='Please enter minimum balance'
                      maxLength={10}
                      disabled={isLoading === true}
                      pattern={`[0-9]*`}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
              <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                <GridItem>
                  <Fieldset>
                    <Label>PAN Name</Label>
                    <TextInput
                      value={panName}
                      onChange={setPanName}
                      placeholder='Please enter name on pan card'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>PAN Number</Label>
                    <TextInput
                      value={panNumber}
                      onChange={setPanNumber}
                      placeholder='Please enter pan number'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>PAN Verification Status</Label>
                    <Dropdown
                      placeholder='Please select pan verification status'
                      value={panVerified}
                      onChange={setPanVerified}
                      options={[
                        {
                          title: '-- SELECT IF PAN IS VERIFIED --',
                          value: null,
                        },
                        {
                          title: 'Yes',
                          value: 'Yes',
                        },
                        {
                          title: 'No',
                          value: 'No',
                        },
                      ]}
                      disabled={isLoading}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Pan File</Label>
                    <FileInput
                      files={panFile}
                      onChange={setPanFile}
                      accept='image/*'
                      disabled={isLoading === true}
                    />
                    {originalFormData?.panFile && (
                      <StyledViewAttachmentButton
                        onClick={() => {
                          setShowExistingImage(true);
                          setModalImage(originalFormData.panFile);
                        }}
                      >
                        Click here to view existing file
                      </StyledViewAttachmentButton>
                    )}
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <Label>Aadhaar Name</Label>
                    <TextInput
                      value={aadhaarName}
                      onChange={setAadhaarName}
                      placeholder='Please enter name on aadhaar card'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Aadhaar Number</Label>
                    <TextInput
                      value={aadhaarNumber}
                      onChange={setAadhaarNumber}
                      placeholder='Please enter aadhaar number'
                      disabled={isLoading === true}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Aadhaar Verification Status</Label>
                    <Dropdown
                      placeholder='Please select aadhaar verification status'
                      value={aadhaarVerified}
                      onChange={setAadhaarVerified}
                      options={[
                        {
                          title: '-- SELECT IF AADHAAR IS VERIFIED --',
                          value: null,
                        },
                        {
                          title: 'Yes',
                          value: 'Yes',
                        },
                        {
                          title: 'No',
                          value: 'No',
                        },
                      ]}
                      disabled={isLoading}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Aadhaar File</Label>
                    <FileInput
                      files={aadhaarFile}
                      onChange={setAadhaarFile}
                      accept='image/*'
                      disabled={isLoading === true}
                    />
                    {originalFormData?.aadhaarFile && (
                      <StyledViewAttachmentButton
                        onClick={() => {
                          setShowExistingImage(true);
                          setModalImage(originalFormData.aadhaarFile);
                        }}
                      >
                        Click here to view existing file
                      </StyledViewAttachmentButton>
                    )}
                  </Fieldset>
                </GridItem>
              </Grid>
            </>
          )}
          {![
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
          ].includes(accountType) && (
            <Fieldset>
              <Label required>Account Status</Label>
              <Dropdown
                placeholder='Please select aadhaar verification status'
                value={accountsStatus}
                onChange={setAccountsStatus}
                options={[
                  {
                    title: '-- SELECT ACCOUNTS STATUS --',
                    value: '',
                  },
                  {
                    title: 'Active',
                    value: 'Active',
                  },
                  {
                    title: 'Inactive',
                    value: 'Inactive',
                  },
                ]}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          <Fieldset>
            <Label>Is GST Invoice Required?</Label>
            <Dropdown
              placeholder='Please select gst invoice status'
              value={isGstInvoiceRequired}
              onChange={setIsGstInvoiceRequired}
              options={[
                {
                  title: '-- SELECT GST INVOICE STATUS --',
                  value: '',
                },
                {
                  title: 'Yes',
                  value: 'Yes',
                },
                {
                  title: 'No',
                  value: 'No',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image width={150} source={modalImage} alt={'Existing File'} />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(RolesAndPermissionsUpdate);
