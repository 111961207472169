import React from 'react';

const TermsAndConditions = (props) => {
  const { termsAndConditions } = props.appData;

  return (
    <section
      className='content'
      dangerouslySetInnerHTML={{ __html: termsAndConditions }}
    ></section>
  );
};

export default TermsAndConditions;
