import React from 'react';
import styled from 'styled-components';

const StyledStatusHighliter = styled.span`
  min-width: 80px;
  width: auto;
  height: auto;
  margin: 0px;
  padding: 10px 5px;
  background-color: #e5e5e5;
  border-radius: 50px;
  display: inline-block;
  &.red {
    color: #ffffff;
    background-color: red;
  }
  &.green {
    color: #ffffff;
    background-color: forestgreen;
  }
  &.yellow {
    color: #000000;
    background-color: #ff9800;
  }
  &.blue {
    color: #ffffff;
    background-color: #0095ff;
  }
`;

const StatusHighliter = ({ children, ...rest }) => {
  return <StyledStatusHighliter {...rest}>{children}</StyledStatusHighliter>;
};

export default StatusHighliter;
