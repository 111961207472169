import React, { useState } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  MessageBox,
  CreateButton,
  ResetButton,
  Dropdown,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';
import { useEffect } from 'react';

const VendorsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [apiProviders, setApiProviders] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [firmName, setFirmName] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [firmAddress, setFirmAddress] = useState('');
  const [invoicePrefix, setInvoicePrefix] = useState('');
  const [hsnCode, setHsnCode] = useState('');
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [isAPIProvider, setIsAPIProvider] = useState('');
  const [apiProvidersId, setApiProvidersId] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setMobileNumber('');
    setBankName('');
    setAccountNumber('');
    setIfscCode('');
    setFirmName('');
    setGstNumber('');
    setFirmAddress('');
    setInvoicePrefix('');
    setHsnCode('');
    setInvoiceDescription('');
    setIsAPIProvider('');
    setApiProvidersId('');
  };

  const readApiProviders = () => {
    API.get('/recharge-configurations/api-providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setApiProviders(data);
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      bankName: bankName,
      accountNumber: accountNumber,
      ifscCode: ifscCode,
      firmName: firmName,
      gstNumber: gstNumber,
      firmAddress: firmAddress,
      invoicePrefix: invoicePrefix,
      hsnCode: hsnCode,
      invoiceDescription: invoiceDescription,
      isAPIProvider: isAPIProvider,
      apiProvidersId: apiProvidersId,
    };
    API.post('/accounts-management/vendors', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readApiProviders();
  }, []);

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: '' },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  value={firstName}
                  onChange={setFirstName}
                  placeholder='Please enter first name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  value={lastName}
                  onChange={setLastName}
                  placeholder='Please enter last name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  placeholder='Please enter mobile number'
                  maxLength={10}
                  disabled={isLoading === true}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Bank Name</Label>
                <TextInput
                  value={bankName}
                  onChange={setBankName}
                  placeholder='Please enter bank name'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Account Number</Label>
                <TextInput
                  value={accountNumber}
                  onChange={setAccountNumber}
                  placeholder='Please enter account number'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>IFSC Code</Label>
                <TextInput
                  value={ifscCode}
                  onChange={setIfscCode}
                  placeholder='Please enter IFSC Code'
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Is API Provider?</Label>
            <Dropdown
              placeholder='Please select if vendor is api provider'
              value={isAPIProvider}
              onChange={(value) => {
                setApiProvidersId('');
                setIsAPIProvider(value);
              }}
              options={[
                {
                  title: '-- SELECT IF VENDOR IS API PROVIDER --',
                  value: '',
                },
                {
                  title: 'Yes',
                  value: 'Yes',
                },
                {
                  title: 'No',
                  value: 'No',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {isAPIProvider === 'Yes' && (
            <Fieldset>
              <Label required>API Provider</Label>
              <Dropdown
                placeholder='Please select api provider'
                value={apiProvidersId}
                onChange={setApiProvidersId}
                options={apiProvidersOptions}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Firm Name</Label>
                <TextInput
                  value={firmName}
                  onChange={setFirmName}
                  placeholder='Please enter firm name'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>GST Number</Label>
                <TextInput
                  value={gstNumber}
                  onChange={setGstNumber}
                  placeholder='Please enter gst number'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Firm Address</Label>
            <TextInput
              value={firmAddress}
              onChange={setFirmAddress}
              placeholder='Please enter firm address'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Invoice Prefix</Label>
                <TextInput
                  value={invoicePrefix}
                  onChange={setInvoicePrefix}
                  placeholder='Please enter invoice prefix'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>HSN Code</Label>
                <TextInput
                  value={hsnCode}
                  onChange={setHsnCode}
                  placeholder='Please enter hsn code'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Invoice Description</Label>
            <TextInput
              value={invoiceDescription}
              onChange={setInvoiceDescription}
              placeholder='Please enter invoice description'
              disabled={isLoading === true}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type='submit'>
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(VendorsCreate);
