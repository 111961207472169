import React from "react";
import styled from "styled-components";
import Logo from "../Logo/Logo";
import AccountExpirySection from "../AccountExpirySection/AccountExpirySection";
import SupportSection from "../SupportSection/SupportSection";
import WalletSection from "../WalletSection/WalletSection";
import NotificationsSection from "../NotificationsSection/NotificationsSection";
import AccountsSection from "../AccountsSection/AccountsSection";
import LogoutSection from "../LogoutSection/LogoutSection";
import { ACCOUNT_TYPES, MODULES, PERMISSION_TYPES } from "../../constants";
import { authData } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

const StyledHeaderContainer = styled.header`
  width: 100%;
  height: auto;
  display: block;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border-bottom: 1px solid #e5e5e5;
`;

const StyledHeader = styled.section`
  width: 100%;
  height: 55px;
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-template-columns: max-content auto;
  @media (max-width: 600px) {
    grid-template-columns: min-content max-content auto;
  }
`;

const StyledAccountsSectionContainer = styled.section`
  width: auto;
  height: 55px;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: right;
`;

const StyledNavigationIcon = styled.section`
  display: none;
  @media (max-width: 600px) {
    width: 55px;
    height: 100%;
    margin: 0px;
    padding: 0px;
    border-right: 1px solid #e5e5e5;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: #707070;
    transition: background-color 0.5s;
    font-size: 20px;
    &:hover {
      background-color: #e5e5e5;
    }
  }
`;

const Header = ({
  walletBalance,
  creditDue,
  minimumBalance,
  supportMobileNumber,
  supportEmailAddress,
  fullName,
  profilePhoto,
  onClickLogout,
  appLogo,
  appName,
  packageExpiryDate,
  showNav,
  setShowNav,
  notifications,
  updateNotification,
  commissionMode,
  commissionValue,
}) => {
  const { accountType, accountsPermissions } = authData.get();
  const hasPushNotifications =
    accountsPermissions[MODULES.PUSH_NOTIFICATIONS] &&
    accountsPermissions[MODULES.PUSH_NOTIFICATIONS].includes(
      PERMISSION_TYPES.READ
    )
      ? true
      : false;

  return (
    <StyledHeaderContainer>
      <StyledHeader>
        <StyledNavigationIcon onClick={setShowNav}>
          <FontAwesomeIcon icon={showNav ? faXmark : faBars} />
        </StyledNavigationIcon>
        <Logo src={appLogo} alt={appName} />
        <StyledAccountsSectionContainer>
          {accountType === ACCOUNT_TYPES.ADMINS && (
            <AccountExpirySection packageExpiryDate={packageExpiryDate} />
          )}
          {[
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <WalletSection
              walletBalance={walletBalance}
              creditDue={creditDue}
              minimumBalance={minimumBalance}
              commissionMode={commissionMode}
              commissionValue={commissionValue}
            />
          )}
          <SupportSection
            supportMobileNumber={supportMobileNumber}
            supportEmailAddress={supportEmailAddress}
          />
          {hasPushNotifications && (
            <NotificationsSection
              notifications={notifications}
              updateNotification={updateNotification}
            />
          )}
          <AccountsSection
            fullName={fullName}
            profilePhoto={profilePhoto}
            showReferAndEarn={
              ![ACCOUNT_TYPES.ADMINS, ACCOUNT_TYPES.API_USERS].includes(
                accountType
              )
            }
          />
          <LogoutSection onClickLogout={onClickLogout} />
        </StyledAccountsSectionContainer>
      </StyledHeader>
    </StyledHeaderContainer>
  );
};

export default Header;
