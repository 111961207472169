import React from 'react';

const PrivacyPolicy = (props) => {
  const { privacyPolicy } = props.appData;

  return (
    <section
      className='content'
      dangerouslySetInnerHTML={{ __html: privacyPolicy }}
    ></section>
  );
};

export default PrivacyPolicy;
