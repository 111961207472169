import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withAuthHOC } from '../../hoc';
import {
  Form,
  Fieldset,
  Label,
  NumberInput,
  PasswordInput,
  OTPInput,
  Button,
  MessageBox,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { authData } from '../../utils';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';

const StyledAuthArea = styled.section`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 0px;
  margin: 0px;
  box-shadow: 0px 0px 50px -10px #b9b9b9;
  position: relative;
  @media (max-width: 900px) {
    width: calc(100% - 40px);
    min-width: auto;
    height: auto;
    padding: 0px;
    margin: 0 auto;
    position: relative;
    top: 0px;
    left: 0px;
    transform: none;
  }
  @media (max-width: 1000px) {
    width: 92%;
    min-width: auto;
    height: auto;
    padding: 0px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
`;

const StyledBody = styled.section`
  width: calc(100% - 60px);
  height: auto;
  margin: 0px;
  padding: 30px;
  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    padding: 20px;
  }
`;

const StyledAuthHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 0px;
`;

const StyleLink = styled(NavLink)`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSpanAsButton = styled.span`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 11.5px;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledPara = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const StyledSpan = styled.span`
  color: forestgreen;
`;

const StyledRegisterLink = styled.h2`
  display: block;
  font-size: 14px;
  margin: 0px;
  color: #000000;
  text-transform: normal;
  font-weight: normal;
  text-align: center;
  padding: 20px 0px 0px 0px;
  a {
    position: static;
  }
`;

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [loginType, setLoginType] = useState('Password');
  const [routeStage, setRouteStage] = useState('Login');
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');

  const resetForm = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
    setRouteStage('Login');
    setPassword('');
    setRequestToken('');
    setOneTimePassword('');
  };

  const onChangeRouteType = () => {
    resetForm();
    setLoginType((loginType) =>
      loginType === 'Password' ? 'OTP' : 'Password'
    );
  };

  const setAuthData = (data) => {
    const {
      token,
      data: { accountsRole, permissions },
    } = data;
    authData.set('isLoggedIn', true);
    authData.set('accountType', accountsRole);
    authData.set('authId', mobileNumber);
    authData.set('authToken', token);
    authData.set('accountsPermissions', permissions);
    authData.set('accountData', JSON.stringify(data.data));
    window.location.href = '/';
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    if (loginType === 'Password') {
      const formData = {
        loginType,
        mobileNumber,
        password,
      };
      API.post('/auth/login', formData)
        .then((response) => {
          const { status, message } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setAuthData(response.data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (loginType === 'OTP') {
      if (routeStage === 'Login') {
        const formData = {
          loginType,
          mobileNumber,
        };
        API.post('/auth/login', formData)
          .then((response) => {
            const { status, message, token } = response.data;
            setResponseStatus(status);
            setResponseMessage(message);
            setRequestToken(token);
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setRouteStage('OTPVerification');
            }
          })
          .catch((error) => {
            setResponseStatus(API_RESPONSE_TYPES.FAILURE);
            setResponseMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (routeStage === 'OTPVerification') {
        const formData = {
          requestType: 'Login',
          requestToken,
          mobileNumber,
          oneTimePassword: oneTimePassword
            ? oneTimePassword.join('')
            : oneTimePassword,
        };
        API.post('/auth/verify-otp', formData)
          .then((response) => {
            const { status, message, token } = response.data;
            setResponseStatus(status);
            setResponseMessage(message);
            setRequestToken(token);
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setAuthData(response.data);
            }
          })
          .catch((error) => {
            setResponseStatus(API_RESPONSE_TYPES.FAILURE);
            setResponseMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      requestType: 'Login',
      mobileNumber,
      requestToken,
    };
    API.post('/auth/resend-otp', formData)
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setRequestToken(token);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StyledAuthArea>
        <StyledBody>
          <StyledAuthHeading>
            {routeStage === 'Login'
              ? `Login to your Account`
              : `Verify & Login`}
          </StyledAuthHeading>
          <Form action='#' method='POST' onSubmit={onSubmit}>
            {routeStage !== 'OTPVerification' && (
              <>
                <Fieldset>
                  <Label required>Mobile Number</Label>
                  <NumberInput
                    placeholder='Please enter your mobile number'
                    value={mobileNumber}
                    onChange={setMobileNumber}
                    maxLength={10}
                    autoComplete='off'
                    disabled={isLoading === true}
                    pattern={`[0-9]*`}
                  />
                </Fieldset>
                {loginType === 'Password' && (
                  <Fieldset>
                    <Label required>Password</Label>
                    <StyleLink to='/forgot-password' title='Forgot Password ?'>
                      Forgot Password ?
                    </StyleLink>
                    <PasswordInput
                      placeholder='Please enter your password'
                      value={password}
                      onChange={setPassword}
                      autoComplete='off'
                      disabled={isLoading}
                    />
                  </Fieldset>
                )}
              </>
            )}
            {routeStage === 'OTPVerification' && (
              <>
                <StyledPara>
                  Please enter your OTP sent to{' '}
                  <StyledSpan>{mobileNumber}</StyledSpan>
                </StyledPara>
                <Fieldset>
                  <OTPInput
                    values={oneTimePassword}
                    onChange={setOneTimePassword}
                    length={6}
                    alignInputs='center'
                    disabled={isLoading}
                  />
                </Fieldset>
              </>
            )}
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={responseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={isLoading}>
                {routeStage === 'Login'
                  ? loginType === 'Password'
                    ? `Login`
                    : `Send OTP`
                  : `Verify & Proceed`}
              </Button>
              {routeStage === 'OTPVerification' && (
                <StyledSpanAsButton onClick={onClickResendOTP}>
                  Resend OTP
                </StyledSpanAsButton>
              )}
              {routeStage === 'Login' && (
                <StyledSpanAsButton onClick={onChangeRouteType}>
                  Login with {loginType === 'Password' ? `OTP` : `Password`}
                </StyledSpanAsButton>
              )}
            </Fieldset>
          </Form>
          <StyledRegisterLink>
            Don't have an account ?{' '}
            <StyleLink to='/register' title='Register'>
              Register
            </StyleLink>
          </StyledRegisterLink>
        </StyledBody>
        <OverlayLoader showLoader={isLoading} />
      </StyledAuthArea>
    </>
  );
};

export default withAuthHOC(Login);
