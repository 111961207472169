import React, { useState, useEffect } from 'react';

const ExcelExport = ({ data: { summary, summaryTotals } }) => {
  const [salesData, setSalesData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [saleTotal, setSaleTotal] = useState('');
  const [saleTotalTax, setSaleTotalTax] = useState('');
  const [saleGrandTotal, setSaleGrandTotal] = useState('');
  const [purchaseTotal, setPurchaseTotal] = useState('');
  const [purchaseTotalTax, setPurchaseTotalTax] = useState('');
  const [purchaseGrandTotal, setPurchaseGrandTotal] = useState('');

  useEffect(() => {
    if (summary?.length > 0) {
      setSalesData(summary.filter(({ invoiceType }) => invoiceType === 'Sale'));
      setPurchaseData(
        summary.filter(({ invoiceType }) => invoiceType === 'Purchase')
      );
    }
  }, [summary]);

  useEffect(() => {
    if (salesData?.length > 0) {
      const saleTotals = summaryTotals.find(
        ({ invoiceType }) => invoiceType === 'Sale'
      );
      if (saleTotals !== -1) {
        setSaleTotal(saleTotals.subTotal);
        setSaleTotalTax(saleTotals.taxAmount);
        setSaleGrandTotal(saleTotals.total);
      }
    }
  }, [salesData]);

  useEffect(() => {
    if (purchaseData?.length > 0) {
      const purchaseTotals = summaryTotals.find(
        ({ invoiceType }) => invoiceType === 'Purchase'
      );
      if (purchaseTotals !== -1) {
        setPurchaseTotal(purchaseTotals.subTotal);
        setPurchaseTotalTax(purchaseTotals.taxAmount);
        setPurchaseGrandTotal(purchaseTotals.total);
      }
    }
  }, [purchaseData]);

  return (
    <>
      <table
        id='data'
        style={{
          display: 'none',
          fontSize: '14px',
          fontFamily: 'arial',
          color: '#000',
          border: '1px solid #000',
        }}
      >
        {salesData?.length > 0 && (
          <>
            <tr>
              <td
                style={{
                  backgroundColor: 'yellow',
                  textAlign: 'center',
                }}
                colSpan={8}
              >
                Sales
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '300px',
                }}
              >
                Firm Name
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '200px',
                }}
              >
                GST Number
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',

                  width: '150px',
                }}
              >
                Invoice Number
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',

                  width: '150px',
                }}
              >
                Invoice Date
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                Subtotal
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                CGST
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                SGST
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                IGST
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                Total
              </td>
            </tr>
            {salesData.map(
              (
                {
                  firmName,
                  gstNumber,
                  invoiceNumber,
                  invoiceDate,
                  subTotal,
                  csgtAmount,
                  sgstAmount,
                  igstAmount,
                  total,
                },
                index
              ) => (
                <tr key={`sale-${index}`}>
                  <td style={{ border: '0.5px solid #000' }}>{firmName}</td>
                  <td style={{ border: '0.5px solid #000' }}>{gstNumber}</td>
                  <td style={{ border: '0.5px solid #000' }}>
                    {invoiceNumber}
                  </td>
                  <td style={{ border: '0.5px solid #000' }}>{invoiceDate}</td>
                  <td style={{ border: '0.5px solid #000' }}>{subTotal}</td>
                  <td style={{ border: '0.5px solid #000' }}>{csgtAmount}</td>
                  <td style={{ border: '0.5px solid #000' }}>{sgstAmount}</td>
                  <td style={{ border: '0.5px solid #000' }}>{igstAmount}</td>
                  <td style={{ border: '0.5px solid #000' }}>{total}</td>
                </tr>
              )
            )}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td
                colSpan={4}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'left',
                }}
              >
                Total{' '}
              </td>
              <td
                colSpan={5}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'center',
                }}
              >
                {saleTotal}
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'left',
                }}
              >
                Total Tax(CGST+IGST+SGST)
              </td>
              <td
                colSpan={5}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'center',
                }}
              >
                {saleTotalTax}
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'left',
                }}
              >
                Grand Total
              </td>
              <td
                colSpan={5}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'center',
                }}
              >
                {saleGrandTotal}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </>
        )}
        {purchaseData?.length > 0 && (
          <>
            <tr>
              <td
                style={{
                  backgroundColor: 'yellow',
                  textAlign: 'center',
                }}
                colSpan={8}
              >
                Purchase
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '300px',
                }}
              >
                Firm Name
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '200px',
                }}
              >
                GST Number
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',

                  width: '150px',
                }}
              >
                Invoice Number
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',

                  width: '150px',
                }}
              >
                Invoice Date
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                Subtotal
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                CGST
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                SGST
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                IGST
              </td>
              <td
                style={{
                  backgroundColor: 'rgb(250, 191, 142)',
                  border: '0.5px solid #000',
                  width: '100px',
                }}
              >
                Total
              </td>
            </tr>
            {purchaseData.map(
              (
                {
                  firmName,
                  gstNumber,
                  invoiceNumber,
                  invoiceDate,
                  subTotal,
                  csgtAmount,
                  sgstAmount,
                  igstAmount,
                  total,
                },
                index
              ) => (
                <tr key={`purchase-${index}`}>
                  <td style={{ border: '0.5px solid #000' }}>{firmName}</td>
                  <td style={{ border: '0.5px solid #000' }}>{gstNumber}</td>
                  <td style={{ border: '0.5px solid #000' }}>
                    {invoiceNumber}
                  </td>
                  <td style={{ border: '0.5px solid #000' }}>{invoiceDate}</td>
                  <td style={{ border: '0.5px solid #000' }}>{subTotal}</td>
                  <td style={{ border: '0.5px solid #000' }}>{csgtAmount}</td>
                  <td style={{ border: '0.5px solid #000' }}>{sgstAmount}</td>
                  <td style={{ border: '0.5px solid #000' }}>{igstAmount}</td>
                  <td style={{ border: '0.5px solid #000' }}>{total}</td>
                </tr>
              )
            )}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td
                colSpan={4}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'left',
                }}
              >
                Total Excluding GST
              </td>
              <td
                colSpan={5}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'center',
                }}
              >
                {purchaseTotal}
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'left',
                }}
              >
                Total Tax(CGST+IGST+SGST)
              </td>
              <td
                colSpan={5}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'center',
                }}
              >
                {purchaseTotalTax}
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'left',
                }}
              >
                Total Inclusive of Tax
              </td>
              <td
                colSpan={5}
                style={{
                  border: '0.5px solid #000',
                  textAlign: 'center',
                }}
              >
                {purchaseGrandTotal}
              </td>
            </tr>
          </>
        )}
      </table>
    </>
  );
};

export default ExcelExport;
