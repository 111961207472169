import { MODULES, PERMISSION_TYPES } from "./index";

const {
  DASHBOARD,
  STATS,
  // Accounts Management
  ACCOUNTS_MANAGEMENT,
  ROLES_AND_PERMISSIONS,
  ACCOUNTS,
  VENDORS,
  // Configurations
  CONFIGURATIONS_MANAGEMENT,
  APP_META_DATA,
  REFERRAL_CONFIGURATION,
  PAYMENT_GATEWAY_CONFIGURATION,
  MESSAGING_CONFIGURATION,
  PUSH_NOTIFICATIONS_CONFIGURATION,
  PUSH_NOTIFICATIONS,
  NOTIFICATIONS,
  SCROLL_MESSAGE,
  SMS_TEMPLATES,
  BANK_ACCOUNTS,
  API_SETTINGS,
  API_KEYS,
  // Recharge Configurations
  RECHARGE_CONFIGURATIONS_MANAGEMENT,
  SERVICES,
  CIRCLES,
  OPERATORS,
  OPERATORS_AMOUNT_RANGES,
  API_PROVIDERS,
  OPERATORS_MAPPING,
  API_SWITCHING,
  STOCK_MANAGEMENT,
  ACCOUNTS_API_MAPPING,
  ROLES_API_MAPPING,
  INVALID_RECHARGE_AMOUNTS,
  ACCOUNT_OPERATOR_BLOCKERS,
  BILL_FETCH_CONFIGURATION,
  COMMON_APIS_CONFIGURATION,
  // Margins
  MARGIN_TEMPLATES_MANAGEMENT,
  MY_MARGINS,
  MARGIN_TEMPLATES,
  ROLES_DEFAULT_MARGIN_TEMPLATES,
  // Transactions
  TRANSACTIONS_MANAGEMENT,
  RECHARGE_TRANSACTIONS,
  PENDING_RECHARGE_TRANSACTIONS,
  DISPUTED_RECHARGE_TRANSACTIONS,
  LIVE_RECHARGE_TRANSACTIONS,
  API_CALLBACK_LOGS,
  // Accounting
  ACCOUNTING_MANAGEMENT,
  WALLET_DEPOSIT_REQUESTS,
  PAYMENT_GATEWAY_DEPOSITS,
  VENDOR_PAYMENTS,
  VENDOR_GST_INVOICES,
  ACCOUNT_GST_INVOICES,
  GST_INVOICES_SUMMARY,
  API_PROVIDER_PAYMENTS,
  // Reports
  REPORTS_MANAGEMENT,
  OPERATORS_DAY_BOOK,
  API_SUMMARY_BOOK,
  ACCOUNTS_DAY_BOOK,
  USERS_OPERATORS_DAY_BOOK,
  ALERT_HISTORY,
  // API Documentation
  API_DOCS,
} = MODULES;

const {
  CREATE,
  READ,
  UPDATE,
  DELETE,
  CHANGE_PASSWORD,
  READ_LATEST_OTP,
  WALLET_PROCESS_FUNDS,
  WALLET_TRANSACTIONS_READ,
  REFUND,
  RELOAD,
  RAISE_DISPUTE,
  UPDATE_DISPUTE,
  TRACK,
  HEAVY_RELOAD,
  EXPORT,
  OPERATORS_CONFIG,
  UPI_LOAD,
  REPROCESS,
} = PERMISSION_TYPES;

const modulesMapper = [
  {
    moduleName: DASHBOARD,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: STATS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Accounts Management
  {
    moduleName: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ROLES_AND_PERMISSIONS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: ACCOUNTS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
      {
        permission: CHANGE_PASSWORD,
        dependencyPermissions: [READ],
      },
      {
        permission: READ_LATEST_OTP,
        dependencyPermissions: [READ],
      },
      {
        permission: WALLET_PROCESS_FUNDS,
        dependencyPermissions: [READ],
      },
      {
        permission: WALLET_TRANSACTIONS_READ,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: VENDORS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  // Configurations
  {
    moduleName: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: APP_META_DATA,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: UPDATE,
      },
    ],
  },
  {
    moduleName: REFERRAL_CONFIGURATION,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: UPDATE,
      },
    ],
  },
  {
    moduleName: PAYMENT_GATEWAY_CONFIGURATION,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: UPDATE,
      },
    ],
  },
  {
    moduleName: MESSAGING_CONFIGURATION,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: UPDATE,
      },
    ],
  },
  {
    moduleName: PUSH_NOTIFICATIONS_CONFIGURATION,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: UPDATE,
      },
    ],
  },
  {
    moduleName: PUSH_NOTIFICATIONS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
      },
    ],
  },
  {
    moduleName: NOTIFICATIONS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
    ],
  },
  {
    moduleName: SCROLL_MESSAGE,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: UPDATE,
      },
    ],
  },
  {
    moduleName: SMS_TEMPLATES,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: BANK_ACCOUNTS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: API_SETTINGS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: API_KEYS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Recharge Configurations
  {
    moduleName: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: SERVICES,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: CIRCLES,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: OPERATORS,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: OPERATORS_AMOUNT_RANGES,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: API_PROVIDERS,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
      {
        permission: OPERATORS_CONFIG,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: OPERATORS_MAPPING,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: API_SWITCHING,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: STOCK_MANAGEMENT,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: ACCOUNTS_API_MAPPING,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: ROLES_API_MAPPING,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: INVALID_RECHARGE_AMOUNTS,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: ACCOUNT_OPERATOR_BLOCKERS,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: BILL_FETCH_CONFIGURATION,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: COMMON_APIS_CONFIGURATION,
    masterModule: RECHARGE_CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Margins
  {
    moduleName: MARGIN_TEMPLATES_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: MY_MARGINS,
    masterModule: MARGIN_TEMPLATES_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: MARGIN_TEMPLATES,
    masterModule: MARGIN_TEMPLATES_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: ROLES_DEFAULT_MARGIN_TEMPLATES,
    masterModule: MARGIN_TEMPLATES_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  // Transactions
  {
    moduleName: TRANSACTIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: REFUND,
        dependencyPermissions: [READ],
      },
      {
        permission: RELOAD,
        dependencyPermissions: [READ],
      },
      {
        permission: RAISE_DISPUTE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE_DISPUTE,
        dependencyPermissions: [READ],
      },
      {
        permission: TRACK,
        dependencyPermissions: [READ],
      },
      {
        permission: EXPORT,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: PENDING_RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: REFUND,
        dependencyPermissions: [READ],
      },
      {
        permission: RELOAD,
        dependencyPermissions: [READ],
      },
      {
        permission: RAISE_DISPUTE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE_DISPUTE,
        dependencyPermissions: [READ],
      },
      {
        permission: TRACK,
        dependencyPermissions: [READ],
      },
      {
        permission: HEAVY_RELOAD,
        dependencyPermissions: [READ],
      },
      {
        permission: EXPORT,
        dependencyPermissions: [READ],
      },
      {
        permission: REPROCESS,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: DISPUTED_RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: REFUND,
        dependencyPermissions: [READ],
      },
      {
        permission: RELOAD,
        dependencyPermissions: [READ],
      },
      {
        permission: RAISE_DISPUTE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE_DISPUTE,
        dependencyPermissions: [READ],
      },
      {
        permission: TRACK,
        dependencyPermissions: [READ],
      },
      {
        permission: EXPORT,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: LIVE_RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: API_CALLBACK_LOGS,
    masterModule: TRANSACTIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Accounting
  {
    moduleName: ACCOUNTING_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: WALLET_DEPOSIT_REQUESTS,
    masterModule: ACCOUNTING_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
      {
        permission: EXPORT,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: PAYMENT_GATEWAY_DEPOSITS,
    masterModule: ACCOUNTING_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPI_LOAD,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: VENDOR_PAYMENTS,
    masterModule: ACCOUNTING_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: VENDOR_GST_INVOICES,
    masterModule: ACCOUNTING_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ACCOUNT_GST_INVOICES,
    masterModule: ACCOUNTING_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: GST_INVOICES_SUMMARY,
    masterModule: ACCOUNTING_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: API_PROVIDER_PAYMENTS,
    masterModule: ACCOUNTING_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Reports
  {
    moduleName: REPORTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: OPERATORS_DAY_BOOK,
    masterModule: REPORTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: API_SUMMARY_BOOK,
    masterModule: REPORTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ACCOUNTS_DAY_BOOK,
    masterModule: REPORTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: USERS_OPERATORS_DAY_BOOK,
    masterModule: REPORTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ALERT_HISTORY,
    masterModule: REPORTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // API Documentation
  {
    moduleName: API_DOCS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Others
  {
    moduleName: NOTIFICATIONS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
    ],
  },
];

export default modulesMapper;
