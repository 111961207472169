import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  MessageBox,
  Form,
  Fieldset,
  TextInput,
} from "../../../components/FormElements";
import { Image } from "../../../components/Image";
import { TableBuilder } from "../../../components/TableElements";
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledFiltersContainer,
} from "../../../components/Styled";
import API from "../../../api";
import { ACCOUNT_TYPES, API_RESPONSE_TYPES } from "../../../constants";
import { authData, formatCurrency } from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import { isEmpty } from "lodash";

const MyMarginsTable = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [operators, setOperators] = useState([]);
  const [template, setTemplate] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { accountType } = authData.get();

  const generateTemplate = useCallback(
    (templateData) => {
      const processedTemplateData =
        templateData.length !== 0 ? JSON.parse(templateData) : templateData;
      setTemplate(
        operators?.map(
          ({
            id,
            serviceName,
            surcharge,
            defaultMasterDistributorsShare,
            defaultDistributorsShare,
            defaultRetailersShare,
            defaultApiUsersShare,
            operatorName,
            operatorLogo,
            field1,
            field2,
            field3,
            field4,
            fromAmount,
            toAmount,
            rangesId,
            isRange,
          }) => {
            const item =
              processedTemplateData.length > 0 &&
              processedTemplateData?.find((item) =>
                isRange === "Y"
                  ? item?.rangesId === rangesId
                  : item?.operatorsId === id
              );

            const commissionType = item?.commissionType ?? "Percentage";
            const commission = item?.commission ?? 0;

            return {
              operatorsId: id,
              serviceName,
              surcharge,
              defaultMasterDistributorsShare,
              defaultDistributorsShare,
              defaultRetailersShare,
              defaultApiUsersShare,
              operatorName,
              operatorLogo,
              commissionType: commissionType || "Percentage",
              commission: commission || 0,
              field1,
              field2,
              field3,
              field4,
              fromAmount,
              toAmount,
            };
          }
        )
      );
    },
    [operators]
  );

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/recharge-configurations/operators/?isMargin=Y")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      });
  }, []);

  const readData = useCallback(() => {
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/margins/my-margins`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          generateTemplate(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      });
  }, [generateTemplate]);

  const filterData = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    readOperators();
  }, [readOperators]);

  useEffect(() => {
    readData();
  }, [operators, readData]);

  useEffect(() => {
    if (template.length > 0) {
      setIsLoading(false);
    }
  }, [template]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  const filteredResults = !isEmpty(searchQuery)
    ? template.length > 0
      ? template.filter(
          ({ serviceName, operatorName }) =>
            serviceName.toLowerCase().indexOf(searchQuery?.toLowerCase()) >
              -1 ||
            operatorName.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1
        )
      : template
    : template;

  return (
    <>
      <PageHeading {...props} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer
        className={showFilters ? "show no-border" : "no-border"}
      >
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search for Service Name, Operator Name"
              disabled={isLoading === true}
            />
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && filteredResults.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          filteredResults.length === 0
        }
        message={`No template found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {filteredResults.length > 0 && (
        <TableBuilder
          isLoading={filteredResults.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: "Operator",
              dataSelector: "operatorName",
              dataType: "string",
              width: 500,
              CellRenderer: (value, item) => (
                <StyledIconTitleContainer>
                  <StyledIconContainer
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    <Image
                      source={
                        item.operatorLogo ? item.operatorLogo : "/no-image.png"
                      }
                      alt={value}
                      style={{ width: "50px", height: "50px" }}
                    />
                  </StyledIconContainer>
                  <StyledTitleContainer>
                    {value}
                    <StyledSpan>{item.serviceName}</StyledSpan>
                  </StyledTitleContainer>
                </StyledIconTitleContainer>
              ),
            },
            {
              title: "Price Range",
              dataSelector: "fromAmount",
              dataType: "number",
              align: "center",
              CellRenderer: (value, item) =>
                value
                  ? `${formatCurrency(value)} - ${formatCurrency(
                      item?.toAmount
                    )}`
                  : "N/A",
            },
            {
              title: "Operator Code",
              dataSelector: "operatorsId",
              dataType: "number",
              align: "center",
              requiredRoles: [ACCOUNT_TYPES.API_USERS],
            },
            {
              title: "Commission",
              dataSelector: "commission",
              dataType: "number",
              align: "center",
              CellRenderer: (value, item) => {
                if (item.surcharge === 0) {
                  return item?.commissionType === "Percentage"
                    ? `${value}%`
                    : formatCurrency(value);
                } else {
                  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
                    return formatCurrency(
                      (Number(item.surcharge) / 100) *
                        item.defaultMasterDistributorsShare
                    );
                  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
                    return formatCurrency(
                      (Number(item.surcharge) / 100) *
                        item.defaultDistributorsShare
                    );
                  } else if (accountType === ACCOUNT_TYPES.RETAILERS) {
                    return formatCurrency(
                      (Number(item.surcharge) / 100) *
                        item.defaultRetailersShare
                    );
                  } else if (accountType === ACCOUNT_TYPES.API_USERS) {
                    return formatCurrency(
                      (Number(item.surcharge) / 100) * item.defaultApiUsersShare
                    );
                  }
                }
              },
            },
            {
              title: "Commission Type",
              dataSelector: "commissionType",
              dataType: "string",
              CellRenderer: (value, item) =>
                item.surcharge === 0 ? value : "Surcharge",
            },
            {
              title: "Field 1",
              dataSelector: "field1",
              dataType: "string",
              requiredRoles: [ACCOUNT_TYPES.API_USERS],
            },
            {
              title: "Field 2",
              dataSelector: "field2",
              dataType: "string",
              requiredRoles: [ACCOUNT_TYPES.API_USERS],
            },
            {
              title: "Field 3",
              dataSelector: "field3",
              dataType: "string",
              requiredRoles: [ACCOUNT_TYPES.API_USERS],
            },
            {
              title: "Field 4",
              dataSelector: "field4",
              dataType: "string",
              requiredRoles: [ACCOUNT_TYPES.API_USERS],
            },
          ]}
          tableData={filteredResults}
        />
      )}
    </>
  );
};

export default withAppHOC(MyMarginsTable);
