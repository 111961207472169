import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { getReturnUrl } from '../../../utils';

const StyledStatusContainer = styled.section`
  text-align: center;
  padding: 50px 0px;
`;

const StyledStatusIcon = styled.section`
  font-size: 50px;
  svg {
    &.blue {
      color: #0095ff;
    }
    &.green {
      color: forestgreen;
    }
    &.red {
      color: red;
    }
  }
`;

const StyledStatusHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  margin: 10px 0px;
  padding: 0px;
  &.blue {
    color: #0095ff;
  }
  &.green {
    color: forestgreen;
  }
  &.red {
    color: red;
  }
`;

const StyledStatusMessage = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
`;

const PaymentGatewayDepositsStatus = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const { id } = useParams();

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/accounting/payment-gateway-deposits/status-check/${id}?isStatusCheck=Yes`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        setResponseMessage(message);
        if (status === 'fail') {
          setResponseStatus(`Transaction Failed`);
        } else if (status === 'success') {
          setResponseStatus(`Transaction Success`);
        } else {
          setResponseStatus(`Transaction Pending`);
        }
        setTimeout(() => {
          window.location.href = getReturnUrl('status');
        }, 5000);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <StyledStatusContainer>
        {isLoading && <LoadingSpinner isLoading={isLoading} />}
        {!isLoading && (
          <>
            <StyledStatusIcon>
              <FontAwesomeIcon
                icon={
                  responseStatus === 'Transaction Pending'
                    ? faExclamationCircle
                    : responseStatus === 'Transaction Success'
                    ? faCircleCheck
                    : faCircleXmark
                }
                className={
                  responseStatus === 'Transaction Pending'
                    ? 'blue'
                    : responseStatus === 'Transaction Success'
                    ? 'green'
                    : 'red'
                }
              />
            </StyledStatusIcon>
            <StyledStatusHeading
              className={
                responseStatus === 'Transaction Pending'
                  ? 'blue'
                  : responseStatus === 'Transaction Success'
                  ? 'green'
                  : 'red'
              }
            >
              {responseStatus}
            </StyledStatusHeading>
            <StyledStatusMessage>{responseMessage}</StyledStatusMessage>
          </>
        )}
      </StyledStatusContainer>
    </>
  );
};

export default withAppHOC(PaymentGatewayDepositsStatus);
