import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  NumberInput,
} from '../../../components/FormElements';
import { StyledSpan } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl, getAmountInWords } from '../../../utils';

const WalletDepositRequestsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [bankAccounts, setBankAccounts] = useState([]);
  const [toAccount, setToAccount] = useState('');
  const [depositRequestType, setDepositRequestType] = useState('');
  const [banksId, setBankId] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAccountNumberOrUpi, setBankAccountNumberOrUpi] = useState('');
  const [transactionsUTRNumber, setTransactionsUTRNumber] = useState('');
  const [transactionsDate, setTransactionsDate] = useState('');
  const [amount, setAmount] = useState('');
  const [fromAccountsRemark, setFromAccountsRemark] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setToAccount('');
    setDepositRequestType('');
    setBankName('');
    setBankAccountNumberOrUpi('');
    setTransactionsUTRNumber('');
    setTransactionsDate('');
    setAmount('');
    setFromAccountsRemark('');
  };

  const readBankAccounts = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/bank-accounts?role=${toAccount}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setBankAccounts(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [toAccount]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      toAccount,
      depositRequestType,
      banksId,
      bankName,
      bankAccountNumberOrUpi,
      transactionsUTRNumber,
      transactionsDate,
      amount,
      fromAccountsRemark,
    };
    API.post('/accounting/wallet-deposit-requests', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (toAccount !== '') {
      readBankAccounts();
    } else {
      setBankAccounts([]);
    }
  }, [toAccount, readBankAccounts]);

  useEffect(() => {
    const accountDetails = bankAccounts.find(({ id }) => id === banksId);
    if (accountDetails && accountDetails !== -1) {
      const { bankName, accountNumber } = accountDetails;
      setBankName(bankName);
      setBankAccountNumberOrUpi(accountNumber);
    }
  }, [banksId, bankAccounts, setBankName, setBankAccountNumberOrUpi]);

  useEffect(() => {
    setBankId('');
    setBankName('');
    setBankAccountNumberOrUpi('');
  }, [depositRequestType]);

  const bankAccountsOptions =
    depositRequestType === 'UPI'
      ? bankAccounts.filter((bankAccount) => bankAccount.type === 'UPI')
      : bankAccounts.filter(
          (bankAccount) => bankAccount.type === 'Wiretransfer'
        );

  const finalBankAccountsOptions = bankAccountsOptions.map(
    ({ id, bankName, accountNumber }) => ({
      title: `${bankName} (${accountNumber})`,
      value: id,
    })
  );
  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Requet To</Label>
            <Dropdown
              placeholder='Please select request to'
              value={toAccount}
              onChange={setToAccount}
              options={[
                {
                  title: '-- SELECT REQUEST TO ACCOUNT --',
                  value: '',
                },
                {
                  title: 'Admin',
                  value: 'admin',
                },
                {
                  title: 'Supervisor',
                  value: 'supervisor',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Deposit Type</Label>
            <Dropdown
              placeholder='Please select deposit type'
              value={depositRequestType}
              onChange={setDepositRequestType}
              options={[
                {
                  title: '-- SELECT DEPOSIT TYPE --',
                  value: '',
                },
                {
                  title: 'Wiretransfer',
                  value: 'Wiretransfer',
                },
                {
                  title: 'UPI',
                  value: 'UPI',
                },
                {
                  title: 'Cash',
                  value: 'Cash',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {depositRequestType !== '' && depositRequestType !== 'Cash' && (
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label required>Bank Account / UPI</Label>
                  <Dropdown
                    placeholder='Please select bank account'
                    value={banksId}
                    onChange={setBankId}
                    options={finalBankAccountsOptions}
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>UTR Number</Label>
                  <TextInput
                    value={transactionsUTRNumber}
                    onChange={setTransactionsUTRNumber}
                    placeholder='Please enter UTR number'
                    maxLength={500}
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          )}
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Transaction Date</Label>
                <TextInput
                  type='date'
                  value={transactionsDate}
                  onChange={setTransactionsDate}
                  placeholder='Please select transaction date'
                  disabled={isLoading === true}
                  max={moment(new Date()).format('YYYY-MM-DD')}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Amount</Label>
                <NumberInput
                  value={amount}
                  onChange={setAmount}
                  placeholder='Please enter amount'
                  maxLength={100}
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
                {amount && (
                  <StyledSpan style={{ color: '#000' }}>
                    {getAmountInWords(amount)}
                  </StyledSpan>
                )}
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Remark</Label>
                <TextInput
                  value={fromAccountsRemark}
                  onChange={setFromAccountsRemark}
                  placeholder='Please enter remark'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type='submit'>
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(WalletDepositRequestsCreate);
