import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faPlus,
  faRefresh,
  faRotate,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Button } from '../FormElements';
import { PERMISSION_TYPES, VIEW_TYPES } from '../../constants';

const StyledHeadingContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 20px 0px;
  display: grid;
  grid-template-columns: auto max-content;
  @media (max-width: 600px) {
    grid-template-columns: auto;
    padding: 20px 0px 0px 0px;
  }
`;

const StyledPageHeadingContainer = styled.section`
  width: auto;
  height: auto;
`;

const StyledPageHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
  align-self: center;
`;

const StyledButtonsGroup = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
  button {
    padding: 10px 30px;
    outline: 0px;
    transition: background-color 0.5s;
    background-color: #333e7a;
    @media (max-width: 600px) {
      width: 100%;
      margin: 0px 0px 20px 0px;
    }
    &:hover {
      background-color: #1e2759;
    }
    &.delete {
      background-color: red;
      margin: 0px 10px;
      @media (max-width: 600px) {
        margin: 0px 0px 20px 0px;
      }
      &:hover {
        background-color: #9d0101;
      }
    }
    &.export {
      background-color: green;
      margin: 0px 10px;
      @media (max-width: 600px) {
        margin: 0px 0px 20px 0px;
      }
      &:hover {
        background-color: #006200;
      }
    }
  }
  a {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    background-color: forestgreen;
    text-decoration: none;
    margin: 0px 10px;
    padding: 10px 30px;
    border-radius: 50px;
    cursor: pointer;
    outline: 0px;
    transition: background-color 0.5s;
    display: inline-block;
    @media (max-width: 600px) {
      width: 100%;
      margin: 0px 0px 20px 0px;
      text-align: center;
    }
    &:hover {
      background-color: #006200;
    }
    &.edit {
      background-color: #0095ff;
    }
  }
`;

const PageHeader = ({
  hideHeading,
  title,
  accountsPermissions = [],
  subRoutes = [],
  viewType,
  reloadData = () => {},
  deleteData = () => {},
  canReloadProp = viewType === VIEW_TYPES.TABLE ? true : false,
  showLogoutAllSessions = false,
  logoutAllSessions = () => {},
  showExport = false,
  isCustomExport = false,
  exportButton: CustomExportButton,
  onClickExport = () => {},
  showHeavyReload = false,
  onClickHeavyReload = () => {},
  showCanReprocess = false,
  onClickReprocess = () => {},
}) => {
  let canCreate = false;
  let canReload = canReloadProp;
  let createLink = '';
  let createTitle = '';
  if (viewType === VIEW_TYPES.TABLE) {
    canCreate = accountsPermissions?.includes(PERMISSION_TYPES.CREATE) || false;
    if (canCreate) {
      const subRoute = subRoutes?.find(
        ({ permissionRequired }) =>
          permissionRequired === PERMISSION_TYPES.CREATE
      );
      if (subRoute !== -1) {
        if (subRoute?.path) {
          createLink = subRoute.path;
        }
        if (subRoute?.title) {
          createTitle = subRoute.title;
        }
      }
    }
  }

  return (
    <StyledHeadingContainer>
      {!hideHeading && title && (
        <StyledPageHeadingContainer>
          <StyledPageHeading>{title}</StyledPageHeading>
          <Breadcrumbs />
        </StyledPageHeadingContainer>
      )}
      <StyledButtonsGroup>
        {showCanReprocess && (
          <Button
            onClick={onClickReprocess}
            className='heavy-reload'
            style={{ marginRight: 10 }}
          >
            <FontAwesomeIcon icon={faRefresh} /> Reprocess
          </Button>
        )}
        {showHeavyReload && (
          <Button
            onClick={onClickHeavyReload}
            className='heavy-reload'
            style={{ marginRight: 10 }}
          >
            <FontAwesomeIcon icon={faRefresh} /> Heavy
          </Button>
        )}
        {showLogoutAllSessions && (
          <Button onClick={logoutAllSessions} className='delete'>
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout All
          </Button>
        )}
        {canCreate && (
          <NavLink to={createLink} title={createTitle}>
            <FontAwesomeIcon icon={faPlus} /> Create
          </NavLink>
        )}
        {canReload && (
          <Button onClick={reloadData}>
            <FontAwesomeIcon icon={faRotate} /> Reload
          </Button>
        )}
        {showExport && (
          <>
            {isCustomExport ? (
              <CustomExportButton />
            ) : (
              <Button onClick={onClickExport} className='export'>
                <FontAwesomeIcon icon={faFileExcel} /> Export
              </Button>
            )}
          </>
        )}
      </StyledButtonsGroup>
    </StyledHeadingContainer>
  );
};
export default PageHeader;
