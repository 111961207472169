import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withAppHOC } from "../../hoc";
import PageHeading from "../../components/PageHeader/PageHeader";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import { MessageBox } from "../../components/FormElements";
import API from "../../api";
import { API_RESPONSE_TYPES } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const StyledContainer = styled.section`
  width: auto;
  height: auto;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  display: flex;
  padding: 30px;
  align-items: center;
`;

const StyledTextArea = styled.section`
  flex: 1;
`;

const StyledButton = styled.button`
  width: auto;
  height: auto;
  padding: 10px;
  background-color: #535353;
  font-size: 20px;
  border: 0px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
`;

const StyledPara = styled.span`
  font-size: 14px;
  margin-left: 10px;
`;

const ReferAndEarn = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [referText, setReferText] = useState("");
  const [copyTextStatus, setCopyTextStatus] = useState("Copy");

  const readReferText = () => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/auth/refer-text")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setReferText(data);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCopyClick = () => {
    const textField = document.createElement("textarea");
    textField.innerText = referText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopyTextStatus("Copied");
    setTimeout(() => {
      setCopyTextStatus("Copy");
    }, 3000);
  };

  useEffect(() => {
    readReferText();
  }, []);

  return (
    <>
      <PageHeading {...props} />
      <OverlayLoader showLoader={isLoading} />
      <MessageBox status={responseStatus} message={responseMessage} />
      <StyledContainer>
        <StyledTextArea>{referText}</StyledTextArea>
        <StyledButton onClick={handleCopyClick}>
          <FontAwesomeIcon icon={faCopy} size={40} />
          <StyledPara>{copyTextStatus}</StyledPara>
        </StyledButton>
      </StyledContainer>
    </>
  );
};

export default withAppHOC(ReferAndEarn);
