import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  Legend,
} from '../../../components/FormElements';
import { StyledMicroButton } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';

const PlanAPIConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  // Number Details
  const [numberDetailsApiEndpoint, setNumberDetailsApiEndpoint] = useState('');
  const [numberDetailsApiRequestMethod, setNumberDetailsApiRequestMethod] =
    useState('');
  const [numberDetailsApiRequestBody, setNumberDetailsApiRequestBody] =
    useState('');
  const [numberDetailsApiResponseType, setNumberDetailsApiResponseType] =
    useState('');
  const [numberDetailsApiStatusKey, setNumberDetailsApiStatusKey] =
    useState('');
  const [numberDetailsApiSuccessKey, setNumberDetailsApiSuccessKey] =
    useState('');
  const [numberDetailsApiOperatorKey, setNumberDetailsApiOperatorKey] =
    useState('');
  const [numberDetailsApiCircleKey, setNumberDetailsApiCircleKey] =
    useState('');

  // Mobile Plans
  const [mobilePlansApiEndpoint, setMobilePlansApiEndpoint] = useState('');
  const [mobilePlansBody, setMobilePlansBody] = useState('');
  const [mobilePlansMethod, setMobilePlansMethod] = useState('');
  const [mobilePlansStatusKey, setMobilePlansStatusKey] = useState('');
  const [mobilePlansSuccessValue, setMobilePlansSuccessValue] = useState('');
  const [mobilePlansFailedValue, setMobilePlansFailedValue] = useState('');
  const [mobilePlansResponseType, setMobilePlansResponseType] = useState('');
  const [mobilePlansPlansKey, setMobilePlansPlansKey] = useState('');
  const [mobilePlansAmountKey, setMobilePlansAmountKey] = useState('');
  const [mobilePlansValidityKey, setMobilePlansValidityKey] = useState('');
  const [mobilePlansDescriptionKey, setMobilePlansDescriptionKey] =
    useState('');

  // Mobile Roffers
  const [mobileRoffersApiEndpoint, setMobileRoffersApiEndpoint] = useState('');
  const [mobileRoffersBody, setMobileRoffersBody] = useState('');
  const [mobileRoffersMethod, setMobileRoffersMethod] = useState('');
  const [mobileRoffersStatusKey, setMobileRoffersStatusKey] = useState('');
  const [mobileRoffersSuccessValue, setMobileRoffersSuccessValue] =
    useState('');
  const [mobileRoffersFailedValue, setMobileRoffersFailedValue] = useState('');
  const [mobileRoffersResponseType, setMobileRoffersResponseType] =
    useState('');
  const [mobileRoffersRoffersKey, setMobileRoffersRoffersKey] = useState('');
  const [mobileRoffersAmountKey, setMobileRoffersAmountKey] = useState('');
  const [mobileRoffersValidityKey, setMobileRoffersValidityKey] = useState('');
  const [mobileRoffersDescriptionKey, setMobileRoffersDescriptionKey] =
    useState('');

  // DTH Plans
  const [dthPlansApiEndpoint, setDthPlansApiEndpoint] = useState('');
  const [dthPlansBody, setDthPlansBody] = useState('');
  const [dthPlansMethod, setDthPlansMethod] = useState('');
  const [dthPlansStatusKey, setDthPlansStatusKey] = useState('');
  const [dthPlansSuccessValue, setDthPlansSuccessValue] = useState('');
  const [dthPlansFailedValue, setDthPlansFailedValue] = useState('');
  const [dthPlansResponseType, setDthPlansResponseType] = useState('');
  const [dthPlansPlansKey, setDthPlansPlansKey] = useState('');
  const [dthPlansAmountKey, setDthPlansAmountKey] = useState('');
  const [dthPlansValidityKey, setDthPlansValidityKey] = useState('');
  const [dthPlansDescriptionKey, setDthPlansDescriptionKey] = useState('');

  // DTH Roffers
  const [dthRoffersApiEndpoint, setDthRoffersApiEndpoint] = useState('');
  const [dthRoffersBody, setDthRoffersBody] = useState('');
  const [dthRoffersMethod, setDthRoffersMethod] = useState('');
  const [dthRoffersStatusKey, setDthRoffersStatusKey] = useState('');
  const [dthRoffersSuccessValue, setDthRoffersSuccessValue] = useState('');
  const [dthRoffersFailedValue, setDthRoffersFailedValue] = useState('');
  const [dthRoffersResponseType, setDthRoffersResponseType] = useState('');
  const [dthRoffersRoffersKey, setDthRoffersRoffersKey] = useState('');
  const [dthRoffersAmountKey, setDthRoffersAmountKey] = useState('');
  const [dthRoffersValidityKey, setDthRoffersValidityKey] = useState('');
  const [dthRoffersDescriptionKey, setDthRoffersDescriptionKey] = useState('');

  // DTH Customer Info
  const [dthCustomerInfoApiEndpoint, setDthCustomerInfoApiEndpoint] =
    useState('');
  const [dthCustomerInfoBody, setDthCustomerInfoBody] = useState('');
  const [dthCustomerInfoMethod, setDthCustomerInfoMethod] = useState('');
  const [dthCustomerInfoResponseType, setDthCustomerInfoResponseType] =
    useState('');
  const [dthCustomerInfoStatusKey, setDthCustomerInfoStatusKey] = useState('');
  const [dthCustomerInfoSuccessValue, setDthCustomerInfoSuccessValue] =
    useState('');
  const [dthCustomerInfoFailedValue, setDthCustomerInfoFailedValue] =
    useState('');
  const [dthCustomerInfoNameKey, setDthCustomerInfoNameKey] = useState('');
  const [dthCustomerInfoRmnKey, setDthCustomerInfoRmnKey] = useState('');
  const [dthCustomerInfoPlanKey, setDthCustomerInfoPlanKey] = useState('');
  const [
    dthCustomerInfoMonthlyRechargeKey,
    setDthCustomerInfoMonthlyRechargeKey,
  ] = useState('');
  const [dthCustomerInfoBalanceKey, setDthCustomerInfoBalanceKey] =
    useState('');
  const [
    dthCustomerInfoNextRechargeDateKey,
    setDthCustomerInfoNextRechargeDateKey,
  ] = useState('');
  const [dthCustomerInfoBoxStatusKey, setDthCustomerInfoBoxStatusKey] =
    useState('');

  // DTH Heavy Refresh
  const [dthHeavyRefreshApiEndpoint, setDthHeavyRefreshApiEndpoint] =
    useState('');
  const [dthHeavyRefreshBody, setDthHeavyRefreshBody] = useState('');
  const [dthHeavyRefreshMethod, setDthHeavyRefreshMethod] = useState('');
  const [dthHeavyRefreshResponseType, setDthHeavyRefreshResponseType] =
    useState('');
  const [dthHeavyRefreshStatusKey, setDthHeavyRefreshStatusKey] = useState('');
  const [dthHeavyRefreshSuccessValue, setDthHeavyRefreshSuccessValue] =
    useState('');
  const [dthHeavyRefreshFailedValue, setDthHeavyRefreshFailedValue] =
    useState('');

  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      // Number Details
      numberDetailsApiEndpoint,
      numberDetailsApiRequestMethod,
      numberDetailsApiRequestBody,
      numberDetailsApiResponseType,
      numberDetailsApiStatusKey,
      numberDetailsApiSuccessKey,
      numberDetailsApiOperatorKey,
      numberDetailsApiCircleKey,

      // Mobile Plans
      mobilePlansApiEndpoint,
      mobilePlansBody,
      mobilePlansMethod,
      mobilePlansStatusKey,
      mobilePlansSuccessValue,
      mobilePlansFailedValue,
      mobilePlansResponseType,
      mobilePlansPlansKey,
      mobilePlansAmountKey,
      mobilePlansValidityKey,
      mobilePlansDescriptionKey,

      // Mobile Roffers
      mobileRoffersApiEndpoint,
      mobileRoffersBody,
      mobileRoffersMethod,
      mobileRoffersStatusKey,
      mobileRoffersSuccessValue,
      mobileRoffersFailedValue,
      mobileRoffersResponseType,
      mobileRoffersRoffersKey,
      mobileRoffersAmountKey,
      mobileRoffersValidityKey,
      mobileRoffersDescriptionKey,

      // DTH Plans
      dthPlansApiEndpoint,
      dthPlansBody,
      dthPlansMethod,
      dthPlansStatusKey,
      dthPlansSuccessValue,
      dthPlansFailedValue,
      dthPlansResponseType,
      dthPlansPlansKey,
      dthPlansAmountKey,
      dthPlansValidityKey,
      dthPlansDescriptionKey,

      // DTH Roffers
      dthRoffersApiEndpoint,
      dthRoffersBody,
      dthRoffersMethod,
      dthRoffersStatusKey,
      dthRoffersSuccessValue,
      dthRoffersFailedValue,
      dthRoffersResponseType,
      dthRoffersRoffersKey,
      dthRoffersAmountKey,
      dthRoffersValidityKey,
      dthRoffersDescriptionKey,

      // DTH Customer Info
      dthCustomerInfoApiEndpoint,
      dthCustomerInfoBody,
      dthCustomerInfoMethod,
      dthCustomerInfoResponseType,
      dthCustomerInfoStatusKey,
      dthCustomerInfoSuccessValue,
      dthCustomerInfoFailedValue,
      dthCustomerInfoNameKey,
      dthCustomerInfoRmnKey,
      dthCustomerInfoPlanKey,
      dthCustomerInfoMonthlyRechargeKey,
      dthCustomerInfoBalanceKey,
      dthCustomerInfoNextRechargeDateKey,
      dthCustomerInfoBoxStatusKey,

      // DTH Heavy Refresh
      dthHeavyRefreshApiEndpoint,
      dthHeavyRefreshBody,
      dthHeavyRefreshMethod,
      dthHeavyRefreshResponseType,
      dthHeavyRefreshStatusKey,
      dthHeavyRefreshSuccessValue,
      dthHeavyRefreshFailedValue,
    } = originalFormData;
    // Number Details
    setNumberDetailsApiEndpoint(numberDetailsApiEndpoint);
    setNumberDetailsApiRequestMethod(numberDetailsApiRequestMethod);
    setNumberDetailsApiRequestBody(numberDetailsApiRequestBody);
    setNumberDetailsApiResponseType(numberDetailsApiResponseType);
    setNumberDetailsApiStatusKey(numberDetailsApiStatusKey);
    setNumberDetailsApiSuccessKey(numberDetailsApiSuccessKey);
    setNumberDetailsApiOperatorKey(numberDetailsApiOperatorKey);
    setNumberDetailsApiCircleKey(numberDetailsApiCircleKey);

    // Mobile Plans
    setMobilePlansApiEndpoint(mobilePlansApiEndpoint);
    setMobilePlansBody(mobilePlansBody);
    setMobilePlansMethod(mobilePlansMethod);
    setMobilePlansStatusKey(mobilePlansStatusKey);
    setMobilePlansSuccessValue(mobilePlansSuccessValue);
    setMobilePlansFailedValue(mobilePlansFailedValue);
    setMobilePlansResponseType(mobilePlansResponseType);
    setMobilePlansPlansKey(mobilePlansPlansKey);
    setMobilePlansAmountKey(mobilePlansAmountKey);
    setMobilePlansValidityKey(mobilePlansValidityKey);
    setMobilePlansDescriptionKey(mobilePlansDescriptionKey);

    // Mobile Roffers
    setMobileRoffersApiEndpoint(mobileRoffersApiEndpoint);
    setMobileRoffersBody(mobileRoffersBody);
    setMobileRoffersMethod(mobileRoffersMethod);
    setMobileRoffersStatusKey(mobileRoffersStatusKey);
    setMobileRoffersSuccessValue(mobileRoffersSuccessValue);
    setMobileRoffersFailedValue(mobileRoffersFailedValue);
    setMobileRoffersResponseType(mobileRoffersResponseType);
    setMobileRoffersRoffersKey(mobileRoffersRoffersKey);
    setMobileRoffersAmountKey(mobileRoffersAmountKey);
    setMobileRoffersValidityKey(mobileRoffersValidityKey);
    setMobileRoffersDescriptionKey(mobileRoffersDescriptionKey);

    // DTH Plans
    setDthPlansApiEndpoint(dthPlansApiEndpoint);
    setDthPlansBody(dthPlansBody);
    setDthPlansMethod(dthPlansMethod);
    setDthPlansStatusKey(dthPlansStatusKey);
    setDthPlansSuccessValue(dthPlansSuccessValue);
    setDthPlansFailedValue(dthPlansFailedValue);
    setDthPlansResponseType(dthPlansResponseType);
    setDthPlansPlansKey(dthPlansPlansKey);
    setDthPlansAmountKey(dthPlansAmountKey);
    setDthPlansValidityKey(dthPlansValidityKey);
    setDthPlansDescriptionKey(dthPlansDescriptionKey);

    // DTH Roffers
    setDthRoffersApiEndpoint(dthRoffersApiEndpoint);
    setDthRoffersBody(dthRoffersBody);
    setDthRoffersMethod(dthRoffersMethod);
    setDthRoffersStatusKey(dthRoffersStatusKey);
    setDthRoffersSuccessValue(dthRoffersSuccessValue);
    setDthRoffersFailedValue(dthRoffersFailedValue);
    setDthRoffersResponseType(dthRoffersResponseType);
    setDthRoffersRoffersKey(dthRoffersRoffersKey);
    setDthRoffersAmountKey(dthRoffersAmountKey);
    setDthRoffersValidityKey(dthRoffersValidityKey);
    setDthRoffersDescriptionKey(dthRoffersDescriptionKey);

    // DTH Customer Info
    setDthCustomerInfoApiEndpoint(dthCustomerInfoApiEndpoint);
    setDthCustomerInfoBody(dthCustomerInfoBody);
    setDthCustomerInfoMethod(dthCustomerInfoMethod);
    setDthCustomerInfoResponseType(dthCustomerInfoResponseType);
    setDthCustomerInfoStatusKey(dthCustomerInfoStatusKey);
    setDthCustomerInfoSuccessValue(dthCustomerInfoSuccessValue);
    setDthCustomerInfoFailedValue(dthCustomerInfoFailedValue);
    setDthCustomerInfoNameKey(dthCustomerInfoNameKey);
    setDthCustomerInfoRmnKey(dthCustomerInfoRmnKey);
    setDthCustomerInfoPlanKey(dthCustomerInfoPlanKey);
    setDthCustomerInfoMonthlyRechargeKey(dthCustomerInfoMonthlyRechargeKey);
    setDthCustomerInfoBalanceKey(dthCustomerInfoBalanceKey);
    setDthCustomerInfoNextRechargeDateKey(dthCustomerInfoNextRechargeDateKey);
    setDthCustomerInfoBoxStatusKey(dthCustomerInfoBoxStatusKey);

    // DTH Heavy Refresh
    setDthHeavyRefreshApiEndpoint(dthHeavyRefreshApiEndpoint);
    setDthHeavyRefreshBody(dthHeavyRefreshBody);
    setDthHeavyRefreshMethod(dthHeavyRefreshMethod);
    setDthHeavyRefreshResponseType(dthHeavyRefreshResponseType);
    setDthHeavyRefreshStatusKey(dthHeavyRefreshStatusKey);
    setDthHeavyRefreshSuccessValue(dthHeavyRefreshSuccessValue);
    setDthHeavyRefreshFailedValue(dthHeavyRefreshFailedValue);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/common-apis-configuration`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            // Number Details
            numberDetailsApiEndpoint,
            numberDetailsApiRequestMethod,
            numberDetailsApiRequestBody,
            numberDetailsApiResponseType,
            numberDetailsApiStatusKey,
            numberDetailsApiSuccessKey,
            numberDetailsApiOperatorKey,
            numberDetailsApiCircleKey,

            // Mobile Plans
            mobilePlansApiEndpoint,
            mobilePlansBody,
            mobilePlansMethod,
            mobilePlansStatusKey,
            mobilePlansSuccessValue,
            mobilePlansFailedValue,
            mobilePlansResponseType,
            mobilePlansPlansKey,
            mobilePlansAmountKey,
            mobilePlansValidityKey,
            mobilePlansDescriptionKey,

            // Mobile Roffers
            mobileRoffersApiEndpoint,
            mobileRoffersBody,
            mobileRoffersMethod,
            mobileRoffersStatusKey,
            mobileRoffersSuccessValue,
            mobileRoffersFailedValue,
            mobileRoffersResponseType,
            mobileRoffersRoffersKey,
            mobileRoffersAmountKey,
            mobileRoffersValidityKey,
            mobileRoffersDescriptionKey,

            // DTH Plans
            dthPlansApiEndpoint,
            dthPlansBody,
            dthPlansMethod,
            dthPlansStatusKey,
            dthPlansSuccessValue,
            dthPlansFailedValue,
            dthPlansResponseType,
            dthPlansPlansKey,
            dthPlansAmountKey,
            dthPlansValidityKey,
            dthPlansDescriptionKey,

            // DTH Roffers
            dthRoffersApiEndpoint,
            dthRoffersBody,
            dthRoffersMethod,
            dthRoffersStatusKey,
            dthRoffersSuccessValue,
            dthRoffersFailedValue,
            dthRoffersResponseType,
            dthRoffersRoffersKey,
            dthRoffersAmountKey,
            dthRoffersValidityKey,
            dthRoffersDescriptionKey,

            // DTH Customer Info
            dthCustomerInfoApiEndpoint,
            dthCustomerInfoBody,
            dthCustomerInfoMethod,
            dthCustomerInfoResponseType,
            dthCustomerInfoStatusKey,
            dthCustomerInfoSuccessValue,
            dthCustomerInfoFailedValue,
            dthCustomerInfoNameKey,
            dthCustomerInfoRmnKey,
            dthCustomerInfoPlanKey,
            dthCustomerInfoMonthlyRechargeKey,
            dthCustomerInfoBalanceKey,
            dthCustomerInfoNextRechargeDateKey,
            dthCustomerInfoBoxStatusKey,

            // DTH Heavy Refresh
            dthHeavyRefreshApiEndpoint,
            dthHeavyRefreshBody,
            dthHeavyRefreshMethod,
            dthHeavyRefreshResponseType,
            dthHeavyRefreshStatusKey,
            dthHeavyRefreshSuccessValue,
            dthHeavyRefreshFailedValue,
          } = data;
          // Number Details
          setNumberDetailsApiEndpoint(numberDetailsApiEndpoint);
          setNumberDetailsApiRequestMethod(numberDetailsApiRequestMethod);
          setNumberDetailsApiRequestBody(numberDetailsApiRequestBody);
          setNumberDetailsApiResponseType(numberDetailsApiResponseType);
          setNumberDetailsApiStatusKey(numberDetailsApiStatusKey);
          setNumberDetailsApiSuccessKey(numberDetailsApiSuccessKey);
          setNumberDetailsApiOperatorKey(numberDetailsApiOperatorKey);
          setNumberDetailsApiCircleKey(numberDetailsApiCircleKey);

          // Mobile Plans
          setMobilePlansApiEndpoint(mobilePlansApiEndpoint);
          setMobilePlansBody(mobilePlansBody);
          setMobilePlansMethod(mobilePlansMethod);
          setMobilePlansStatusKey(mobilePlansStatusKey);
          setMobilePlansSuccessValue(mobilePlansSuccessValue);
          setMobilePlansFailedValue(mobilePlansFailedValue);
          setMobilePlansResponseType(mobilePlansResponseType);
          setMobilePlansPlansKey(mobilePlansPlansKey);
          setMobilePlansAmountKey(mobilePlansAmountKey);
          setMobilePlansValidityKey(mobilePlansValidityKey);
          setMobilePlansDescriptionKey(mobilePlansDescriptionKey);

          // Mobile Roffers
          setMobileRoffersApiEndpoint(mobileRoffersApiEndpoint);
          setMobileRoffersBody(mobileRoffersBody);
          setMobileRoffersMethod(mobileRoffersMethod);
          setMobileRoffersStatusKey(mobileRoffersStatusKey);
          setMobileRoffersSuccessValue(mobileRoffersSuccessValue);
          setMobileRoffersFailedValue(mobileRoffersFailedValue);
          setMobileRoffersResponseType(mobileRoffersResponseType);
          setMobileRoffersRoffersKey(mobileRoffersRoffersKey);
          setMobileRoffersAmountKey(mobileRoffersAmountKey);
          setMobileRoffersValidityKey(mobileRoffersValidityKey);
          setMobileRoffersDescriptionKey(mobileRoffersDescriptionKey);

          // DTH Plans
          setDthPlansApiEndpoint(dthPlansApiEndpoint);
          setDthPlansBody(dthPlansBody);
          setDthPlansMethod(dthPlansMethod);
          setDthPlansStatusKey(dthPlansStatusKey);
          setDthPlansSuccessValue(dthPlansSuccessValue);
          setDthPlansFailedValue(dthPlansFailedValue);
          setDthPlansResponseType(dthPlansResponseType);
          setDthPlansPlansKey(dthPlansPlansKey);
          setDthPlansAmountKey(dthPlansAmountKey);
          setDthPlansValidityKey(dthPlansValidityKey);
          setDthPlansDescriptionKey(dthPlansDescriptionKey);

          // DTH Roffers
          setDthRoffersApiEndpoint(dthRoffersApiEndpoint);
          setDthRoffersBody(dthRoffersBody);
          setDthRoffersMethod(dthRoffersMethod);
          setDthRoffersStatusKey(dthRoffersStatusKey);
          setDthRoffersSuccessValue(dthRoffersSuccessValue);
          setDthRoffersFailedValue(dthRoffersFailedValue);
          setDthRoffersResponseType(dthRoffersResponseType);
          setDthRoffersRoffersKey(dthRoffersRoffersKey);
          setDthRoffersAmountKey(dthRoffersAmountKey);
          setDthRoffersValidityKey(dthRoffersValidityKey);
          setDthRoffersDescriptionKey(dthRoffersDescriptionKey);

          // DTH Customer Info
          setDthCustomerInfoApiEndpoint(dthCustomerInfoApiEndpoint);
          setDthCustomerInfoBody(dthCustomerInfoBody);
          setDthCustomerInfoMethod(dthCustomerInfoMethod);
          setDthCustomerInfoResponseType(dthCustomerInfoResponseType);
          setDthCustomerInfoStatusKey(dthCustomerInfoStatusKey);
          setDthCustomerInfoSuccessValue(dthCustomerInfoSuccessValue);
          setDthCustomerInfoFailedValue(dthCustomerInfoFailedValue);
          setDthCustomerInfoNameKey(dthCustomerInfoNameKey);
          setDthCustomerInfoRmnKey(dthCustomerInfoRmnKey);
          setDthCustomerInfoPlanKey(dthCustomerInfoPlanKey);
          setDthCustomerInfoMonthlyRechargeKey(
            dthCustomerInfoMonthlyRechargeKey
          );
          setDthCustomerInfoBalanceKey(dthCustomerInfoBalanceKey);
          setDthCustomerInfoNextRechargeDateKey(
            dthCustomerInfoNextRechargeDateKey
          );
          setDthCustomerInfoBoxStatusKey(dthCustomerInfoBoxStatusKey);

          // DTH Heavy Refresh
          setDthHeavyRefreshApiEndpoint(dthHeavyRefreshApiEndpoint);
          setDthHeavyRefreshBody(dthHeavyRefreshBody);
          setDthHeavyRefreshMethod(dthHeavyRefreshMethod);
          setDthHeavyRefreshResponseType(dthHeavyRefreshResponseType);
          setDthHeavyRefreshStatusKey(dthHeavyRefreshStatusKey);
          setDthHeavyRefreshSuccessValue(dthHeavyRefreshSuccessValue);
          setDthHeavyRefreshFailedValue(dthHeavyRefreshFailedValue);

          setOriginalFormData({
            // Number Details
            numberDetailsApiEndpoint,
            numberDetailsApiRequestMethod,
            numberDetailsApiRequestBody,
            numberDetailsApiResponseType,
            numberDetailsApiStatusKey,
            numberDetailsApiSuccessKey,
            numberDetailsApiOperatorKey,
            numberDetailsApiCircleKey,

            // Mobile Plans
            mobilePlansApiEndpoint,
            mobilePlansBody,
            mobilePlansMethod,
            mobilePlansStatusKey,
            mobilePlansSuccessValue,
            mobilePlansFailedValue,
            mobilePlansResponseType,
            mobilePlansPlansKey,
            mobilePlansAmountKey,
            mobilePlansValidityKey,
            mobilePlansDescriptionKey,

            // Mobile Roffers
            mobileRoffersApiEndpoint,
            mobileRoffersBody,
            mobileRoffersMethod,
            mobileRoffersStatusKey,
            mobileRoffersSuccessValue,
            mobileRoffersFailedValue,
            mobileRoffersResponseType,
            mobileRoffersRoffersKey,
            mobileRoffersAmountKey,
            mobileRoffersValidityKey,
            mobileRoffersDescriptionKey,

            // DTH Plans
            dthPlansApiEndpoint,
            dthPlansBody,
            dthPlansMethod,
            dthPlansStatusKey,
            dthPlansSuccessValue,
            dthPlansFailedValue,
            dthPlansResponseType,
            dthPlansPlansKey,
            dthPlansAmountKey,
            dthPlansValidityKey,
            dthPlansDescriptionKey,

            // DTH Roffers
            dthRoffersApiEndpoint,
            dthRoffersBody,
            dthRoffersMethod,
            dthRoffersStatusKey,
            dthRoffersSuccessValue,
            dthRoffersFailedValue,
            dthRoffersResponseType,
            dthRoffersRoffersKey,
            dthRoffersAmountKey,
            dthRoffersValidityKey,
            dthRoffersDescriptionKey,

            // DTH Customer Info
            dthCustomerInfoApiEndpoint,
            dthCustomerInfoBody,
            dthCustomerInfoMethod,
            dthCustomerInfoResponseType,
            dthCustomerInfoStatusKey,
            dthCustomerInfoSuccessValue,
            dthCustomerInfoFailedValue,
            dthCustomerInfoNameKey,
            dthCustomerInfoRmnKey,
            dthCustomerInfoPlanKey,
            dthCustomerInfoMonthlyRechargeKey,
            dthCustomerInfoBalanceKey,
            dthCustomerInfoNextRechargeDateKey,
            dthCustomerInfoBoxStatusKey,

            // DTH Heavy Refresh
            dthHeavyRefreshApiEndpoint,
            dthHeavyRefreshBody,
            dthHeavyRefreshMethod,
            dthHeavyRefreshResponseType,
            dthHeavyRefreshStatusKey,
            dthHeavyRefreshSuccessValue,
            dthHeavyRefreshFailedValue,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      // Number Details
      numberDetailsApiEndpoint,
      numberDetailsApiRequestMethod,
      numberDetailsApiRequestBody,
      numberDetailsApiResponseType,
      numberDetailsApiStatusKey,
      numberDetailsApiSuccessKey,
      numberDetailsApiOperatorKey,
      numberDetailsApiCircleKey,

      // Mobile Plans
      mobilePlansApiEndpoint,
      mobilePlansBody,
      mobilePlansMethod,
      mobilePlansStatusKey,
      mobilePlansSuccessValue,
      mobilePlansFailedValue,
      mobilePlansResponseType,
      mobilePlansPlansKey,
      mobilePlansAmountKey,
      mobilePlansValidityKey,
      mobilePlansDescriptionKey,

      // Mobile Roffers
      mobileRoffersApiEndpoint,
      mobileRoffersBody,
      mobileRoffersMethod,
      mobileRoffersStatusKey,
      mobileRoffersSuccessValue,
      mobileRoffersFailedValue,
      mobileRoffersResponseType,
      mobileRoffersRoffersKey,
      mobileRoffersAmountKey,
      mobileRoffersValidityKey,
      mobileRoffersDescriptionKey,

      // DTH Plans
      dthPlansApiEndpoint,
      dthPlansBody,
      dthPlansMethod,
      dthPlansStatusKey,
      dthPlansSuccessValue,
      dthPlansFailedValue,
      dthPlansResponseType,
      dthPlansPlansKey,
      dthPlansAmountKey,
      dthPlansValidityKey,
      dthPlansDescriptionKey,

      // DTH Roffers
      dthRoffersApiEndpoint,
      dthRoffersBody,
      dthRoffersMethod,
      dthRoffersStatusKey,
      dthRoffersSuccessValue,
      dthRoffersFailedValue,
      dthRoffersResponseType,
      dthRoffersRoffersKey,
      dthRoffersAmountKey,
      dthRoffersValidityKey,
      dthRoffersDescriptionKey,

      // DTH Customer Info
      dthCustomerInfoApiEndpoint,
      dthCustomerInfoBody,
      dthCustomerInfoMethod,
      dthCustomerInfoResponseType,
      dthCustomerInfoStatusKey,
      dthCustomerInfoSuccessValue,
      dthCustomerInfoFailedValue,
      dthCustomerInfoNameKey,
      dthCustomerInfoRmnKey,
      dthCustomerInfoPlanKey,
      dthCustomerInfoMonthlyRechargeKey,
      dthCustomerInfoBalanceKey,
      dthCustomerInfoNextRechargeDateKey,
      dthCustomerInfoBoxStatusKey,

      // DTH Heavy Refresh
      dthHeavyRefreshApiEndpoint,
      dthHeavyRefreshBody,
      dthHeavyRefreshMethod,
      dthHeavyRefreshResponseType,
      dthHeavyRefreshStatusKey,
      dthHeavyRefreshSuccessValue,
      dthHeavyRefreshFailedValue,
    };
    API.put(`/recharge-configurations/common-apis-configuration`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          {/* Number Details */}
          <Fieldset forLegend>
            <Legend>Number Details API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={numberDetailsApiEndpoint}
                onChange={setNumberDetailsApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={numberDetailsApiRequestBody}
                onChange={setNumberDetailsApiRequestBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setNumberDetailsApiRequestBody, '{{{NUMBER}}}')
                }
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setNumberDetailsApiRequestBody, '{{{OPERATOR}}}')
                }
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setNumberDetailsApiRequestBody, '{{{CIRCLE}}}')
                }
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={numberDetailsApiRequestMethod}
                    onChange={setNumberDetailsApiRequestMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={numberDetailsApiResponseType}
                    onChange={setNumberDetailsApiResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={numberDetailsApiStatusKey}
                    onChange={setNumberDetailsApiStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Key</Label>
                  <TextInput
                    value={numberDetailsApiSuccessKey}
                    onChange={setNumberDetailsApiSuccessKey}
                    placeholder='Please enter success key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Operator Key</Label>
                  <TextInput
                    value={numberDetailsApiOperatorKey}
                    onChange={setNumberDetailsApiOperatorKey}
                    placeholder='Please enter operator key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Circle Key</Label>
                  <TextInput
                    value={numberDetailsApiCircleKey}
                    onChange={setNumberDetailsApiCircleKey}
                    placeholder='Please enter circle key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {/* Mobile Plans */}
          <Fieldset forLegend>
            <Legend>Mobile Plans API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={mobilePlansApiEndpoint}
                onChange={setMobilePlansApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={mobilePlansBody}
                onChange={setMobilePlansBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setMobilePlansBody, '{{{NUMBER}}}')}
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setMobilePlansBody, '{{{OPERATOR}}}')}
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setMobilePlansBody, '{{{CIRCLE}}}')}
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={mobilePlansMethod}
                    onChange={setMobilePlansMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={mobilePlansResponseType}
                    onChange={setMobilePlansResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={mobilePlansStatusKey}
                    onChange={setMobilePlansStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Value</Label>
                  <TextInput
                    value={mobilePlansSuccessValue}
                    onChange={setMobilePlansSuccessValue}
                    placeholder='Please enter success value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failed Value</Label>
                  <TextInput
                    value={mobilePlansFailedValue}
                    onChange={setMobilePlansFailedValue}
                    placeholder='Please enter failed value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Plans Key</Label>
                  <TextInput
                    value={mobilePlansPlansKey}
                    onChange={setMobilePlansPlansKey}
                    placeholder='Please enter plans key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Amount Key</Label>
                  <TextInput
                    value={mobilePlansAmountKey}
                    onChange={setMobilePlansAmountKey}
                    placeholder='Please enter amount key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Validity Key</Label>
                  <TextInput
                    value={mobilePlansValidityKey}
                    onChange={setMobilePlansValidityKey}
                    placeholder='Please enter validity key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Description Key</Label>
                  <TextInput
                    value={mobilePlansDescriptionKey}
                    onChange={setMobilePlansDescriptionKey}
                    placeholder='Please enter description key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {/* Mobile Roffers */}
          <Fieldset forLegend>
            <Legend>Mobile Roffers API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={mobileRoffersApiEndpoint}
                onChange={setMobileRoffersApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={mobileRoffersBody}
                onChange={setMobileRoffersBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setMobileRoffersBody, '{{{NUMBER}}}')}
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setMobileRoffersBody, '{{{OPERATOR}}}')}
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setMobileRoffersBody, '{{{CIRCLE}}}')}
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={mobileRoffersMethod}
                    onChange={setMobileRoffersMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={mobileRoffersResponseType}
                    onChange={setMobileRoffersResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={mobileRoffersStatusKey}
                    onChange={setMobileRoffersStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Value</Label>
                  <TextInput
                    value={mobileRoffersSuccessValue}
                    onChange={setMobileRoffersSuccessValue}
                    placeholder='Please enter success value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failed Value</Label>
                  <TextInput
                    value={mobileRoffersFailedValue}
                    onChange={setMobileRoffersFailedValue}
                    placeholder='Please enter failed value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Roffers Key</Label>
                  <TextInput
                    value={mobileRoffersRoffersKey}
                    onChange={setMobileRoffersRoffersKey}
                    placeholder='Please enter roffers key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Amount Key</Label>
                  <TextInput
                    value={mobileRoffersAmountKey}
                    onChange={setMobileRoffersAmountKey}
                    placeholder='Please enter amount key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Validity Key</Label>
                  <TextInput
                    value={mobileRoffersValidityKey}
                    onChange={setMobileRoffersValidityKey}
                    placeholder='Please enter validity key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Description Key</Label>
                  <TextInput
                    value={mobileRoffersDescriptionKey}
                    onChange={setMobileRoffersDescriptionKey}
                    placeholder='Please enter description key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {/* DTH Plans */}
          <Fieldset forLegend>
            <Legend>DTH Plans API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={dthPlansApiEndpoint}
                onChange={setDthPlansApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={dthPlansBody}
                onChange={setDthPlansBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthPlansBody, '{{{NUMBER}}}')}
              >
                Account Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthPlansBody, '{{{OPERATOR}}}')}
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthPlansBody, '{{{CIRCLE}}}')}
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={dthPlansMethod}
                    onChange={setDthPlansMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={dthPlansResponseType}
                    onChange={setDthPlansResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={dthPlansStatusKey}
                    onChange={setDthPlansStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Value</Label>
                  <TextInput
                    value={dthPlansSuccessValue}
                    onChange={setDthPlansSuccessValue}
                    placeholder='Please enter success value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failed Value</Label>
                  <TextInput
                    value={dthPlansFailedValue}
                    onChange={setDthPlansFailedValue}
                    placeholder='Please enter failed value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Plans Key</Label>
                  <TextInput
                    value={dthPlansPlansKey}
                    onChange={setDthPlansPlansKey}
                    placeholder='Please enter plans key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Amount Key</Label>
                  <TextInput
                    value={dthPlansAmountKey}
                    onChange={setDthPlansAmountKey}
                    placeholder='Please enter amount key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Validity Key</Label>
                  <TextInput
                    value={dthPlansValidityKey}
                    onChange={setDthPlansValidityKey}
                    placeholder='Please enter validity key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Description Key</Label>
                  <TextInput
                    value={dthPlansDescriptionKey}
                    onChange={setDthPlansDescriptionKey}
                    placeholder='Please enter description key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {/* DTH Roffers */}
          <Fieldset forLegend>
            <Legend>DTH Roffers API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={dthRoffersApiEndpoint}
                onChange={setDthRoffersApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={dthRoffersBody}
                onChange={setDthRoffersBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthRoffersBody, '{{{NUMBER}}}')}
              >
                Account Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthRoffersBody, '{{{OPERATOR}}}')}
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthRoffersBody, '{{{CIRCLE}}}')}
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={dthRoffersMethod}
                    onChange={setDthRoffersMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={dthRoffersResponseType}
                    onChange={setDthRoffersResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={dthRoffersStatusKey}
                    onChange={setDthRoffersStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Value</Label>
                  <TextInput
                    value={dthRoffersSuccessValue}
                    onChange={setDthRoffersSuccessValue}
                    placeholder='Please enter success value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failed Value</Label>
                  <TextInput
                    value={dthRoffersFailedValue}
                    onChange={setDthRoffersFailedValue}
                    placeholder='Please enter failed value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Roffers Key</Label>
                  <TextInput
                    value={dthRoffersRoffersKey}
                    onChange={setDthRoffersRoffersKey}
                    placeholder='Please enter roffers key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Amount Key</Label>
                  <TextInput
                    value={dthRoffersAmountKey}
                    onChange={setDthRoffersAmountKey}
                    placeholder='Please enter amount key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Validity Key</Label>
                  <TextInput
                    value={dthRoffersValidityKey}
                    onChange={setDthRoffersValidityKey}
                    placeholder='Please enter validity key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Description Key</Label>
                  <TextInput
                    value={dthRoffersDescriptionKey}
                    onChange={setDthRoffersDescriptionKey}
                    placeholder='Please enter description key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {/* DTH Customer Info */}
          <Fieldset forLegend>
            <Legend>DTH Customer Info API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={dthCustomerInfoApiEndpoint}
                onChange={setDthCustomerInfoApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={dthCustomerInfoBody}
                onChange={setDthCustomerInfoBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthCustomerInfoBody, '{{{NUMBER}}}')}
              >
                Account Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setDthCustomerInfoBody, '{{{OPERATOR}}}')
                }
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthCustomerInfoBody, '{{{CIRCLE}}}')}
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={dthCustomerInfoMethod}
                    onChange={setDthCustomerInfoMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={dthCustomerInfoResponseType}
                    onChange={setDthCustomerInfoResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={dthCustomerInfoStatusKey}
                    onChange={setDthCustomerInfoStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Value</Label>
                  <TextInput
                    value={dthCustomerInfoSuccessValue}
                    onChange={setDthCustomerInfoSuccessValue}
                    placeholder='Please enter success value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failed Value</Label>
                  <TextInput
                    value={dthCustomerInfoFailedValue}
                    onChange={setDthCustomerInfoFailedValue}
                    placeholder='Please enter failed value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Name Key</Label>
                  <TextInput
                    value={dthCustomerInfoNameKey}
                    onChange={setDthCustomerInfoNameKey}
                    placeholder='Please enter name key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>RMN Key</Label>
                  <TextInput
                    value={dthCustomerInfoRmnKey}
                    onChange={setDthCustomerInfoRmnKey}
                    placeholder='Please enter rmn key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Plan Key</Label>
                  <TextInput
                    value={dthCustomerInfoPlanKey}
                    onChange={setDthCustomerInfoPlanKey}
                    placeholder='Please enter plan key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Monthly Recharge Key</Label>
                  <TextInput
                    value={dthCustomerInfoMonthlyRechargeKey}
                    onChange={setDthCustomerInfoMonthlyRechargeKey}
                    placeholder='Please enter monthly recharge key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Balance Key</Label>
                  <TextInput
                    value={dthCustomerInfoBalanceKey}
                    onChange={setDthCustomerInfoBalanceKey}
                    placeholder='Please enter balance key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Next Recharge Key</Label>
                  <TextInput
                    value={dthCustomerInfoNextRechargeDateKey}
                    onChange={setDthCustomerInfoNextRechargeDateKey}
                    placeholder='Please enter next recharge key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Box Status Key</Label>
                  <TextInput
                    value={dthCustomerInfoBoxStatusKey}
                    onChange={setDthCustomerInfoBoxStatusKey}
                    placeholder='Please enter box status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {/* DTH Heavy Refresh */}
          <Fieldset forLegend>
            <Legend>DTH Heavy Refresh API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={dthHeavyRefreshApiEndpoint}
                onChange={setDthHeavyRefreshApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={dthHeavyRefreshBody}
                onChange={setDthHeavyRefreshBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthHeavyRefreshBody, '{{{NUMBER}}}')}
              >
                Account Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setDthHeavyRefreshBody, '{{{OPERATOR}}}')
                }
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() => addText(setDthHeavyRefreshBody, '{{{CIRCLE}}}')}
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={dthHeavyRefreshMethod}
                    onChange={setDthHeavyRefreshMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={dthHeavyRefreshResponseType}
                    onChange={setDthHeavyRefreshResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={dthHeavyRefreshStatusKey}
                    onChange={setDthHeavyRefreshStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Value</Label>
                  <TextInput
                    value={dthHeavyRefreshSuccessValue}
                    onChange={setDthHeavyRefreshSuccessValue}
                    placeholder='Please enter success value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failed Value</Label>
                  <TextInput
                    value={dthHeavyRefreshFailedValue}
                    onChange={setDthHeavyRefreshFailedValue}
                    placeholder='Please enter failed value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(PlanAPIConfiguration);
