import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  MessageBox,
  UpdateButton,
  ResetButton,
  TextInput,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';

const PushNotificationsConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [fcmMobileServerToken, setFcmMobileServerToken] = useState('');
  const [fcmWebServerToken, setFcmWebServerToken] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [authDomain, setAuthDomain] = useState('');
  const [projectId, setProjectId] = useState('');
  const [storageBucket, setStorageBucket] = useState('');
  const [messagingSenderId, setMessagingSenderId] = useState('');
  const [appId, setAppId] = useState('');
  const [measurementId, setMeasurementId] = useState('');
  const [vapidKey, setVapidKey] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      fcmMobileServerToken,
      fcmWebServerToken,
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId,
      vapidKey,
    } = originalFormData;
    setFcmMobileServerToken(fcmMobileServerToken);
    setFcmWebServerToken(fcmWebServerToken);
    setApiKey(apiKey);
    setAuthDomain(authDomain);
    setProjectId(projectId);
    setStorageBucket(storageBucket);
    setMessagingSenderId(messagingSenderId);
    setAppId(appId);
    setMeasurementId(measurementId);
    setVapidKey(vapidKey);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/configurations/push-notifications-configuration')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            fcmMobileServerToken,
            fcmWebServerToken,
            apiKey,
            authDomain,
            projectId,
            storageBucket,
            messagingSenderId,
            appId,
            measurementId,
            vapidKey,
          } = data;
          setFcmMobileServerToken(fcmMobileServerToken);
          setFcmWebServerToken(fcmWebServerToken);
          setApiKey(apiKey);
          setAuthDomain(authDomain);
          setProjectId(projectId);
          setStorageBucket(storageBucket);
          setMessagingSenderId(messagingSenderId);
          setAppId(appId);
          setMeasurementId(measurementId);
          setVapidKey(vapidKey);
          setOriginalFormData({
            fcmMobileServerToken,
            fcmWebServerToken,
            apiKey,
            authDomain,
            projectId,
            storageBucket,
            messagingSenderId,
            appId,
            measurementId,
            vapidKey,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      fcmMobileServerToken,
      fcmWebServerToken,
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId,
      vapidKey,
    };
    API.put(`/configurations/push-notifications-configuration`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label>Google FCM Mobile Server Token</Label>
            <TextInput
              placeholder='Please enter FCM mobile server token'
              value={fcmMobileServerToken}
              onChange={setFcmMobileServerToken}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web Server Token</Label>
            <TextInput
              placeholder='Please enter FCM web server token'
              value={fcmWebServerToken}
              onChange={setFcmWebServerToken}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web Api Key</Label>
            <TextInput
              placeholder='Please enter FCM web api key'
              value={apiKey}
              onChange={setApiKey}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web Auth Domain</Label>
            <TextInput
              placeholder='Please enter FCM web auth domain'
              value={authDomain}
              onChange={setAuthDomain}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web Project ID</Label>
            <TextInput
              placeholder='Please enter FCM web project id'
              value={projectId}
              onChange={setProjectId}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web Storage Bucket</Label>
            <TextInput
              placeholder='Please enter FCM web storage bucket'
              value={storageBucket}
              onChange={setStorageBucket}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web Messaging Sender ID</Label>
            <TextInput
              placeholder='Please enter FCM web messaging sender id'
              value={messagingSenderId}
              onChange={setMessagingSenderId}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web App ID</Label>
            <TextInput
              placeholder='Please enter FCM web app id'
              value={appId}
              onChange={setAppId}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web Measurement ID</Label>
            <TextInput
              placeholder='Please enter FCM web measurement id'
              value={measurementId}
              onChange={setMeasurementId}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Google FCM Web Vapid Key</Label>
            <TextInput
              placeholder='Please enter FCM web vapid key'
              value={vapidKey}
              onChange={setVapidKey}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(PushNotificationsConfiguration);
