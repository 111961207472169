import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  Legend,
} from '../../../components/FormElements';
import { StyledMicroButton } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import APIConfig from '../../../constants/api.config';

const StyledCallbackUrl = styled.p`
  font-size: 14px;
  color: red;
`;

const PaymentGatewayConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  // Create Order
  const [paymentGatewayApiEndpoint, setPaymentGatewayApiEndpoint] =
    useState('');
  const [paymentGatewayApiRequestMethod, setPaymentGatewayApiRequestMethod] =
    useState('');
  const [paymentGatewayApiRequestBody, setPaymentGatewayApiRequestBody] =
    useState('');
  const [paymentGatewayApiResponseType, setPaymentGatewayApiResponseType] =
    useState('');
  const [paymentGatewayApiStatusKey, setPaymentGatewayApiStatusKey] =
    useState('');
  const [paymentGatewayApiSuccessValue, setPaymentGatewayApiSuccessValue] =
    useState('');
  const [paymentGatewayApiFailureValue, setPaymentGatewayApiFailureValue] =
    useState('');
  const [paymentGatewayApiUrlKey, setPaymentGatewayApiUrlKey] = useState('');
  const [paymentGatewayApiPhonePeUrlKey, setPaymentGatewayApiPhonePeUrlKey] =
    useState('');
  const [paymentGatewayApiGpayUrlKey, setPaymentGatewayApiGpayUrlKey] =
    useState('');
  const [paymentGatewayApiPaytmUrlKey, setPaymentGatewayApiPaytmUrlKey] =
    useState('');
  const [paymentGatewayApiBharatpeUrlKey, setPaymentGatewayApiBharatpeUrlKey] =
    useState('');
  // Status Check
  const [
    paymentGatewayStatusCheckApiEndpoint,
    setPaymentGatewayStatusCheckApiEndpoint,
  ] = useState('');
  const [
    paymentGatewayStatusCheckApiRequestMethod,
    setPaymentGatewayStatusCheckApiRequestMethod,
  ] = useState('');
  const [
    paymentGatewayStatusCheckApiRequestBody,
    setPaymentGatewayStatusCheckApiRequestBody,
  ] = useState('');
  const [
    paymentGatewayStatusCheckApiResponseType,
    setPaymentGatewayStatusCheckApiResponseType,
  ] = useState('');
  const [
    paymentGatewayStatusCheckApiStatusKey,
    setPaymentGatewayStatusCheckApiStatusKey,
  ] = useState('');
  const [
    paymentGatewayStatusCheckApiSuccessValue,
    setPaymentGatewayStatusCheckApiSuccessValue,
  ] = useState('');
  const [
    paymentGatewayStatusCheckApiFailureValue,
    setPaymentGatewayStatusCheckApiFailureValue,
  ] = useState('');
  const [
    paymentGatewayStatusCheckApiUtrKey,
    setPaymentGatewayStatusCheckApiUtrKey,
  ] = useState('');
  const [
    paymentGatewayStatusCheckApiRemarkKey,
    setPaymentGatewayStatusCheckApiRemarkKey,
  ] = useState('');
  // Callback
  const [paymentGatewayIPAddress, setPaymentGatewayIPAddress] = useState('');
  const [
    paymentGatewayCallbackApiStatusKey,
    setPaymentGatewayCallbackApiStatusKey,
  ] = useState('');
  const [
    paymentGatewayCallbackApiSuccessValue,
    setPaymentGatewayCallbackApiSuccessValue,
  ] = useState('');
  const [
    paymentGatewayCallbackApiFailureValue,
    setPaymentGatewayCallbackApiFailureValue,
  ] = useState('');
  const [paymentGatewayCallbackApiUtrKey, setPaymentGatewayCallbackApiUtrKey] =
    useState('');
  const [
    paymentGatewayCallbackApiRemarkKey,
    setPaymentGatewayCallbackApiRemarkKey,
  ] = useState('');
  const [paymentGatewayCallbackApiId, setPaymentGatewayCallbackApiId] =
    useState('');
  const [
    paymentGatewayCallbackApiRequestMethod,
    setPaymentGatewayCallbackApiRequestMethod,
  ] = useState('GET');
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      // Create Order
      paymentGatewayApiEndpoint,
      paymentGatewayApiRequestMethod,
      paymentGatewayApiRequestBody,
      paymentGatewayApiResponseType,
      paymentGatewayApiStatusKey,
      paymentGatewayApiSuccessValue,
      paymentGatewayApiFailureValue,
      paymentGatewayApiUrlKey,
      paymentGatewayApiPhonePeUrlKey,
      paymentGatewayApiGpayUrlKey,
      paymentGatewayApiPaytmUrlKey,
      paymentGatewayApiBharatpeUrlKey,
      // Status Check
      paymentGatewayStatusCheckApiEndpoint,
      paymentGatewayStatusCheckApiRequestMethod,
      paymentGatewayStatusCheckApiRequestBody,
      paymentGatewayStatusCheckApiResponseType,
      paymentGatewayStatusCheckApiStatusKey,
      paymentGatewayStatusCheckApiSuccessValue,
      paymentGatewayStatusCheckApiFailureValue,
      paymentGatewayStatusCheckApiUtrKey,
      paymentGatewayStatusCheckApiRemarkKey,
      // Callback
      paymentGatewayIPAddress,
      paymentGatewayCallbackApiStatusKey,
      paymentGatewayCallbackApiSuccessValue,
      paymentGatewayCallbackApiFailureValue,
      paymentGatewayCallbackApiUtrKey,
      paymentGatewayCallbackApiRemarkKey,
      paymentGatewayCallbackApiId,
      paymentGatewayCallbackApiRequestMethod,
    } = originalFormData;
    // Create Order
    setPaymentGatewayApiEndpoint(paymentGatewayApiEndpoint);
    setPaymentGatewayApiRequestMethod(paymentGatewayApiRequestMethod);
    setPaymentGatewayApiRequestBody(paymentGatewayApiRequestBody);
    setPaymentGatewayApiResponseType(paymentGatewayApiResponseType);
    setPaymentGatewayApiStatusKey(paymentGatewayApiStatusKey);
    setPaymentGatewayApiSuccessValue(paymentGatewayApiSuccessValue);
    setPaymentGatewayApiFailureValue(paymentGatewayApiFailureValue);
    setPaymentGatewayApiUrlKey(paymentGatewayApiUrlKey);
    setPaymentGatewayApiPhonePeUrlKey(paymentGatewayApiPhonePeUrlKey);
    setPaymentGatewayApiGpayUrlKey(paymentGatewayApiGpayUrlKey);
    setPaymentGatewayApiPaytmUrlKey(paymentGatewayApiPaytmUrlKey);
    setPaymentGatewayApiBharatpeUrlKey(paymentGatewayApiBharatpeUrlKey);
    // Status Check
    setPaymentGatewayStatusCheckApiEndpoint(
      paymentGatewayStatusCheckApiEndpoint
    );
    setPaymentGatewayStatusCheckApiRequestMethod(
      paymentGatewayStatusCheckApiRequestMethod
    );
    setPaymentGatewayStatusCheckApiRequestBody(
      paymentGatewayStatusCheckApiRequestBody
    );
    setPaymentGatewayStatusCheckApiResponseType(
      paymentGatewayStatusCheckApiResponseType
    );
    setPaymentGatewayStatusCheckApiStatusKey(
      paymentGatewayStatusCheckApiStatusKey
    );
    setPaymentGatewayStatusCheckApiSuccessValue(
      paymentGatewayStatusCheckApiSuccessValue
    );
    setPaymentGatewayStatusCheckApiFailureValue(
      paymentGatewayStatusCheckApiFailureValue
    );
    setPaymentGatewayStatusCheckApiUtrKey(paymentGatewayStatusCheckApiUtrKey);
    setPaymentGatewayStatusCheckApiRemarkKey(
      paymentGatewayStatusCheckApiRemarkKey
    );
    // Callback
    setPaymentGatewayIPAddress(paymentGatewayIPAddress);
    setPaymentGatewayCallbackApiStatusKey(paymentGatewayCallbackApiStatusKey);
    setPaymentGatewayCallbackApiSuccessValue(
      paymentGatewayCallbackApiSuccessValue
    );
    setPaymentGatewayCallbackApiFailureValue(
      paymentGatewayCallbackApiFailureValue
    );
    setPaymentGatewayCallbackApiUtrKey(paymentGatewayCallbackApiUtrKey);
    setPaymentGatewayCallbackApiRemarkKey(paymentGatewayCallbackApiRemarkKey);
    setPaymentGatewayCallbackApiId(paymentGatewayCallbackApiId);
    setPaymentGatewayCallbackApiRequestMethod(
      paymentGatewayCallbackApiRequestMethod
    );
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/payment-gateway-configuration`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            // Create Order
            paymentGatewayApiEndpoint,
            paymentGatewayApiRequestMethod,
            paymentGatewayApiRequestBody,
            paymentGatewayApiResponseType,
            paymentGatewayApiStatusKey,
            paymentGatewayApiSuccessValue,
            paymentGatewayApiFailureValue,
            paymentGatewayApiUrlKey,
            paymentGatewayApiPhonePeUrlKey,
            paymentGatewayApiGpayUrlKey,
            paymentGatewayApiPaytmUrlKey,
            paymentGatewayApiBharatpeUrlKey,
            // Status Check
            paymentGatewayStatusCheckApiEndpoint,
            paymentGatewayStatusCheckApiRequestMethod,
            paymentGatewayStatusCheckApiRequestBody,
            paymentGatewayStatusCheckApiResponseType,
            paymentGatewayStatusCheckApiStatusKey,
            paymentGatewayStatusCheckApiSuccessValue,
            paymentGatewayStatusCheckApiFailureValue,
            paymentGatewayStatusCheckApiUtrKey,
            paymentGatewayStatusCheckApiRemarkKey,
            // Callback
            paymentGatewayIPAddress,
            paymentGatewayCallbackApiStatusKey,
            paymentGatewayCallbackApiSuccessValue,
            paymentGatewayCallbackApiFailureValue,
            paymentGatewayCallbackApiUtrKey,
            paymentGatewayCallbackApiRemarkKey,
            paymentGatewayCallbackApiId,
            paymentGatewayCallbackApiRequestMethod,
          } = data;
          // Create Order
          setPaymentGatewayApiEndpoint(paymentGatewayApiEndpoint);
          setPaymentGatewayApiRequestMethod(paymentGatewayApiRequestMethod);
          setPaymentGatewayApiRequestBody(paymentGatewayApiRequestBody);
          setPaymentGatewayApiResponseType(paymentGatewayApiResponseType);
          setPaymentGatewayApiStatusKey(paymentGatewayApiStatusKey);
          setPaymentGatewayApiSuccessValue(paymentGatewayApiSuccessValue);
          setPaymentGatewayApiFailureValue(paymentGatewayApiFailureValue);
          setPaymentGatewayApiUrlKey(paymentGatewayApiUrlKey);
          setPaymentGatewayApiPhonePeUrlKey(paymentGatewayApiPhonePeUrlKey);
          setPaymentGatewayApiGpayUrlKey(paymentGatewayApiGpayUrlKey);
          setPaymentGatewayApiPaytmUrlKey(paymentGatewayApiPaytmUrlKey);
          setPaymentGatewayApiBharatpeUrlKey(paymentGatewayApiBharatpeUrlKey);
          // Status Check
          setPaymentGatewayStatusCheckApiEndpoint(
            paymentGatewayStatusCheckApiEndpoint
          );
          setPaymentGatewayStatusCheckApiRequestMethod(
            paymentGatewayStatusCheckApiRequestMethod
          );
          setPaymentGatewayStatusCheckApiRequestBody(
            paymentGatewayStatusCheckApiRequestBody
          );
          setPaymentGatewayStatusCheckApiResponseType(
            paymentGatewayStatusCheckApiResponseType
          );
          setPaymentGatewayStatusCheckApiStatusKey(
            paymentGatewayStatusCheckApiStatusKey
          );
          setPaymentGatewayStatusCheckApiSuccessValue(
            paymentGatewayStatusCheckApiSuccessValue
          );
          setPaymentGatewayStatusCheckApiFailureValue(
            paymentGatewayStatusCheckApiFailureValue
          );
          setPaymentGatewayStatusCheckApiUtrKey(
            paymentGatewayStatusCheckApiUtrKey
          );
          setPaymentGatewayStatusCheckApiRemarkKey(
            paymentGatewayStatusCheckApiRemarkKey
          );
          // Callback
          setPaymentGatewayIPAddress(paymentGatewayIPAddress);
          setPaymentGatewayCallbackApiStatusKey(
            paymentGatewayCallbackApiStatusKey
          );
          setPaymentGatewayCallbackApiSuccessValue(
            paymentGatewayCallbackApiSuccessValue
          );
          setPaymentGatewayCallbackApiFailureValue(
            paymentGatewayCallbackApiFailureValue
          );
          setPaymentGatewayCallbackApiUtrKey(paymentGatewayCallbackApiUtrKey);
          setPaymentGatewayCallbackApiRemarkKey(
            paymentGatewayCallbackApiRemarkKey
          );
          setPaymentGatewayCallbackApiId(paymentGatewayCallbackApiId);
          setPaymentGatewayCallbackApiRequestMethod(
            paymentGatewayCallbackApiRequestMethod
          );
          setOriginalFormData({
            // Create Order
            paymentGatewayApiEndpoint,
            paymentGatewayApiRequestMethod,
            paymentGatewayApiRequestBody,
            paymentGatewayApiResponseType,
            paymentGatewayApiStatusKey,
            paymentGatewayApiSuccessValue,
            paymentGatewayApiFailureValue,
            paymentGatewayApiUrlKey,
            paymentGatewayApiPhonePeUrlKey,
            paymentGatewayApiGpayUrlKey,
            paymentGatewayApiPaytmUrlKey,
            paymentGatewayApiBharatpeUrlKey,
            // Status Check
            paymentGatewayStatusCheckApiEndpoint,
            paymentGatewayStatusCheckApiRequestMethod,
            paymentGatewayStatusCheckApiRequestBody,
            paymentGatewayStatusCheckApiResponseType,
            paymentGatewayStatusCheckApiStatusKey,
            paymentGatewayStatusCheckApiSuccessValue,
            paymentGatewayStatusCheckApiFailureValue,
            paymentGatewayStatusCheckApiUtrKey,
            paymentGatewayStatusCheckApiRemarkKey,
            // Callback
            paymentGatewayIPAddress,
            paymentGatewayCallbackApiStatusKey,
            paymentGatewayCallbackApiSuccessValue,
            paymentGatewayCallbackApiFailureValue,
            paymentGatewayCallbackApiUtrKey,
            paymentGatewayCallbackApiRemarkKey,
            paymentGatewayCallbackApiId,
            paymentGatewayCallbackApiRequestMethod,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      // Create Order
      paymentGatewayApiEndpoint,
      paymentGatewayApiRequestMethod,
      paymentGatewayApiRequestBody,
      paymentGatewayApiResponseType,
      paymentGatewayApiStatusKey,
      paymentGatewayApiSuccessValue,
      paymentGatewayApiFailureValue,
      paymentGatewayApiUrlKey,
      paymentGatewayApiPhonePeUrlKey,
      paymentGatewayApiGpayUrlKey,
      paymentGatewayApiPaytmUrlKey,
      paymentGatewayApiBharatpeUrlKey,
      // Status Check
      paymentGatewayStatusCheckApiEndpoint,
      paymentGatewayStatusCheckApiRequestMethod,
      paymentGatewayStatusCheckApiRequestBody,
      paymentGatewayStatusCheckApiResponseType,
      paymentGatewayStatusCheckApiStatusKey,
      paymentGatewayStatusCheckApiSuccessValue,
      paymentGatewayStatusCheckApiFailureValue,
      paymentGatewayStatusCheckApiUtrKey,
      paymentGatewayStatusCheckApiRemarkKey,
      // Callback
      paymentGatewayIPAddress,
      paymentGatewayCallbackApiStatusKey,
      paymentGatewayCallbackApiSuccessValue,
      paymentGatewayCallbackApiFailureValue,
      paymentGatewayCallbackApiUtrKey,
      paymentGatewayCallbackApiRemarkKey,
      paymentGatewayCallbackApiId,
      paymentGatewayCallbackApiRequestMethod,
    };
    API.put(`/configurations/payment-gateway-configuration`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>API Endpoint</Label>
            <TextInput
              value={paymentGatewayApiEndpoint}
              onChange={setPaymentGatewayApiEndpoint}
              placeholder='Please enter api endpoint'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label required>API Request Body</Label>
            <TextInput
              value={paymentGatewayApiRequestBody}
              onChange={setPaymentGatewayApiRequestBody}
              placeholder='Please enter api request body'
              disabled={isLoading === true}
            />
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() =>
                addText(setPaymentGatewayApiRequestBody, '{{{ORDERS_ID}}}')
              }
            >
              Orders ID
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() =>
                addText(setPaymentGatewayApiRequestBody, '{{{AMOUNT}}}')
              }
            >
              Amount
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() =>
                addText(setPaymentGatewayApiRequestBody, '{{{MOBILE_NUMBER}}}')
              }
            >
              Mobile Number
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() =>
                addText(setPaymentGatewayApiRequestBody, '{{{FIRST_NAME}}}')
              }
            >
              Name
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() =>
                addText(setPaymentGatewayApiRequestBody, '{{{USERS_ID}}}')
              }
            >
              Users Id
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() =>
                addText(setPaymentGatewayApiRequestBody, '{{{REDIRECT_URL}}}')
              }
            >
              Redirect Url
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() =>
                addText(setPaymentGatewayApiRequestBody, '{{{CALLBACK_URL}}}')
              }
            >
              Callback Url
            </StyledMicroButton>
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>API Request Method</Label>
                <Dropdown
                  value={paymentGatewayApiRequestMethod}
                  onChange={setPaymentGatewayApiRequestMethod}
                  placeholder='Please select api request method'
                  disabled={isLoading === true}
                  options={[
                    {
                      title: '-- SELECT API REQUEST METHOD --',
                      value: '',
                    },
                    {
                      title: 'POST',
                      value: 'POST',
                    },
                    {
                      title: 'GET',
                      value: 'GET',
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>API Response Type</Label>
                <Dropdown
                  value={paymentGatewayApiResponseType}
                  onChange={setPaymentGatewayApiResponseType}
                  placeholder='Please select api response type'
                  disabled={isLoading === true}
                  options={[
                    {
                      title: '-- SELECT API RESPONSE TYPE --',
                      value: '',
                    },
                    {
                      title: 'JSON',
                      value: 'JSON',
                    },
                    {
                      title: 'XML',
                      value: 'XML',
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Status Key</Label>
                <TextInput
                  value={paymentGatewayApiStatusKey}
                  onChange={setPaymentGatewayApiStatusKey}
                  placeholder='Please enter status key'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Success Value</Label>
                <TextInput
                  value={paymentGatewayApiSuccessValue}
                  onChange={setPaymentGatewayApiSuccessValue}
                  placeholder='Please enter success value'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Failure Value</Label>
                <TextInput
                  value={paymentGatewayApiFailureValue}
                  onChange={setPaymentGatewayApiFailureValue}
                  placeholder='Please enter failure value'
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>URL Key</Label>
            <TextInput
              value={paymentGatewayApiUrlKey}
              onChange={setPaymentGatewayApiUrlKey}
              placeholder='Please enter url key'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>PhonePe URL Key</Label>
            <TextInput
              value={paymentGatewayApiPhonePeUrlKey}
              onChange={setPaymentGatewayApiPhonePeUrlKey}
              placeholder='Please enter phonepe url key'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Gpay URL Key</Label>
            <TextInput
              value={paymentGatewayApiGpayUrlKey}
              onChange={setPaymentGatewayApiGpayUrlKey}
              placeholder='Please enter gpay url key'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>PayTm URL Key</Label>
            <TextInput
              value={paymentGatewayApiPaytmUrlKey}
              onChange={setPaymentGatewayApiPaytmUrlKey}
              placeholder='Please enter paytm url key'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>BharatPe Key</Label>
            <TextInput
              value={paymentGatewayApiBharatpeUrlKey}
              onChange={setPaymentGatewayApiBharatpeUrlKey}
              placeholder='Please enter bharatpe url key'
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset forLegend>
            <Legend>Check Status API Configuration</Legend>
            <Fieldset>
              <Label required>API Endpoint</Label>
              <TextInput
                value={paymentGatewayStatusCheckApiEndpoint}
                onChange={setPaymentGatewayStatusCheckApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label required>API Request Body</Label>
              <TextInput
                value={paymentGatewayStatusCheckApiRequestBody}
                onChange={setPaymentGatewayStatusCheckApiRequestBody}
                placeholder='Please enter api request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(
                    setPaymentGatewayStatusCheckApiRequestBody,
                    '{{{ORDERS_ID}}}'
                  )
                }
              >
                Orders ID
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(
                    setPaymentGatewayStatusCheckApiRequestBody,
                    '{{{ORDERS_DATE}}}'
                  )
                }
              >
                Order Date
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label required>API Request Method</Label>
                  <Dropdown
                    value={paymentGatewayStatusCheckApiRequestMethod}
                    onChange={setPaymentGatewayStatusCheckApiRequestMethod}
                    placeholder='Please select api request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>API Response Type</Label>
                  <Dropdown
                    value={paymentGatewayStatusCheckApiResponseType}
                    onChange={setPaymentGatewayStatusCheckApiResponseType}
                    placeholder='Please select api response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label required>Status Key</Label>
                  <TextInput
                    value={paymentGatewayStatusCheckApiStatusKey}
                    onChange={setPaymentGatewayStatusCheckApiStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Success Value</Label>
                  <TextInput
                    value={paymentGatewayStatusCheckApiSuccessValue}
                    onChange={setPaymentGatewayStatusCheckApiSuccessValue}
                    placeholder='Please enter success value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Failure Value</Label>
                  <TextInput
                    value={paymentGatewayStatusCheckApiFailureValue}
                    onChange={setPaymentGatewayStatusCheckApiFailureValue}
                    placeholder='Please enter failure value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label required>UTR Key</Label>
                  <TextInput
                    value={paymentGatewayStatusCheckApiUtrKey}
                    onChange={setPaymentGatewayStatusCheckApiUtrKey}
                    placeholder='Please enter utr key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Remark Key</Label>
                  <TextInput
                    value={paymentGatewayStatusCheckApiRemarkKey}
                    onChange={setPaymentGatewayStatusCheckApiRemarkKey}
                    placeholder='Please enter remark key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          <Fieldset forLegend>
            <Legend>Callback Configuration</Legend>
            <Fieldset>
              <StyledCallbackUrl>
                <b>Your Payment Gateway Callback URL : </b>
                {APIConfig.API_BASE_URL}
                /accounting/payment-gateway-deposits/callback
              </StyledCallbackUrl>
            </Fieldset>
            <Fieldset>
              <Label required>Payment Gateway IP Address</Label>
              <TextInput
                value={paymentGatewayIPAddress}
                onChange={setPaymentGatewayIPAddress}
                placeholder='Please enter payment gateway ip address or 0.0.0.0 to allow from anywhere'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label required>API Request Method</Label>
              <Dropdown
                value={paymentGatewayCallbackApiRequestMethod}
                onChange={setPaymentGatewayCallbackApiRequestMethod}
                placeholder='Please select callback request method'
                disabled={isLoading === true}
                options={[
                  {
                    title: '-- SELECT CALLBACK REQUEST METHOD --',
                    value: '',
                  },
                  {
                    title: 'POST',
                    value: 'POST',
                  },
                  {
                    title: 'GET',
                    value: 'GET',
                  },
                ]}
              />
            </Fieldset>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label required>Status Key</Label>
                  <TextInput
                    value={paymentGatewayCallbackApiStatusKey}
                    onChange={setPaymentGatewayCallbackApiStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Success Value</Label>
                  <TextInput
                    value={paymentGatewayCallbackApiSuccessValue}
                    onChange={setPaymentGatewayCallbackApiSuccessValue}
                    placeholder='Please enter success value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Failure Value</Label>
                  <TextInput
                    value={paymentGatewayCallbackApiFailureValue}
                    onChange={setPaymentGatewayCallbackApiFailureValue}
                    placeholder='Please enter failure value'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label required>Order Id Key</Label>
                  <TextInput
                    value={paymentGatewayCallbackApiId}
                    onChange={setPaymentGatewayCallbackApiId}
                    placeholder='Please enter order id key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>UTR Key</Label>
                  <TextInput
                    value={paymentGatewayCallbackApiUtrKey}
                    onChange={setPaymentGatewayCallbackApiUtrKey}
                    placeholder='Please enter utr key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Remark Key</Label>
                  <TextInput
                    value={paymentGatewayCallbackApiRemarkKey}
                    onChange={setPaymentGatewayCallbackApiRemarkKey}
                    placeholder='Please enter remark key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(PaymentGatewayConfiguration);
